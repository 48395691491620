import React from 'react';

import {Close as CloseIcon} from '@mui/icons-material';
import {
    Alert,
    Button,
    IconButton,
    Slide,
    Snackbar as MuiSnackbar,
    useMediaQuery,
    useTheme
} from '@mui/material';

import {useTranslation} from 'hooks';
import {useSnackbarStore} from 'store';

// animation function

const TransitionSlideUp = (props) => {
    return <Slide {...props} direction="up"/>;
};

const TransitionSlideDown = (props) => {
    return <Slide {...props} direction="down"/>;
};

// animation options
const transitions = {
    SlideUp: TransitionSlideUp,
    SlideDown: TransitionSlideDown,
};

const Snackbar = () => {
    const open = useSnackbarStore(state => state.open);
    const hide = useSnackbarStore(state => state.hide);
    const alertSeverity = useSnackbarStore(state => state.alertSeverity);
    const message = useSnackbarStore(state => state.message);
    const variant = useSnackbarStore(state => state.variant);
    const closeButton = useSnackbarStore(state => state.closeButton);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

    const translate = useTranslation();

    const handleClose = (_event, _reason) => {
        hide();
    };

    // On mobile animate SlideUp otherwise SlideDown
    const TransitionComponent = isMobile ? transitions.SlideUp : transitions.SlideDown;

    // On mobile show Snackbar at the bottom otherwiese at the top
    const anchorOrigin = isMobile ? {vertical: 'bottom', horizontal: 'center'} : {vertical: 'top', horizontal: 'center'};

    // On warnings or errors show Snackbar 3 sec. otherwise 1 sec.
    const autoHideDuration = alertSeverity === 'warning' || alertSeverity === 'error' ? 3000 : 1000;

    return <>
        {/* default snackbar */}
        {variant === 'default' && (
            <MuiSnackbar
                TransitionComponent={TransitionComponent}
                anchorOrigin={anchorOrigin}
                open={open}
                autoHideDuration={autoHideDuration}
                onClose={handleClose}
                message={translate(message)}
                action={
                    <React.Fragment>
                        <Button color="secondary" size="small" onClick={handleClose}>
                            UNDO
                        </Button>
                        {closeButton !== false &&
                            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                                <CloseIcon fontSize="small"/>
                            </IconButton>}
                    </React.Fragment>
                }
            />
        )}

        {/* alert snackbar */}
        {variant === 'alert' && (
            <MuiSnackbar
                TransitionComponent={TransitionComponent}
                anchorOrigin={anchorOrigin}
                open={open}
                autoHideDuration={autoHideDuration}
                ClickAwayListenerProps={{
                    mouseEvent: 'onMouseDown',
                    touchEvent: 'onTouchStart'
                }}
                onClose={handleClose}
            >
                <Alert
                    variant="filled"
                    severity={alertSeverity}
                    sx={{
                        bgcolor: alertSeverity + '.dark',
                        color: 'white.main'
                    }}
                    action={
                        <React.Fragment>
                            {closeButton !== false &&
                                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                                    <CloseIcon fontSize="small"/>
                                </IconButton>}
                        </React.Fragment>
                    }
                >
                    {translate(message)}
                </Alert>
            </MuiSnackbar>
        )}
    </>;
};

export default Snackbar;
