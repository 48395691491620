import React from 'react';

import {Grid} from '@mui/material';
import {styled} from '@mui/material/styles';

import SwiperCore from 'swiper';
import {Autoplay, Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

import Banner from './Banner';

import 'swiper/css/bundle';

const PREFIX = 'Banners';

const classes = {
    containerHolderTop: `${PREFIX}-containerHolderTop`,
    containerHolderMiddle: `${PREFIX}-containerHolderMiddle`,
    containerHolderBottom: `${PREFIX}-containerHolderBottom`,
    swiperContainer: `${PREFIX}-swiperContainer`,
    swiperSlide: `${PREFIX}-swiperSlide`,
};

const StyledGrid = styled(Grid)(({theme: _theme}) => ({
    backgroundColor: _theme.palette.black.main,
    [`& .${classes.containerHolderTop}`]: {
        width: '100%',
        height: '100%',

        '& .swiper-pagination': {
            bottom: 10,
            left: 10,
            display: 'flex',
            justifyContent: 'flex-start',
        },

        '& .swiper-pagination-bullet': {
            width: 25,
            height: 25,
            borderRadius: 0,
            backgroundColor: _theme.palette.white.main,
            border: '2px solid',
            borderColor: _theme.palette.orange.main,
            opacity: 1,
            margin: '0 5px 0 0',
        },

        '& .swiper-pagination-bullet-active': {
            backgroundColor: _theme.palette.orange.main,
            opacity: 1,
        },
    },

    [`& .${classes.containerHolderMiddle}`]: {
        width: '100%',
        height: '630px',

        '& .swiper-pagination': {
            bottom: 165,
            left: 25,
            display: 'flex',
            justifyContent: 'flex-start',
        },

        '& .swiper-pagination-bullet': {
            width: 25,
            height: 25,
            borderRadius: 0,
            backgroundColor: _theme.palette.white.main,
            opacity: 0.5,
        },

        '& .swiper-pagination-bullet-active': {
            backgroundColor: _theme.palette.orange.main,
            opacity: 1,
        },
    },

    [`& .${classes.containerHolderBottom}`]: {
        width: '100%',
        height: '630px',

        '& .swiper-pagination': {
            bottom: 25,
            left: 25,
            display: 'flex',
            justifyContent: 'flex-start',
        },

        '& .swiper-pagination-bullet': {
            width: 25,
            height: 25,
            borderRadius: 0,
            backgroundColor: _theme.palette.white.main,
            opacity: 0.5,
        },

        '& .swiper-pagination-bullet-active': {
            backgroundColor: _theme.palette.orange.main,
            opacity: 1,
        },
    },

    [`& .${classes.swiperContainer}`]: {
        height: '100%',
        backgroundColor: _theme.palette.black.main,
    },

    [`& .${classes.swiperSlide}`]: {
        height: '100%',
    },
}));

SwiperCore.use([Pagination, Autoplay]);

const autoplayDelay = 0.30; // 30 sekunden

const getBanners = (banners) => {
    return banners.sort((a, b) => a.order - b.order);
};

const FullBanners = ({banners}) => {
    
    return (
        <StyledGrid container direction="column" alignItems={'stretch'} sx={{height: '100%'}}>
            <Grid item className={classes.containerHolderTop}>
                <Swiper
                    pagination={{clickable: true}}
                    slidesPerView={1}
                    className={classes.swiperContainer}
                    autoplay={{delay: autoplayDelay * 60 * 1000, disableOnInteraction: false}}
                >
                    {getBanners(banners, 'full').map((b, idx) => (
                        <SwiperSlide className={classes.swiperSlide} key={idx}>
                            <Banner banner={b} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Grid>
        </StyledGrid>
    );
};

export default FullBanners;
