import {useEffect,useState} from 'react';

export const hasWindow = typeof window !== 'undefined';

const getWindowDimensions = () => {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {width, height};
};

const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    // const roomId = useLockerRoomStore((store) => store.roomId);
    // const user = useUserStore((store) => store.user);

    function debounce(fn, ms) {
        let timer;

        return () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                fn.apply(this, arguments);
            }, ms);
        };
    }

    useEffect(() => {
        if (hasWindow) {
            const debouncedHandleResize = debounce(function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }, 50);

            const handleVisibilityChange = () => {
            };

            window.addEventListener('resize', debouncedHandleResize);
            window.addEventListener('visibilitychange', handleVisibilityChange);

            return () => {
                window.removeEventListener('resize', debouncedHandleResize);
                window.removeEventListener('visibilitychange', handleVisibilityChange);
            
            };
        }
    }, []);

    return windowDimensions;
};

export default useWindowDimensions;
