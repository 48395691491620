import {useCallback, useRef, useState} from 'react';

import axios from 'axios';

import useAbortSignal from './useAbortSignal';
import {buildErrorState, buildSuccessState, formatUrl} from './utils';

export const isCancel = (res) => res?.abortSignal?.aborted === true || res?.status === 0;
export const isOk = (res) => res !== undefined && !res.hasError;

const responseEffect = {};

export const registerResponseEffect = (statusCode, func) => {
    responseEffect[statusCode] = func;
};

export const registerDefaultHeader = (header, value) => {
    axios.defaults.headers.common[header] = value;
};

export const removeDefaultHeader = (header) => {
    delete axios.defaults.headers.common[header];
};

class FetcherError extends Error {
    name = 'FetcherError';
}

export const useFetcher = (url, args = {}, defaultData = undefined, throwOnError = false) => {
    const {newAbortSignal} = useAbortSignal();

    const defaultState = {
        error: {},
        data: defaultData,
        headers: {},
        status: 0,
        hasError: undefined,
    };
    const [state, setState] = useState(defaultState);

    const loadingRef = useRef(false);
    const abortSignalRef = useRef(undefined);
    const argsStr = JSON.stringify(args);

    const setData = useCallback((data) => {
        setState((oldState) => ({...oldState, data}));
    }, []);

    const fetch = useCallback(async (args2 = undefined) => {
        if (loadingRef.current) {
            console.log('isloading', url, args);
            return;
        }

        loadingRef.current = true;

        const abortSignal = newAbortSignal();
        abortSignalRef.current = abortSignal;

        let newState;

        try {
            const interimState = {error: {}, status: 0, hasError: undefined};
            let finalUrl = url;

            if (typeof args2 === 'object') {
                if (typeof args2.url === 'string') {
                    finalUrl = args2.url;
                    delete args2.url;
                }

                if (typeof args2.urlParams === 'object') {
                    finalUrl = formatUrl(finalUrl, args2.urlParams);
                    delete args2.urlParams;
                }
            }

            setState((oldState) => {
                let newData = oldState.data;

                if (args2?.data) {
                    newData = {...newData, ...args2.data};
                }

                return {...oldState, ...interimState, data: newData};
            });

            const res = await axios({
                url: finalUrl,
                signal: abortSignal,
                ...args,
                ...args2,
            });

            loadingRef.current = false;

            newState = buildSuccessState(res);
            setState((oldState) => ({...oldState, ...newState}));

            if (typeof responseEffect[newState.status] === 'function') {
                responseEffect[newState.status](newState);
            }

        } catch (e) {
            loadingRef.current = false;

            if (abortSignal.aborted) {
                console.log(`aborted ${url}`);
                return {
                    ...defaultState,
                    abortSignal: abortSignalRef.current
                };
            }

            newState = buildErrorState(e);
            setState((oldState) => ({...oldState, ...newState}));

            if (typeof responseEffect[newState.status] === 'function') {
                responseEffect[newState.status](newState);
            }

            if (throwOnError) {
                throw new FetcherError(newState.error.message, {cause: newState});
            }
        }

        return newState;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, argsStr]);

    return {...state, loading: loadingRef.current, fetch, abortSignal: abortSignalRef.current, setData};
};

export default useFetcher;
