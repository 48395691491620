import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';

import {FormControl, FormHelperText, TextField} from '@mui/material';
import {MobileDatePicker} from '@mui/x-date-pickers';

import {useTranslation} from 'hooks';
import {dateFormat} from 'lib/datefns';

import {DateTime} from 'luxon';

const DISPLAY_FORMAT = 'dd.LL.yyyy';
const VALUE_FORMAT = 'yyyy-LL-dd';

const DateField = ({name, defaultValue, label, helperText, fullWidth, ...rest}) => {
    const {control} = useFormContext();
    const translate = useTranslation();

    return (<Controller
        control={control}
        name={name}
        shouldUnregister={true}
        defaultValue={defaultValue}
        render={({field: {onChange, onBlur, name, value}, fieldState: {error}}) => {
            value = DateTime.fromISO(value);
            return (<FormControl fullWidth={fullWidth} error={!!error?.message}>
                <MobileDatePicker
                    name={name}
                    label={translate(label)}
                    inputFormat={DISPLAY_FORMAT}
                    value={value}
                    onBlur={onBlur}
                    onChange={v => {
                        if (typeof v === 'object' && v !== null) {
                            v = dateFormat(v, VALUE_FORMAT);
                        }

                        onChange(v);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    {...rest}
                />
                <FormHelperText>{error?.message || translate(helperText)}</FormHelperText>
            </FormControl>);
        }}
    />);
};

DateField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValue: PropTypes.any,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
};

export default DateField;
