import React from 'react';
import {useSwipeable} from 'react-swipeable';

import {Check as CheckIcon} from '@mui/icons-material';
import {
    Avatar,
    Badge,
    Collapse,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';

import {Puller} from 'components';
import {imageUrl} from 'lib/media';

const styles = {
    listItem: {
        width: '100%',
        borderBottom: '1px solid',
        borderBottomColor: 'grey.darkest2',

        '&:last-of-type': {
            borderBottom: 0,
        }
    },
    listItemActive: {
        background: 'linear-gradient(90deg, rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, 1) 50%, rgba(17, 17, 17, 0) 100%)',
        borderBottom: '1px solid',
        borderBottomColor: 'grey.darkest2',
    },
    listContent: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px 0',
    },
    itemTextCollapse: {
        display: 'flex',
        flexGrow: 1,
        overflow: 'hidden',
        '&. MuiCollapse-wrapperInner': {
            display: 'flex',
            flexGrow: 1,
        },
    },
    itemAvatarBox: {
        backgroundColor: 'white.main',
        borderRadius: '50%',
        height: 56,
        width: 56,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '20px'
    },
    itemAvatar: {
        height: 56,
        width: 56,
    },
    badgeIcon: {
        backgroundColor: 'green.dark',
        borderRadius: '50%',
        fontWeight: '700',
        width: '24px',
        height: '24px',
        padding: '3px',
    },
    badge: {
        '& .MuiBadge-badge': {
            bottom: '23%'
        }
    },
    moreText: {
        width: 19,
        marginRight: 1,
        textAlign: 'left',
        fontWeight: '700',
        fontSize: 16,
    }
};

const MenuItem = ({
    onClick,
    avatarText,
    image,
    listItemTextPrimary,
    listItemTextSecondary = '',
    showBadge = false,
    moreText = '',
    pullerFunction,
    collapseButtons = [],
    showMenu
}) => {

    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            if (pullerFunction) {
                pullerFunction(eventData);
            }
        },
        trackMouse: true,
    });

    return <ListItem sx={[styles.listItem, showMenu && styles.listItemActive]} {...handlers}>
        <ListItemButton sx={styles.listContent}>
            <Collapse
                sx={styles.itemTextCollapse}
                orientation="horizontal"
                collapsedSize={0}
                onClick={onClick}
            >
                <Stack direction="row" alignItems="center">
                    <ListItemAvatar sx={styles.itemAvatarBox}>
                        {showBadge ?
                            <Badge
                                sx={styles.badge}
                                badgeContent={<CheckIcon color="white" fontSize="small" sx={styles.badgeIcon}/>}
                                overlap="circular"
                                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                            >
                                <Avatar sx={styles.itemAvatar} alt={avatarText} src={imageUrl(image, {w: 56, h: 56})}>
                                    {avatarText.substring(0, 2)}
                                </Avatar>
                            </Badge> :
                            <Avatar sx={styles.itemAvatar} alt={avatarText} src={imageUrl(image, {w: 56, h: 56})}>
                                {avatarText.substring(0, 2)}
                            </Avatar>
                        }
                    </ListItemAvatar>
                    {moreText && <Typography sx={styles.moreText}>{moreText}</Typography>}
                    <ListItemText
                        primary={listItemTextPrimary}
                        secondary={listItemTextSecondary}
                    />
                </Stack>
            </Collapse>

            {collapseButtons.length > 0 && (<>
                <Puller onClick={pullerFunction}/>
                <Collapse in={showMenu} orientation="horizontal" collapsedSize={0}>
                    <Stack direction="row">
                        {collapseButtons}
                    </Stack>
                </Collapse>
            </>)}

        </ListItemButton>
    </ListItem>;
};

export default MenuItem;