const log = ((config, options) => (set, get, api) => config(args => {
    set(args);

    if (options?.debug) {
        const state = get();

        const name = options?.name ? options?.name : '';

        for (const prop in state) {
            if (typeof state[prop] !== 'function') {
                console.debug(`zustand[${name}][${prop}]:`, state[prop]);
            }
        }
    }
}, get, api));

export const middleware = ((fn, opts = {}) => log(fn, opts));
