import React from 'react';

import {
    Box,
    Stack,
    Typography
} from '@mui/material';

import {Text} from 'components';

const styles = {
    root: {
        padding: 2.5,
    },
    block: {
        marginBottom: 2.5,
    },
    blockTitle: {
        marginBottom: 0.5,
    },
    colorRow: {
        marginBottom: 0.5,
    },
    color: {
        marginRight: 1.5,
    }
};

const Information = ({client}) => {
    return (
        <Stack sx={styles.root}>
            <Stack sx={styles.block}>
                <Stack sx={styles.blockTitle}>
                    <Typography color="grey.mainlight"><Text>global.phone</Text></Typography>
                </Stack>
                <Stack>
                    {client.contact.phone ?
                        <Typography color="grey.mainlight2">{client.contact.phone}</Typography> :
                        <Typography color="grey.mainlight2"><Text>ui.placeholder.textfield</Text></Typography>
                    }
                </Stack>
            </Stack>

            <Stack sx={styles.block}>
                <Stack sx={styles.blockTitle}>
                    <Typography color="grey.mainlight"><Text>global.email</Text></Typography>
                </Stack>
                <Stack>
                    {client.contact.phone ?
                        <Typography color="grey.mainlight2">{client.contact.email}</Typography> :
                        <Typography color="grey.mainlight2"><Text>ui.placeholder.textfield</Text></Typography>
                    }
                </Stack>
            </Stack>

            <Stack sx={styles.block}>
                <Stack sx={styles.blockTitle}>
                    <Typography color="grey.mainlight"><Text>client.colors</Text></Typography>
                </Stack>
                <Stack>
                    <Stack>
                        <Stack direction="row" sx={styles.colorRow}>
                            {client.colors.value1 ? <Stack direction="row">
                                <Stack sx={styles.color}>
                                    <Box backgroundColor={client.colors.value1} width={20} height={20} borderRadius="50%" />
                                </Stack>
                                <Stack>
                                    <Typography color="grey.mainlight2"><Text>client.color.primary</Text></Typography>
                                </Stack>
                            </Stack> : <Typography color="grey.mainlight2"><Text>ui.placeholder.textfield</Text></Typography>
                            }
                        </Stack>
                        <Stack direction="row" sx={styles.colorRow}>
                            {client.colors.value1 ? <Stack direction="row">
                                <Stack sx={styles.color}>
                                    <Box backgroundColor={client.colors.value2} width={20} height={20} borderRadius="50%" />
                                </Stack>
                                <Stack>
                                    <Typography color="grey.mainlight2"><Text>client.color.secondary</Text></Typography>
                                </Stack>
                            </Stack> : <Typography color="grey.mainlight2"><Text>ui.placeholder.textfield</Text></Typography>
                            }
                        </Stack>
                    </Stack>
                </Stack>

            </Stack>

        </Stack>
    );
};

export default Information;