import * as React from "react";
const SvgSoccerLandscape = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Ebene_1", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:svg": "http://www.w3.org/2000/svg", "xmlns:rdf": "http://www.w3.org/1999/02/22-rdf-syntax-ns#", "xmlns:cc": "http://creativecommons.org/ns#", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", "xmlns:dc": "http://purl.org/dc/elements/1.1/", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 900 1345.9", width: 900, height: 1346, style: {
  enableBackground: "new 0 0 900 1345.9"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { id: "rect4", x: 2, y: 3.8, style: {
  fill: "#629313"
}, width: 896, height: 1336.7 }), /* @__PURE__ */ React.createElement("rect", { id: "rect6", x: 808.1, y: 4.1, style: {
  fill: "#6C9E1E"
}, width: 90, height: 1336.4 }), /* @__PURE__ */ React.createElement("rect", { id: "rect8", x: 628.8, y: 4.1, style: {
  fill: "#6C9E1E"
}, width: 90, height: 1336.4 }), /* @__PURE__ */ React.createElement("rect", { id: "rect10", x: 449.5, y: 4.1, style: {
  fill: "#6C9E1E"
}, width: 90, height: 1336.4 }), /* @__PURE__ */ React.createElement("rect", { id: "rect12", x: 270.2, y: 4.1, style: {
  fill: "#6C9E1E"
}, width: 90, height: 1336.4 }), /* @__PURE__ */ React.createElement("rect", { id: "rect14", x: 91, y: 4.1, style: {
  fill: "#6C9E1E"
}, width: 90, height: 1336.4 }), /* @__PURE__ */ React.createElement("rect", { id: "rect16", x: 4, y: 4.1, style: {
  fill: "#76AE2C"
}, width: 1.2, height: 1336.4 }), /* @__PURE__ */ React.createElement("g", { id: "g20", transform: "translate(152.4,251)" }, /* @__PURE__ */ React.createElement("path", { id: "path18", "inkscape:connector-curvature": 0, style: {
  fill: "#FFFFFF"
}, d: "M193.4,255.9c51.6,35.5,82.4,97.3,82.4,165.3 S245,550.9,193.4,586.4l-4-5.8c49.7-34.2,79.3-93.8,79.3-159.3S238.9,296.2,189.3,262L193.4,255.9z" })), /* @__PURE__ */ React.createElement("g", { id: "g24", transform: "translate(152.4,251)" }, /* @__PURE__ */ React.createElement("path", { id: "path22", "inkscape:connector-curvature": 0, style: {
  fill: "#FFFFFF"
}, d: "M744.9,623c-111.4,0-202-90.6-202-202 s90.6-201.9,202-201.9 M744.9,226.1C637.5,226.1,550,313.5,550,421s87.5,194.9,194.9,194.9" })), /* @__PURE__ */ React.createElement("g", { id: "g28", transform: "translate(152.4,251)" }, /* @__PURE__ */ React.createElement("path", { id: "path26", "inkscape:connector-curvature": 0, style: {
  fill: "#FFFFFF"
}, d: "M-111.3-247.2h7.2c0,25.7-20.9,46.7-46.7,46.7v-7.2 C-129-207.8-111.3-225.4-111.3-247.2z" })), /* @__PURE__ */ React.createElement("g", { id: "g32", transform: "translate(152.4,251)" }, /* @__PURE__ */ React.createElement("path", { id: "path30", "inkscape:connector-curvature": 0, style: {
  fill: "#FFFFFF"
}, d: "M-150.8,1042.8c25.7,0,46.7,20.9,46.7,46.7h-7.2 c0-21.8-17.8-39.6-39.6-39.6L-150.8,1042.8L-150.8,1042.8z" })), /* @__PURE__ */ React.createElement("g", { id: "g36", transform: "translate(152.4,251)" }, /* @__PURE__ */ React.createElement("polygon", { id: "polygon34", style: {
  fill: "#FFFFFF"
}, points: "-19,637.7 -151.4,637.7 -151.4,630.5 -26.1,630.5 -26.1,211.7  -151.4,211.7 -151.4,204.5 -19,204.5  " })), /* @__PURE__ */ React.createElement("g", { id: "g40", transform: "translate(152.4,251)" }, /* @__PURE__ */ React.createElement("polygon", { id: "polygon38", style: {
  fill: "#FFFFFF"
}, points: "194.8,847.6 -151.4,847.6 -151.4,840.4 187.7,840.4 187.7,1.7 -151.4,1.7  -151.4,-5.5 194.8,-5.5  " })), /* @__PURE__ */ React.createElement("path", { id: "path42", "inkscape:connector-curvature": 0, style: {
  fill: "#FFFFFF"
}, d: "M893.3,685c-7,0-12.7-5.8-12.7-12.7 c0-7,5.7-12.7,12.7-12.7V685z" }), /* @__PURE__ */ React.createElement("path", { id: "path44", "inkscape:connector-curvature": 0, style: {
  fill: "#FFFFFF"
}, d: "M225.3,672.3c0-7,5.7-12.7,12.7-12.7 s12.7,5.7,12.7,12.7S245,685,238,685C230.9,685,225.3,679.2,225.3,672.3z" }), /* @__PURE__ */ React.createElement("path", { id: "path46", "inkscape:connector-curvature": 0, style: {
  fill: "#FFFFFF"
}, d: "M900,1345.8V0.2h-7.1l0,0H0v1345.6h892.9L900,1345.8 L900,1345.8z M892.9,1336.9H7.2V7.4h885.7V1336.9z" })));
export default SvgSoccerLandscape;
