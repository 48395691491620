import React from 'react';

import {Avatar, Box, Typography} from '@mui/material';

import {useTranslation} from 'hooks';

const avatarCrop = '?w=120&h=120&m=crop';

const styles = {
    playerContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 0,
        with: '100%',
        flexGrow: 1,
    },
    name: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    // sub: {
    //     fontSize: '12px',
    // }
};

const ListItem = ({player, onClick}) => {
    //const team = player.team;

    const translate = useTranslation();

    //const positions = team?.positions?.map(p => translate('team.position.' + p)) || [];

    return <Box sx={styles.playerContainer} onClick={onClick}>
        <Avatar alt={player.username} src={player?.image?.url + avatarCrop}/>
        <Box display="flex" justifyContent="center" flexDirection="column" ml={{mobile: 2, desktop: 2}}>
            {player?.firstname || player?.lastname ? (<Typography sx={styles.name}>
                {player.firstname} {player.lastname}
            </Typography>) : (<Typography sx={styles.name}>
                {translate('board.player.player_anonymous')}
            </Typography>)}

            {/* {(team?.number || team?.positions?.length > 0) && (<Box display="flex" flexWrap="wrap">
                {team?.number && <Typography
                    sx={styles.sub}
                >{`${translate('team.player.back_number')}: ${team?.number}`}</Typography>}
                {team?.positions?.length > 0 && <Typography
                    sx={styles.sub}
                >, {positions.join(', ')}</Typography>}
            </Box>)} */}

        </Box>
    </Box>;
};

export default ListItem;
