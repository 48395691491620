import React from 'react';

import {styled} from '@mui/material/styles';

const PREFIX = 'ImageBanner';

const classes = {
    root: `${PREFIX}-root`
};

const Root = styled('img')(({theme: _theme}) => ({
    [`&.${classes.root}`]: {
        height: '100%',
        width: '100%',
        border: 'none',
        overflowY: 'hidden',
        overflowX: 'hidden',
    }
}));

const ImageBanner = ({onClick, banner}) => {
    return <Root onClick={onClick} src={banner.url} className={classes.root} alt={banner.title} title={banner.title}/>;
};

export default ImageBanner;
