import PropTypes from 'prop-types';

import {fabric} from 'fabric';

import {limitReached} from '../canvas-helper';

const Triangle = function ({canvas, stroke, width, fill = ''}) {

    let isDrawing = false;
    let rect = null;

    canvas.on('mouse:down', (o) => {
        if (limitReached(canvas)) {
            return;
        }

        // don't draw if an object is selected on the canvas
        if (canvas.getActiveObject()) {
            return;
        }

        isDrawing = true;
        const pointer = canvas.getPointer(o.e);
        rect = new fabric.Triangle({
            strokeWidth: width,
            fill: fill,
            stroke: stroke,
            left: pointer.x,
            top: pointer.y,
            hasBorders: false,
            hasControls: false,
        });
        canvas.add(rect);
    });

    canvas.on('mouse:move', (o) => {
        if (!isDrawing) {
            return;
        }

        let pointer = canvas.getPointer(o.e);
        rect.set({width: pointer.x - rect.left, height: pointer.y - rect.top});
        canvas.renderAll();
    });

    canvas.on('mouse:up', () => {
        isDrawing = false;

        if (rect) {
            canvas.remove(rect).add(rect);
            canvas.sendToBack(rect);
        }
    });

};

Triangle.propTypes = {
    canvas: PropTypes.object.isRequired
};

export default Triangle;
