import {useCallback} from 'react';

import {useQuery} from '@tanstack/react-query';

import axios from 'axios';
import * as yup from 'yup';

import {isOk, useFetcher} from 'fetcher';
import {getEnv} from 'lib/env';
import {useUserUtils} from 'module/user/useUser';

const CONTROL_URL = getEnv('CONTROL_URL');

const defaultData = {
    name: '',
    client_id: null,
    num_players: 11,
    lineup: '4-3-3',
    prefs: {
        direction: 'home',
        player: {
            color1: '',
            color2: '',
            image: false,
            size: 'M',
            type: 'player'
        }
    }
};

export const schema = yup.object({
    name: yup.string(),
    num_players: yup.number(),
    lineup: yup.string(),
    managers: yup.array().of(yup.string()),
    prefs: yup.object({
        direction: yup.string(),
        player: yup.object({
            color1: yup.string(),
            color2: yup.string(),
            image: yup.boolean(),
            size: yup.string(),
            type: yup.string()
        })
    })
});

export const positions = ['goal', 'defense', 'midfield', 'attack', 'no_position'];
export const roles = ['player', 'trainer', 'official'];

const teamSearch = async ({signal, clientId}) => {
    const res = await axios.get(CONTROL_URL + `user/client/${clientId}/team/`, {signal});

    return res.data;
};

export const useApiQuery = ({clientId}) => {
    const {isAuthenticated} = useUserUtils();

    return useQuery({
        queryKey: ['client-teams', clientId],
        queryFn: ({signal}) => teamSearch({signal, clientId}),
        placeholderData: [],
        enabled: isAuthenticated && !!clientId
    });
};

const teamRead = async ({signal, teamId}) => {
    const res = await axios.get(CONTROL_URL + `user/team/${teamId}`, {signal});

    return res.data;
};

export const useApiRead = ({teamId}) => {
    const {isAuthenticated} = useUserUtils();

    return useQuery({
        queryKey: ['user-team', teamId],
        queryFn: ({signal}) => teamRead({signal, teamId}),
        enabled: isAuthenticated && !!teamId,
        staleTime: 30 * 1000,
    });
};

export const useApiAdd = () => {
    const {loading, fetch, data, error} = useFetcher(CONTROL_URL + 'user/team/', {
        method: 'POST',
    }, defaultData, true);

    const add = useCallback(async (data) => {
        const res = await fetch({data});
        if (isOk(res) && typeof res.data === 'object') {
            return res.data;
        }
    }, [fetch]);

    return {add, loading, data, errors: error.errors};
};

export const useApiDelete = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'user/team/{id}', {
        method: 'DELETE',
    }, undefined, true);

    const del = useCallback(async (id) => {
        const res = await fetch({
            urlParams: {
                id
            },
        });

        if (isOk(res)) {
            return true;
        }
    }, [fetch]);

    return {del, loading};
};

export const useApiUpdate = (defaultData) => {
    const {loading, fetch, data, error} = useFetcher(CONTROL_URL + 'user/team/', {
        method: 'PATCH',
    }, defaultData, true);

    const update = useCallback(async (data) => {
        const res = await fetch({data});

        if (isOk(res) && typeof res.data === 'object') {
            return res.data;
        }
    }, [fetch]);

    return {update, loading, data, errors: error.errors};
};

export const useApiUpload = () => {
    const {loading, fetch, data, error} = useFetcher(CONTROL_URL + 'user/team/{id}/image-upload', {
        method: 'POST',
    }, undefined, true);

    const upload = useCallback(async (id, file) => {
        const formData = new FormData();
        formData.append('file', file);

        const res = await fetch({
            data: formData,
            urlParams: {
                id
            },
        });

        if (isOk(res) && typeof res.data === 'object') {
            return res.data;
        }
    }, [fetch]);

    return {upload, loading, data, errors: error.errors};
};
