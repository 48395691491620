import * as React from 'react';

import {Button, Typography} from '@mui/material';
import {keyframes} from '@mui/system';
import {Box} from '@mui/system';

import {Text} from 'components';

const pulseOrangeAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(241,167,1);
  }
  100% {
    box-shadow: 0 0 0 11px rgba(94, 178, 2, 0);
  }
`;

const styles = {
    toolbarButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        margin: 0,
        padding: 0,
    },
    toolbarButtonContainerOpen: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        margin: 0,
        padding: 0,
    },
    toolbarButton: {
        pointerEvents: 'visible !important',
        height: '37px',
        width: '37px',
        minWidth: '37px',
        borderRadius: '25px',
        fontSize: '19px',
        margin: 0,
        padding: 0,
        position: 'relative',
    },
    toolbarButtonOpen: {
        pointerEvents: 'visible !important',
        height: '37px',
        width: '37px',
        minWidth: '37px',
        borderRadius: '25px',
        fontSize: '19px',
        margin: 0,
        padding: 0,
        position: 'relative',
    },
    toolbarIconContainer: {
        padding:'0',
        position: 'relative',
        lineHeight: 0,
        height: '37px',
        width: '37px',
        backgroundColor: 'black.main',
        color: 'white.main',
        borderRadius: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    toolbarIconContainerOpen: {
        padding: 0,
        position: 'relative',
        lineHeight: 0,
        height: '37px',
        width: '37px',
        backgroundColor: 'black.main',
        color: 'white.main',
        borderRadius: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
   
    },
    pulse: {
        animation: `${pulseOrangeAnimation} 1s infinite linear`,
    },

    toolbarIconBadge: {
        position: 'absolute',
        top: '-6px',
        right: '-6px',
        width: '18px',
        height: '18px',
        borderRadius: '50%',
        zIndex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    toolbarIconBadgeText: {
        fontSize: '11px',
    },
    toolbarButtonSubline: {
        position: 'absolute',
        bottom: '6px',
        color: 'white.darkest',
        fontSize: '10px',
        marginTop: '6px',
        textTransform: 'none',
    },
    toolbarButtonSubline2: {
        position: 'absolute',
        bottom: '-10px',
        color: 'white.darker',
        fontSize: '11px',
        marginTop: 0,
        fontWeight: '600',
    },

};

const ControlButton = ({openState, onClickHandler, icon, iconTextLine1, iconTextLine2, styleBoxColor, pulse, badge, disabled=false, badgeBgColor, badgeTextColor}) => {

    return (
        <Box sx={openState ? styles.toolbarButtonContainerOpen : styles.toolbarButtonContainer}>
            {disabled ?
                (
                    <Box
                        sx={[openState ? styles.toolbarButtonOpen : styles.toolbarButton, pulse ? styles.pulse : null]}
                        onClick={onClickHandler}>
                        {badge && !openState && <Box sx={[styles.toolbarIconBadge, {backgroundColor: badgeBgColor}]}><Typography sx={[styles.toolbarIconBadgeText, {color: badgeTextColor}]}>{badge}</Typography></Box>}
                        <Box sx={[openState ? styles.toolbarIconContainerOpen : styles.toolbarIconContainer, styleBoxColor]}>
                            {icon}
                        </Box>
                    </Box>
                )
                :
                (
                    <Button
                        sx={[openState ? styles.toolbarButtonOpen : styles.toolbarButton, pulse ? styles.pulse : null]}
                        onClick={onClickHandler}>
                        {badge && !openState && <Box sx={[styles.toolbarIconBadge, {backgroundColor: badgeBgColor}]}><Typography sx={[styles.toolbarIconBadgeText, {color: badgeTextColor}]}>{badge}</Typography></Box> }
                        <Box sx={[openState ? styles.toolbarIconContainerOpen : styles.toolbarIconContainer, styleBoxColor]}>
                            {icon}
                        </Box>
                    </Button>
                )
            }

            {(iconTextLine1) && <Typography sx={styles.toolbarButtonSubline}><Text>{iconTextLine1}</Text></Typography>}
            {(iconTextLine2) && <Typography sx={[styles.toolbarButtonSubline2, {visibility: openState ? 'visible' :'hidden'}]}><Text>{iconTextLine2}</Text></Typography>}
        </Box>
    );
};

export default ControlButton;