import React from 'react';

import {
    Close as CloseIcon,
} from '@mui/icons-material';
import {Backdrop, Box,Button} from '@mui/material';

import {useCampaignStore} from 'store';

import Banners from './Banners';
import FullBanners from './FullBanners';

const styles = {
    backdrop: {
        zIndex: theme => theme.zIndex.drawer + 1
    },
    closeButton: {
        position: 'absolute',
        top: 10,
        right: 10,
        minWidth: 0,
        width: 40,
        height: 40,
        backgroundColor: 'black.main',
        color: 'white.main',
        boxShadow: 'none',
        border: '2px solid',
        borderColor: 'black.main',

        '&:hover': {
            backgroundColor: 'white.main',
            color: 'black.main',
            boxShadow: 'none',
            borderColor: 'black.main',
        }
    },
    closeButtonBg: {
        position: 'absolute',
        top: 0,
        right: 0,
        height: '60px',
        width: '80px',
        zIndex: theme => theme.zIndex.drawer + 2,
        cursor: 'pointer',
    }
};

const BannersDialog = ({open, handleClose}) => {
    
    const banners = useCampaignStore(state => state.banners);

    const full = banners.filter(b => b.position === 'full');

    return <Backdrop open={open} sx={styles.backdrop}>
        <Box sx={styles.closeButtonBg} onClick={handleClose}>
            <Button variant="contained" sx={styles.closeButton} size={'small'}>
                <CloseIcon size="2x"/>
            </Button>
        </Box>
        {full.length === 0 && <Banners banners={banners}/>}
        {full.length > 0 && <FullBanners banners={full}/>}

    </Backdrop>;
};

export default BannersDialog;
