import {create} from 'zustand';
import {persist} from 'zustand/middleware';

import {middleware} from 'store/zustand';

import {produce} from 'immer';

// loads info from electron
export const loadInfo = async () => {
    if (window?.infoApi?.info === undefined) {
        return Promise.resolve();
    }

    return window.infoApi?.info();
};

export const useInfoStore = create(persist(middleware(set => ({
    mode: undefined,
    setMode: mode => set(produce(draft => {
        draft.mode = mode;
    })),
    deviceId: null,
    title: null,
    logo: null,
    logo2: null,
    setInfo: info => set(produce(draft => {
        draft.deviceId = info?.deviceId || null;
        draft.title = info?.title || null;
        draft.logo = info?.logo || null;
        draft.logo2 = info?.logo2 || null;
    }))
})), 
// ...
{
    name: 'info', // unique name
    version: 1,
    migrate: (persistedState) => {
        return persistedState;
    }}
));
