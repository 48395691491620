import React, {useEffect, useState} from 'react';

import {
    Avatar, ButtonBase,
    Stack, Tab, Tabs,
    Typography,
} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';

import Icons from 'assets/icons';
import {
    Drawer, HiddenFileInput,
    MenuHeader,
    Text
} from 'components';
import {imageUrl} from 'lib/media';
import {useUserStore} from 'module/user/zustand';
import {useLayoutStore} from 'store';

import Data from './Data';
import Equipment from './Equipment';
import {useApiGet, useApiUpload} from './useUserApi';

const styles = {
    avatar: {
        width: 300,
        height: 300
    },
    clientHeadline: {
        fontSize: '18px',
        color: 'grey.darkest2'
    },
    container: {
        background: 'linear-gradient(to bottom, #1c1c1c, #111)',
        padding: '40px 20px'
    },
    uploadButton: {
        position: 'absolute',
        right: 25,
        bottom: 25,
        backgroundColor: 'red.highlight',
        borderRadius: '50%',
        width: 40,
        height: 40,
        padding: 1,
        '> svg': {
            fill: theme => theme.palette.white.main,
            width: 20,
            height: 20
        }
    }
};

const Profile = () => {
    const [tab, setTab] = useState('data');

    const setUser = useUserStore((state) => state.setUser);
    const user = useUserStore((state) => state.user);

    const closeMenu = useLayoutStore(state => state.closeMenu);
    const setShowLoading = useLayoutStore((state) => state.setShowLoading);
    const setShowDrawer = useLayoutStore((state) => state.setShowDrawer);
    const setShowProfile = useLayoutStore((state) => state.setShowProfile);

    const queryClient = useQueryClient();
    const query = useApiGet();
    const userData = query.data;

    const {upload, loading} = useApiUpload();

    const goBack = () => {
        setShowDrawer(true);
        setShowProfile(false);
    };

    const onSave = (newUser) => {
        newUser.jwt = user.jwt; // preserve JWT
        setUser(newUser);
    };

    const doUpload = async (e) => {
        if (e.target.files.length === 1) {
            const res = await upload(e.target.files[0]);
            e.target.value = null;
            onSave(res);
            query.refetch();
        }
    };

    useEffect(() => {
        setShowLoading(query.isFetching || loading);
        return () => {
            setShowLoading(false);
        };
    }, [query.isFetching, loading, setShowLoading]);

    useEffect(() => {
        return () => {
            queryClient.removeQueries({queryKey: ['user-get'], exact: true});
        };
    }, [queryClient]);

    console.log(userData);

    return (
        <Drawer open={true} onClose={closeMenu}>
            <MenuHeader text={userData?.username} handleBackButton={goBack} handleClose={closeMenu}/>

            <Stack direction="column" pt={2} alignItems="center">
                <Stack justifyContent="center" alignItems="center" mb={2} position="relative">
                    <Avatar sx={styles.avatar} src={imageUrl(userData?.image, {w: 300, h: 300})}>
                        {userData?.firstname[0] + userData?.lastname[0]}
                    </Avatar>
                    <ButtonBase component="label" sx={styles.uploadButton}>
                        <Icons.Camera/>
                        <HiddenFileInput name="file" onChange={doUpload}/>
                    </ButtonBase>
                </Stack>
                <Stack direction="row" alignItems="center" mb={1}>
                    <Typography sx={styles.clientHeadline}>{userData?.username}</Typography>
                </Stack>
                <Tabs value={tab} onChange={(_e, tab) => setTab(tab)}>
                    <Tab value="data" label={<Text>team.player.data</Text>}/>
                    <Tab value="equipment" label={<Text>user.equipment</Text>}/>
                </Tabs>
            </Stack>

            <Stack sx={styles.container} direction="column" justifyContent="space-between">
                {tab === 'data' && userData && <Data user={userData} onSave={onSave}/>}
                {tab === 'equipment' && <Equipment user={userData} onSave={onSave}/>}
            </Stack>

        </Drawer>
    );
};

export default Profile;