import React from 'react';

import {Stack, useMediaQuery, useTheme} from '@mui/material';

import Icons from 'assets/icons';
import {useTranslation} from 'hooks';
import {useBannersStore} from 'module/banner/zustand';
import {useInfoStore} from 'store';

const styles = {
    logoContainer: {
        width: '7.692307%',
        height: '60px'
    },
    logo: {
        objectFit: 'contain',
        width: 40,
        height: 40,
    },
    iconStyle: {
        width: 25,
        height: 25,
    }
};

const Logo = () => {
    const translate = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

    const logo = useInfoStore((store) => store.logo);
    const logo2 = useInfoStore((store) => store.logo2);
    const useLogo = !isMobile && (logo2 ? `${logo2}?h=80&m=thumb` : logo);

    const showBanners = useBannersStore((store) => store.show);

    if (!useLogo) {
        return null;
    }

    return <Stack justifyContent="center" alignItems="center" sx={styles.logoContainer} onClick={showBanners}>
        {useLogo && <img src={useLogo} alt={translate('board.menu.tactix')} style={styles.logo}/>}
        {!useLogo && <Icons.TactixX style={styles.iconStyle}/>}
    </Stack>;
};

export default Logo;
