import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';

import {Checkbox, FormControl, FormControlLabel, FormHelperText} from '@mui/material';

import {useTranslation} from 'hooks';
// import {makeStyles} from "@mui/styles";
//
// const useStyles = makeStyles((theme) => ({
//     helper: {
//         marginTop: 0,
//         marginLeft: 14,
//         marginRight: 14,
//         marginBottom: 10,
//         lineHeight: 1.3
//     }
// }));

const CheckBoxField = ({name, label, defaultValue, fullWidth, helperText, ...rest}) => {
    const {control} = useFormContext();
    const translate = useTranslation();

    const lbl = typeof label === 'string' ? translate(label) : label;

    return <Controller
        control={control}
        name={name}
        shouldUnregister={true}
        defaultValue={defaultValue}
        render={({field: {onChange, onBlur, value, name}, fieldState: {error}}) => {
            const checked = Boolean(value);
            return <FormControl fullWidth={fullWidth} error={!!error?.message}>
                <FormControlLabel
                    label={lbl}
                    control={<Checkbox
                        color="primary"
                        name={name}
                        checked={checked}
                        onBlur={onBlur}
                        onChange={(_e, v) => onChange(v)}
                        {...rest}/>}
                />
                <FormHelperText>{error?.message || translate(helperText)}</FormHelperText>
            </FormControl>;
        }}
    />;
};

CheckBoxField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    defaultValue: PropTypes.any,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
};

export default CheckBoxField;
