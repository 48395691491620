import * as React from "react";
const SvgHandball = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Ebene_1", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:svg": "http://www.w3.org/2000/svg", "xmlns:rdf": "http://www.w3.org/1999/02/22-rdf-syntax-ns#", "xmlns:cc": "http://creativecommons.org/ns#", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", "xmlns:dc": "http://purl.org/dc/elements/1.1/", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 900 1345.9", width: 900, height: 1346, style: {
  enableBackground: "new 0 0 900 1345.9"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 0.1, y: 0.1, style: {
  fill: "#2980B9"
}, width: 900, height: 1345.9 }), /* @__PURE__ */ React.createElement("rect", { x: 73.1, y: 85.8, style: {
  fill: "#3996D2"
}, width: 752.1, height: 1168 }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#F39B13"
}, d: "M741.7,1255.6L741.7,1255.6c0-133.1-116.9-241-261.2-241h-62.7c-144.2,0-261.2,107.9-261.2,241 H741.7z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 429.8, y: 976, style: {
  fill: "#FFFFFF"
}, width: 38.8, height: 7.4 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 437.9, y: 1082.8, style: {
  fill: "#FFFFFF"
}, width: 22.5, height: 7.4 })), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#F39B13"
}, d: "M741.7,86.7L741.7,86.7c0,133.1-116.9,241-261.2,241h-62.7c-144.2,0-261.2-107.9-261.2-241 L741.7,86.7z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 429.8, y: 358.8, style: {
  fill: "#FFFFFF"
}, width: 38.8, height: 7.4 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 437.9, y: 252, style: {
  fill: "#FFFFFF"
}, width: 22.5, height: 7.4 })), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M89.1,1124.8c-3.8,11.5-6.9,23.4-9.2,35.2l7.6,1.5c2.3-11.5,5.3-23.1,9-34.3L89.1,1124.8z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M166.1,1002.7c-8.7,8.5-17,17.6-24.6,26.9l6,4.9c7.4-9.1,15.5-18,24-26.3L166.1,1002.7z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M752.6,1034.6l6-4.9c-7.6-9.3-15.8-18.4-24.5-26.9l-5.4,5.5C737.2,1016.7,745.2,1025.5,752.6,1034.6 z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M120.3,1059.2c-6.5,10.3-12.4,21-17.6,31.9l7,3.3c5-10.6,10.8-21.1,17.1-31.1L120.3,1059.2z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M790.5,1094.5l7-3.3c-5.2-10.9-11.1-21.6-17.6-31.9l-6.5,4.1 C779.7,1073.5,785.4,1083.9,790.5,1094.5z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M289,926.3c-11.4,4.1-22.7,8.8-33.6,14l3.4,6.9c10.6-5.1,21.7-9.7,32.9-13.7L289,926.3z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M410.2,913.1h22.1v-8h-22.1c-4.7,0-9.5,0.3-14.2,0.5l0.3,7.8C400.9,913.2,405.6,913.1,410.2,913.1z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M489.8,913.1c4.8,0,9.6,0.2,14.3,0.3l0.3-7.9c-4.8-0.2-9.7-0.5-14.6-0.5h-22.1v8h22.1V913.1z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M812.6,1161.8l7.6-1.5c-2.3-11.9-5.4-23.7-9.2-35.3l-7.3,2.4 C807.3,1138.7,810.3,1150.2,812.6,1161.8z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M359.7,909c-12,1.7-24.1,4-35.8,6.8l1.8,7.5c11.5-2.8,23.2-5.1,35-6.7L359.7,909z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M223.4,957.9c-10.3,6.4-20.4,13.4-29.9,20.8l4.8,6.1c9.3-7.3,19.1-14.1,29.2-20.4L223.4,957.9z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M611.3,926.5l-2.6,7.3c11.2,4,22.2,8.6,32.9,13.8l3.4-6.9C634.1,935.3,622.8,930.6,611.3,926.5z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M540.7,909.1l-1.1,7.6c11.8,1.6,23.5,3.9,35,6.7l1.8-7.5C564.7,913.1,552.7,910.7,540.7,909.1z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M676.9,958.1l-4.1,6.5c10.1,6.3,19.9,13.1,29.2,20.4l4.8-6.1C697.2,971.5,687.2,964.5,676.9,958.1z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M540.7,434.8c12-1.7,24.1-4,35.8-6.9l-1.9-7.5c-11.5,2.8-23.2,5.1-35,6.7L540.7,434.8z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M258.8,396.5l-3.4,6.9c10.9,5.2,22.2,9.9,33.6,14l2.6-7.3C280.5,406.3,269.4,401.6,258.8,396.5z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M489.8,431.3h-22.1v6.4h22.1c4.8,0,9.8,0.2,14.6,0l-0.3-7.2C499.4,430.7,494.6,431.3,489.8,431.3z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M432.3,437.7v-6.4h-22.1c-4.6,0-9.3-0.4-13.9-0.6l-0.3,7.2c4.7,0.2,9.5-0.2,14.2-0.2H432.3z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M325.8,420.5L324,428c11.7,2.9,23.8,5.2,35.8,6.8l1.1-7.6C349,425.6,337.2,423.3,325.8,420.5z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M611.3,417.4c11.4-4.1,22.7-8.8,33.6-14.1l-3.4-6.9c-10.6,5.1-21.7,9.8-32.9,13.8L611.3,417.4z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M120.3,284.7l6.5-4.1c-6.3-10-12.1-20.5-17.1-31.1l-7,3.3C107.8,263.7,113.7,274.4,120.3,284.7z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M166.1,341.2l5.4-5.5c-8.5-8.3-16.6-17.1-24-26.3l-6,4.9C149.1,323.6,157.4,332.7,166.1,341.2z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M89.1,219l7.3-2.4c-3.7-11.2-6.7-22.7-9-34.3l-7.6,1.5C82.2,195.6,85.3,207.5,89.1,219z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M734.1,341c8.7-8.5,16.9-17.6,24.5-26.9l-6-4.9c-7.4,9.1-15.4,17.9-23.9,26.3L734.1,341z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M198.3,359.1l-4.8,6.1c9.5,7.4,19.6,14.4,29.9,20.8l4.1-6.6C217.4,373.2,207.6,366.3,198.3,359.1z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M676.9,385.8c10.3-6.4,20.4-13.4,29.8-20.8l-4.8-6.1c-9.3,7.2-19.1,14.1-29.2,20.4L676.9,385.8z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M790.5,249.3c-5,10.6-10.8,21.1-17.1,31.1l6.5,4.1c6.5-10.3,12.4-21,17.6-31.9L790.5,249.3z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M812.6,182c-2.2,11.6-5.3,23.1-8.9,34.3l7.3,2.4c3.8-11.5,6.9-23.4,9.2-35.3L812.6,182z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M380.9,83.4H70.7v1176h310.2v41.7h138.2v-41.7h311.8V83.4H519.1V41.7H380.9V83.4z M512.7,1261v33.7 H387.3V1261H512.7z M157.8,1251.4c2.9-129.9,117.2-232.5,257.2-232.5h62.7c140.1,0,254.3,102.6,257.2,232.5H157.8z M822.9,673.1 v521.1l-7.5,1.2c0.4,6.1-1.2,14.3-1.2,19.2h8.7v36.9h-80.2c-2.9-133.1-120.6-240.5-265-240.5H415c-144.3,0-262.1,107.4-265,240.5 H78.8v-36.9h-0.1c0-4.8,2-13.1,2.4-19.2l-2.3-1.1V673.1H822.9z M735,91.4c-2.3,128.3-116.8,232.5-257.2,232.5h-62.7 c-140.5,0-255-104.2-257.2-232.5C157.9,91.4,735,91.4,735,91.4z M150.1,91.4c2.3,133.1,120.2,240.5,265,240.5h62.7 c144.7,0,262.7-107.4,265-240.5h80.1v36.9h-8.7c0,6.4,1.6,11.2,1.2,17.3l7.5-0.7V665H78.8V144.7l2.3,0.8 c-0.4-6.1-2.4-10.9-2.4-17.3h0.1V91.4H150.1z M512.7,49.7v33.7H387.3V49.7H512.7z" })));
export default SvgHandball;
