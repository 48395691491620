import React from 'react';

import {
    Stack,
    Typography
} from '@mui/material';

import {
    Text
} from 'components';
import {useInfo} from 'module/client/team/member/useInfo';

const styles = {
    root: {
        padding: 2.5
    },
    block: {
        marginBottom: 2.5,
    },
};

const Information = ({member}) => {
    const memberInfo = useInfo();
    const {firstname, lastname, initials, roles} = memberInfo(member);

    return (
        <Stack sx={styles.root} direction="column">
            <Stack direction="column" sx={styles.block}>
                <Typography color="grey.mainlight"><Text>global.person.firstname</Text></Typography>
                <Typography color="white.main">{firstname}</Typography>
            </Stack>
            <Stack direction="column" sx={styles.block}>
                <Typography color="grey.mainlight"><Text>global.person.lastname</Text></Typography>
                <Typography color="white.main">{lastname}</Typography>
            </Stack>
            <Stack direction="column" sx={styles.block}>
                <Typography color="grey.mainlight"><Text>user.birthday</Text></Typography>
                <Typography color="white.main">{member.user?.birthday}</Typography>
            </Stack>
            <Stack direction="column" sx={styles.block}>
                <Typography color="grey.mainlight"><Text>user.initials</Text></Typography>
                <Typography color="white.main">{initials}</Typography>
            </Stack>
            <Stack direction="column" sx={styles.block}>
                <Typography color="grey.mainlight"><Text>client.role.roles</Text></Typography>
                <Typography color="white.main">{roles}</Typography>
            </Stack>
            <Stack direction="column" sx={styles.block}>
                <Typography color="grey.mainlight"><Text>user.body.height</Text></Typography>
                <Typography color="white.main">{member.user?.body?.height} cm</Typography>
            </Stack>
            <Stack direction="column" sx={styles.block}>
                <Typography color="grey.mainlight"><Text>user.body.weight</Text></Typography>
                <Typography color="white.main">{member.user?.body?.weight} kg</Typography>
            </Stack>
        </Stack>
    );
};

export default Information;