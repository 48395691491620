import * as React from 'react';

import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUp from '@mui/icons-material/VolumeUp';

const MicrophoneIcon = ({volume=0}) => {
    return (
        <>
            {volume === 0 &&
                <VolumeMuteIcon sx={{color: 'green.main', fontSize: 25}}/>
            }
            {volume === 1 && 
                <VolumeDown sx={{color: 'green.main', fontSize: 25}}/>
            }
            {volume === 2 && 
                <VolumeUp sx={{color: 'green.main', fontSize: 25}} />
            }
            {volume === -1 &&
                <VolumeOffIcon sx={{color: 'red.main', fontSize: 24}}/>
            }
        </>
    );
};

export default MicrophoneIcon;