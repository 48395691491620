import React, {useEffect, useState} from 'react';

import {
    Stack,
} from '@mui/material';

import {yupResolver} from '@hookform/resolvers/yup';

import {useLayoutStore, useSnackbarStore} from 'store';

import {schema, useApiUpdate} from './useUserApi';

import {DateField, Form, FormField, SubmitableField, useForm} from 'form';

const styles = {
    root: {
        background: theme => theme.palette.grey.darkest,
    },
};

const Data = ({user, onSave}) => {
    const [editable, setEditable] = useState('');

    const {update, loading, data, errors} = useApiUpdate(user);

    const setShowLoading = useLayoutStore((state) => state.setShowLoading);
    const showInfo = useSnackbarStore((state) => state.show);

    console.log(data);

    const rhf = useForm(data, errors, true, {resolver: yupResolver(schema)});

    const doSave = () => {
        rhf.methods.handleSubmit(async formData => {
            try {
                const res = await update(formData);
                setEditable(null);
                showInfo('user.action.save_success');
                if (res !== undefined) {
                    onSave(res);
                }
            } catch (e) {
                showInfo(e.message, {severity: 'warning'});
            }
        })();
    };

    useEffect(() => {
        setShowLoading(loading);
        return () => {
            setShowLoading(false);
        };
    }, [loading, setShowLoading]);

    return (
        <Stack sx={styles.root} direction="column" justifyContent="space-between">
            <Form methods={rhf.methods} onSubmit={doSave}>
                <Stack direction="column" justifyContent="flex-end" p={2}>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <FormField
                            name="firstname"
                            label="global.person.firstname"
                        />
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <FormField
                            name="lastname"
                            label="global.person.lastname"
                        />
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <DateField
                            name="birthday"
                            label="user.birthday"
                            disableFuture={true}
                            slotProps={{
                                actionBar: {
                                    actions: ['clear', 'accept', 'cancel'],
                                },
                            }}
                        />
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <FormField
                            name="number"
                            label="team.player.back_number"
                            type="number"
                        />
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <FormField
                            name="body.height"
                            label="user.body.height"
                            type="number"
                        />
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <FormField
                            name="body.weight"
                            label="user.body.weight"
                            type="number"
                        />
                    </SubmitableField>
                </Stack>
            </Form>
        </Stack>
    );
};

export default Data;