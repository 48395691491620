import {useEffect} from 'react';

import {hasWindow} from 'hooks/useWindow';
import {useLockerRoomStore} from 'module/lockerRoom/zustand';

export const useAudioDevice = () => {
    const setSelectedDevice = useLockerRoomStore((state) => state.setSelectedDevice);
    const media = useLockerRoomStore((state) => state.media);
    
    useEffect(() => {
        const selectHardwareDevice = async () => {
            
            // Many browsers, without the consent of getUserMedia, cannot enumerate the devices.
            if (media.audio === false && media.video === false) {
                //media devices are disabled
                return null;
            }
            
            try {
                let audioDevice = null;
                let videoDevice = null;

                const stream = await navigator.mediaDevices.getUserMedia(media);
                // console.log('MEDIASOUP: audiostream found', stream);

                // const used_devices = stream.getTracks()
                //     .map( (track) => track.getSettings().deviceId );
                // console.log('used_devices', used_devices);

                stream?.getTracks().forEach(function (track) {
                    track.stop();
                });

                const devices = await navigator.mediaDevices.enumerateDevices();
                console.log('MEDIASOUP: audiodevices found', devices);

                // loop through all devices and choose...
                // audio: using 'default' device
                // video: using last device found with tag 'videoinput'

                // find the default device
                devices.forEach((d) => {
                    // console.log(d);
                    switch (d.kind) {
                    case 'audioinput':
                        if (d.deviceId === 'default') {
                            audioDevice = d.deviceId;
                            console.log('default audio device', d);
                        } else {
                            //not a default device
                            // console.log('other audio device', d);
                        }
                    
                        if (!audioDevice) {
                            // if no default has been found, use the first device found with tag 'audioinput'
                            audioDevice = d.deviceId;
                        
                        }

                        break;
                    
                    case 'audiooutput':
                        if (d.deviceId === 'default') {
                            console.log('default audio output device', d);
                        } else {
                            //not a default device
                            // console.log('other audio device', d);
                        }
                    
                        break;
                    
                    case 'videoinput':
                        videoDevice = d.deviceId;
                        break;
                    
                    default:
                        break;
                    }
               
                });
                // console.log('audioDevice', audioDevice);
       
                setSelectedDevice({
                    audioDevice,
                    videoDevice
                });

            } catch (err) {
                console.error('MEDIASOUP: DEVICE ERROR: ', err);
            }

        };

        navigator.mediaDevices.ondevicechange = () => {
            // add the event listener that selects the audio device if device changed
            selectHardwareDevice(); 
        };

        // do the initial load
        selectHardwareDevice();
    
        // after changing to another app that uses audio reinitializing the audio device on comeback from app background mode if call was made or audio device blocked
        if (hasWindow) {
            const handleVisibilityChange = () => {
                // to catch the case where the app was in a phone call and lost audio device
                if(document.visibilityState === 'visible'){
                    selectHardwareDevice();
                }
            };
            
            let listener = window.addEventListener('visibilitychange', handleVisibilityChange);
        
            return () => {
                //cleanup on unmount  
                window.removeEventListener(listener, handleVisibilityChange);
            };
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};
