import React, {useEffect, useState} from 'react';
import {useController, useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';

import {FormControl, FormHelperText, TextField} from '@mui/material';

import {Row} from 'components';
import {useTranslation} from 'hooks';

const styles = {
    input: {
        minWidth: 80
    }
};

const buildParts = value => {
    if (!value) {
        return [0, 0, 0];
    } else {
        const days = Math.trunc(value / 86400);
        const hours = Math.trunc((value % 86400) / 3600);
        const minutes = Math.trunc((value - days * 86400 - hours * 3600) / 60);
        return [days, hours, minutes];
    }
};

const IntervalField = ({name, label, defaultValue, fullWidth, required, helperText}) => {
    const translate = useTranslation();
    const {control, setValue} = useFormContext();
    const {field: {ref, value}, fieldState} = useController({
        name,
        control,
        defaultValue,
    });

    // days, hours, minutes
    const [parts, setParts] = useState(buildParts(value));

    useEffect(() => {
        setParts(buildParts(value));
    }, [value]);

    useEffect(() => {
        const val = parts[0] * 86400 + parts[1] * 3600 + parts[2] * 60;

        if (val !== value) {
            setValue(name, val);
        }
    }, [setValue, value, name, parts]);

    return <FormControl fullWidth={fullWidth} error={!!fieldState.error?.message} required={required}>
        <Row style={{paddingTop: 3}}>
            <TextField
                variant="outlined" size="small"
                label={translate(label) + ' / ' + translate('global.time.days')}
                type="number" inputProps={{min: 0, step: 1}}
                value={parts[0]} inputRef={ref}
                sx={styles.input}
                onChange={e => setParts([e.target.value, parts[1], parts[2]])}/>
            <TextField
                variant="outlined" size="small" label={translate('global.time.hours')}
                type="number" inputProps={{min: 0, step: 1}}
                sx={styles.input}
                value={parts[1]} onChange={e => setParts([parts[0], e.target.value, parts[2]])}/>
            <TextField
                variant="outlined" size="small" label={translate('global.time.minutes')}
                type="number" inputProps={{min: 0, step: 5}}
                sx={styles.input}
                value={parts[2]} onChange={e => setParts([parts[0], parts[1], e.target.value])}/>
        </Row>
        <FormHelperText>{fieldState.error?.message || translate(helperText)}</FormHelperText>
    </FormControl>;

};

IntervalField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValue: PropTypes.any,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
    required: PropTypes.bool,
};

export default IntervalField;
