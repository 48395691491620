import {create} from 'zustand';

import {middleware} from 'store/zustand';

import {produce} from 'immer';

export const useSheetStore = create(middleware((set, get) => ({
    sheets: [],
    selected: null,
    frameIndex: 0,

    setSelected: sheetId => set(produce(draft => {
        draft.selected = sheetId;
    })),

    setSheets: sheets => set(produce(draft => {
        draft.sheets = sheets;

        // be nice about selection, try to keep it
        if (draft.selected !== null) {
            const exists = sheets.findIndex(sheet => sheet.id === draft.selected);

            if (exists === -1) {
                draft.selected = null;
            }
        }

        if (draft.selected === null && sheets.length > 0) {
            draft.selected = sheets[0].id;
        }
    })),

    getByGroup: groupId => {
        return get().sheets.filter(sht => sht.group === groupId);
    },

    delByGroup: groupId => set(produce(draft => {
        for (const sheet of draft.sheets) {
            if (sheet.group === groupId) {
                deleteSheet(draft, sheet.id);
            }
        }
    })),

    getNextSort: groupId => {
        const groupSheets = get().getByGroup(groupId);

        if (groupSheets.length === 0) {
            return 1;
        }

        return groupSheets[groupSheets.length-1].sort + 1;
    },

    get: id => {
        const index = get().sheets.findIndex(sht => sht?.id === id);

        if (index === -1) {
            return null;
        }

        return get().sheets[index];
    },

    add: (sheet = {}, select = true) => set(produce(draft => {
        draft.sheets.push(sheet);

        if (select) {
            draft.selected = sheet.id;
        }
    })),

    upd: sheet => set(produce(draft => {
        const index = draft.sheets.findIndex(sht => sht?.id === sheet?.id);

        if (index === -1) {
            return;
        }

        draft.sheets[index] = {
            ...draft.sheets[index],
            ...sheet
        };
    })),

    del: id => set(produce(draft => {
        deleteSheet(draft, id);
    })),

    delAll: () => set(produce(draft => {
        draft.sheets = [];
        draft.selected = null;
        draft.frameIndex = 0;
    })),

    // animation frames
    setFrameIndex: (frameIndex) => set(produce(draft => {
        draft.frameIndex = frameIndex;
    })),

    addFrame: (sheetId, frame, pos) => set(produce(draft => {
        const index = draft.sheets.findIndex(sheet => sheet?.id === sheetId);
        console.log('addFrame', sheetId, index);

        if (index === -1) {
            return;
        }

        draft.sheets[index].frames.splice(pos, 0, frame);
    })),

    updFrame: (sheetId, frame, frameIndex) => set(produce(draft => {
        const index = draft.sheets.findIndex(sheet => sheet?.id === sheetId);

        if (index === -1) {
            return;
        }

        if (draft.sheets[index].frames[frameIndex] === undefined) {
            return;
        }

        draft.sheets[index].frames[frameIndex].frame = frame;
    })),

    delFrame: (sheetId, frameIndex) => set(produce(draft => {
        const index = draft.sheets.findIndex(sheet => sheet?.id === sheetId);

        if (index === -1) {
            return;
        }

        draft.sheets[index].frames.splice(frameIndex, 1);
    })),

    clearFrames: sheetId => set(produce(draft => {
        const index = draft.sheets.findIndex(sheet => sheet?.id === sheetId);

        if (index === -1) {
            return;
        }

        draft.sheets[index].frames = [];
    })),
}), {name: 'SheetStore', debug: true}));

const deleteSheet = (draft, id) => {
    const index = draft.sheets.findIndex(sht => sht?.id === id);

    if (index !== -1) {
        draft.sheets.splice(index, 1);
    }

    if (draft.selected === id) {
        draft.selected = null;

        if (draft.sheets.length > 0) {
            draft.selected = draft.sheets[draft.sheets.length - 1].id;
        }
    }
};