import React from 'react';

import {Box, List, ListItem, ListItemButton, Radio, Typography} from '@mui/material';

import {Drawer,MenuHeader, Text} from 'components';
import {useTranslation} from 'hooks';
import {Modes} from 'lib/tactix';
import {updateRemoteLanguage} from 'module/settings/api';
import {languages,useSettingsStore} from 'module/settings/zustand';
import {useInfoStore} from 'store';
import {useLayoutStore, useSnackbarStore} from 'store';

const styles = {
    listHolder: {
        padding: '0 15px',
    },
    listItem: {
        width: '100%',
        borderBottom: '1px solid',
        borderBottomColor: 'grey.darkest2',

        '&:last-of-type': {
            borderBottom: 0,
        }
    },
    listItemActive: {
        backgroundColor: 'grey.darkest',
        borderBottom: '1px solid',
        borderBottomColor: 'grey.darkest2',
    },
    listContent: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px !important',
    },
    radioButton: {
        width: 'auto',
        height: '40px',
    },
    radioButtonAction: {
        color: 'white.main',
        '&.Mui-checked': {
            color: 'green.main',
        },
    },
};

const Language = () => {
    const translate = useTranslation();

    const selectedLanguage = useSettingsStore((store) => store.selectedLanguage);
    const setSelectedLanguage = useSettingsStore((store) => store.setSelectedLanguage);

    const setShowLanguage = useLayoutStore((state) => state.setShowLanguage);
    const showLanguage = useLayoutStore((state) => state.showLanguage);

    const showInfo = useSnackbarStore((state) => state.show);
    const mode = useInfoStore(state => state.mode);

    const handleChange = (code) => {
        try {
            setSelectedLanguage(code);

            if (mode === Modes.APP) {
                // update app language as well
                console.log('Updating app language:', code);
                updateRemoteLanguage(code);
            }

            showInfo(translate('ui.confirm.saved'));
        } catch (e) {
            showInfo(e.message, {severity: 'warning'});
        }
    };

    return(<Drawer
        open={showLanguage}
        onClose={() => setShowLanguage(false)}
    >
        <MenuHeader
            text={<Text>board.menu.language.language</Text>}
            handleBackButton={() => setShowLanguage(false)}
        />

        <List sx={styles.listHolder}>
            {languages.map((language, i) => (
                <ListItem sx={[styles.listItem, language.code === selectedLanguage && styles.listItemActive]} key={`panelgroup-${i}`}>
                    <ListItemButton
                        sx={styles.listContent}
                        aria-controls={`panel3bh-content${i}`}
                        onClick={() => handleChange(language.code)}
                    >
                        <Typography><Text>{language.name}</Text></Typography>
                        <Box sx={styles.radioButton} justifyContent="center" alignItems="center">
                            <Radio
                                checked={language.code === selectedLanguage}
                                value={`panel${i}`}
                                name="teams"
                                sx={styles.radioButtonAction}
                            />
                        </Box>
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    </Drawer>);
};

export default Language;
