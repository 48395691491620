import {useCallback, useEffect} from 'react';

import {MAX_CANVAS_OBJECTS} from 'config';
import {fabric} from 'fabric';
import {useWindowDimensions} from 'hooks';
import {usePrefsStore} from 'store';

import * as backgrounds from '../assets/backgrounds';

import {useAction} from './canvas-actions';
import {migrate4to5} from './migrations';
import {setScaling} from './object-helper';
import {customControlVisibility} from './useCustomControls';

// 0,614379085
// 1,627659574
// export const CANVAS_WIDTH = 940;
// export const CANVAS_HEIGHT = 1530;
export const CANVAS_WIDTH = 1080;
export const CANVAS_HEIGHT = 1613; // old:1758 new:1613

export const CANVAS_RATIO = CANVAS_HEIGHT / CANVAS_WIDTH;
export const DRAWING_WIDTH = 5;
export const DEFAULT_COLOR = '#000000';
export const DRAWING_MODE = 'draw';
export const SELECTION_MODE = 'select';

export const setStrokeColor = (canvas, color) => {
    canvas.freeDrawingBrush.color = color;
};

export const setStrokeWidth = (canvas, width) => {
    canvas.freeDrawingBrush.width = width;
};

export const setFabricDefaults = () => {
    fabric.Object.prototype.selectionBackgroundColor = 'rgba(0,0,0,0.15)';
    fabric.Object.prototype.hasBorders = false;
    fabric.Object.prototype.borderColor = 'rgba(0,0,0,0.15)';
    fabric.Object.prototype.padding = 15;
};

export const setLastObjectActive = (canvas) => {
    if (!canvas.isEmpty()) {
        canvas.setActiveObject(canvas.item(canvas.size() - 1));
    }
};

export const discardActiveObject = (canvas) => {
    if (canvas?.getActiveObject()) {
        canvas.discardActiveObject();
        canvas.requestRenderAll();
    }
};

export const clear = (canvas, background) => {
    if (!canvas) {
        return;
    }

    canvas.clear();
    setBackground(canvas, background);
};

export const setBackground = (canvas, background, onComplete) => {
    if (backgrounds[background]) {
        fabric.Image.fromURL(backgrounds[background], (img) => {
            img.scaleToWidth(CANVAS_WIDTH, true);
            // img.scaleToHeight(canvas.height, true);

            canvas.setBackgroundColor('#191919');
            canvas.setBackgroundImage(img, () => {
                canvas.renderAll();
            });
            onComplete && onComplete();
        });

        return background;
    } else {
        if (background === null) {
            canvas.setBackgroundColor('#191919');
        } else {
            canvas.setBackgroundColor('#ffffff');
        }

        canvas.setBackgroundImage(null, () => {
            canvas.renderAll();
        });
        onComplete && onComplete();

        return null;
    }
};

export const isCustom = (o) => {
    return !!o.custom;
};

export const isCustomType = (o, type) => {
    return o.custom && o.custom.type === type;
};

/**
 * Calculates the dimensions of a canvas based on the window width, window height, and a canvas ratio.
 * This hook also sets then background into the canvas after the background pref has changed.
 * Background must be re-set if dimensions are changing.
 *
 * @param {Object} canvas - The canvas object.
 * @returns {Object} - The calculated dimensions of the canvas.
 */
export const useCalculateDimensions = (canvas) => {
    const background = usePrefsStore(state => state.background);

    const {width: windowWidth, height: windowHeight} = useWindowDimensions();

    const {dispatch} = useAction();

    const calculateDimensions = useCallback((wWidth, wHeight) => {
        let newWidth;
        let newHeight;
        let adjust = 0;
        const preserveHeight = 200; // Preserve height, header+toolbar+footer

        if (wWidth < wHeight) {
            newWidth = wWidth;
            newHeight = newWidth * CANVAS_RATIO;

            if (newHeight + preserveHeight > wHeight) {
                adjust = newHeight + preserveHeight - wHeight;
                newHeight = newHeight - adjust;
                newWidth = newHeight / CANVAS_RATIO;
            }

        } else {
            newHeight = wHeight - preserveHeight;
            newWidth = newHeight / CANVAS_RATIO;

            if (newWidth > wWidth) {
                adjust = newWidth - wWidth;
                newWidth = newWidth - adjust;
                newHeight = newHeight / CANVAS_RATIO;
            }
        }

        newHeight = Math.floor(newHeight);
        newWidth = Math.floor(newWidth);

        const ratio = newWidth / CANVAS_WIDTH;

        console.debug(`ww:${wWidth} wh:${wHeight} cw:${newWidth} ch:${newHeight} adjW:${adjust} ratio:${ratio}`);

        return {width: newWidth, height: newHeight};
    }, []);

    useEffect(() => {
        if (!canvas) {
            return;
        }

        try {
            canvas.setDimensions(calculateDimensions(windowWidth, windowHeight));
        } catch (e) {
            // suppressing error so app can reload without setDimensions error
            console.log('suppress reload getting error');
        }

        setBackground(canvas, background, () => {
            dispatch('push');
        });

        const scaling = getZoom(canvas);
        canvas.setZoom(scaling.zoom);
    }, [background, canvas, calculateDimensions, windowWidth, windowHeight, dispatch]);

    return calculateDimensions(windowWidth, windowHeight);
};

export const getZoom = (canvas) => {
    return {
        zoom: canvas.width / CANVAS_WIDTH,
    };
};

export const limitReached = (canvas) => {
    if (!canvas) {
        return false;
    }

    const drawings = canvas.getObjects().filter(object => object?.path && object.path.length > 1);
    const objectCountMinusDrawings = (canvas.getObjects().length - drawings.length);

    return objectCountMinusDrawings >= MAX_CANVAS_OBJECTS;
};

export const canvasToJson = (canvas) => {
    if (!canvas) {
        return null;
    }

    return canvas.toJSON(['hasControls', 'hasBorders', 'custom']);
};

export const canvasReviver = (json, element, isMobile) => {
    migrate4to5(json, element);
    customControlVisibility(element);
    setScaling(element, isMobile);
};

export const getModifiedCounter = (canvas) => {
    if (!canvas) {
        return 0;
    }

    return canvas.get('modCount') ? canvas.get('modCount') : 0;
};

export const incModifiedCounter = (canvas) => {
    if (!canvas) {
        return;
    }

    canvas.set('modCount', getModifiedCounter(canvas) + 1);
};

export const restoreCanvas = (canvas, fromCanvas, isMobile, onComplete) => {
    canvas.remove(...canvas.getObjects());
    fabric.util.enlivenObjects(fromCanvas.objects, (objs) => {
        objs.forEach((item) => {
            canvas.add(item);
        });
        canvas.requestRenderAll(); // Make sure to call once we're ready!
        if (fromCanvas.backgroundImage && fromCanvas.backgroundImage.src !== canvas.backgroundImage?.src) {
            fabric.Image.fromURL(fromCanvas.backgroundImage.src, (img) => {
                img.scaleToWidth(CANVAS_WIDTH, true);

                canvas.setBackgroundColor('#191919');
                canvas.setBackgroundImage(img, () => {
                    canvas.renderAll();
                });
            });
        }

        if (!fromCanvas.backgroundImage) {
            canvas.setBackgroundImage(null);
            canvas.setBackgroundColor(fromCanvas.background);
        }

        onComplete && onComplete();
    },'', (json, element) => {
        canvasReviver(json, element, isMobile);
    });
};