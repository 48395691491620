import {create} from 'zustand';

import {middleware} from 'store/zustand';

import {produce} from 'immer';

export const useBannersStore = create(middleware((set, _get) => ({
    visible: false,
    show: () => set(produce(draft => {
        draft.visible = true;
    })),
    hide: () => set(produce(draft => {
        draft.visible = false;
    })),
})));
