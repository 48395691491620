import PropTypes from 'prop-types';

import {fabric} from 'fabric';

import {limitReached} from '../canvas-helper';

const Circle = function ({canvas, stroke, width, origX, origY, fill = ''}) {

    let isDrawing = false;
    let circle = null;
    canvas.on('mouse:down', (o) => {
        if (limitReached(canvas)) {
            return;
        }

        // don't draw if an object is selected on the canvas
        if (canvas.getActiveObject()) {
            return;
        }

        const pointer = canvas.getPointer(o.e);
        origX = pointer.x;
        origY = pointer.y;
        isDrawing = true;

        circle = new fabric.Ellipse({
            strokeWidth: width,
            fill: fill,
            stroke: stroke,
            left: origX,
            top: origY,
            originX: 'center',
            originY: 'center',
            rx: pointer.x - origX,
            ry: pointer.y - origY,
            hasBorders: false,
            hasControls: false,
        });
        canvas.add(circle);
    });

    canvas.on('mouse:move', (o) => {
        if (!isDrawing) {
            return;
        }

        let pointer = canvas.getPointer(o.e);
        let r = Math.sqrt(Math.pow(pointer.x - circle.left, 2) + Math.pow(pointer.y - circle.top, 2));
        circle.set({radius: Math.floor(r)});

        let rx = Math.abs(origX - pointer.x) / 2;
        let ry = Math.abs(origY - pointer.y) / 2;

        if (rx > circle.strokeWidth) {
            rx -= circle.strokeWidth / 2;
        }

        if (ry > circle.strokeWidth) {
            ry -= circle.strokeWidth / 2;
        }

        circle.set({rx: rx, ry: ry});

        if (origX > pointer.x) {
            circle.set({originX: 'right'});
        } else {
            circle.set({originX: 'left'});
        }

        if (origY > pointer.y) {
            circle.set({originY: 'bottom'});
        } else {
            circle.set({originY: 'top'});
        }

        canvas.renderAll();
    });

    canvas.on('mouse:up', () => {
        isDrawing = false;

        if (circle) {
            canvas.remove(circle).add(circle);
            canvas.sendToBack(circle);
        }
    });

};

Circle.propTypes = {
    canvas: PropTypes.object.isRequired
};

export default Circle;
