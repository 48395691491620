import {useCallback, useEffect, useRef} from 'react';

/**
 * When a component unmounts, we need to cancel any potentially
 * ongoing Axios calls that result in a state update on success / fail.
 * This function sets up the appropriate useEffect to handle the aborting.
 *
 * @returns {newAbortSignal: function}
 * newAbortSignal - used to generate the cancel signal sent in the Axios request, 
 * use to abort request and use to check abort status.
 */
const useAbortSignal = () => {
    const abortSignal = useRef(null);

    const newAbortSignal = useCallback(() => {
        abortSignal.current = new AbortController();
        return abortSignal.current.signal;
    }, []);

    useEffect(() => {
        return () => {
            if (abortSignal.current) abortSignal.current.abort();
        };
    }, []);

    return {newAbortSignal};
};

export default useAbortSignal;
