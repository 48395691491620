import {create} from 'zustand';

import {middleware} from 'store/zustand';

import {produce} from 'immer';

// do not use this store directly, use the useGroup hook instead
export const useGroupStore = create(middleware((set, get) => ({
    selected: null,
    groups: [],
    setSelected: groupId => set(produce(draft => {
        draft.selected = groupId ? groupId : null;
    })),
    add: group => set(produce(draft => {
        draft.groups.push(group);
        draft.selected = draft.selected ? draft.selected : group?.id;
    })),
    del: groupId => set(produce(draft => {
        const index = draft.groups.findIndex(grp => grp?.id === groupId);

        if (index !== -1) {
            draft.groups.splice(index, 1);
        }

        if (draft.selected === groupId) {
            draft.selected = null;

            if (draft.groups.length > 0) {
                draft.selected = draft.groups[draft.groups.length - 1]?.id;
            }
        }
    })),
    setGroups: groups => set(produce(draft => {
        draft.groups = groups;

        // be nice about selection, try to keep it
        if (draft.selected !== null) {
            const exists = groups.findIndex(sheet => sheet.id === draft.selected);

            if (exists === -1) {
                draft.selected = null;
            }
        }

        if (draft.selected === null && groups.length > 0) {
            draft.selected = groups[0].id;
        }

        draft.selected = null;
    })),
    delAll: () => set(produce(draft => {
        draft.groups = [];
        draft.selected = null;
    })),
    upd: group => set(produce(draft => {
        const index = draft.groups.findIndex(grp => grp?.id === group?.id);

        if (index !== -1) {
            draft.groups[index] = group;
        }
    })),
    sort: (group, direction) => set(produce(draft => {
        const currentIndex = draft.groups.findIndex(grp => grp?.id === group?.id);

        const offset = direction === 'up' ? 1 : -1;
        const newSort = group.sort + offset;
        const currentSort = group.sort;
        const nextElementIndex = draft.groups.findIndex(grp => grp.sort === newSort);

        draft.groups[currentIndex].sort = newSort;
        draft.groups[nextElementIndex].sort = currentSort;
    })),
    get: id => {
        const index = get().groups.findIndex(grp => grp?.id === id);

        if (index === -1) {
            return null;
        }

        return get().groups[index];
    },
    getByName: name => {
        const index = get().groups.findIndex(grp => grp.name === name);

        if (index === -1) {
            return null;
        }

        return get().groups[index];
    }
}), {name: 'GroupStore', debug: true}));
