import {useCallback} from 'react';

import {isOk, useFetcher} from 'fetcher';
import {getEnv} from 'lib/env';

const CONTROL_URL = getEnv('CONTROL_URL');

export const useApiLoad = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'user/sheet/group/', {
        method: 'GET',
    }, [], true);

    const load = useCallback(async () => {
        let groups = [];

        const res = await fetch();

        if (isOk(res) && Array.isArray(res.data)) {
            groups = res.data;
        }

        return groups;
    }, [fetch]);

    return {load, loading};
};

export const useApiAdd = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'user/sheet/group/', {
        method: 'POST',
    }, undefined, true);

    const add = useCallback(async (group) => {

        const res = await fetch({
            data: group
        });

        if (isOk(res) && typeof res.data === 'object') {
            return res.data;
        }
    }, [fetch]);

    return {add, loading};
};

export const useApiUpdate = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'user/sheet/group/', {
        method: 'PUT',
    }, undefined, true);

    const update = useCallback(async (group) => {
        const res = await fetch({
            data: group
        });

        if (isOk(res) && typeof res.data === 'object') {
            return res.data;
        }
    }, [fetch]);

    return {update, loading};
};

export const useApiDelete = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'user/sheet/group/{id}', {
        method: 'DELETE',
    }, undefined, true);

    const del = useCallback(async (groupId) => {
        const res = await fetch({
            urlParams: {
                id: groupId
            },
        });

        if (isOk(res)) {
            return res;
        }
    }, [fetch]);

    return {del, loading};
};

export const useApiDeleteAll = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'user/sheet/group/', {
        method: 'DELETE',
    }, undefined, true);

    const del = useCallback(async () => {
        const res = await fetch();

        if (isOk(res)) {
            return true;
        }
    }, [fetch]);

    return {del, loading};
};

export const useApiSort = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'user/sheet/group/sort/', {
        method: 'PATCH',
    }, undefined, true);

    const sort = useCallback(async (groupId, direction) => {
        const res = await fetch({
            data: {
                id: groupId,
                step: direction
            }
        });

        if (isOk(res)) {
            return true;
        }
    }, [fetch]);

    return {sort, loading};
};
