import PropTypes from 'prop-types';

import {v4 as uuidv4} from 'uuid';

import * as cliparts from 'assets/cliparts';
import {fabric} from 'fabric';

import {isCustom, limitReached, setLastObjectActive} from '../canvas-helper';

import {createPlayerNumber, getNextPlayerNumber, getPlayerSizeOptions} from './player-utils';

const Player = function ({canvas, clipart, colors, size}) {

    canvas.on({
        'mouse:down': (o) => {
            if (limitReached(canvas)) {
                return;
            }

            if (canvas.getActiveObject()) {
                if (isCustom(canvas.getActiveObject())) {
                    return;
                }

                canvas.discardActiveObject();
            }

            const pointer = canvas.getPointer(o.e);
            const nextNumber = getNextPlayerNumber(canvas, colors.name).toString();

            createPlayer(canvas, clipart, colors, size, pointer.x, pointer.y, nextNumber, {},
                (canvas, group) => {
                    canvas.add(group);
                    setLastObjectActive(canvas);
                });
        },
    });
};

Player.propTypes = {
    canvas: PropTypes.object.isRequired
};

export const createPlayer = (canvas, clipart, colors, size, posX, posY, playerNumber, customData, onComplete) => {
    fabric.loadSVGFromString(cliparts[clipart], (loaded) => {

        let img = fabric.util.groupSVGElements(loaded);
        const playerSize = getPlayerSizeOptions(size);

        img.scaleToWidth(playerSize.size, false);
        //img.scaleToWidth(90, false);
        // duotone SVGs have two paths
        img.item(0).set('fill', colors.value1);
        img.item(0).set('opacity', 1);
        img.item(1).set('fill', colors.value2);
        img.item(1).set('opacity', 1);
        // would work, but not ideal
        // img.item(0).set('shadow', {color: '#000000', blur: 10, offsetX: 0, offsetY: 0});
        // draw black border, causes some display problems
        // img.item(2).set('fill', '#000000');
        // img.item(2).set('strokeWidth', 8);
        img.originX = 'center';
        img.originY = 'center';
        img.hasBorders = false;
        // cacheProperties used to set dirty flag(triggering re-render) on property change - currently not needed
        // img.item(0).cacheProperties = fabric.Object.prototype.cacheProperties.concat('fill', 'opacity');
        // img.item(1).cacheProperties = fabric.Object.prototype.cacheProperties.concat('fill', 'opacity');

        const textSize = 28 + size;
        const number = createPlayerNumber(playerNumber.toString(), colors.textColor, textSize);
        const player = new fabric.Group([img, number], {
            custom: {
                type: 'player',
                subType: 'player',
                id: uuidv4(),
                team: colors.name,
                size,
                ...customData
            },
            originX: 'center',
            originY: 'center',
            left: posX,
            top: posY,
            hasBorders: false,
            hasControls: true
        });

        player.addWithUpdate();

        onComplete && onComplete(canvas, player);
    });
};

createPlayer.propTypes = {
    canvas: PropTypes.object.isRequired,
    clipart: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    posX: PropTypes.number.isRequired,
    poxY: PropTypes.number.isRequired,
    playerNumber: PropTypes.string.isRequired,
    team: PropTypes.string.isRequired,
    onComplete: PropTypes.func
};

export default Player;
