import {useCallback, useEffect, useRef} from 'react';

import {useMediaQuery, useTheme} from '@mui/material';

import {getModifiedCounter, incModifiedCounter} from 'canvas/canvas-helper';
import {useCanvasLoad} from 'canvas/useCanvas';
import {useCanvasStore} from 'canvas/zustand';
import {fabric} from 'fabric';
import {useInterval} from 'hooks';
import {useTranslation} from 'hooks';
import {sendImage} from 'module/lockerRoom/api';
import lockerRoom from 'module/lockerRoom/socket';
import {useLoadActiveLockerrooms} from 'module/lockerRoom/useLoadActiveLockerrooms';
import {updateRemoteLockerroomState} from 'module/settings/api';
import {useSettingsStore} from 'module/settings/zustand';
import {useSheet} from 'module/sheet/useSheet';
import {useUserStore} from 'module/user';
import {useSnackbarStore} from 'store';
import {usePrefsStore} from 'store';

import {createLockerroom, joinLockerroom} from './api';
import {useLockerRoomStore} from './zustand';

export const useLockerroom = () => {
    const loadActiveLockerrooms = useLoadActiveLockerrooms();
    const translate = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const prevData = useRef(0);

    const setRole = useLockerRoomStore((state) => state.setRole);
    const role = useLockerRoomStore((state) => state.role);
    // const setMedia = useLockerRoomStore((state) => state.setMedia);

    const canvas = useCanvasStore(state => state.canvas);
    // const setCanvasMode = useMode();
    const {load: loadCanvas} = useCanvasLoad();

    // const setTool = usePrefsStore(state => state.setTool);

    const setOpening = useLockerRoomStore((state) => state.setOpening);
    const setClosing = useLockerRoomStore((state) => state.setClosing);

    const active = useLockerRoomStore((state) => state.active);
    // const setPing = useLockerRoomStore((state) => state.setPing);
    const setActive = useLockerRoomStore((state) => state.setActive);
    
    const setDataImage = useLockerRoomStore((state) => state.setDataImage);
    // const roomId = useLockerRoomStore((state) => state.roomId);
    const setRoomId = useLockerRoomStore((state) => state.setRoomId);
    const setCreatedAt = useLockerRoomStore((state) => state.setCreatedAt);
    const setProducing = useLockerRoomStore((state) => state.setProducing);
    const setLoadingChangeEditor = useLockerRoomStore((state) => state.setLoadingChangeEditor);

    const user = useUserStore((state) => state.user);
    
    const setCurrentRoom = useUserStore((state) => state.setCurrentRoom);
    // const setLockerrooms = useUserStore((state) => state.setLockerrooms);
    const editor = useLockerRoomStore((state) => state.editor);
    const setEditor = useLockerRoomStore((state) => state.setEditor);
    // const connected = useLockerRoomStore((state) => state.connected);
    const setConnected = useLockerRoomStore((state) => state.setConnected);
    const setCreated = useLockerRoomStore((state) => state.setCreated);
    const reconnects = useLockerRoomStore((state) => state.reconnects);
    const incReconnects = useLockerRoomStore((state) => state.incReconnects);
    const roomTime = useLockerRoomStore((state) => state.roomTime);
    const setRoomTime = useLockerRoomStore((state) => state.setRoomTime);
    const setUsers = useLockerRoomStore((state) => state.setUsers);
    // const users = useLockerRoomStore((state) => state.users);
    const setMicro = useLockerRoomStore((state) => state.setMicro);
    // const selectedDevice = useLockerRoomStore((state) => state.selectedDevice);
    // const mediaRoomConnected = useLockerRoomStore((state) => state.mediaRoomConnected);
    // const setMediaRoomConnected = useLockerRoomStore((state) => state.setMediaRoomConnected);
    // const setMediaRoomCreated = useLockerRoomStore((state) => state.setMediaRoomCreated);
    // const setProducerTransport = useLockerRoomStore((state) => state.setProducerTransport);
    // const setProducerTransportConnected = useLockerRoomStore((state) => state.setProducerTransportConnected);
    // const setConsumerTransport = useLockerRoomStore((state) => state.setConsumerTransport);
    // const setConsumerTransportConnected = useLockerRoomStore((state) => state.setConsumerTransportConnected);
    // const producing = useLockerRoomStore((state) => state.producing);
    const setMediaRoomForceReconnect = useLockerRoomStore((state) => state.setMediaRoomForceReconnect);

    const selectedTeam = useSettingsStore((store) => store.selectedTeam);
    // const roomId = useUserStore((state) => state.roomId);

    const showInfo = useSnackbarStore(state => state.show);

    const background = usePrefsStore((state) => state.background);
    const setBackground = usePrefsStore((state) => state.setBackground);

    // eslint-disable-next-line no-unused-vars
    const {frameIndex, selectedSheet: sheet} = useSheet();
    
    // const editable= frameIndex === 0;
    const isEditor = useRef(editor);

    const backgroundRef = useRef(background);

    // console.log(`active: ${active} editor: ${editor}`);

    const clearLockerRoom = () => {
        console.log('CLEAR LOCKERRROOM');
        setOpening(false);
        setCreated(false);
        setRoomId(null);
        clearImageTimer();
        updateRemoteLockerroomState(false); // updates app state
        // setMedia(MEDIA_OFF); // disabled as we always use audio
        setMicro(false);
        setProducing(false);
        setActive(false);
        incReconnects(0); // reset reconnects
        setEditor(true); // sets dataimage null
        lockerRoom.removeAllListeners(); // 'newProducers', 'consumerClosed'

        if (role !== 'admin' && sheet) {
            loadCanvas(sheet, isMobile);
        }

        setRole(null);
        
        loadActiveLockerrooms();
        setClosing(false);

    };

    // const runSendImage = () => {
    //     if (prevData.current == getModifiedCounter(canvas)) {
    //         return;
    //     }

    //     console.log('image useInterval', prevData.current, getModifiedCounter(canvas), background, isEditor.current);
    //     prevData.current = getModifiedCounter(canvas);

    //     sendImage(canvas, active, isEditor.current);

    // useInterval(useCallback(() => {
    //     pingServer((pingValue) => {
    //         setPing(pingValue);
    //         // console.log('pingServer', pingValue);
    //     });
    // }, [setPing]), 1000);

    const [clearImageTimer, startImageTimer] = useInterval(useCallback(() => {
        if (prevData.current == getModifiedCounter(canvas)) {
            return;
        }

        sendImage(canvas, active, editor, prevData, getModifiedCounter(canvas));
    }, [canvas, active, editor, prevData]), 200);

    useEffect(() => {
        
        if (editor && isEditor.current !== editor) {
            isEditor.current = editor;
            // for starting image send on editor change
            incModifiedCounter(canvas);
            startImageTimer();
        } else {

            isEditor.current = false;
            clearImageTimer();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor, startImageTimer, clearImageTimer]);

    useEffect(() => {
        updateRemoteLockerroomState(active);
    }, [active]);

    useEffect(() => {
        if (backgroundRef.current !== background) {
            console.log('update counter for backgroundchange', background);
            backgroundRef.current = background;
            incModifiedCounter(canvas);
        }
       
    }, [background, canvas]);

    useEffect(() => {

        console.log('LOCKEROOM EFFECT');
        
        lockerRoom.on('connect', async () => {
            incReconnects();
            console.log('SOCKET IO connected - actionOnConnect: ', lockerRoom.actionOnConnect, reconnects );
            if (reconnects > 0) {
                console.log(`reconnected: ${reconnects} `);
                // TODO fix audio room if user changed network
                setMediaRoomForceReconnect(true);
                
                showInfo(`Verbindung wurde wiederhergestellt`);
            
            }

            // allow imagesend after connection loss for editor if the sendimage call did not go through
            lockerRoom.allowSendImage = true;

            if (lockerRoom.actionOnConnect === 'create') {
                createLockerroom(selectedTeam, user, lockerRoom.title, lockerRoom.roomId, lockerRoom.invitedUsers);
                lockerRoom.actionOnConnect = '';
            } else {
                joinLockerroom(lockerRoom.roomId, user);
                lockerRoom.actionOnConnect = '';
            }

            setConnected(true);
            
        });
    
        lockerRoom.on('created', (data) => {
            console.log('Lockerroom created', data);
            setCreatedAt(data.createdAt || 0);
            setMicro(true);
            setCreated(true);
            setCurrentRoom(data);
            setActive(true);
            startImageTimer();
            incModifiedCounter(canvas);
            setOpening(false);

            console.log('created', data.createdAt);
        });
    
        lockerRoom.on('exitRoomDone', () => {
            console.log('exitRoomDone');

        });
    
        lockerRoom.on('adminToEdit', () => {
            console.log('admin to edit');
            setEditor(true);
            setLoadingChangeEditor(false);
            startImageTimer();
        });
    
        lockerRoom.on('createError', () => {
            setOpening(false);
            showInfo('lockerroom.message.cannot_start', {severity: 'error'});
        });
    
        lockerRoom.on('joined', (data) => {
            console.log('Lockerroom joined', data);
            setEditor(false);
            // setTool('select'); // activate mode selects the tool as well
            // activateMode(); // should have the editor flag false
            setActive(true);
            setCurrentRoom(data);
            setCreatedAt(data.createdAt || 0);
            setMicro(false);
            setProducing(false); // join with microfone muted
            setOpening(false);

            // dataImage transfer active now, so remove standard canvas/bg 
            // setBackground(null); // clear background so it can be updated on editorchange
        });
    
        lockerRoom.on('adminJoined', (data) => {
            console.log('Lockerroom joined by admin', data);
    
            if (data.user.appId === user.appId) {
                setRole('admin');
                setActive(true);
                setCurrentRoom(data);
                setMicro(true);
                setProducing(true);
                
                console.log('Adminjoined is editor?', data.editing, data.users);

                if (data.editing) {
                    setEditor(true);
                    incModifiedCounter(canvas);
                    startImageTimer();
                } else {
                    setEditor(false);
                    clearImageTimer();
                }
            } else {
                showInfo('lockerroom.message.admin_joined');
            }
    
        });
    
        lockerRoom.on('userHand', (data) => {
            if (role === 'admin') {
                showInfo('lockerroom.message.become_editor', data);
            }
        });
    
        lockerRoom.on('joinError', (data) => {
            setOpening(false);
            setClosing(false);
            showInfo('lockerroom.message.unavailable', {severity: 'error'});
            console.log('JOINERROR',data);
        });
    
        lockerRoom.on('editorUnlock', async () => {
            console.log('editorUnlock');
            // TODO add timeout to prevent ui flicker
            await new Promise(resolve => setTimeout(resolve(), 350));
            setLoadingChangeEditor(false);
        });
    
        // this event is triggered from client(admin) -> server/loseEditing
        // purpose: need to transfer canvas from current editor to new editor before editor changes
        lockerRoom.on('loseEditing', (roomId, userData) => {
            console.log('loseEditing', userData);
            const updateData = {canvasObject: canvas, background: backgroundRef.current};
            lockerRoom?.emit('changeRoomEditor', JSON.stringify(updateData), roomId, userData);
            setEditor(false);
        });
    
        lockerRoom.on('left', (data) => {
            console.log('lockerrooms rcv left', data);
            clearLockerRoom({reason: 'left'});
        });
    
        lockerRoom.on('ping', (data, time) => {
            // console.log('lockerrooms rcv ping', data?.users );

            lockerRoom.emit('pong', data.timeStamp, (response) => {
                // we measure the roundtrip time by sending the original time back to the server
                if(!response.status === 'ok'){

                    console.error('lockerroom rcv no pong response', response);

                }
            });

            if (data) {
                let ownUser = data?.users.find((u) => u.appId === user.appId);
                let newUsers = [...data.users];
                if (ownUser?.status?.editing && !isEditor.current) {
                    // if server says user is editor make user editor
                    // TODO maybe rework this, currently if editor rejoins he is overwriting the room canvas data
                    setEditor(true); 
                } else {
                    if (ownUser?.status?.editing === false && isEditor.current !== false) {
                        //prevents double editor on bad network connection
                        setEditor(false);
                    }
                }
                // TODO own user not found on server? -> disconnect
           
                // copy users volumes to data.users.status volume

                data.volumes.forEach((volumeData) => {
                    let index = newUsers.findIndex((user) => user.appId === volumeData.appId);

                    if (index > -1) {
                        // low volume
                        if (volumeData.volume >= 0 && volumeData.volume <= 2) {
                            newUsers[index] = {...newUsers[index], status: {...newUsers[index].status, volume: 0}};
                            return;
                        }

                        // mid volume
                        if (volumeData.volume > 2 && volumeData.volume < 6) {
                            newUsers[index] = {...newUsers[index], status: {...newUsers[index].status, volume: 1}};
                            return;
                        }

                        // max volume
                        if (volumeData.volume >= 6) {
                            newUsers[index] = {...newUsers[index], status: {...newUsers[index].status, volume: 2}};
                            return;
                        }

                        // no volume data found, but user is there, so turn volume off (-1)
                        newUsers[index] = {...newUsers[index], status: {...newUsers[index].status, volume: -1}};

                    } 

                });
                // console.log('newUsers', newUsers);

                setUsers(newUsers);
            }

            if (time && time !== roomTime) setRoomTime(time);
        });
    
        lockerRoom.on('disconnect', (reason, details) => {
            // showInfo('disconnected, trying reconnect, please wait', {severity: 'warning'});
            // TODO reconnect cases for reasons
            console.log('LOCKERROOM DISCONNECTED',reason,details?.context);

            if (reason === 'transport close') {
                // if socket connection is lost,
                showInfo(translate('lockerroom.message.connection_lost'), {severity: 'error'});
                // TODO display info message for disconnection
                // exitRoom(); //TODO fix will be sent on reconnect
            }

            if (reason === 'ping timeout') {
                // if socket connection is lost because of timeout
                // TODO close lockeroom for client? case networkchange happens too..
                showInfo(translate('lockerroom.message.connection_lost'), {severity: 'error'});

                // exitRoom();
            }

            // console.log(details?.context?.status); // 400
            // console.log(details?.context?.responseText); // '{"code":1,"message":"Session ID unknown"}'
            // now cleanup room -> close room
            // leaveLockerroom(currentRoom, user);
            // console.log('....SOCKET IO re-connecting');
            // lockerRoom.connect();
        });
    
        lockerRoom.on('closed', async (data) => {
            console.log('lockerrooms rcv closed', data);
            clearLockerRoom({reason: 'closed'});
        });
    
        lockerRoom.on('terminated', async (data) => {
            showInfo('lockerroom.message.admin_inactive', {severity: 'warning'});
            console.log('lockerrooms rcv terminated', data);
            clearLockerRoom({reason: 'terminated'});
        });
    
        // listen to users update on server
        lockerRoom.on('users', (newUsers) => {
            console.log('all users - IS THIS USED ANYMORE?');
            setOpening(false);

            if (!newUsers) {
                console.log('LOCKERRROOM no new users set active to false, no cleanup?');
                setActive(false);
                return;
            }
    
            setUsers(newUsers);
        });
    
        lockerRoom.on('userImage', (data) => {
            // console.log(data.length);
            console.log('userImage', data?.length);

            if (data) {
                // console.log('image update');
                // setBackground(null);
                // canvas.clear();
                setDataImage(data);
            }
        });
    
        lockerRoom.on('userCanvas', (data, editorChange, roomId) => {
            console.log('userCanvas');
    
            try {
                if (canvas && data.length) {
                    const jsonData = JSON.parse(data);
    
                    if (jsonData.canvasObject) {
                        console.log('canvas update');
                        setDataImage(null);
                        if (canvas.getObjects()) canvas.remove(...canvas.getObjects());
                        fabric.util.enlivenObjects(jsonData.canvasObject.objects, (objs) => {
                            // canvas._objects.push.apply(canvas._objects, objs);
                            objs.forEach((item) => {
                                canvas.add(item);
                            });
                        });
                    }
    
                    if (jsonData.background) {
                        console.log('background update', jsonData.background);
                        setBackground(jsonData.background);
                    }
                    
                    if (jsonData.canvasObject || jsonData.background) {
                        canvas.renderAll();
                        incModifiedCounter(canvas);
                    }
                }
            } catch (error) {
                console.log(error);
                // TODO reset editor state to prevent user crash on disconnecting client connection 
            }
            finally {
                if (editorChange) {
                    console.log('editor change complete', roomId);
                    lockerRoom?.emit('editorChangeComplete', roomId);
                    setEditor(true);
    
                }
            }
    
        });

        return () => {
            console.log('lockerroom unmounted');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

};