/**
 * Generates a URL for an image with optional width, height, and mode parameters.
 *
 * @param {Object} image - The image object containing a URL.
 * @param {number} [image.url] - The URL of the image.
 * @param {number} [w] - The desired width of the image.
 * @param {number} [h] - The desired height of the image.
 * @param {string} [m='thumb'] - The mode of the image.
 *
 * @returns {string|undefined} - The generated image URL with optional parameters.
 */
export const imageUrl = (image, {w, h, m = 'thumb'}) => {
    if (!image || !image.url) {
        return undefined;
    }

    const params = new URLSearchParams({m});

    if (w) {
        params.set('w', w);
    }

    if (h) {
        params.set('h', h);
    }

    return `${image.url}?${params.toString()}`;
};