import React, {useState} from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText, DialogTitle,
} from '@mui/material';
import {styled} from '@mui/material/styles';

import Text from './Text';

const styles = {
    title: {
        color: 'white.main',
        fontSize: '18px',
        paddingBottom: 0,
    },
    contentTextContainer: {
        backgroundColor: 'grey.darker',
    },
    actionsContainer: {
        padding: '0 24px 24px 24px',
    }
};

const PREFIX = 'MuiDialog';

const classes = {
    paper: `${PREFIX}-paper`,
};

const StyledDialog = styled(Dialog)(({theme}) => ({
    [`& .${classes.paper}`]: {
        position: 'fixed',
        top: 40,
        backgroundColor: theme.palette.grey.darker
    },
}));

const ConfirmDialog = ({
    open,
    title = 'ui.confirm.title',
    message = 'ui.confirm.message',
    onConfirm,
    onCancel,
    closeLabel
}) => {
    const handleKeyPress = (e) => {
        if (e.charCode === 32 || e.charCode === 13) {
            e.preventDefault();
            onConfirm();
        }
    };

    return <StyledDialog open={open} onClose={onCancel} maxWidth={'xs'} fullWidth>
        <DialogTitle sx={styles.title}><Text>{title}</Text></DialogTitle>
        <DialogContent sx={styles.contentTextContainer}>
            <DialogContentText>
                <Text>{message}</Text>
            </DialogContentText>
        </DialogContent>
        <DialogActions sx={styles.actionsContainer}>
            <Button
                onClick={onCancel}
                color="secondary"
                variant="contained"
                data-testid="cancel-btn"
            >
                <Text>ui.confirm.cancel</Text>
            </Button>
            <Button
                onClick={onConfirm}
                autoFocus
                color="warning"
                onKeyPress={handleKeyPress}
                variant="contained"
                data-testid="confirm-btn"
                disableElevation
                // positive
                // tabIndex={0}
                // labelPosition='right'
                // icon='checkmark'
                // content='Ja'>
            >
                {closeLabel ? closeLabel : <Text>ui.confirm.delete</Text>}
            </Button>
        </DialogActions>
    </StyledDialog>;
};

export default ConfirmDialog;

export const useConfirmDialog = (onConfirm, title = undefined, message = undefined, closeLabel) => {
    const [isOpen, setIsOpen] = useState(false);
    const [payload, setPayload] = useState(undefined);

    return {
        open: isOpen,
        show: (payload) => {
            setPayload(payload);
            setIsOpen(true);
        },
        hide: () => setIsOpen(false),
        onCancel: () => setIsOpen(false),
        onConfirm: () => {
            onConfirm(payload);
            setIsOpen(false);
        },
        title,
        message,
        closeLabel
    };
};
