/* eslint-disable no-inner-declarations */
import {useCallback, useEffect, useMemo, useRef} from 'react';

import {fabric} from 'fabric';
import {usePlayerStore} from 'module/player';
import {useSheet} from 'module/sheet/useSheet';
import {useTextStore} from 'module/text';
import {useSnackbarStore} from 'store';

import {useAction} from './canvas-actions';

// controls distribution to elements
const editControlElement = ['player', 'text'];

// controls
const customControls = ['editControl', 'deleteControl'];

export const setGroupControlVisibility = (group) => {
    // hide default controls
    const hideDefaultControls = {
        mtr: false,
        tl: false,
        mt: false,
        tr: false,
        ml: false,
        mr: false,
        bl: false,
        mb: false,
        br: true,
    };
    group.hasBorders = true;
    group.setControlsVisibility(hideDefaultControls);
};

export const customControlVisibility = (element) => {

    // hide default controls
    const hideDefaultControls = {
        mtr: true,
        tl: false,
        mt: false,
        tr: false,
        ml: false,
        mr: false,
        bl: false,
        mb: false,
        br: true,
    };

    // add text type proper controls
    // @Warning can't use directly
    // if(element.custom.type === 'text')
    // as somehow its interfering pencil functionality and make it stop
    const custom = {...element.custom};

    // allow controls
    if(custom.type === 'text') {
        // allow text box resize
        hideDefaultControls.mr = true;
    }

    // disallow controls
    if(custom.type === 'player') {
        // player cant resize
        hideDefaultControls.br = false;
    }

    fabric.Object.prototype.selectionBackgroundColor = 'rgba(0,0,0,0.15)';
    fabric.Object.prototype.hasBorders = false;
    fabric.Object.prototype.borderColor = 'rgba(0,0,0,0.15)';
    fabric.Object.prototype.padding = 15;
    fabric.Object.prototype.controls.mtr.y = 0.5;
    fabric.Object.prototype.controls.mtr.x = -0.5;
    fabric.Object.prototype.controls.mtr.offsetY = 8;
    fabric.Object.prototype.controls.mtr.offsetX = -8;
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = '#000';

    element.setControlsVisibility(hideDefaultControls);

    // allow our custom controls to visible
    element.hasControls = true;
};

const editIcon =
    'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="fill:white;"><circle style="fill:black;" class="st0" cx="256" cy="256" r="256"/>\n' +
    '<path d="M194.9,367.8l-47.4,9.6c-7.7,1.6-14.4-5.2-12.8-12.8l9.6-47.4c1.2-6.1,4.2-11.7,8.7-16.1l7.3-7.2l58.1,58.1l-7.3,7.3\n' +
    '\tC206.6,363.5,201,366.5,194.9,367.8z"/>\n' +
    '<path d="M380.7,189.5l-134,134l-58.1-58.1l134-134c7.3-7.3,19.2-7.3,26.6,0l31.6,31.6C388,170.2,388,182.1,380.7,189.5z"/></svg>';

const deleteIcon =
    'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="fill:white;"><path d="M256,0c141.4,0,256,114.6,256,256S397.4,512,256,512S0,397.4,0,256S114.6,0,256,0z" style="fill:black;"/>\n' +
    '<path d="M175,175c9.4-9.4,24.6-9.4,33.9,0l47,47l47-47c9.4-9.4,24.6-9.4,33.9,0s9.4,24.6,0,33.9l-47,47l47,47\n' +
    '\tc9.4,9.4,9.4,24.6,0,33.9s-24.6,9.4-33.9,0l-47-47l-47,47c-9.4,9.4-24.6,9.4-33.9,0s-9.4-24.6,0-33.9l47-47l-47-47\n' +
    '\tC165.6,199.5,165.6,184.3,175,175z"/></svg>';

const rotateIcon = `data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100">
<circle cx="50" cy="50" r="50"/>
<path style="fill:white;" d="M66.2,34.1c-4.1-4.1-9.7-6.4-15.5-6.6l.8-1c1.1-1.3.9-3.2-.3-4.3-1.3-1.1-3.2-1-4.3.3,0,0,0,0,0,0l-5.6,6.7c-1.1,1.3-1,3.2.3,4.3,0,0,0,0,0,0l6.6,5.8c1.3,1,3.2.9,4.3-.3.5-.6.7-1.4.7-2.2-.1-.8-.5-1.5-1.1-2.1l-1.1-1c9.1.6,16.2,8.1,16.3,17.3.1,4.7-1.6,9.2-4.8,12.6-6.6,6.9-17.6,7.2-24.5.6-3.4-3.2-5.3-7.7-5.4-12.5,0-.8-.4-1.6-1-2.2-.5-.6-1.3-.9-2.1-.8-.8,0-1.6.3-2.1.9-.6.6-.9,1.4-.9,2.2,0,6.3,2.7,12.3,7.2,16.7,4.4,4.3,10.2,6.6,16.3,6.6h.4c6.3-.1,12.2-2.8,16.5-7.3,4.3-4.6,6.6-10.7,6.5-17,0-6.3-2.7-12.3-7.2-16.7Z"/>
    </svg>`;

const useCustomControls = (canvas) => {
    const setPlayer = usePlayerStore((state) => state.set);
    const setText = useTextStore((state) => state.set);
    const {frameIndex} = useSheet();
    const allowDelete = frameIndex === 0;
    const clickedControl = useRef('');
    const showInfo = useSnackbarStore((state) => state.show);

    const {dispatch: onAction} = useAction();

    const eventHandlers = useMemo(
        () => ({
            editControl: (event, transform) => {
                if(!transform) return;
                const {target} = transform;

                if (target && target.custom) {
                    if (target.custom.type === 'text') {
                        setText(target);
                    }

                    if (target.custom.type === 'player') {
                        setPlayer(target);
                    }
                }
            },
            deleteControl: (_event, _transform) => {
                // to prevent accidental delete
                if(clickedControl.current === 'deleteControl') {
                    clickedControl.current = '';

                    if (!allowDelete) {
                        showInfo('board.sheet.move.no_delete', {severity: 'error'});
                        return;
                    }

                    onAction('remove');
                }
            },
        }),
        [setPlayer, setText, onAction, allowDelete, showInfo]
    );

    useEffect(() => {
        customControls.forEach((control) => {
            if (fabric.Object.prototype.controls[control]) return;

            switch (control) {
            case 'editControl': {
                const img = document.createElement('img');
                img.src = editIcon;

                function renderIcon(ctx, left, top, styleOverride, fabricObject) {
                    // only draw for player custom type
                    if (editControlElement.includes(fabricObject.custom?.type)) {
                        const size = this.cornerSize;
                        ctx.save();
                        ctx.translate(left, top);
                        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                        ctx.drawImage(img, -size / 2, -size / 2, size, size);
                        ctx.restore();
                    }
                }

                // for custom all will be objects
                fabric.Object.prototype.controls[control] = new fabric.Control({
                    x: -0.5,
                    y: -0.5,
                    offsetY: -8,
                    offsetX: -8,
                    cursorStyle: 'pointer',
                    mouseUpHandler: eventHandlers[control],
                    render: renderIcon,
                    cornerSize: 24,
                    sizeX: 34,
                    sizeY: 34,
                });

                // for native type we need to add it manually
                fabric.Textbox.prototype.controls[control] = new fabric.Control({
                    x: -0.5,
                    y: -0.5,
                    offsetY: -8,
                    offsetX: -8,
                    cursorStyle: 'pointer',
                    mouseUpHandler: eventHandlers[control],
                    render: renderIcon,
                    cornerSize: 24,
                    sizeX: 34,
                    sizeY: 34,
                });

                break;
            }

            case 'deleteControl': {
                const img = document.createElement('img');
                img.src = deleteIcon;

                function renderIcon(ctx, left, top, styleOverride, fabricObject) {
                    // draw for all controls
                    const size = this.cornerSize;
                    ctx.save();
                    ctx.translate(left, top);
                    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                    ctx.drawImage(img, -size / 2, -size / 2, size, size);
                    ctx.restore();
                }

                // for custom all will be objects
                fabric.Object.prototype.controls[control] = new fabric.Control({
                    x: 0.5,
                    y: -0.5,
                    offsetY: -8,
                    offsetX: 8,
                    cursorStyle: 'pointer',
                    mouseDownHandler: () => clickedControl.current = control,
                    mouseUpHandler: eventHandlers[control],
                    render: renderIcon,
                    cornerSize: 24,
                    sizeX: 34,
                    sizeY: 34,
                });

                // for native type we need to add it manually
                fabric.Textbox.prototype.controls[control] = new fabric.Control({
                    x: 0.5,
                    y: -0.5,
                    offsetY: -8,
                    offsetX: 8,
                    cursorStyle: 'pointer',
                    mouseDownHandler: () => clickedControl.current = control,
                    mouseUpHandler: eventHandlers[control],
                    render: renderIcon,
                    cornerSize: 24,
                    sizeX: 34,
                    sizeY: 34,
                });

                break;
            }

            default:
                break;
            }
        });

        // textbox size corner color
        fabric.Textbox.prototype.transparentCorners = false;
        //fabric.Textbox.prototype.cornerColor = '#000';
        fabric.Textbox.prototype.cornerSize = 8;

        // so it will be easy to grab control
        fabric.Textbox.prototype.controls['br'].sizeX = 28;
        fabric.Textbox.prototype.controls['br'].sizeY = 28;

        // adding the correct text box resizing controls
        fabric.Textbox.prototype.controls['br'].render = function (ctx, left, top, styleOverride, fabricObject) {
            ctx.save();
            ctx.translate(left, top);
            ctx.beginPath();
            ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
            ctx.fillStyle = '#fff';
            ctx.rect(-6, -12, 2, 8);
            ctx.rect(-14, -6, 10, 2);
            ctx.fill();
            ctx.restore();
        };

        fabric.Object.prototype.controls.mtr.render = function (ctx, left, top, styleOverride, fabricObject) {
            const img = document.createElement('img');
            img.src = rotateIcon;
            ctx.save();
            ctx.translate(left, top);
            ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
            ctx.drawImage(img, -24 / 2, -24 / 2, 24, 24);
            ctx.restore();
        };

    }, [canvas, onAction, eventHandlers]);

    const updateControls = useCallback(() => {
        customControls.forEach((control) => {
            fabric.Object.prototype.controls[control].mouseUpHandler = eventHandlers[control];
            fabric.Textbox.prototype.controls[control].mouseUpHandler = eventHandlers[control];
        });
    }, [eventHandlers]);

    return {
        updateControls,
    };
};

export default useCustomControls;
