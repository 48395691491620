export const getTextContent = (target) => {
    if (!target || !target.custom) return '';

    return target ? target.get('text').trim() : '';
};

export const setTextContent = (target, content) => {
    if (!target || !target.custom || !target.canvas) return;

    target.set('text', content);
    target.canvas.renderAll();
};

export const getFormats = (text) => {
    const formats = [];

    if (text.fontWeight === 'bold') {
        formats.push('bold');
    }

    if (text.fontStyle === 'italic') {
        formats.push('italic');
    }

    if (text.linethrough) {
        formats.push('linethrough');
    }

    return formats;
};

export const getFontSize = (text) => {
    return text.fontSize;
};
