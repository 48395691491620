import {CANVAS_HEIGHT} from './canvas-helper';

export const setScaling = (element, isMobile) => {
    if (element.custom?.clipart === 'futbol') {
        element.scaleX = isMobile ? 2 : 1;
        element.scaleY = isMobile ? 2 : 1;
    }
};

export const enliveObject = (element, canvas) => {
    const bounding = element.getBoundingRect();
    if (bounding.top + bounding.height > CANVAS_HEIGHT) {
        element.set('top', CANVAS_HEIGHT - bounding.height);
        // element.setCoords();
    }

    canvas.add(element);
};