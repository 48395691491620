import {incModifiedCounter} from './canvas-helper';

let dblClickTimer = null;

export const MAX_OBJECT_SCALE = 3.5;
export const MIN_OBJECT_SCALE = 0.8;

export const startDblClickTimer = () => {
    if (dblClickTimer !== null) {
        stopDblClickTimer();
    } else {
        dblClickTimer = setTimeout(() => {
            dblClickTimer = null;
        }, 400);
    }
};

export const stopDblClickTimer = () => {
    if (dblClickTimer) {
        clearTimeout(dblClickTimer);
        dblClickTimer = null;
    }
};

export function mouseDownHandler(e) {
    if (e.target?.canvas) {
        e.target.opacity = 0.5;
        e.target.canvas.renderAll();
        startDblClickTimer();
    }
}

export function mouseUpHandler(e) {
    if (e.target?.canvas) {
        e.target.opacity = 1;
        e.target.canvas.renderAll();
    }
}

export function objectMovedHandler(e) {
    stopDblClickTimer();

    incModifiedCounter(e.target.canvas);

    if (e.target) {
        e.target.opacity = 1;
    }
}

export function objectModifiedHandler(e) {
    if (e.target) {
        e.target.opacity = 1;
    }
}

export function objectScaleHandler(e) {
    // restrict scaling for the text object
    // as they are allowed to resize
    if(e.target?.custom?.type === 'text') {
        if(e.target.scaleX > MAX_OBJECT_SCALE) {
            e.target.scaleX = MAX_OBJECT_SCALE;
        }

        if(e.target.scaleY > MAX_OBJECT_SCALE) {
            e.target.scaleY = MAX_OBJECT_SCALE;
        }

        if(e.target.scaleX < MIN_OBJECT_SCALE) {
            e.target.scaleX = MIN_OBJECT_SCALE;
        }

        if(e.target.scaleY < MIN_OBJECT_SCALE) {
            e.target.scaleY = MIN_OBJECT_SCALE;
        }
    }
}

export const clearEvents = (canvas) => {
    stopDblClickTimer();
    canvas.off('mouse:down', null);
    canvas.off('mouse:move', null);
    canvas.off('mouse:up', null);
    canvas.off('selection:created', null);
    canvas.off('selection:updated', null);
    canvas.off('selection:cleared', null);
};

export const clearObjectEvents = (canvas) => {
    canvas.off('mouse:down', null);
    canvas.off('mouse:up', null);
    canvas.off('object:modified', null);
    canvas.off('object:scaling', null);
    canvas.off('object:moving', null);
    canvas.off('object:moved', null);
    canvas.off('object:added', null);
    canvas.off('object:removed', null);
    canvas.off('text:changed', null);
    canvas.off('text:selection:changed', null);
    stopDblClickTimer();
};

export const registerObjectEvents = (canvas, _target) => {
    canvas.on({
        // moved to custom controls
        'mouse:down': (e) => mouseDownHandler(e),
        'mouse:up': (e) => mouseUpHandler(e),
        'object:moving': e => incModifiedCounter(e.target.canvas),
        'object:moved': objectMovedHandler,
        'object:modified': objectModifiedHandler,
        'object:scaling': objectScaleHandler,
    });
};

export const registerGlobalEvents = (canvas, onObjectModified, onObjectAdded, onObjectRemoved) => {
    canvas.on({
        'object:modified': e => {
            incModifiedCounter(e.target.canvas);
            onObjectModified(e);
        },
        'object:added': e => {
            incModifiedCounter(e.target.canvas);
            onObjectAdded(e);
        },
        'object:removed': e => {
            incModifiedCounter(e.target.canvas);
            onObjectRemoved(e);
        },
        'text:changed': e => {
            incModifiedCounter(e.target.canvas);
        },
        'text:selection:changed': e => {
            incModifiedCounter(e.target.canvas);
        }
    });
};
