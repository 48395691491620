import {create} from 'zustand';
import {persist} from 'zustand/middleware';

import {middleware} from 'store/zustand';

import {produce} from 'immer';

// loads campaign from electron
export const loadCampaign = async () => {
    if (window?.infoApi?.campaign === undefined) {
        return Promise.resolve();
    }

    return window.infoApi?.campaign();
};

export const useCampaignStore = create(persist(middleware(set => ({
    title: null,
    header: {
        line1: null,
        line2: null
    },
    banners: [],
    setCampaign: campaign => set(produce(draft => {
        const banners = campaign?.banners || [];
        draft.title = campaign?.title || null;
        draft.header.line1 = campaign?.header?.line1 || null;
        draft.header.line2 = campaign?.header?.line2 || null;

        if (JSON.stringify(draft.banners) !== JSON.stringify(banners)) {
            draft.banners = banners;
        }
    })),
})), 
// ...
{
    name: 'campaign', // unique name
    version: 1,
    migrate: (persistedState) => {
        return persistedState;
    }}
));
