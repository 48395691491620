import {useCallback} from 'react';

import {fabric} from 'fabric';
import {useFrameClear, useFrameUpdate} from 'module/sheet/useFrame';
import {useSheet} from 'module/sheet/useSheet';
import storage from 'store/localStorage';

import {useAction} from './canvas-actions';
import {animFrame} from './canvas-animation';
import {canvasReviver, canvasToJson, setBackground} from './canvas-helper';
import {enliveObject} from './object-helper';
import {useApiLoad, useApiSave} from './useCanvasApi';
import {useCanvasStore} from './zustand';

const localStoragePrefix = 'sheet';

export const useCanvasLoad = () => {
    const {load: backendLoad, loading} = useApiLoad();

    const canvas = useCanvasStore(state => state.canvas);
    const resetCanvasStates = useCanvasStore(state => state.resetCanvasStates);

    const load = useCallback(async (sheet, isMobile = false) => {
        if (!sheet || !canvas) {
            return;
        }

        let data;

        if (sheet.local) {
            console.debug('load local canvas', sheet.id);
            const jsonStr = storage.getItem(`${localStoragePrefix}_${sheet.id}`);

            if (typeof jsonStr !== 'string') {
                return;
            }

            data = JSON.parse(jsonStr);

        } else {
            console.debug('load remote canvas', sheet.id);
            data = await backendLoad(sheet.id);
        }

        console.debug('load canvas', data);

        if (typeof data !== 'object' || data === null) {
            return;
        }

        canvas.isLoading = true;
        canvas.remove(...canvas.getObjects());
        fabric.util.enlivenObjects(data.canvas.objects, (objs) => {
            objs.forEach((item) => {
                enliveObject(item, canvas);
            });
            setBackground(canvas, data.background, () => {
                resetCanvasStates(canvasToJson(canvas)); // sets dirty to false
            });
            canvas.requestRenderAll(); // Make sure to call once we're ready!
            canvas.isLoading = false;
        }, '', (json, element) => {
            canvasReviver(json, element, isMobile);
        });

        return data;
    }, [backendLoad, canvas, resetCanvasStates]);

    return {load, loading};
};

export const useCanvasSave = () => {
    const {frameIndex} = useSheet();

    const {update: updateFrame, loading: loading1} = useFrameUpdate();
    const {save: backendSave, loading: loading2} = useApiSave();

    const canvas = useCanvasStore(state => state.canvas);
    const setDirty = useCanvasStore(state => state.setDirty);

    const save = useCallback(async (sheet, custom = {}) => {
        if (!sheet || !canvas) {
            return;
        }

        const canvasJson = canvasToJson(canvas);
        delete (canvasJson['backgroundImage']); // do not save backgroundImage

        const data = {
            ...custom,
            width: canvas.width,
            height: canvas.height,
            canvas: canvasJson
        };

        console.log('save canvas', data, sheet);

        if (frameIndex === 0) {
            if (sheet.local) {
                storage.setItem(`${localStoragePrefix}_${sheet.id}`, JSON.stringify(data));
            } else {
                await backendSave(sheet.id, data);
            }
        }

        if (sheet.frames.length > 0) {
            await updateFrame(sheet, animFrame(canvas), frameIndex);
        }

        setDirty(false);
    }, [backendSave, canvas, frameIndex, setDirty, updateFrame]);

    return {save, loading: loading1 || loading2};
};

export const useCanvasClear = () => {
    const setDirty = useCanvasStore(state => state.setDirty);

    const {dispatch} = useAction();

    const {clear: clearFrames, loading} = useFrameClear();

    const clear = useCallback(async (sheet) => {
        await clearFrames(sheet);
        dispatch('clear');
        setDirty(true);
    }, [clearFrames, dispatch, setDirty]);

    return {clear, loading};
};