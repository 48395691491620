import Preview01 from './01.jpg';
import Preview02 from './02.jpg';
import Preview03 from './03.jpg';
import Preview041 from './04_1.jpg';
import Preview042 from './04_2.jpg';
import Preview05 from './05.jpg';
import Preview06 from './06.jpg';

const Previews = {
    '01': Preview01,
    '02': Preview02,
    '03': Preview03,
    '04_1': Preview041,
    '04_2': Preview042,
    '05': Preview05,
    '06': Preview06,
};

export default Previews;
