import React, {forwardRef, useEffect, useState} from 'react';

import {
    Avatar, ButtonBase, Slide, Stack
} from '@mui/material';
import {MuiColorInput} from 'mui-color-input';

import Icons from 'assets/icons';
import {
    Dialog, HiddenFileInput,
} from 'components';
import {imageUrl} from 'lib/media';
import {useLayoutStore, useSnackbarStore} from 'store';

import {useApiUpdate, useApiUpload} from './useClientApi';

import {SubmitableField} from 'form';
import {Form, FormField, useForm} from 'form/index';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const styles = {
    clientLogoHolder: {
        width: 120,
        height: 120,
        borderRadius: '50%',
        backgroundColor: 'white.main',
        position: 'relative'
    },
    absolutIconBox: {
        position: 'absolute',
        right: '0',
        bottom: '0',
        backgroundColor: 'red.highlight',
        borderRadius: '50%',
        height: 35,
        width: 35,
    },
    avatar: {
        width: 120,
        height: 120
    },
    uploadButton: {
        backgroundColor: 'red.highlight',
        borderRadius: '50%',
        padding: 1,
        '> svg': {
            fill: theme => theme.palette.white.main,
            width: 16,
            height: 16
        }
    }
};

const Edit = ({client, onClose, onSave}) => {
    const [editable, setEditable] = useState('');

    const showInfo = useSnackbarStore((state) => state.show);

    const setShowLoading = useLayoutStore((state) => state.setShowLoading);

    const {update, loading: loading1, data, errors} = useApiUpdate(client);
    const {upload, loading: loading2} = useApiUpload();

    const rhf = useForm(data, errors);

    const doSave = () => {
        rhf.methods.handleSubmit(async formData => {
            formData.id = client.id;

            try {
                const res = await update(formData);
                setEditable(null);
                showInfo('client.action.save_success');
                onSave && onSave(res);
            } catch (e) {
                showInfo(e.message, {severity: 'warning'});
            }
        })();
    };

    const doUpload = async (e) => {
        if (e.target.files.length === 1) {
            const res = await upload(client.id, e.target.files[0]);
            e.target.value = null;
            onSave && onSave(res);
        }
    };

    useEffect(() => {
        rhf.methods.setFocus(editable, {shouldSelect: true});
    }, [editable, rhf.methods]);

    useEffect(() => {
        setShowLoading(loading1 || loading2);
        return () => {
            setShowLoading(false);
        };
    }, [loading1, loading2, setShowLoading]);

    return (
        <Dialog
            maxWidth={false}
            pt={0}
            open={true}
            TransitionComponent={Transition}
            title="client.action.edit"
            handleClose={onClose}
            dividers={true}
        >
            <Stack justifyContent="center" alignItems="center" mb={2} mt={2}>
                <Stack sx={styles.clientLogoHolder} justifyContent="center" alignItems="center">
                    <Avatar sx={styles.avatar} alt={client.name} src={imageUrl(client.image, {w: 120, h: 120})}/>
                    <Stack sx={styles.absolutIconBox} alignItems="center" justifyContent="center">
                        <ButtonBase component="label" sx={styles.uploadButton}>
                            <Icons.Camera/>
                            <HiddenFileInput name="file" onChange={doUpload}/>
                        </ButtonBase>
                    </Stack>
                </Stack>
            </Stack>
            <Form methods={rhf.methods} onSubmit={doSave}>
                <Stack direction="column" justifyContent="flex-end" p={2}>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <FormField
                            name="name"
                            label="client.name"
                        />
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <FormField
                            name="contact.phone"
                            label="global.phone"
                        />
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <FormField
                            name="contact.email"
                            label="global.email"
                        />
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <FormField
                            name="colors.value1"
                            label="client.color.primary"
                            as={MuiColorInput}
                            format="hex"
                        />
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <FormField
                            name="colors.value2"
                            label="client.color.secondary"
                            as={MuiColorInput}
                            format="hex"
                        />
                    </SubmitableField>
                </Stack>
            </Form>
        </Dialog>
    );
};

export default Edit;