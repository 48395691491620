export const getWwanStatus = async () => {
    if (window?.sysInfoApi?.getWwanStatus === undefined) {
        return Promise.resolve();
    }

    return window.sysInfoApi.getWwanStatus();
};

export const hasSysInfoAPi = () => {
    return !!window.sysInfoApi;
};
