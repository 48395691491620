import React from 'react';

import {Box, IconButton, useMediaQuery, useTheme} from '@mui/material';

import icons from 'assets/icons';
import DropDown from 'assets/icons/icon_dropdown.svg?react';

const svgIcons = {
    'arrow': icons.Arrow,
    'arrowDashed': icons.ArrowDashed,
    'circle': icons.Circle,
    'color': icons.Color,
    'colorQuadrat': icons.ColorQuadrat,
    'cone': icons.ConeLarge,
    'coneSmall': icons.ConeSmall,
    'down': icons.ArrowDownward,
    'futbol': icons.SoccerBall,
    'ladder': icons.Ladder,
    'lineup': icons.Lineup,
    'pencil': icons.Pencil,
    'player': icons.Tshirt,
    'playerCircle': icons.CircleFull,
    'pole': icons.Pole,
    'rect': icons.Rectangle,
    'select': icons.Pointer,
    'tactixX': icons.TactixX,
    'text': icons.Text,
    'timeline': icons.Timeline,
    'triangle': icons.Triangle,
    'up': icons.ArrowUpward,
};

const styles = {
    root: {
        position: 'relative',
        padding: 0,
        width: {
            mobile: '12.5%',
            desktop: '7.692307%',
        },
        borderRadius: 0,
        fontSize: '12px',
    },
    selected: {
        backgroundColor: 'black.light',
    },
    badge: {
        position: 'absolute',
        top: '50%',
        right: '50%',
        transform:  {
            desktop: 'translate(calc(50% + 25px), calc(-50% - 15px))',
            mobile: 'translate(calc(50% + 10px), calc(-50% - 10px))',
        },
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        backgroundColor: 'green.main',
        color: 'white.main',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid',
        borderColor: 'grey.darkest',
        fontWeight: '600'
    },
};

const ToolbarButton = ({color, icon, badge, selected = false, dropdown = false, ...rest}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

    const SvgIcon = svgIcons[icon];

    if (SvgIcon === undefined) {
        console.error(`undefined icon ${icon}`);
        return null;
    }

    return <IconButton color="secondary" sx={[styles.root, selected && styles.selected]} disableRipple {...rest}>
        <SvgIcon
            style={{
                stroke: color ? color : '#fff',
                fill: color ? color : '#fff',
                width: isMobile ? 25 : 35,
                height: isMobile ? 25 : 35,
            }}
        />
        {dropdown && <DropDown
            style={{
                fill: selected ? theme.palette.white.main : theme.palette.grey.darker,
                display: 'block',
                position: 'absolute',
                bottom: '5px',
                right: '5px',
                width: isMobile ? 5 : 10,
                height: isMobile ? 5 : 10,
            }}
        />}
        {badge && <Box sx={styles.badge}>{badge}</Box>}
    </IconButton>;
};

export default ToolbarButton;