import {useEffect} from 'react';

import {useMediaQuery, useTheme} from '@mui/material';

import {useCanvasLoad} from 'canvas/useCanvas';
import {useGroup} from 'module/group/useGroup';
import {usePrefsStore} from 'store';

import {useSheet} from './useSheet';

const Switcher = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const setBackground = usePrefsStore((state) => state.setBackground);

    const {getById: getSheetById, selected} = useSheet();
    const {setSelected: setSelectedGroup} = useGroup();
    const {load: loadCanvas} = useCanvasLoad();

    useEffect(() => {
        const handler = async () => {
            const selectedSheet = getSheetById(selected);

            if (!selectedSheet) {
                return;
            }

            const data = await loadCanvas(selectedSheet, isMobile);
            console.debug('Switcher loaded canvas', data);

            if (data === undefined) {
                return;
            }

            if (typeof data?.background === 'string') {
                setBackground(data.background);
            }

            setSelectedGroup(selectedSheet.group);
        };

        console.log('Switcher run', selected);

        if (!selected) {
            return;
        }

        handler();
        // we can not use selectedSheet as a dependency in this case, this would cause to many re-renders
    }, [selected, setBackground, loadCanvas, isMobile, setSelectedGroup, getSheetById]);
    //}, [selected]);
};

export default Switcher;