import AmericanFootball from '../backgrounds/americanfootball.svg?react';
import Basketball from '../backgrounds/basketball.svg?react';
import Handball from '../backgrounds/handball.svg?react';
import Icehockey from '../backgrounds/icehockey.svg?react';
import Soccer from '../backgrounds/soccer.svg?react';
import SoccerBW from '../backgrounds/soccer_bw.svg?react';
import SoccerLandscape from '../backgrounds/soccer_landscape.svg?react';
import SoccerLandscapeBW from '../backgrounds/soccer_landscape_bw.svg?react';
import SoccerQuarterBW from '../backgrounds/soccer_quarter_bw.svg?react';
import SoccerSquaresBW from '../backgrounds/soccer_squares_bw.svg?react';
import SoccerThird from '../backgrounds/soccer_third.svg?react';
import SoccerThirdBW from '../backgrounds/soccer_third_bw.svg?react';
import SoccerVerticalZones from '../backgrounds/soccer_vertical_zones.svg?react';
import SoccerVerticalZonesBW from '../backgrounds/soccer_vertical_zones_bw.svg?react';
import FieldWhite from '../backgrounds/white.svg?react';

const BackgroundComponents = {
    AmericanFootball,
    Basketball,
    Handball,
    Icehockey,
    Soccer,
    SoccerBW,
    SoccerLandscape,
    SoccerLandscapeBW,
    SoccerQuarterBW,
    SoccerSquaresBW,
    SoccerThird,
    SoccerThirdBW,
    SoccerVerticalZones,
    SoccerVerticalZonesBW,
    FieldWhite
};

export default BackgroundComponents;
