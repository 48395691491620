import React, {memo, useMemo} from 'react';

import BackHandIcon from '@mui/icons-material/BackHand';
import PersonIcon from '@mui/icons-material/Person';
import VoiceOverOffIcon from '@mui/icons-material/VoiceOverOff';
import {Avatar, Box, LinearProgress, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';

import {useTranslation} from 'hooks';
import {useUserStore} from 'module/user';
import {useLayoutStore, useSnackbarStore} from 'store';

import {toggleMicrophone} from './api';
import ConnectionIcon from './ConnectionIcon';
import MicrophoneIcon from './MicrophoneIcon';

const styles = {
    item: {
        height: '60px',
        color: 'white.main',
        borderColor: 'grey.dark',
        padding: '10px',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',

        background: '#0f0f0f',

        borderBottom: '1px solid',
        borderBottomColor: '#1f1f1f',
    },
    avatarContainer: {
        minWidth: '1px',
        position: 'relative',
        marginRight: '15px',
    },
    avatar: {
        bgcolor: 'white.main',
        color: 'black.main',
        position: 'relative',
    },
    pingItem: {
        minWidth: '1px',
        position: 'absolute',
        bottom: '-2px',
        right: '-2px',
    },
    listItemIconStyles: {
        minWidth: '40px',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 0,
        marginLeft: '10px',
    },
    listItemButtonIconStyles: {
        background: 'white',
        borderRadius: '50%',

        ':hover': {
            backgroundColor: 'white !important',
        },
    },
    loadingBar: {
        width: '100%',
        padding: '0',
        margin: '0 0 -80px 0',
        height: '80px',
        overflowY: 'visible',
        overflowX: 'visible',
    },
};
const avatarCrop = '?w=120&h=120&m=crop';

const UserItem =({user, role, setEditor, loading, infoLineSecondaryText = ''}) => {
    const translate = useTranslation();
    const showInfo = useSnackbarStore((state) => state.show);
    const currentRoom = useUserStore((state) => state.currentRoom);
    //const myUser = useUserStore((state) => state.user);
    const avatarUrl = user.image?.url ? user.image.url + avatarCrop : undefined;
    // whether slide animations are active
    const showTimeline = useLayoutStore((state) => state.showTimeline);

    const doSetEditor = () => {
        if (showTimeline) {
            showInfo('lockerroom.message.moves_no_edit_change');
        } else {
            setEditor();
        }
    };

    // User-Info-Text in List (Admin, Editor, User)
    let infoLinePrimaryTypographyProps = {
        color: 'white.main',
        fontWeight: 'bold',
        fontSize: '12px',
    };

    if (user.adminId && user.status.editing) { // Moderator & Editor
        infoLineSecondaryText += ' | ' + translate('lockerroom.editor');
    } else {
        if (user.adminId && !user.status.editing) { // Moderator
            infoLineSecondaryText += ' | ' + translate('lockerroom.moderator');
        } else {
            if(user.status.editing) { // Editor
                infoLineSecondaryText += ' | ' + translate('lockerroom.editor');
            } else { // User
                infoLinePrimaryTypographyProps = {
                    color: 'white.darkest',
                    fontWeight: 'normal',
                    fontSize: '12px',
                };
            }
        }
    }

    // /User-Info-Text in List (Admin, Editor, User)
    return useMemo(() =>(<>
        {loading && <Box sx={styles.loadingBar}>
            <LinearProgress sx={{backgroundColor: 'green.main', height: '60px'
            }}/>
        </Box>}

        {/* row */}
        <ListItem disableGutters key={user?.id} sx={[styles.item, user.status.editing && {backgroundColor: '#2f2f2f'}]}>

            {/* avatar & ping */}
            <ListItemAvatar sx={styles.avatarContainer}>

                {/* ping */}
                <ListItemIcon sx={styles.pingItem}>
                    <ConnectionIcon ping={user.status.ping} />
                </ListItemIcon>

                {/* avatar */}
                <Avatar sx={styles.avatar} src={avatarUrl}>
                    <PersonIcon />
                </Avatar>

            </ListItemAvatar>

            {/*
            id: data.userId,
            channels: new Set([socket?.id]),
            firstname: 'some',
            lastname: 'user',
            status: {
                audio: true,
                microphone: true,
                editing: false,
                hand: false,
            },
            */}

            {/* user names and roles (admin, editor) */}
            <ListItemText primary={user.firstname + ' ' + user.lastname} secondary={infoLineSecondaryText} primaryTypographyProps={infoLinePrimaryTypographyProps} secondaryTypographyProps={{color: 'black.darkest', fontSize: '10px'}} />

            {/* handzeichen */}
            {user.status.hand &&
                <ListItemIcon sx={styles.listItemIconStyles}><BackHandIcon sx={{color: 'orange.main', fontSize: 20}} /></ListItemIcon>
            }

            {user.status.connected === false ? 
                <ListItemIcon sx={styles.listItemIconStyles}><VoiceOverOffIcon sx={{color: 'orange.main', fontSize: 20}} /></ListItemIcon>
                : null
            }

            {/* Action-Buttons */}
            {!loading && role === 'admin' ?
                user.status.editing ?
                    <ListItemIcon sx={styles.listItemIconStyles}><PersonIcon sx={{color: 'green.main', fontSize: 24}} /></ListItemIcon>
                    :
                    <ListItemButton sx={[styles.listItemIconStyles, styles.listItemButtonIconStyles]} onClick={() => doSetEditor()}>
                        <PersonIcon sx={{color: 'black.main', fontSize: 24}} />
                    </ListItemButton>
                :
                user.status.editing &&
                    <ListItemIcon sx={styles.listItemIconStyles}><PersonIcon sx={{color: 'green.main', fontSize: 24}} /></ListItemIcon>
            }

            {/* {role === 'admin' ?
            <ListItemButton sx={[styles.listItemIconStyles]} onClick={() => toggleMicrophone(currentRoom, users.find((u) => (u.appId === user?.appId) ))}>
                {user.status.microphone ? <MicIcon sx={{color: 'green.main', fontSize: 20}} /> : <MicOff sx={{color: 'grey.darkest', fontSize: 20}} />}
            </ListItemButton> : null
            } */}

            {role === 'admin' ?

                user.status.microphone ?
                    (
                        <ListItemButton sx={[styles.listItemIconStyles, styles.listItemButtonIconStyles]} onClick={() => toggleMicrophone(currentRoom, user)}>
                            <MicrophoneIcon volume={user.status.volume}/>
                        </ListItemButton>
                    ) : (
                        <ListItemIcon sx={styles.listItemIconStyles}>
                            <MicrophoneIcon volume={-1}/>
                        </ListItemIcon>
                    )
                :
                user.status.microphone ?
                    (
                        <ListItemIcon sx={styles.listItemIconStyles}>
                            <MicrophoneIcon volume={user.status.volume}/>
                        </ListItemIcon>
                    ) : (
                        <ListItemIcon sx={styles.listItemIconStyles}>
                            <MicrophoneIcon volume={-1}/>
                        </ListItemIcon>
                    )
            }

        </ListItem>
    </>),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, role, user, avatarUrl, currentRoom]);

};

export default memo(UserItem);
