import React, {useEffect, useState} from 'react';

import {Avatar, Box, ButtonBase, Grid, Stack, Typography} from '@mui/material';

import {getPlayerInfo, getPlayerName, getPlayerNumber, setPlayerInfo, setPlayerName} from 'canvas/Drawer/player-utils';
import {Text} from 'components';
import {useTranslation} from 'hooks';
import {usePlayerStore} from 'module/player/zustand';

import {Form, FormField, useForm} from 'form';

const styles = {
    formContainer: {
        mt: 2
    },
    button: {
        color: 'white.main',
        marginLeft: 4,
        fontSize: '16px'
    }
};

const Edit = ({player}) => {
    const translate = useTranslation();
    const avatarCrop = '?w=100&h=100&m=crop';

    const unsetPlayer = usePlayerStore(state => state.unset);

    const [errors] = useState({});

    const [formPlayer] = useState({
        id: player.custom.playerId,
        name: getPlayerName(player),
        number: getPlayerNumber(player),
        info: getPlayerInfo(player),
        image: null, // XXX ToDo: read image from playerdata
    });

    const [avatar, setAvatar] = useState(formPlayer.image?.url + avatarCrop);

    useEffect(() => {
        setAvatar(formPlayer.image?.url + avatarCrop);
    }, [formPlayer]);

    const rhf = useForm(formPlayer, errors);

    const doClose = () => {
        unsetPlayer();
    };

    const onSubmit = async (formData) => {
        setPlayerName(player, formData.name);
        setPlayerInfo(player, formData.info);
        doClose();
    };

    return <Grid container direction="column">
        <Form {...rhf} onSubmit={onSubmit}>
            <Stack sx={styles.formContainer} direction="column" justifyContent="space-between" spacing={2}>
                <FormField
                    name="number"
                    variant="standard"
                    margin="normal"
                    label={translate('board.player.number')}
                    maxLength={2}
                    autoComplete="off"
                    fullWidth={true}
                />
                <FormField
                    name="name"
                    variant="standard"
                    margin="normal"
                    label={translate('global.name')}
                    autoComplete="off"
                    fullWidth={true}
                />
                <FormField
                    name="info"
                    variant="standard"
                    margin="normal"
                    label={translate('global.info')}
                    autoComplete="off"
                    fullWidth={true}
                />
                {formPlayer?.image?.url && (<>
                    <FormField
                        type="hidden"
                        name="image"
                    />
                    <Box direction="column" alignItems="start" ml={0.5}>
                        <Typography color="secondary" mb={1} variant="body2">{translate('board.player.avatar')}</Typography>
                        <Avatar alt={translate('board.player.avatar')} src={avatar} />
                    </Box>
                </>
                )}

                <Stack direction="row" justifyContent="flex-end" pb={1} pt={2}>
                    <ButtonBase disableRipple sx={styles.button} onClick={doClose}>
                        <Text>ui.actions.cancel</Text>
                    </ButtonBase>
                    <ButtonBase disableRipple sx={styles.button} type="submit">
                        <Text>ui.actions.save</Text>
                    </ButtonBase>
                </Stack>

            </Stack>

        </Form>
    </Grid>;
};

export default Edit;
