import React, {useState} from 'react';

import {
    Avatar, Stack, Tab, Tabs, Typography,
} from '@mui/material';

import {
    Drawer,
    MenuHeader,
    Text} from 'components';
import {imageUrl} from 'lib/media';
import {useLayoutStore} from 'store';

import Equipment from './Equipment';
import Information from './Information';
import {useInfo} from './useInfo';

const styles = {
    container: {
        alignItems: 'center',
        paddingX: 2.5,
    },
    headlineContainer: {
        overflow: 'hidden',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 1,
    },
    avatarHolder: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 160,
        height: 160,
        borderRadius: '50%',
        backgroundColor: 'white.main',
        padding: 2.5,
        margin: 2.5,
        position: 'relative'
    },
    avatar: {
        width: 160,
        height: 160
    },
    tabContainer: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'grey.darkest2',
        width: '100%',
        alignItems: 'center',
    },
};

const Member = ({member}) => {
    const [tab, setTab] = useState('information');

    const closeMenu = useLayoutStore(state => state.closeMenu);
    const setShowMember = useLayoutStore(state => state.setShowMember);

    const memberInfo = useInfo();
    const {name} = memberInfo(member);

    const goBack = () => {
        setShowMember(false);
    };

    console.log(member);

    return (
        <Drawer open={true} onClose={goBack}>
            <MenuHeader text={member.name} handleBackButton={goBack} handleClose={closeMenu}/>

            <Stack sx={styles.container} direction="column">
                <Stack sx={styles.avatarHolder}>
                    <Avatar sx={styles.avatar} alt={member.name} src={imageUrl(member.user?.image, {w: 100, h: 100})}/>
                </Stack>
                <Stack direction="row" sx={styles.headlineContainer}>
                    <Typography>{name}</Typography>
                </Stack>
                <Stack sx={styles.tabContainer}>
                    <Tabs value={tab} onChange={(_e, tab) => setTab(tab)}>
                        <Tab value="information" label={<Typography><Text>team.player.data</Text></Typography>}/>
                        <Tab value="equipment" label={<Typography><Text>user.equipment</Text></Typography>}/>
                    </Tabs>
                </Stack>
            </Stack>

            {tab === 'information' && <Information member={member}/>}
            {tab === 'equipment' && <Equipment member={member}/>}

        </Drawer>
    );
};

export default Member;