import {create} from 'zustand';

import {middleware} from 'store/zustand';

import {produce} from 'immer';

const useKeyboardStore = create(middleware(set => ({
    visible: false,
    value: '',
    onInput: undefined,
    top: undefined,
    left: undefined,
    maxLength: 0,
    hideOnEnter: true,
    layout: 'default',
    show: (opts = {
        value: '',
        onInput: undefined,
        top: 0,
        left: 0,
        maxLength: 0,
        hideOnEnter: true,
        layout: 'default'
    }) => set(produce(draft => {
        draft.visible = true;
        draft.value = opts.value;
        draft.onInput = opts.onInput;
        draft.top = opts.top;
        draft.left = opts.left;
        draft.hideOnEnter = opts.hideOnEnter;
        draft.maxLength = opts.maxLength;
        draft.layout = opts.layout;
    })),
    hide: () => set(produce(draft => {
        draft.visible = false;
        draft.value = '';
    }))
})));

export default useKeyboardStore;
