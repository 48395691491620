import {v4 as uuidv4} from 'uuid';
import {create} from 'zustand';
import {persist} from 'zustand/middleware';

import {middleware} from 'store/zustand';

import {produce} from 'immer';

// this is the "real" migration group id, not storing uuids in an uuid field causes problems with server side validation
export const migrationGroupId = 'ef1715ab-f7bb-4be7-8d51-cdd89186fc58';
export const legacyMigrationGroupId = 'id_migration_v0_v1_2022';
export const legacyMigrationGroupName = 'nicht zugeordnet';

const addSortField = (state) => {
    state.groups.forEach((group, index) => {
        group.sort = index+1;
    });
    return state;
};

// this is a private store never use is directly
export const useLocalStore = create(persist(middleware((set, get) => ({
    groups: [],
    addMigrationGroup: group => set(produce(draft => {
        const grp = {id: migrationGroupId, name: legacyMigrationGroupName, ...group, modified: new Date().getTime(), sort: draft.groups.length + 1};
        draft.groups.push(grp);
    })),
    add: group => set(produce(draft => {
        const grp = {id: uuidv4(), ...group, modified: new Date().getTime()};

        if (grp.sort === undefined) {
            grp.sort = get().getNextSort();
        }

        draft.groups.push(grp);
        draft.selected = draft.selected ? draft.selected : grp?.id;
    })),
    getNextSort: () => {
        // sort descending, do not use length + 1, there might be gaps between sort numbers
        const groups = get().groups.slice().sort((a, b) => b.sort - a.sort);

        if (groups.length === 0) {
            return 1;
        }

        return groups[0].sort + 1;
    },
    del: groupId => set(produce(draft => {
        const index = draft.groups.findIndex(grp => grp?.id === groupId);

        if (index !== -1) {
            draft.groups.splice(index, 1);
        }
    })),
    delAll: () => set(produce(draft => {
        draft.groups = [];
    })),
    upd: group => set(produce(draft => {
        const index = draft.groups.findIndex(grp => grp?.id === group?.id);

        if (index !== -1) {
            draft.groups[index] = {...group, modified: new Date().getTime()};
        }
    })),
    sort: (group, direction) => set(produce(draft => {
        try {
            const currentIndex = draft.groups.findIndex(grp => grp?.id === group?.id);

            const offset = direction === 'up' ? 1 : -1;
            const newSort = group.sort + offset;
            const currentSort = group.sort;
            const nextElementIndex = draft.groups.findIndex(grp => grp.sort === newSort);

            draft.groups[currentIndex].sort = newSort;
            draft.groups[nextElementIndex].sort = currentSort;
        }
        catch(ex) {
            console.warn(`sort field not exist adding it`);
            // try to add sort field
            addSortField(draft);
        }
    })),
    get: id => {
        const index = get().groups.findIndex(grp => grp?.id === id);

        if (index === -1) {
            return null;
        }

        return get().groups[index];
    },
    getByName: name => {
        const index = get().groups.findIndex(grp => grp.name === name);

        if (index === -1) {
            return null;
        }

        return get().groups[index];
    },

    // needed for migrations only
    updateId: (oldId, newId) => set(produce(draft => {
        const index = draft.groups.findIndex(grp => grp?.id === oldId);

        if (index < 0) {
            return;
        }

        draft.groups[index] = {
            ...draft.groups[index],
            id: newId,
        };
    })),
})), {
    name: 'groups', // unique name
    version: 2, /** NOTE: make sure migrations length should be version NO. + 1 */
    migrate: (persistedState, previousVersion) => {
        // Migrate store here...
        const migrations = [
            // version 0
            // to make sure we align with logic just add this version as array filler
            (state) => state, // mostly will not use just for filler
            // version 1
            // add sorting field
            (state) => {
                return addSortField(state);
            },
            // version 2
            (state) => {
                delete state.selected;

                // we need valid uuids
                state.groups.forEach(group => {
                    if (group.id === legacyMigrationGroupId) {
                        group.id = migrationGroupId;
                    }
                });

                delete state.migrationGroupId;
                delete state.migrationGroupName;

                return state;
            },
        ];

        let state = persistedState;

        for (let i = previousVersion + 1; i < migrations.length; i++) {
            state = migrations[i](state);
        }

        return state;
    }}
));
