import React from 'react';

import {IconButton, Typography, useMediaQuery, useTheme} from '@mui/material';

import Icons from 'assets/icons';

const styles = {
    button: {
        borderRadius: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: 2,
        width: '100%',
    },

    label: {
        color: 'grey.mainlight',
        fontSize: '16px',
        fontWeight: '400',
        marginLeft: '30px',
    },

    labelDisabled: {
        color: 'grey.lightest',
    }
};

const Icon = ({icon, text, onClick, fill, disabled, ...rest}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

    let UsedIcon = Icons[icon];

    return <IconButton
        sx={styles.button}
        disableFocusRipple={false}
        disableRipple={false}
        disableTouchRipple={false}
        onClick={onClick}
        disabled={disabled}
        {...rest}>
        <UsedIcon
            style={{
                fill: disabled ? theme.palette.grey.lightest : (fill ? fill : theme.palette.grey.mainlight),
                width: isMobile ? 'auto' : 28,
                height: isMobile ? 20 : 28,
            }}
        />{text && <Typography sx={[styles.label, disabled && styles.labelDisabled]}>{text}</Typography>}
    </IconButton>;
};

export default Icon;
