import React, {Children, cloneElement, useEffect} from 'react';
import {useFormContext} from 'react-hook-form';

import {Check as CheckIcon, Close as CloseIcon, Edit as EditIcon} from '@mui/icons-material';
import {IconButton, Stack} from '@mui/material';

const SubmitableField = ({editable, setEditable, children, onSave}) => {
    let name = null;

    const {setFocus, resetField} = useFormContext();

    const disabledChildren = Children.map(children, (child) => {
        if (name === null && child.props.name) {
            name = child.props.name;
        }

        return cloneElement(child, {
            disabled: editable !== name,
        });
    });

    const doEdit = () => {
        setFocus(name, {shouldSelect: false});
        setEditable(name);
    };

    const doCancel = () => {
        setEditable('');
    };

    useEffect(() => {
        resetField(name);
    }, [name, resetField, editable]);

    return <Stack direction="row">
        <Stack direction="column" flexGrow={1}>
            {disabledChildren}
        </Stack>
        {editable !== name && <IconButton color="secondary" onClick={doEdit} disableRipple><EditIcon/></IconButton>}
        {editable === name && <>
            <IconButton color="third" onClick={doCancel} disableRipple><CloseIcon/></IconButton>
            <IconButton color="primary" onClick={onSave} disableRipple><CheckIcon/></IconButton>
        </>}
    </Stack>;
};

export default SubmitableField;