import React from 'react';

import {Card, CardContent, CardHeader, Divider, Grid, Typography} from '@mui/material';

import {useTranslation} from 'hooks';

const Modem = ({data}) => {
    if (typeof data !== 'object') {
        return null;
    }

    return <Grid container direction="column">

        <Grid container direction={'row'}>
            <Grid item md={5}>
                manufacturer
            </Grid>
            <Grid item md={6}>
                {data?.generic?.manufacturer}
            </Grid>
        </Grid>

        <Grid container direction={'row'}>
            <Grid item md={5}>
                model
            </Grid>
            <Grid item md={6}>
                {data?.generic?.model}
            </Grid>
        </Grid>

        <Grid container direction={'row'}>
            <Grid item md={5}>
                equipment id
            </Grid>
            <Grid item md={6}>
                {data?.generic?.['equipment-identifier']}
            </Grid>
        </Grid>

        <Grid container direction={'row'}>
            <Grid item md={5}>
                firmware revision
            </Grid>
            <Grid item md={6}>
                {data?.generic?.revision}
            </Grid>
        </Grid>

        <Grid container direction={'row'}>
            <Grid item md={5}>
                ports
            </Grid>
            <Grid item md={6}>
                {data?.generic?.ports?.join(', ')}
            </Grid>
        </Grid>

    </Grid>;
};

const Wwan = ({data}) => {
    const translate = useTranslation();

    if (typeof data !== 'object') {
        return null;
    }

    console.log('wwan', data);
    return <Card>
        <CardHeader title={translate('board.selftest.wwan')} />
        <Divider/>
        <CardContent>
            {data.error && <Typography>{data.error}</Typography>}
            {!data.error && <Modem data={data?.modem}/>}
        </CardContent>
    </Card>;
};

export default Wwan;
