import React, {useEffect} from 'react';

import {
    Clear as CloseIcon,
} from '@mui/icons-material';
import {ButtonBase, Paper, Stack, Typography, useMediaQuery, useTheme} from '@mui/material';

import {useAction} from 'canvas/canvas-actions';
import {useCanvasClear, useCanvasLoad} from 'canvas/useCanvas';
import {useTranslation} from 'hooks';
import {useGroupLoad} from 'module/group/useGroup';
import {useSheet, useSheetLoad} from 'module/sheet/useSheet';
import {useUserUtils} from 'module/user/useUser';
import {useLayoutStore, useSnackbarStore} from 'store';

import InfobarIcon from './Icon';

const styles = {
    infoContainer: {
        height: '100%',
        backgroundColor: 'black.light',
        borderRadius: 0,
    },
    headerContainer: {
        borderBottom: '2px solid',
        borderColor: '#5f5f5f'
    },
    headerText: {
        fontSize: '18px',
        color: '#dddddd',
    },
    menueCloseIcon: {
        color: 'red.dark'
    }
};

const Options = ({onComplete}) => {
    const translate = useTranslation();

    const {dispatch} = useAction();

    const setShowFieldSelector = useLayoutStore((state) => state.setShowFieldSelector);

    const {frameIndex, selectedSheet: sheet} = useSheet();

    const {clear: clearCanvas} = useCanvasClear();
    const {load: loadCanvas} = useCanvasLoad();

    const {loading: loading1, load: loadSheets} = useSheetLoad();
    const {loading: loading2, load: loadGroups} = useGroupLoad();

    const {isAuthenticated} = useUserUtils();

    const showInfo = useSnackbarStore((state) => state.show);

    const setShowLoading = useLayoutStore((state) => state.setShowLoading);

    const clear = async () => {
        await clearCanvas(sheet);
        onComplete();
    };

    const undo = async () => {
        await loadCanvas(sheet, isMobile);
        dispatch('push');
        onComplete();
    };

    const reload = async () => {
        if (!isAuthenticated) {
            return;
        }

        try {
            await loadSheets(false);
            await loadGroups(false);
        } catch (e) {
            console.log(e.cause);
            showInfo(e.message, {severity: 'warning'});
        }

        onComplete();
    };

    const selectBackground = () => {
        setShowFieldSelector(true);
        onComplete();
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

    useEffect(() => {
        setShowLoading(loading1 || loading2);

        return () => {
            setShowLoading(false);
        };
    }, [loading1, loading2, setShowLoading]);

    return <Paper sx={styles.infoContainer}>
        <Stack direction="row" justifyContent="space-between" p={2} sx={styles.headerContainer}>
            <Typography sx={styles.headerText}>{translate('board.layout.sheet_actions')}</Typography>
            <ButtonBase onClick={onComplete}>
                <CloseIcon sx={styles.menueCloseIcon}/>
            </ButtonBase>
        </Stack>
        <Stack pt={1}>
            {isAuthenticated && <InfobarIcon
                text={translate('board.layout.reload')} icon={'Reload'}
                onClick={reload}/>
            }
            {/* <InfobarIcon
                text={translate('board.sheet.copy')} icon={'CopySlide'}
                onClick={copy}/> */}
            <InfobarIcon
                disabled={frameIndex > 0}
                text={translate('board.sheet.clear')} icon={'DeleteSlide'}
                onClick={clear}
            />
            <InfobarIcon
                disabled={frameIndex > 0}
                text={translate('board.sheet.reset')} icon={'FirstPage'}
                onClick={undo}/>
            <InfobarIcon
                disabled={frameIndex > 0}
                text={translate('board.sheet.select_background')} icon={'SlideBackground'}
                onClick={selectBackground}/>
            {/* <InfobarIcon
                text={sheet?.frames?.length > 0 ? translate('board.sheet.delete_moves') : translate('board.sheet.activate_moves')}
                icon={'Timeline'}
                onClick={toggleTimeline}/> */}
        </Stack>
    </Paper>;
};

export default Options;
