import {useCallback} from 'react';

import {useQuery} from '@tanstack/react-query';

import axios from 'axios';
import * as yup from 'yup';

import {isOk, useFetcher} from 'fetcher';
import {getEnv} from 'lib/env';
import {useUserUtils} from 'module/user/useUser';

const CONTROL_URL = getEnv('CONTROL_URL');

export const schema = yup.object({
    firstname: yup.string(),
    lastname: yup.string(),
    birthday: yup.string().nullable().transform(v => v === null ? '' : v),
    number: yup.number().transform(v => v ? v : 0),
    body: yup.object({
        height: yup.number().transform(v => v ? v : 0),
        weight: yup.number().transform(v => v ? v : 0),
    }),
    size: yup.object({
        shoe: yup.number().transform(v => v ? v : 0),
        pants: yup.string().nullable().transform(v => v === null ? '' : v),
        head: yup.string().nullable().transform(v => v === null ? '' : v),
        shirt: yup.string().nullable().transform(v => v === null ? '' : v),
        jacket: yup.string().nullable().transform(v => v === null ? '' : v),
    }),
});

export const sizes = ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'];

const userGet = async ({signal}) => {
    const res = await axios.get(CONTROL_URL + 'user/read', {signal});

    return res.data;
};

export const useApiGet = () => {
    const {isAuthenticated} = useUserUtils();

    return useQuery({
        queryKey: ['user-get'],
        queryFn: userGet,
        enabled: isAuthenticated,
    });
};

export const useApiUpdate = (defaultData) => {
    const {loading, fetch, data, error} = useFetcher(CONTROL_URL + 'user/patch', {
        method: 'PATCH',
    }, defaultData, true);

    const update = useCallback(async (data) => {
        const res = await fetch({data});

        if (isOk(res) && typeof res.data === 'object') {
            return res.data;
        }
    }, [fetch]);

    return {update, loading, data, errors: error.errors};
};

export const useApiUpload = () => {
    const {loading, fetch, data, error} = useFetcher(CONTROL_URL + 'user/image-upload', {
        method: 'POST',
    }, undefined, true);

    const upload = useCallback(async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        const res = await fetch({
            data: formData,
        });

        if (isOk(res) && typeof res.data === 'object') {
            return res.data;
        }
    }, [fetch]);

    return {upload, loading, data, errors: error.errors};
};
