import{useEffect} from 'react';

import lockerRoom from 'module/lockerRoom/socket';
import {useLockerRoomStore} from 'module/lockerRoom/zustand';

import {consume,loadDevice, producers,removeConsumer} from './mediaLib';
let isProducerTransportConnecting = false;
let isConsumerTransportConnecting = false;

export const useMediaDevice = () => {
    const roomId = useLockerRoomStore((state) => state.roomId);
    const role = useLockerRoomStore((state) => state.role);
    // const user = useUserStore((store) => store.user);
    // const setSelectedDevice = useLockerRoomStore((state) => state.setSelectedDevice);
    // const media = useLockerRoomStore((state) => state.media);

    const connected = useLockerRoomStore((state) => state.connected);
    const active = useLockerRoomStore((state) => state.active);
    const created = useLockerRoomStore((state) => state.created);
    const opening = useLockerRoomStore((state) => state.opening);
    // const closing = useLockerRoomStore((state) => state.closing);

    const selectedDevice = useLockerRoomStore((state) => state.selectedDevice);

    const mediaClientDevice = useLockerRoomStore((state) => state.mediaClientDevice);
    const mediaRoomCreated = useLockerRoomStore((state) => state.mediaRoomCreated);
    const mediaRoomConnected = useLockerRoomStore((state) => state.mediaRoomConnected);
    // const setMediaRoomCreated = useLockerRoomStore((state) => state.setMediaRoomCreated);

    const producerTransport = useLockerRoomStore((state) => state.producerTransport);
    const setProducerTransport = useLockerRoomStore((state) => state.setProducerTransport);
    const setProducerTransportIceParams = useLockerRoomStore((state) => state.setProducerTransportIceParams);
    const producerTransportConnected = useLockerRoomStore((state) => state.producerTransportConnected);
    const setProducerTransportConnected = useLockerRoomStore((state) => state.setProducerTransportConnected);

    const consumerTransport = useLockerRoomStore((state) => state.consumerTransport);
    const setConsumerTransport = useLockerRoomStore((state) => state.setConsumerTransport);
    const setConsumerTransportIceParams = useLockerRoomStore((state) => state.setConsumerTransportIceParams);
    const consumerTransportConnected = useLockerRoomStore((state) => state.consumerTransportConnected);
    const setConsumerTransportConnected = useLockerRoomStore((state) => state.setConsumerTransportConnected);
    
    useEffect(() => {

        // 2. all users create media device 
        console.log('MEDIASOUP EFFECT: LOAD DEVICE CHECK', connected ,active, !!roomId , mediaClientDevice?._loaded !== true, !mediaRoomConnected);

        if (connected && active && roomId && mediaClientDevice?._loaded !== true && !mediaRoomConnected) {
            console.log('MEDIASOUP EFFECT: LOAD DEVICE', role, created, mediaRoomCreated, mediaClientDevice);

            if (role === 'admin') {
                // admin after starting new room
                if (created && !mediaRoomCreated) {
                    // do nothing, as the room needs to be created first
                    // -> useMediaSoup line 55
                } else {
                    // rejoining lockerroom
                    loadDevice(mediaClientDevice); // creates mediaclient device
                }
            } else {
                // user joins room
                console.log('MEDIASOUP EFFECT: LOAD DEVICE USER');

                loadDevice(mediaClientDevice); // creates mediaclient device
            }
        }
        // Do not add role as this triggers double device load on rejoining the room for admin
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connected, opening, active, roomId, created, mediaRoomCreated, mediaClientDevice, mediaRoomConnected]);

    // inittransports
    useEffect(() => {
        // connect producer transport
        const connectProducerTransport = async () => {
            isProducerTransportConnecting = true;
            const data = await lockerRoom.request('createWebRtcTransport', {
                forceTcp: false,
                rtpCapabilities: mediaClientDevice?.rtpCapabilities
            });
            
            if (data.error) {
                console.error(data.error);
                return;
            }

            // save the iceparameters for reconnect
            setProducerTransportIceParams(data.iceParameters);
            
            let pt = await mediaClientDevice.createSendTransport(data);
            pt?.on(
                'connect', async ({dtlsParameters}, callback, errback) =>
                {
                    lockerRoom
                        .request('connectTransport', {
                            dtlsParameters,
                            transportId: data?.id
                        })
                        .then(callback)
                        .catch(errback);
                }
            );

            pt?.on(
                'produce', async ({kind, rtpParameters}, callback, errback) => {
                    try {
                        const {producerId} = await lockerRoom.request('produce', {
                            producerTransportId: pt?.id,
                            kind,
                            rtpParameters
                        });
                        callback({id: producerId});
                    } catch (err) {
                        errback(err);
                    }
                }
            );

            pt?.on(
                'connectionstatechange', async (state) => {
                    console.log('PRODUCER MEDIA CONNECTIONSTATE CHANGE', state);

                    switch (state) {
                    case 'connecting':
                        break;

                    case 'connected':
                        console.log('PRODUCER MEDIA CONNECTIONSTATE CONNECTED');
                        //localVideo.srcObject = stream
                        setProducerTransportConnected(true);
                        isProducerTransportConnecting = false;

                        break;

                    case 'failed':
                    // showInfo('producer connectionstatechange failed');
                        setProducerTransportConnected(false);
                        isProducerTransportConnecting = false;

                        pt.close();
                        break;

                    default:
                        break;
                    }
                }
            );
            console.log('producer transport created', pt);
            setProducerTransport(pt);

        };

        if (connected && roomId && mediaClientDevice?._loaded === true && !isProducerTransportConnecting && mediaRoomConnected) {
            // connect producer
            if (!producerTransport || (!producerTransportConnected && producerTransport) || producerTransport?._closed === true) {
                console.log('MEDIASOUP EFFECT: CONNECT PRODUCER TRANSPORT');
                
                connectProducerTransport();
                
            } 

        }
     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connected, roomId, mediaClientDevice, producerTransport, producerTransportConnected,mediaRoomConnected]);

    useEffect(() => {
        // connect consumer transport
        const connectConsumerTransport = async () => {
            isConsumerTransportConnecting = true;
            const data = await lockerRoom.request('createWebRtcTransport', {
                forceTcp: false
            });
            // console.log('consumer transport DATA:', data);
            if (data.error) {
                console.error(data.error);
                return;
            }

            // save the iceparameters for reconnect
            setConsumerTransportIceParams(data.iceParameters);

            let ct = await mediaClientDevice.createRecvTransport(data);
            // only one needed
            ct?.on(
                'connect', ({dtlsParameters}, callback, errback) => {
                    console.log('CONSUMER MEDIA CONNECTIONSTATE CONNECT', data);

                    lockerRoom
                        .request('connectTransport', {
                            transportId: data?.id,
                            dtlsParameters
                        })
                        .then(callback)
                        .catch(errback);
                }
            );

            ct?.on(
                'connectionstatechange', async (state) => {
                    console.log('CONSUMER MEDIA CONNECTIONSTATE CHANGE', state);

                    switch (state) {
                    case 'connecting':
                        break;

                    case 'connected':
                        // TODO warum kommt hier nix?
                        //remoteVideo.srcObject = await stream;
                        setConsumerTransportConnected(true);
                        // await lockerRoom.request('resume');
                        isConsumerTransportConnecting = false;

                        break;

                    case 'failed':
                        // showInfo('consumer connectionstatechange failed');
                        ct.close();
                        setConsumerTransportConnected(false);

                        break;

                    default:
                        break;
                    }
                }
            );
            console.log('consumer transport created', ct);
            setConsumerTransport(ct);

            // get all producers to connect to stream
            // TODO MAYBE THIS BREAKS CONSUMER?
            console.log('consumer transport isConsumerTransportConnecting', isConsumerTransportConnecting);
            
        };

        const startConsuming = async () => {
            console.log('consumer transport startconsuming');

            // getproducers after produce
            await lockerRoom.emit('getProducers');

        };

        const initSockets = () => {
            lockerRoom.on('consumerClosed', (consumerId) => {
                console.log('Closing consumer:', consumerId);
                removeConsumer(consumerId);
            }
            );
            lockerRoom.on('newProducers', async (data) => {
                console.log('New producers', data);
                // check for own producer.. 
                for (let {producerId} of data) {
                    // do not consume own producer or you hear yourself
                    console.log('consume', !producers.has(producerId) , !!consumerTransport, consumerTransport?._closed !== true);
                    if (producerId && !producers.has(producerId) && !!consumerTransport && consumerTransport?._closed !== true) {
                        // prevent double consuming of same producer

                        await consume(producerId);
                        // producersList
                    } 
                }}
            );

            // we do not leave room on disconnect -> we try to reconnect socket, mediasoup stays active all the time unless user acts to close room
            // lockerRoom.on('disconnect',() => exitRoom(true));
        };
        
        if (connected && roomId && mediaClientDevice?._loaded === true && mediaRoomConnected && selectedDevice?.audioDevice) {
            console.log('MEDIASOUP EFFECT: CONSUMER LOAD', consumerTransport);
            // connect consumer
            if (!isConsumerTransportConnecting && (!consumerTransport || (consumerTransport && !consumerTransportConnected) || consumerTransport?._closed === true)) {
                console.log('MEDIASOUP EFFECT: CONNECT CONSUMER TRANSPORT');
                
                connectConsumerTransport();

            } else {
                if (consumerTransport) {
                    initSockets();
                }

                startConsuming();
                console.log('MEDIASOUP EFFECT: CONSUMING DONE');
            }
            
        }
     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connected, roomId, mediaClientDevice, consumerTransport, consumerTransportConnected, mediaRoomConnected ]);
   
    return null;
};
