import React, {forwardRef, useEffect} from 'react';

import {ButtonBase, Slide, Stack, Typography} from '@mui/material';
import {Box} from '@mui/system';

import {yupResolver} from '@hookform/resolvers/yup';

import {Dialog, Text} from 'components';
import {useLayoutStore, useSnackbarStore} from 'store';

import {schema, useApiInvite, useApiInviteToken} from './useMemberApi.js';

import {Form, FormField, useForm} from 'form';
import QRCode from 'qrcode.react';

const styles = {
    button: {
        color: 'white.main',
        marginLeft: 4,
        fontSize: '16px'
    },
    qrTitle: {
        padding: '0 16px',
        color: 'white.main',
        fontSize: '16px',
        fontWeight: '700',
    },
    qrContainer: {
        display: 'flex',
        backgroundColor: 'white.main',
        padding: '16px',
        justifyContent: 'center',
    }
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const InviteDialog = ({team, onClose}) => {
    const setShowLoading = useLayoutStore((state) => state.setShowLoading);
    const showInfo = useSnackbarStore((state) => state.show);

    const {inviteToken, loading: loading1, data} = useApiInviteToken();

    const {invite, loading: loading2, errors} = useApiInvite();

    const doInvite = async (formData) => {
        try {
            await invite(team.id, formData);
            showInfo('team.action.user.invited');
        } catch (e) {
            showInfo(e.message, {severity: 'warning'});
        }
    };

    useEffect(() => {
        inviteToken(team.id);
    }, [inviteToken, team.id]);

    useEffect(() => {
        setShowLoading(loading1 || loading2);
        return () => {
            setShowLoading(false);
        };
    }, [loading1, loading2, setShowLoading]);

    const rhf = useForm(data, errors, true, {resolver: yupResolver(schema)});

    return <Dialog
        maxWidth={false}
        pt={0}
        open={true}
        title="team.action.player.add_mail"
        TransitionComponent={Transition}
        handleClose={onClose}
        dividers={true}
    >
        <Stack direction="column" pt="16px">
            <Stack sx={styles.qrTitle}><Text>team.action.player.add_mail</Text></Stack>
            <Form methods={rhf.methods} onSubmit={doInvite}>
                <Stack direction="column" px="16px">
                    <FormField name="username" label="global.email" required/>
                    <Stack direction="row" justifyContent="flex-end" pb="16" pt="16">
                        <ButtonBase disableRipple sx={styles.button} type="submit">
                            <Text>team.action.player.invite</Text>
                        </ButtonBase>
                    </Stack>
                </Stack>
            </Form>
            <Stack sx={styles.qrTitle} mt="32px"><Text>team.action.player.add_qr</Text></Stack>
            <Box px="16px" pb="32px">
                <Typography pb="32px" color="grey.mainlight"><Text>team.action.player.qrinvite_exp</Text></Typography>
                <Box sx={styles.qrContainer} >{data && <QRCode value={`tactix://tactixteam?token=${data.token}`} level="H" size={236}/>}</Box>

                {/* <Stack direction="row" justifyContent="flex-end" pt={3}>
                    <ButtonBase disableRipple sx={styles.button} onClick={doCancel}>
                        <Text>ui.actions.cancel</Text>
                    </ButtonBase>
                </Stack> */}
            </Box>
        </Stack>
    </Dialog>;
};

export default InviteDialog;