import React from 'react';

import {Stack, Typography, useMediaQuery, useTheme} from '@mui/material';

import icons from 'assets/icons';

const styles = {
    root: {
        cursor: 'pointer',
        margin: '0.5%',
        backgroundColor: 'grey.darkest',
        aspectRatio: '1/1',
        width: {
            mobile: '24%',
            desktop: '11.5%',
        },
    },
    start: {
        marginLeft: 0,
    },
    end: {
        marginRight: 0,
    },
    selected: {
        border: '1px',
        borderStyle: 'solid',
        borderColor: 'white.main',
    }
};

const FlyoutButton = ({icon, title, color, onClick, selected, iconSize, edge = false}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

    const SvgIcon = icons[icon];

    let size = isMobile ? 40 : 60;
    if (iconSize) {
        size = iconSize;
    }

    return <Stack
        alignItems="center"
        justifyContent="center"
        sx={[styles.root, selected && styles.selected, edge && styles[edge]]}
        onClick={onClick}
    >
        <SvgIcon style={{
            fill: color ? color : theme.palette.white.main,
            stroke: color,
            display: 'block',
            width: size,
            height: size,
        }}/>
        {title && <Typography>{title}</Typography>}
    </Stack>;
};

export default FlyoutButton;
