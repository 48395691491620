import React, {useEffect} from 'react';

import {
    Backdrop,
    Box,
    CircularProgress,
    useMediaQuery,
    useTheme
} from '@mui/material';

import {Canvas} from 'canvas';
import {InformationDialog, Keyboard} from 'components';
import {LineupTool} from 'layout/components/toolbar/components';
import {BannersDialog, useBannersStore} from 'module/banner';
import {Client, ClientMenu, Team} from 'module/client';
import {Member} from 'module/client/team/member';
import {GroupMenu} from 'module/group';
import {LockerRoom} from 'module/lockerRoom';
import {useLockerRoomStore} from 'module/lockerRoom/zustand';
import {PairingDialog} from 'module/pairing';
import {SheetAnimationFrames, SheetFieldSelector, SheetMenu, SheetSwitcher} from 'module/sheet';
import {useSheet} from 'module/sheet/useSheet';
import {Profile, useUserStore} from 'module/user';
import {useCampaignStore, useLayoutStore} from 'store';

import {Toolbar} from './components/toolbar';
import {Help, Language, MainMenu} from './components';
import DnD from './DnD';
import Footer from './Footer';
import Header from './Header';

const styles = {
    root: (_theme) => ({
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        backgroundColor: 'black.light',
    }),
    backdrop: {
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1
    },
};

const Layout = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // this is workaround, do not use get from the store, because after adding a new sheet which has field set to false
    // no re-rendering will be triggered, because selected id does not change
    const {getById, selected} = useSheet();
    const sheet = getById(selected); // we need the sheet data on every render

    const bannersVisible = useBannersStore((store) => store.visible);
    const bannersHide = useBannersStore((store) => store.hide);
    const banners = useCampaignStore((state) => state.banners);

    const showTimeline = useLayoutStore((state) => state.showTimeline);
    const showPairing = useLayoutStore((state) => state.showPairing);
    const setShowPairing = useLayoutStore((state) => state.setShowPairing);
    const showFieldSelector = useLayoutStore((state) => state.showFieldSelector);
    const showLoading = useLayoutStore((state) => state.showLoading);

    const showProfile = useLayoutStore((state) => state.showProfile);
    const showClientMenu = useLayoutStore(state => state.showClientMenu);
    const showClientShow = useLayoutStore(state => state.showClient.show);
    const showClient = useLayoutStore(state => state.showClient.client);
    const showTeamShow = useLayoutStore(state => state.showTeam.show);
    const showTeam = useLayoutStore(state => state.showTeam.team);
    const showMember = useLayoutStore(state => state.showMember);
    const showLanguage = useLayoutStore(state => state.showLanguage);

    const user = useUserStore((store) => store.user);
    const editor = useLockerRoomStore((state) => state.editor);

    useEffect(() => {
        // reset banners visibility, if there are no banners, otherwise toolbar flyout gets blocked
        // a clean fix would be merging banner store into campaign store
        if (bannersVisible && banners.length === 0) {
            bannersHide();
        }
    }, [bannersVisible, banners, bannersHide]);

    return <DnD>
        <Box>
            <Keyboard/>
            {showLoading && <Backdrop sx={styles.backdrop} open={showLoading}>
                <CircularProgress color="inherit"/>
            </Backdrop>}
            {showPairing && <PairingDialog open={showPairing} handleClose={() => setShowPairing(false)}/>}
            {showFieldSelector && <SheetFieldSelector sheet={sheet}/>}
            <BannersDialog open={bannersVisible && banners.length > 0} handleClose={bannersHide}/>
            <InformationDialog/>

            <Help/>
            <MainMenu/>
            {showLanguage && <Language/>}
            <GroupMenu/>
            <SheetMenu/>
            <LineupTool/>
            {showProfile && user && <Profile/>}
            {showClientMenu && <ClientMenu/>}
            {showClientShow && <Client client={showClient}/>}
            {showTeamShow && <Team team={showTeam}/>}
            {showMember.show && <Member member={showMember.member}/>}

            <Box sx={styles.root}>
                <Header/>

                {(showTimeline && editor) && <SheetAnimationFrames/>}
                {!showTimeline && <Toolbar/>}

                <Canvas isMobile={isMobile}/>
                {editor && <Footer/>}

            </Box>
            <SheetSwitcher/>
            {/*<Lockerrrom Wrapper Component />*/}
            {user && <LockerRoom/>}
        </Box>
    </DnD>;
};

export default Layout;
