import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';

import {FormControl, FormHelperText, TextField} from '@mui/material';
import {DesktopTimePicker} from '@mui/x-date-pickers';

import {useTranslation} from 'hooks';
import {dateFormat} from 'lib/datefns';

const DISPLAY_FORMAT = 'HH:mm:ss';
const VALUE_FORMAT = 'HH:mm:ss';
const DATE_FORMAT = 'yyyy-MM-dd';

const TimeField = ({name, fullWidth, label, helperText, maxWidth, ...rest}) => {
    const {control} = useFormContext();
    const translate = useTranslation();

    return (<Controller
        control={control}
        name={name}
        shouldUnregister={true}
        render={({field: {onChange, name, value}, fieldState: {error}}) => {
            // make sure value is a valid date string
            if (typeof value === 'string') {
                const parts = value.split(' ');

                if (parts.length === 1) {
                    const d = dateFormat(null, DATE_FORMAT);
                    value = `${d} ${value}`;
                }
            }

            return (<FormControl fullWidth={fullWidth} error={!!error?.message}>
                <DesktopTimePicker
                    name={name}
                    label={translate(label)}
                    autoOk={true}
                    error={error}
                    ampm={false}
                    format={DISPLAY_FORMAT}
                    value={value}
                    onChange={v => {
                        if (typeof v === 'object') {
                            v = dateFormat(v, VALUE_FORMAT);
                        }

                        onChange(v);
                    }}
                    fullWidth={true}
                    sx={{maxWidth}}
                    renderInput={(params) => <TextField size="small" variant="outlined" {...params} />}
                    {...rest}
                />
                <FormHelperText>{error?.message || translate(helperText)}</FormHelperText>
            </FormControl>);
        }}
    />);
};

TimeField.propTypes = {
    name: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
    label: PropTypes.string,
};

export default TimeField;
