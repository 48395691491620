export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export const sleepWithCancel = (ms, signal) => {
    return new Promise((resolve, reject) => {
        if (signal.aborted) {
            reject(signal.reason);
        }

        const timerId = setTimeout(resolve, ms);
        signal.addEventListener('abort', () => {
            clearTimeout(timerId);
            reject(signal.reason);
        });
    });
};

export default sleep;
