import {useCallback} from 'react';

import translations from 'assets/translations/translations.json';
import {useSettingsStore} from 'module/settings/zustand';

import {vsprintf} from 'sprintf';

export const translate = (path, language, ...args) => {
    if (!path) {
        return path;
    }

    if (typeof path === 'number') {
        path = path.toString();
    }

    if (typeof path !== 'string') {
        return path;
    }

    const p = path.split('.');

    let sub = translations[language || 'de'];

    p.forEach(e => {
        if (!sub) {
            return;
        }

        sub = sub[e];
    });

    if (typeof sub === 'object') {
        sub = path + '.INCOMPLETE-PATH';
    }

    if (sub === undefined) {
        return path;
    }

    if (args.length > 0) {
        return vsprintf(sub, args);
    }

    return sub;
};

export const unTranslated = (text) => {
    return text;
};

const useTranslation = (lang) => {
    const language = useSettingsStore(state => state.selectedLanguage);

    return useCallback((path, ...args) => translate(path, lang || language, ...args), [lang, language]);
};

export default useTranslation;
