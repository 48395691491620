import React from 'react';

import {
    Stack,
    Typography
} from '@mui/material';

import {
    Text
} from 'components';

const styles = {
    root: {
        padding: 2.5
    },
    block: {
        marginBottom: 2.5,
    },
};

const Item = ({label, value, unit}) => {
    return <Stack direction="column" sx={styles.block}>
        <Typography color="grey.mainlight"><Text>{label}</Text></Typography>
        <Typography color="white.main">{value} {unit}</Typography>
    </Stack>;
};

const Equipment = ({member}) => {

    return (
        <Stack sx={styles.root} direction="column">
            <Item label="user.size.shoe" value={member.user?.size.shoe}/>
            <Item label="user.size.shirt" value={member.user?.size.shirt}/>
            <Item label="user.size.pants" value={member.user?.size.pants}/>
            <Item label="user.size.head" value={member.user?.size.head}/>
            <Item label="user.size.jacket" value={member.user?.size.jacket}/>
        </Stack>
    );
};

export default Equipment;