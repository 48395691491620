import {useCallback, useEffect, useState} from 'react';

import {v4 as uuidv4} from 'uuid';

import {useAction} from 'canvas/canvas-actions';
import {useCanvasStore} from 'canvas/zustand';
import {useSheetStore} from 'module/sheet';
import {useLocalStore as useLocalSheetStore} from 'module/sheet/useLocalStore';
import {useUserStore} from 'module/user';

import {useApiAdd, useApiDelete, useApiDeleteAll, useApiLoad, useApiSort, useApiUpdate} from './useGroupApi';
import {useLocalStore} from './useLocalStore';
import {useGroupStore} from './zustand';

export const useGroup = () => {
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState();

    const storedGroups = useGroupStore(state => state.groups);
    const selected = useGroupStore(state => state.selected);
    const setSelected = useGroupStore(state => state.setSelected);

    useEffect(() => {
        // we are sorting reverse, this is a legacy from a stupid workaround, because the newest element
        // should always be on top
        // make a copy of the groups array, this avoids modifications on the zustand store
        setGroups(storedGroups.slice().sort((a, b) => b.sort - a.sort));
    }, [storedGroups]);

    useEffect(() => {
        setSelectedGroup(storedGroups.find(grp => grp.id === selected));
    }, [storedGroups, selected]);

    return {groups, selected, setSelected, selectedGroup};
};

export const useGroupLoad = (defaultLoading = false) => {
    // we need a dedicated loading indicator, to block rendering, until data was loaded
    const [loading, setLoading] = useState(defaultLoading);

    const setGroups = useGroupStore(state => state.setGroups);

    const {load: backendLoad} = useApiLoad();

    const load = useCallback(async (local) => {
        let groups;

        setLoading(true);

        if (!local) {
            try {
                groups = await backendLoad();
            } catch (e) {
                setLoading(false);
                throw e;
            }
        } else {
            const groupsJson = localStorage.getItem('groups');
            groups = JSON.parse(groupsJson)?.state?.groups || [];
        }

        groups.forEach(s => s.local = local);

        setGroups(groups);
        setLoading(false);

        return groups;
    }, [backendLoad, setGroups]);

    return {load, loading};
};

export const useGroupAdd = () => {
    const addGroup = useGroupStore(state => state.add);

    const jwt = useUserStore(state => state.user?.jwt);

    const {add: backendAdd, loading} = useApiAdd();
    const addLocalGroup = useLocalStore(store => store.add);
    const getNextSort = useLocalStore(store => store.getNextSort);

    const add = useCallback(async (group) => {
        let newgroup;

        const mergedgroup = {
            name: '',
            created: (new Date()).toISOString(),
            modified: null,
            local: !jwt,
            ...group,
        };

        if (jwt) {
            console.log('add backend');
            newgroup = await backendAdd(mergedgroup);
        } else {
            console.log('add locally');
            mergedgroup.id = uuidv4();
            mergedgroup.sort = getNextSort();
            addLocalGroup(mergedgroup);
            newgroup = mergedgroup;
        }

        if (newgroup) {
            addGroup(newgroup);
        }

        return newgroup;
    }, [addGroup, addLocalGroup, getNextSort, backendAdd, jwt]);

    return {add, loading};
};

export const useGroupUpdate = () => {
    const updateGroup = useGroupStore(state => state.upd);

    const {update: backendUpdate, loading} = useApiUpdate();
    const updateLocalGroup = useLocalStore(store => store.upd);

    const update = useCallback(async (group) => {
        let modifiedgroup;
        const mergedgroup = {
            ...group,
            modified: new Date(),
        };

        if (group.local) {
            console.log('update locally');
            updateLocalGroup(mergedgroup);
            modifiedgroup = mergedgroup;
        } else {
            console.log('update backend');
            modifiedgroup = await backendUpdate(mergedgroup);
        }

        if (modifiedgroup) {
            updateGroup(modifiedgroup);
        }

        return modifiedgroup;
    }, [backendUpdate, updateLocalGroup, updateGroup]);

    return {update, loading};
};

export const useGroupDelete = () => {
    const deleteGroup = useGroupStore(state => state.del);
    const deleteSheetsByGroup = useSheetStore(state => state.delByGroup);

    const {del: backendDelete, loading} = useApiDelete();
    const deleteLocalGroup = useLocalStore(store => store.del);
    const deleteLocalSheetsByGroup = useLocalSheetStore(store => store.delByGroup);

    const setDirty = useCanvasStore(state => state.setDirty);
    const {dispatch} = useAction();

    const del = useCallback(async (group) => {
        if (group.local) {
            console.log('delete locally', group.id);
            deleteLocalGroup(group.id);
            deleteLocalSheetsByGroup(group.id);
        } else {
            console.log('delete backend', group.id);
            await backendDelete(group.id);
        }

        deleteGroup(group.id);
        deleteSheetsByGroup(group.ip);
        setDirty(false);
        dispatch('clear');
    }, [deleteGroup, deleteSheetsByGroup, deleteLocalGroup, deleteLocalSheetsByGroup, backendDelete, setDirty, dispatch]);

    return {del, loading};
};

export const useGroupDeleteAll = () => {
    const deleteGroups = useGroupStore(state => state.delAll);
    const deleteSheets = useSheetStore(state => state.delAll);

    const {del: backendDeleteAll, loading} = useApiDeleteAll();
    const deleteLocalGroups = useLocalStore(store => store.delAll);
    const deleteLocalSheets = useLocalSheetStore(store => store.delAll);

    const {dispatch} = useAction();

    const deleteAll = useCallback(async () => {
        console.log('delete all');

        deleteLocalGroups();
        deleteLocalSheets();
        await backendDeleteAll();

        deleteGroups();
        deleteSheets();
        dispatch('clear');
    }, [deleteLocalGroups, deleteLocalSheets, backendDeleteAll, deleteGroups, deleteSheets, dispatch]);

    return {deleteAll, loading};
};

export const useGroupSort = () => {
    const updateGroup = useGroupStore(state => state.upd);
    const groups = useGroupStore(state => state.groups);

    const {sort: backendSort, loading} = useApiSort();
    const updateLocalGroup = useLocalStore(store => store.upd);

    const sort = useCallback(async (group, direction) => {

        const sortedGroups = groups.slice().sort((a, b) => {
            return direction === 'up' ? a.sort - b.sort : b.sort - a.sort;
        });

        // we do not need consecutive sort numbers, gaps are ok, so we do not need to resort after deleting an element
        const neighbourElement = sortedGroups.find(grp => {
            return direction === 'up' ? grp.sort > group.sort : grp.sort < group.sort;
        });

        // already at top/bottom
        if (!neighbourElement) {
            return;
        }

        const me = {...group, sort: neighbourElement.sort};
        const neigh = {...neighbourElement, sort: group.sort};

        if (group.local) {
            console.log('sort locally');
            updateLocalGroup(me);
            updateLocalGroup(neigh);
        } else {
            console.log('sort backend');
            await backendSort(group.id, direction === 'up' ? 1 : -1);
        }

        updateGroup(me);
        updateGroup(neigh);

    }, [groups, updateGroup, updateLocalGroup, backendSort]);

    return {sort, loading};
};