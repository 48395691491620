import React, {useEffect, useRef, useState} from 'react';
import {useSwipeable} from 'react-swipeable';

import {Button, MobileStepper, Stack} from '@mui/material';

import Icons from 'assets/icons';
import {useGroup} from 'module/group/useGroup';
import {useSheet} from 'module/sheet/useSheet';

const styles = {
    root: {
        height: '100%',
    },
    stepper: {
        backgroundColor: 'black.light',
        position: 'static',
        width: '100%',
        color: 'white.main',
        paddingTop: 3,
        alignItems: 'flex-start',
        '& .MuiMobileStepper-dot': {
            marginRight: 1,
            marginLeft: 1,
            width: 16,
            height: 16,
            backgroundColor: theme => theme.palette.grey.lightester,
        },
        '& .MuiMobileStepper-dotActive': {
            backgroundColor: theme => theme.palette.red.highlight,
        }
    },
    button: {
        height: '100%',
        alignItems: 'flex-start',
        '& svg': {
            width: 20,
            height: 20,
            fill: theme => theme.palette.white.main,
        },
        '&.Mui-disabled svg': {
            fill: theme => theme.palette.grey.darker,
        }
    },
};

const Footer = () => {
    const [activeStep, setActiveStep] = useState(0);

    const {selected: groupId} = useGroup();
    const {sheets, setSelected, selected} = useSheet(groupId);

    const sheetsRef = useRef(sheets);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => {
            if (prevActiveStep >= sheets.length - 1) {
                return prevActiveStep;
            }

            return prevActiveStep + 1;
        });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => {
            if (prevActiveStep === 0) {
                return prevActiveStep;
            }

            return prevActiveStep - 1;
        });
    };

    const doSwipe = (e) => {
        if (e.dir === 'Left') {
            handleNext();
        }

        if (e.dir === 'Right') {
            handleBack();
        }
    };

    const swipeHandlers = useSwipeable({
        onSwiped: doSwipe,
        trackMouse: true,
    });

    useEffect(() => {
        sheetsRef.current = sheets;
    }, [sheets]);

    useEffect(() => {
        setSelected(sheetsRef.current[activeStep]?.id);
    }, [activeStep, setSelected]);

    // watch for external changes (add/remove/sort)
    useEffect(() => {
        for (let i = 0; i < sheets.length; i++) {
            if (sheets[i].id === selected) {
                setActiveStep(i);
            }
        }
    }, [selected, sheets]);

    return <Stack sx={styles.root} direction="row" flexGrow={1}>
        {sheets.length > 1 && <MobileStepper
            sx={styles.stepper}
            {...swipeHandlers}
            variant={sheets.length > 5 ? 'text' : 'dots'}
            steps={sheets.length}
            activeStep={activeStep}
            backButton={<Button
                sx={styles.button}
                color="white"
                onClick={handleBack}
                disableRipple
                disabled={activeStep === 0}>
                <Icons.ArrowLeft/>
            </Button>}
            nextButton={<Button
                sx={styles.button}
                color="white" onClick={handleNext}
                disableRipple
                disabled={activeStep >= sheets.length - 1}>
                <Icons.ArrowRight/>
            </Button>}
        />}
    </Stack>;
};

export default Footer;
