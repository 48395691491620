import React from 'react';

import {useTranslation} from 'hooks';

const Text = ({children}) => {
    const translate = useTranslation();

    return <>{translate(children)}</>;
};

export default Text;
