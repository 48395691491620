import {useCallback} from 'react';

import {useTranslation} from 'hooks';

export const useInfo = () => {
    const translate = useTranslation();

    return useCallback(member => {
        const ret = memberInfo(member);

        ret.trainer = '';
        if (member.roles.includes('trainer')) {
            ret.trainer = '(' + translate('client.role.trainer') + ')';
        }

        ret.roles = member.roles.map(role => translate(`client.role.${role}`)).join(', ');

        return ret;
    }, [translate]);
};

export const memberInfo = (member) => {
    let firstname = member.user ? member.user.firstname : member.firstname;
    firstname = firstname || '';
    let lastname = member.user ? member.user.lastname : member.lastname;
    lastname = lastname || '';

    const initials = firstname?.charAt(0) + lastname?.charAt(0);
    const name = `${firstname} ${lastname}`.trim();

    return {firstname, lastname, name, initials};
};