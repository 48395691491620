import React, {Children, cloneElement} from 'react';

import {Box} from '@mui/material';

import {useDraggable} from '@dnd-kit/core';

const styles = {
    draggable: {
        touchAction: 'manipulation',
    },
};

const DraggablePlayer = ({children, team, player, type, disabled = false}) => {
    const teamId = team ? team.id : player.team;
    const playerId = player.id ? player.id : player.number;

    const {setNodeRef, transform, listeners, attributes, isDragging} = useDraggable({
        id: `player-${type}-${teamId}-${playerId}`,
        data: {
            team,
            player: {
                ...player,
                type,
            }
        },
        disabled
    });

    const style = {
        ...styles.draggable,
        position: transform ? 'fixed' : undefined,
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    };

    const placeholders = Children.map(children, (child) => {
        return cloneElement(child, {
            number: player.number + 1,
            disabled: true
        });
    });

    return <>
        {isDragging && placeholders}
        <Box ref={setNodeRef} sx={style} {...listeners} {...attributes}>
            {children}
        </Box>
    </>;
};

export default DraggablePlayer;