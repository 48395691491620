import React, {useEffect, useState} from 'react';

import {ButtonBase, Stack, TextField} from '@mui/material';

import {getKeyboardOptions, Text} from 'components';
import {useTranslation} from 'hooks';
import {useKeyboardStore, useLayoutStore, useSnackbarStore} from 'store';

import {useGroupAdd, useGroupUpdate} from './useGroup';

const styles = {
    button: {
        color: 'white.main',
        marginLeft: 4,
        fontSize: '16px'
    }
};

export const Group = ({group, onSave, onCancel}) => {
    const translate = useTranslation();

    const [name, setName] = useState(group?.name || '');

    const showInfo = useSnackbarStore((state) => state.show);

    const showKeyboard = useKeyboardStore((state) => state.show);
    const hideKeyboard = useKeyboardStore((state) => state.hide);

    const setShowLoading = useLayoutStore((state) => state.setShowLoading);

    const {update: updateGroup, loading: loading1} = useGroupUpdate();
    const {add: addGroup, loading: loading2} = useGroupAdd();

    const onInputFocus = (e, setVal) => {
        showKeyboard(getKeyboardOptions(e, setVal));
    };

    const onInputBlur = () => {
        hideKeyboard();
    };

    const onInputChange = (e) => {
        setName(e.target.value);
    };

    const doCancel = () => {
        setName(group?.name || '');
        onCancel && onCancel();
    };

    const doSave = async (ev) => {
        ev.stopPropagation();
        ev.preventDefault();

        if (!name || name === '') {
            showInfo('board.group.validation.name_required', {severity: 'warning'});
            return;
        }

        try {
            if (group === null) {
                const newGroup = await addGroup({name: name});
                if (!newGroup) {
                    return;
                }

                onSave(newGroup);
                showInfo('board.group.action.adding_success');
            } else {
                const updatedGroup = await updateGroup({...group, name});
                if (!updatedGroup) {
                    return;
                }

                onSave(updatedGroup);
                showInfo('board.group.action.save_success');
            }
        } catch (e) {
            showInfo(e.message, {severity: 'warning'});
            return;
        }

        setName('');
    };

    useEffect(() => {
        setShowLoading(loading1 || loading2);
    }, [loading1, loading2, setShowLoading]);

    useEffect(() => {
        return () => {
            hideKeyboard();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Stack direction="column" p={2}>
        <TextField
            label={translate('board.group.name')}
            value={name}
            autoFocus
            onFocus={(e) => onInputFocus(e, setName)}
            onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
            }}
            onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                    doSave(ev);
                }
            }}
            onBlur={onInputBlur}
            onChange={onInputChange}
        />
        <Stack direction="row" justifyContent="flex-end" pb={1} pt={2}>
            <ButtonBase disableRipple sx={styles.button} onClick={doCancel}>
                <Text>ui.actions.cancel</Text>
            </ButtonBase>
            <ButtonBase disableRipple onClick={doSave} sx={styles.button} type="submit">
                <Text>ui.actions.save</Text>
            </ButtonBase>
        </Stack>
    </Stack>;
};

export default Group;
