import React from 'react';

import {styled} from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const HiddenFileInput = ({name, accept = '.png, .jpg, .jpeg', onChange, onCancel}) => {
    return <VisuallyHiddenInput name={name} type="file" accept={accept} onChange={onChange} onCancel={onCancel}/>;
};

export default HiddenFileInput;