import {useCallback, useEffect, useRef} from 'react';

const useTimer = (callback, timeout = 1000) => {

    // save timer to ref to survive re-renders
    const timerId = useRef(0);

    // save callback to ref
    const savedCallback = useRef(null);

    // update callback ref if callback func changes
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    const reset = useCallback(() => {
        clearTimeout(timerId.current);

        timerId.current = setTimeout(savedCallback.current, timeout);
    }, [timeout]);

    useEffect(() => {
        timerId.current = setTimeout(savedCallback.current, timeout);

        return () => {
            clearInterval(timerId.current);
        };
    });

    return [reset];
};

export default useTimer;
