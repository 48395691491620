import React from 'react';

import {
    DialogActions,
    DialogContent, Typography,
} from '@mui/material';

import {Dialog as BaseDialog, Text} from 'components';

const Dialog = () => {
    return <BaseDialog open={true} title="board.migration.dialog_title">
        <DialogContent align={'center'}>
            <Typography variant="body1"><Text>board.migration.dialog_message</Text></Typography>
        </DialogContent>
        <DialogActions>
        </DialogActions>
    </BaseDialog>;
};

export default Dialog;
