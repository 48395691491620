import React from 'react';

import {useTranslation} from 'hooks';
import {useSnackbarStore} from 'store';

import ArticleBanner from './Article';
import IframeBanner from './Iframe';
import ImageBanner from './Image';
import Tracking from './Tracking';

const Banner = ({banner}) => {
    const translate = useTranslation();
    const showClickInfo = useSnackbarStore((state) => state.show);
    
    const handleClick = () => {
        showClickInfo(translate('banner.rageclick.info'), {severity: 'info'});
    };

    return <>
        <Tracking banner={banner}/>
        {banner.type === 'article' && <ArticleBanner onClick={handleClick} banner={banner} />}
        {(banner.type === 'image' || banner.type === 'image_upload') && <ImageBanner onClick={handleClick} banner={banner}/>}
        {banner.type === 'html' && <IframeBanner onClick={handleClick} banner={banner}/>}
    </>;
};

export default Banner;
