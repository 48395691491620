import {create} from 'zustand';

import storage from 'store/localStorage';
import {middleware} from 'store/zustand';

import {produce} from 'immer';
import _ from 'lodash';

export const VIDEO_AUDIO = {audio: true, video: true};
export const AUDIO_ONLY = {audio: true, video: false};
export const VIDEO_ONLY = {audio: false, video: true};
export const MEDIA_OFF = {audio: false, video: false};

// define the initial state
const initialState = {
    createdAt: 0,
    created: false, // true when creating a room has been finished
    connected: false,
    mediaClientDevice: null,
    mediaRoomCreated: false, // true if admin has created the room
    producerTransport: null,
    producerTransportIceParams: null,
    producerTransportConnected: false,
    consumerTransport: null,
    consumerTransportIceParams: null,
    consumerTransportConnected: false,
    mediaRoomConnected: false, // true when media room has been connected
    mediaRoomForceReconnect: false, // true when connection has been lost and socket room reconnected
    routerRtpCapabilities: null, // unused for now
    title: '',
    opening: false,
    closing: false,
    closingForced: false,
    active: false, // true when room has been 
    micro: false, // only for the microfone button
    media: AUDIO_ONLY,
    selectedDevice: {},
    ping: 0,
    roomId: null,
    editor: true, // false disables controls
    role: null,
    prevCanvasData: null,
    users: [],
    emitIntervalId: null,
    dataImage: null,
    reconnects: 0,
    invitedUsers: [],
    producing: false,
    roomTime: 0,
    loadingChangeEditor: false,
};

const useLockerRoomStore = create(middleware((set, get) => ({
    ...initialState,
    setMediaClientDevice: mediaClientDevice => set(produce(draft => {
        draft.mediaClientDevice = mediaClientDevice;
    })),
    setProducerTransport: producerTransport => set(produce(draft => {
        draft.producerTransport = producerTransport;
    })),
    setProducerTransportIceParams: params => set(produce(draft => {
        draft.producerTransportIceParams = params;
    })),
    setProducerTransportConnected: producerTransportConnected => set(produce(draft => {
        draft.producerTransportConnected = producerTransportConnected;
    })),
    setConsumerTransport: consumerTransport => set(produce(draft => {
        draft.consumerTransport = consumerTransport;
    })),
    setConsumerTransportIceParams: params => set(produce(draft => {
        draft.consumerTransportIceParams = params;
    })),
    setConsumerTransportConnected: consumerTransportConnected => set(produce(draft => {
        draft.consumerTransportConnected = consumerTransportConnected;
    })),
    setMediaRoomConnected: mediaRoomConnected => set(produce(draft => {
        draft.mediaRoomConnected = mediaRoomConnected;
    })),
    setMediaRoomCreated: mediaRoomCreated => set(produce(draft => {
        draft.mediaRoomCreated = mediaRoomCreated;
    })),
    setRouterRtpCapabilities: routerRtpCapabilities => set(produce(draft => {
        draft.routerRtpCapabilities = routerRtpCapabilities;
    })),
    setDataImage: dataImage => set(produce(draft => {
        draft.dataImage = dataImage;
    })),
    setUsers: users => set(produce(draft => {
        if (!_.isEqual(draft.users, users)) {
            draft.users = users;
        }
    })),
    setTitle: title => set(produce(draft => {
        draft.title = title;
    })),
    setRoomId: roomId => set(produce(draft => {
        draft.roomId = roomId;
    })),
    setMicro: newState => set(produce(draft => {
        draft.micro = newState;
    })),
    setRole: role => set(produce(draft => {
        draft.role = role;
    })),
    setMedia: media => set(produce(draft => {
        draft.media = media;
    })),
    setSelectedDevice: selectedDevice => set(produce(draft => {
        draft.selectedDevice = selectedDevice;
    })),
    setEditor: editor => set(produce(draft => {
        if (editor) draft.dataImage = null;
        draft.editor = editor;
    })),
    setPing: ping => set(produce(draft => {
        draft.ping = ping;
    })),
    incReconnects: (value) => set(produce(draft => {
        if (value === 0) {
            draft.reconnects = 0;
        } else {
            draft.reconnects = get().reconnects + 1;
        }
    })),
    setActive: active => set(produce(draft => {
        draft.active = active;
    })),
    setOpening: opening => set(produce(draft => {
        draft.opening = opening;
    })),
    setClosing: closing => set(produce(draft => {
        draft.closing = closing;
    })),
    setClosingForced: closingForced => set(produce(draft => {
        draft.closingForced = closingForced;
    })),
    setConnected: connected => set(produce(draft => {
        draft.connected = connected;
    })),
    setMediaRoomForceReconnect: flag => set(produce(draft => {
        draft.mediaRoomForceReconnect = flag;
    })),
    setCreated: created => set(produce(draft => {
        draft.created = created;
    })),
    setCreatedAt: createdAt => set(produce(draft => {
        draft.createdAt = createdAt;
    })),
    setInvitedUsers: invitedUsers => set(produce(draft => {
        draft.invitedUsers = invitedUsers;
    })),
    setProducing: producing => set(produce(draft => {
        draft.producing = producing;
    })),
    setRoomTime: roomTime => set(produce(draft => {
        draft.roomTime = roomTime;
    })),
    setLoadingChangeEditor: loadingChangeEditor => set(produce(draft => {
        draft.loadingChangeEditor = loadingChangeEditor;
    })),
    save: () => {
        storage.setItem(`lockerRoom_${get().roomId}`, get().users);
    },
    reset: () => {
        set(initialState);
    },
}), {name: 'LockerRoomStore', debug: false}));

// const mode = useInfoStore.getState().mode;
// if (mode === Modes.BROWSER) {

export {useLockerRoomStore};
