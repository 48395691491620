import React, {useEffect} from 'react';

import {Box} from '@mui/material';
import {Image} from 'mui-image';

import {useDroppable} from '@dnd-kit/core';

import {BANNER_TIMEOUT} from 'config';
import {fabric} from 'fabric';
import {useTimer} from 'hooks';
import {useBannersStore} from 'module/banner';
import {useLockerRoomStore} from 'module/lockerRoom/zustand';
import {PlayerDialog, usePlayerStore} from 'module/player';
import {useSheet} from 'module/sheet/useSheet';
import {TextDialog, useTextStore} from 'module/text';
import {usePrefsStore, useSnackbarStore} from 'store';

import {clearEvents, clearObjectEvents, registerGlobalEvents, registerObjectEvents} from './canvas-handlers';
import {
    canvasToJson,
    DEFAULT_COLOR, discardActiveObject, DRAWING_WIDTH,
    isCustom, limitReached, setFabricDefaults,
    useCalculateDimensions,
} from './canvas-helper';
import {setScaling} from './object-helper';
import useCustomControls, {customControlVisibility, setGroupControlVisibility} from './useCustomControls';
import useFabric from './useFabric';
import useMode from './useMode';
import {useCanvasStore} from './zustand';

const styles = {
    canvas: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    dataImage: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
    dataImageBox: {
        display: 'flex',
        flex: 'none',
    }
};

const Canvas = ({isMobile}) => {
    const canvas = useCanvasStore(state => state.canvas);

    const setMode = useMode();

    // locker room state, to be set intially by APP/pushNots and later by socketIO server
    const editor = useLockerRoomStore((state) => state.editor);
    const dataImage = useLockerRoomStore((state) => state.dataImage);

    const setTool = usePrefsStore(state => state.setTool);

    // edit
    const setPlayer = usePlayerStore(state => state.set);
    const player = usePlayerStore(state => state.player);
    const setText = useTextStore(state => state.set);
    const text = useTextStore(state => state.text);

    const showInfo = useSnackbarStore(state => state.show);

    const setCanvas = useCanvasStore(state => state.set);
    const setCanvasInit = useCanvasStore(state => state.setCanvasInit);
    const setDirty = useCanvasStore(state => state.setDirty);
    const pushCanvas = useCanvasStore(state => state.pushCanvas);
    const getCanvasStates = useCanvasStore(state => state.getCanvasStates);

    const {frameIndex} = useSheet();
    const editable = frameIndex === 0;

    const bannersShow = useBannersStore(store => store.show);

    const {setNodeRef} = useDroppable({
        id: 'canvas-container',
    });

    // custom controls
    const {updateControls} = useCustomControls(canvas);

    if (canvas) {
        //console.log('canvas w/h', canvas.width, canvas.height);
    }

    const dimensions = useCalculateDimensions(canvas);

    const fabricRef = useFabric(fabricCanvas => {
        setFabricDefaults(fabricCanvas);
        fabricCanvas.freeDrawingBrush = new fabric['PencilBrush'](fabricCanvas);
        fabricCanvas.freeDrawingBrush.color = DEFAULT_COLOR;
        fabricCanvas.freeDrawingBrush.width = DRAWING_WIDTH;
        setCanvas(fabricCanvas);
        setCanvasInit(true);
    }, {
        // isDrawingMode: mode === 'draw',
        isDrawingMode: true,
        width: dimensions.width,
        height: dimensions.height
    });

    const [resetBannerTimer] = useTimer(() => {
        !isMobile && bannersShow();
    }, BANNER_TIMEOUT * 1000); // 600 seconds

    const [resetControlHideTimer] = useTimer(() => {
        if (!canvas) {
            return;
        }

        if (!player && !text) {
            // remove selection if nothing is in edit mode
            // check for canvas fast refresh during dev
            // warning: this triggers 'selection:updated' and 'before:selection:cleared'
            discardActiveObject(canvas);
        } else {
            // start again if player or text edit is open
            resetControlHideTimer();
        }
    }, 5 * 1000); // 5 seconds

    // we need to call this after every call of clearObjectEvents
    // const activateMode = () => {
    // setMode(canvas, editable ? mode : SELECTION_MODE, tool, color);
    // };

    // reset inactivity timer on every render
    useEffect(() => {
        resetControlHideTimer();
        resetBannerTimer();
    });

    useEffect(() => {
        // during initialization canvas is null
        if (canvas === null) {
            return;
        }

        const onChange = (_e) => {
            if (canvas.isLoading) {
                return;
            }

            setDirty(true);
            // FIXME - this is a HACK to fix fabricjs object modification bug
            // fabricjs release for object fires later than this onModified so sometimes pushCanvas is done while still
            // in drag mode.
            // please try fabricjs 6.0 (object:modified got a workaround, maybe also fix)
            setTimeout(() => pushCanvas(canvasToJson(canvas)), 20);
            resetControlHideTimer();
        };

        const onAdded = (evt) => {
            if (canvas.isLoading) {
                return;
            }

            setScaling(evt.target, isMobile);

            if (evt.target?.custom?.type === 'text') {
                setTool('select'); // #10
                setText(evt.target);
            }

            if (evt.target?.custom?.type === 'clipart') {
                if (evt.target.custom?.clipart === 'futbol') {
                    setTool('select'); // #6
                }
            }

            if (limitReached(canvas)) {
                showInfo('board.layout.validation.max_items', {severity: 'warning'});
            }

            customControlVisibility(evt.target);
            setDirty(true);
            pushCanvas(canvasToJson(canvas));
            resetControlHideTimer();
        };

        clearEvents(canvas);
        registerGlobalEvents(canvas, onChange, onAdded, onChange);
        canvas.on({
            'selection:created': (e) => {
                const selected = e.selected?.[0];

                if (Array.isArray(e.selected) && e.selected.length > 0) {
                    // its group
                    if (e.selected.length > 1) {
                        setGroupControlVisibility(canvas.getActiveObject());
                    }
                }

                if (selected) {
                    registerObjectEvents(canvas, selected);
                    setMode(editable);
                }

                resetControlHideTimer();
            },
            'selection:updated': (e) => {
                const selected = e.selected?.[0];

                if (Array.isArray(e.selected) && e.selected.length > 0) {
                    // its group
                    if (e.selected.length > 1) {
                        setGroupControlVisibility(canvas.getActiveObject());
                    }
                }

                if (selected) {
                    clearObjectEvents(canvas);
                    registerObjectEvents(canvas, selected);
                    registerGlobalEvents(canvas, onChange, onAdded, onChange);
                    setMode(editable);
                }

                resetControlHideTimer();
            },
            'selection:cleared': (e) => {
                e.deselected && e.deselected.forEach(o => {
                    if (!isCustom(o)) {
                        o.sendBackwards();
                    }
                });
                clearObjectEvents(canvas);
                registerGlobalEvents(canvas, onChange, onAdded, onChange);
                setMode(editable);
                setPlayer(null);
                setText(null);

                if (e.deselected && e.deselected[0] && e.deselected[0].text === '') {
                    canvas.remove(e.deselected[0]);
                }

                resetControlHideTimer();
            },
        });

        setMode(editable, true);

        // update event handler if needed
        updateControls();
        resetControlHideTimer();

        // }, [canvas, editor, editable, tool, mode, color, setPlayer, setText, setDirty, setTool, showInfo, isMobile, getCanvasStates, pushCanvas]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvas, editor, editable, setPlayer, setText, setDirty, setTool, showInfo, isMobile, getCanvasStates, pushCanvas, setMode]);
    // console.log('DATAIMAGE', !!dataImage);
    return <Box sx={styles.canvas} ref={setNodeRef}>
        <PlayerDialog/>
        <TextDialog/>
        <Box sx={styles.dataImageBox}>
            {!editor && dataImage ?
                <Image fit={'cover'} duration={500} src={dataImage} styles={styles.dataImage}/> : null}
        </Box>
        <canvas style={{visibility: editor ? 'visible' : 'hidden'}} ref={fabricRef} id="canvas"/>
    </Box>;
};

export default Canvas;
