import React from 'react';

import {DialogContent} from '@mui/material';

import {Dialog} from 'components';
import {useTranslation} from 'hooks';

import Edit from './Edit';
import {usePlayerStore} from './zustand';

const PlayerDialog = () => {
    const translate = useTranslation();

    const player = usePlayerStore(state => state.player);
    const unsetPlayer = usePlayerStore(state => state.unset);

    const close = () => {
        unsetPlayer();
    };

    return <Dialog
        open={!!player} handleClose={close} title={translate('board.player.player')}>
        <DialogContent dividers>
            {player && <Edit player={player}/>}
        </DialogContent>
    </Dialog>;
};

export default PlayerDialog;
