import React from 'react';

import {Link, Typography} from '@mui/material';

import {Dialog} from 'components';
import {useTranslation} from 'hooks';
import {isDevelopment} from 'lib/env';
import {Modes} from 'lib/tactix';

import appleAppStore from '/src/assets/apple-app-store.png';
import googlePlayStore from '/src/assets/google-play-badge.png';

const styles = {
    dialogContentBlockTitle: {
        color: 'white.main',
        padding: '0 40px 5px 40px',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '24px',
    },
    dialogContentBlock: {
        color: 'white.main',
        padding: '0 40px 20px 40px',
        textAlign: 'center',
    },
    dialogContentBlockButton: {
        color: 'white.main',
        padding: '0 0 10px 0',
        textAlign: 'center',
    },
    dialogContentBlockButtonHighlight: {
        color: 'white.main',
        padding: '0 0 10px 0',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '18px',
    },
    buttonHolder: {
        padding: '20px 40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textDecoration: 'none',
    },
};

const navigateToStore = (storeUrl) => {
    const eventData = {url: storeUrl};
    window.ReactNativeWebView.postMessage(JSON.stringify({type: 'navigateToStoreEvent', data: eventData}));
};

const VersionCheck = ({mode}) => {
    const translate = useTranslation();

    let params = new URLSearchParams(window.location.search);
    console.log(mode, params.get('version'));
    // const translate = useTranslation();

    // TODO: integrate api calls for version checking
    // TODO: styling of dialog
    if (mode === Modes.APP && !params.get('appVersion') && !isDevelopment()) {
        return(
            <Dialog fullScreen Icon={<></>} open handleClose={() => null} title="App-Health">
                <Typography sx={styles.dialogContentBlockTitle}>{translate('version.message.old_version')}</Typography>

                <Typography sx={styles.dialogContentBlock}>{translate('version.message.update')}</Typography>

                <Link onClick={() => navigateToStore('https://apps.apple.com/at/app/geomix/id1572802456')} sx={styles.buttonHolder}>
                    <Typography sx={styles.dialogContentBlockButton}>{translate('version.update.apple')} <Typography sx={styles.dialogContentBlockButtonHighlight}>{translate('version.here')}</Typography></Typography>
                    <img src={appleAppStore} alt={translate('version.alt-text.appleStore_download')} width="300" />
                </Link>

                <Link onClick={() => navigateToStore('https://play.google.com/store/apps/details?id=com.geomix.tactix')} sx={styles.buttonHolder}>
                    <Typography sx={styles.dialogContentBlockButton}>{translate('version.update.android')} <Typography sx={styles.dialogContentBlockButtonHighlight}>{translate('version.here:')}</Typography></Typography>
                    <img src={googlePlayStore} alt={translate('version.alt-text.googlePlay_download')} width="300" />
                </Link>
            
            </Dialog>
        );

    }

    return null;
   
};

export default VersionCheck;