// import {ReactComponent as ConeSmall} from '../cliparts/cone-small.svg';
// import {ReactComponent as Ladder} from '../cliparts/ladder.svg';
// import {ReactComponent as Pole} from '../cliparts/pole.svg';

// import {ReactComponent as Arrow} from './arrow.svg';
// import {ReactComponent as ArrowDashed} from './arrow-dashed.svg';
import {ArrowDownward, ArrowUpward,BroadcastOnHome, ColorLens,FileCopy, FirstPage, Image, Language, MoreHoriz as Menu, Redo, Save as Save, Undo} from '@mui/icons-material';

import Arrow from '../icons/icon_arrow.svg?react';
import ArrowDashed from '../icons/icon_arrow_dashed.svg?react';
import ArrowSwitch from '../icons/icon_arrow_switch.svg?react';
import ArrowLeft from '../icons/icon_arrowLeft.svg?react';
import ArrowRight from '../icons/icon_arrowRight.svg?react';
import Back from '../icons/icon_back.svg?react';
import Burger from '../icons/icon_burger.svg?react';
import Calendar from '../icons/icon_calendar.svg?react';
import Camera from '../icons/icon_camera.svg?react';
import Circle from '../icons/icon_circle.svg?react';
import CircleFull from '../icons/icon_circleFull.svg?react';
import ClearField from '../icons/icon_clear_field.svg?react';
import Color from '../icons/icon_color.svg?react';
import ColorQuadrat from '../icons/icon_color_quadrat.svg?react';
import ConeLarge from '../icons/icon_cone_large.svg?react';
import ConeSmall from '../icons/icon_cone_small.svg?react';
import Connect from '../icons/icon_connect.svg?react';
import Contact from '../icons/icon_contact.svg?react';
import CopyPaste from '../icons/icon_copy_paste.svg?react';
import CopySlide from '../icons/icon_copy_slide.svg?react';
import Cut from '../icons/icon_cut.svg?react';
import Delete from '../icons/icon_delete.svg?react';
import DeleteSlide from '../icons/icon_delete_slide.svg?react';
import Faq from '../icons/icon_faq.svg?react';
import Field from '../icons/icon_field.svg?react';
import Background from '../icons/icon_field_select.svg?react';
import Follow from '../icons/icon_follow.svg?react';
import Forward from '../icons/icon_forward.svg?react';
import Head from '../icons/icon_head.svg?react';
import Hoodie from '../icons/icon_hoodie.svg?react';
import Info from '../icons/icon_info.svg?react';
import Ladder from '../icons/icon_ladder.svg?react';
import Lineup from '../icons/icon_lineup.svg?react';
import Lockerroom from '../icons/icon_lockerroom.svg?react';
import Managment from '../icons/icon_managment.svg?react';
import More from '../icons/icon_more.svg?react';
import New from '../icons/icon_new.svg?react';
import OpenTheme from '../icons/icon_open_theme.svg?react';
import Pants from '../icons/icon_pants.svg?react';
import Pencil from '../icons/icon_pencil.svg?react';
import Pointer from '../icons/icon_pointer.svg?react';
import Pole from '../icons/icon_pole.svg?react';
import QrCode from '../icons/icon_qr_scanner.svg?react';
import Rectangle from '../icons/icon_rectangle.svg?react';
import Reload from '../icons/icon_reload.svg?react';
import RenameSlide from '../icons/icon_rename_slide.svg?react';
import SelfTest from '../icons/icon_selftest.svg?react';
import Settings from '../icons/icon_settings.svg?react';
import Shirt from '../icons/icon_shirt.svg?react';
import Shoe from '../icons/icon_shoe.svg?react';
import SlideBackground from '../icons/icon_slide_background.svg?react';
import SoccerBall from '../icons/icon_soccerball.svg?react';
import Startelf from '../icons/icon_startelf.svg?react';
import Sync from '../icons/icon_sync.svg?react';
import Text from '../icons/icon_text.svg?react';
import Timeline from '../icons/icon_timeline.svg?react';
import Tools from '../icons/icon_tools.svg?react';
import Triangle from '../icons/icon_triangle.svg?react';
import Tshirt from '../icons/icon_tshirt.svg?react';
import Videos from '../icons/icon_videos.svg?react';
import TactixX from '../icons/tactix-x.svg?react';

const Icons = {
    // MUI re-exports
    Arrow,
    ArrowDashed,
    ArrowDownward,
    ArrowLeft,
    ArrowRight,
    ArrowSwitch,
    ArrowUpward,
    Back,
    Background,
    BroadcastOnHome,
    Burger,
    Calendar,
    Camera,
    Circle,
    CircleFull,
    ClearField,
    Color,
    ColorLens,
    ColorQuadrat,
    ConeLarge,
    ConeSmall,
    Connect,
    Contact,
    CopyPaste,
    CopySlide,
    Cut,
    Delete,
    DeleteSlide,
    Faq,
    Field,
    FileCopy,
    FirstPage,
    Follow,
    Forward,
    Head,
    Hoodie,
    Image,
    Info,
    Ladder,
    Language,
    Lineup,
    Lockerroom,
    Managment,
    Menu,
    More,
    New,
    OpenTheme,
    Pants,
    Pencil,
    Pointer,
    Pole,
    QrCode,
    Rectangle,
    Redo,
    Reload,
    RenameSlide,
    Save,
    SelfTest,
    Settings,
    Shirt,
    Shoe,
    SlideBackground,
    SoccerBall,
    Startelf,
    Sync,
    TactixX,
    Text,
    Timeline,
    Tools,
    Triangle,
    Tshirt,
    Undo,
    Videos,
};

export default Icons;
