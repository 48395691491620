import React from 'react';

import {Box, Stack, Typography} from '@mui/material';

import {useTranslation} from 'hooks';
import {useLayoutStore} from 'store';

const styles = {
    textContainer: {
        width: {
            desktop: '15.384614%',
            mobile: '25%'
        },
        height: {
            desktop: '50px',
            mobile: '40px'
        },
        position: 'relative',
    },
    headerText: {
        fontSize: 12,
        color: 'white.main',
        whiteSpace: 'nowrap',
        fontWeight: '500',
        overflow: 'hidden',
    },
    boxShadow: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        right: '0',
        boxShadow: '-81px 0px 23px -39px rgba(0,0,0,1) inset',
    },
};

/* themaFolieHeaderBoxInline */
const Info = ({group, sheet}) => {
    const translate = useTranslation();

    const setShowSheetMenu = useLayoutStore((state) => state.setShowSheetMenu);

    const showSheetMenu = () => {
        setShowSheetMenu(true);
    };

    return <Stack justifyContent="center" sx={styles.textContainer} onClick={showSheetMenu}>
        <Box sx={styles.boxShadow}/>
        <Typography sx={styles.headerText}>{group ? group.name : translate('board.group.no_group')}</Typography>

        <Typography sx={styles.headerText}>
            {sheet ? sheet.name : translate('board.sheet.no_sheet')}
        </Typography>
    </Stack>;
};

export default Info;
