import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';

import {FormControl, FormHelperText, InputLabel, Slider} from '@mui/material';

import {useTranslation} from 'hooks';

const styles = {
    label: {
        position: 'relative',
        transform: 'none',
        height: 60,
        paddingTop: 10
    }
};

const SliderField = ({name, label, defaultValue, fullWidth, required, helperText, ...rest}) => {
    const {control} = useFormContext();
    const translate = useTranslation();

    return <Controller
        control={control}
        name={name}
        shouldUnregister={true}
        defaultValue={defaultValue}
        render={({field: {onChange, onBlur, value}, fieldState: {error}}) => {
            return <FormControl fullWidth={fullWidth} error={!!error?.message} required={required}>
                <InputLabel sx={styles.label}>{translate(label)}</InputLabel>
                <Slider
                    marks={true}
                    valueLabelDisplay="on"
                    value={value}
                    onBlur={onBlur}
                    onChange={(_, v) => {
                        onChange(v);
                    }}
                    {...rest}/>
                <FormHelperText>{error?.message || translate(helperText)}</FormHelperText>
            </FormControl>;
        }}
    />;
};

SliderField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    defaultValue: PropTypes.any,
    helperText: PropTypes.string,
    required: PropTypes.bool,
};

export default SliderField;
