import React, {forwardRef, useEffect, useState} from 'react';

import {
    List, ListItem, ListItemText,
    MenuItem,
    Slide, Stack, Typography
} from '@mui/material';

import {yupResolver} from '@hookform/resolvers/yup';

import {
    Dialog
} from 'components';
import {useTranslation} from 'hooks';
import {imageUrl} from 'lib/media';
import {positions, roles} from 'module/client/team/useTeamApi';
import UserSelectOnDemand from 'module/client/UserSelect';
import {useLayoutStore, useSnackbarStore} from 'store';

import {schema, useApiUpdate} from './useMemberApi';

import {AutocompleteField, Form, FormField, SelectField, SubmitableField, useForm} from 'form';

const styles = {
    logoHolder: {
        width: '100%',
        position: 'relative',
        '& img': {
            display: 'block',
            maxWidth: '100%',
            height: 'auto',
            maxHeight: '450px'
        },
    },
    listItemTitle: {
        backgroundColor: 'grey.darkest2',
        padding: '3px 15px',
        margin: 0,
    },
    listItemTitleText: {
        fontWeight: '300',
        color: 'grey.mainlight'
    },

    listHolder: {
        marginBottom: '15px',
    },
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const Edit = ({team, member, onClose, onSave}) => {
    const translate = useTranslation();

    const [editable, setEditable] = useState('');

    const showInfo = useSnackbarStore((state) => state.show);

    const setShowLoading = useLayoutStore((state) => state.setShowLoading);

    const {update, loading, data, errors} = useApiUpdate(member);

    const rhf = useForm(data, errors, true, {resolver: yupResolver(schema)});

    const doSave = () => {
        rhf.methods.handleSubmit(async formData => {
            formData.id = member.id;
            console.log(formData);
            // post objectid as string, do not post null, this will be ignored
            formData.user_id = formData.user?.id || '';

            try {
                const res = await update(team.id, formData);
                setEditable(null);
                showInfo('client.action.member.save_success');
                onSave && onSave(res);
            } catch (e) {
                showInfo(e.message, {severity: 'warning'});
            }
        })();
    };

    useEffect(() => {
        setShowLoading(loading);
        return () => {
            setShowLoading(false);
        };
    }, [loading, setShowLoading]);

    const firstname = member.user ? member.user.firstname : member.firstname;
    const lastname = member.user ? member.user.lastname : member.lastname;

    return (
        <Dialog
            maxWidth={false}
            pt={0}
            open={true}
            TransitionComponent={Transition}
            title="client.action.member.edit"
            handleClose={onClose}
            dividers={true}
        >
            <Stack sx={styles.logoHolder} justifyContent="center" alignItems="center">
                {member.user?.image?.url && <img
                    src={imageUrl(member.user.image, {w: 200})}
                    alt={`${firstname} ${lastname}`}
                />}
            </Stack>
            <Form methods={rhf.methods} onSubmit={doSave}>
                <Stack direction="column" justifyContent="flex-end" p={2}>
                    <List sx={styles.listHolder}>
                        <ListItem>
                            <ListItemText sx={styles.listItemTitle}><Typography
                                sx={styles.listItemTitleText}>{translate(`team.member.assign_user`)}</Typography></ListItemText>
                        </ListItem>
                        <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                            <UserSelectOnDemand name="user" label="user.user" clientId={team.client_id}/>
                        </SubmitableField>
                    </List>

                    <List sx={styles.listHolder}>
                        <ListItem>
                            <ListItemText sx={styles.listItemTitle}><Typography
                                sx={styles.listItemTitleText}>{translate(`team.player.data`)}</Typography></ListItemText>
                        </ListItem>
                        {!member.user && <>
                            <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                                <FormField
                                    name="firstname"
                                    label="global.person.firstname"
                                />
                            </SubmitableField>
                            <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                                <FormField
                                    name="lastname"
                                    label="global.person.lastname"
                                />
                            </SubmitableField>
                        </>}
                        <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                            <FormField
                                name="number"
                                label="team.player.back_number"
                                type="number"
                            />
                        </SubmitableField>
                        <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                            <SelectField label="team.position.position" name="position">
                                {positions.map(position => <MenuItem
                                    key={position}
                                    value={position}>{translate(`team.position.${position}`)}
                                </MenuItem>)}
                            </SelectField>
                        </SubmitableField>
                        <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                            <AutocompleteField
                                label="client.role.roles"
                                name="roles"
                                multiple
                                options={roles}
                                disableClearable/>
                        </SubmitableField>
                    </List>
                </Stack>
            </Form>
        </Dialog>
    );
};

export default Edit;