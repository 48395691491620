import {useCallback} from 'react';

import {getLineupByName} from 'assets/lineups';
import {canvasToJson} from 'canvas/canvas-helper';
import {createPlayer as createPlayerShirt} from 'canvas/Drawer/Player';
import {
    getNextPlayerNumber,
    getPlayerNumber,
    setPlayerName,
} from 'canvas/Drawer/player-utils';
import {createPlayerCircle} from 'canvas/Drawer/PlayerCircle';
import {useCanvasStore} from 'canvas/zustand';
import {useAnalytics} from 'hooks';
import {memberInfo} from 'module/client/team/member/useInfo';
import {groupByPosition} from 'module/client/team/member/useMemberApi';

import {positions} from './useTeamApi';

export const useDrawTeamLineup = () => {
    const canvas = useCanvasStore(state => state.canvas);
    const setDirty = useCanvasStore(state => state.setDirty);
    const pushCanvas = useCanvasStore(state => state.pushCanvas);

    const sendAnalytics = useAnalytics();

    return useCallback((players, team, lineupName, direction, type, size) => {
        setDirty(true);
        sendAnalytics('action', 'lineup', {team: team.name, lineup: lineupName});

        console.log(players, team, lineupName, direction, type, size);

        const byPosition = groupByPosition(players);

        const lineup = getLineupByName(direction, lineupName);
        if (!lineup) {
            return;
        }

        canvas.isLoading = true; // avoid pushCanvas for each player

        positions.forEach(pos => {
            const coordinates = lineup.positions?.[pos];
            if (!coordinates) {
                return;
            }

            const existingLine = findPlayersByPosition(canvas, team.id, pos);

            console.log(pos, existingLine.length, coordinates.length);

            // remove surplus players
            const numRemove = existingLine.length - coordinates.length;
            for (let i = 0; i < numRemove; i++) {
                canvas.remove(existingLine.pop());
            }

            // remove players, which are already on the canvas
            const availablePlayers = byPosition[pos].filter(p => !existingLine.find(e => e.custom.playerId === p.id));

            // add missing players
            for (let index = 0; index < coordinates.length - existingLine.length; index++) {
                let player = availablePlayers.shift();

                // no more players available
                if (!player) {
                    break;
                }

                const c = getCoordinate(canvas, coordinates, index);

                const colors = {
                    value1: team.prefs.player.color1,
                    value2: team.prefs.player.color2,
                    textColor: team.prefs.player.color2
                };
                // const size = mapPlayerSize(prefs.player.size);
                const image = team.prefs.player.image ? player.user?.image : null;

                createPlayer(canvas, player, type, colors, size, image, c.left, c.top, {
                    team: team.id,
                    playerId: player.id,
                    position: pos
                });
            }

            // animate players
            const line = findPlayersByPosition(canvas, team.id, pos);
            line.forEach((p, index) => {
                p.animate(getCoordinate(canvas, coordinates, index), {
                    duration: 800,
                    onChange: canvas.renderAll.bind(canvas),
                });
            });

        });

        pushCanvas(canvasToJson(canvas));
        canvas.isLoading = false;
    }, [canvas, pushCanvas, sendAnalytics, setDirty]);
};

export const useDrawAnonLineup = () => {
    const canvas = useCanvasStore(state => state.canvas);
    const setDirty = useCanvasStore(state => state.setDirty);
    const pushCanvas = useCanvasStore(state => state.pushCanvas);

    const sendAnalytics = useAnalytics();

    return useCallback((lineupName, direction, type, colors, size) => {
        setDirty(true);
        sendAnalytics('action', 'linup-anonymous', {direction, lineup: lineupName});

        const lineup = getLineupByName(direction, lineupName);
        if (!lineup) {
            return;
        }

        // flatten coordinates
        let coordinates = [];
        for (const pos in lineup.positions) {
            coordinates = coordinates.concat(lineup.positions[pos]);
        }

        const existingPlayers = findPlayersByTeam(canvas, colors.name);

        canvas.isLoading = true; // avoid pushCanvas for each player

        // add remaining players
        for (let i = existingPlayers.length; i < coordinates.length; i++) {
            const number = getNextPlayerNumber(canvas, colors.name);
            const c = getCoordinate(canvas, coordinates, i);
            createPlayer(canvas, {number}, type, colors, size, false, c.left, c.top, {
                team: colors.name
            });
        }

        // animate players
        const line = findPlayersByTeam(canvas, colors.name);
        line.forEach((p, index) => {
            p.animate(getCoordinate(canvas, coordinates, index), {
                duration: 800,
                onChange: canvas.renderAll.bind(canvas),
            });
        });

        pushCanvas(canvasToJson(canvas));
        canvas.isLoading = false;
    }, [canvas, pushCanvas, sendAnalytics, setDirty]);
};

export const createPlayer = (canvas, player, type, colors, size, image, left, top, customData = {}) => {
    const {name} = memberInfo(player);

    const onComplete = (cvs, player) => {
        if (name) {
            setPlayerName(player, name);
        }

        cvs.add(player);
    };

    if (type === 'playerCircle') {
        createPlayerCircle(canvas, colors, size, left, top, player.number, image, customData, onComplete);
    } else {
        createPlayerShirt(canvas, 'player', colors, size, left, top, player.number, customData, onComplete);
    }
};

const getCoordinate = (canvas, coordinates, index) => {
    const left = Math.round(coordinates[index].x);
    const top = Math.round(coordinates[index].y);

    return {left, top};
};

/**
 * Finds players on a canvas by their position.
 *
 * @param {fabric.Canvas} canvas - The canvas object.
 * @param {string} teamId - The team id for holding the players together
 * @param {string} position - The position (goal, defense, ...) of the players to find.
 * @returns {fabric.Object[]} - An array of fabric objects representing the found players.
 */
const findPlayersByPosition = (canvas, teamId, position) => {
    if (!canvas) {
        return [];
    }

    return canvas.getObjects().filter(e => e.custom?.team === teamId && e.custom?.position === position);
};

const findPlayersByTeam = (canvas, teamId) => {
    return canvas.getObjects()
        .filter(e => e.custom?.type === 'player' && e.custom?.team === teamId)
        .sort((a, b) => getPlayerNumber(a) - getPlayerNumber(b));
};
