import React, {useState} from 'react';

import {
    Stack, Tab, Tabs,
    Typography, useTheme
} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';

import Icons from 'assets/icons';
import {
    Drawer,
    MenuHeader,
    Text
} from 'components';
import {imageUrl} from 'lib/media';
import Information from 'module/client/team/Information';
import Members from 'module/client/team/member/Members';
import {useSettingsStore} from 'module/settings/zustand';
import {useLayoutStore} from 'store';

const styles = {
    container: {
        paddingX: 2.5,
        paddingBottom: 2.5,
    },
    imageHolder: {
        width: '100%',
        position: 'relative',
        marginBottom: 2.5,
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            display: 'block',
            width: '100%',
            maxHeight: 600,
            height: 'auto',
        },
    },
    headlineContainer: {
        overflow: 'hidden',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 1,
    },
    headlineContainerInline: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        marginRight: 2.5,
    },
    headlineIcon: {
        flexShrink: 0,
        cursor: 'pointer',
    },
    tabContainer: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'grey.darkest2',
        width: '100%',
        alignItems: 'center',
    },
};

const Team = ({team}) => {
    const theme = useTheme();

    const [tab, setTab] = useState('members');

    const closeMenu = useLayoutStore(state => state.closeMenu);
    const setShowTeam = useLayoutStore(state => state.setShowTeam);

    const setSelectedTeam = useSettingsStore(state => state.setSelectedTeam);

    const queryClient = useQueryClient();

    const goBack = () => {
        setShowTeam(false);
    };

    const selectTeam = () => {
        queryClient.invalidateQueries({queryKey: ['user-team', team.id]});
        setSelectedTeam(team.id);
        closeMenu();
    };

    const iconStyle = {...styles.headlineIcon, fill: theme.palette.white.main};

    return (
        <Drawer open={true} onClose={goBack}>
            <MenuHeader text={team.name} handleBackButton={goBack} handleClose={closeMenu} />

            {team.image &&
                <Stack sx={styles.imageHolder}>
                    <img src={imageUrl(team.image, {w: 600})} alt={team.name}/>
                </Stack>
            }

            <Stack sx={styles.container}>

                <Stack direction="column">

                    <Stack direction="row" sx={styles.headlineContainer}>
                        <Stack sx={styles.headlineContainerInline}>
                            <Typography>{team.name}</Typography>
                        </Stack>
                        <Icons.ArrowSwitch
                            width={32} height={32} style={iconStyle}
                            onClick={selectTeam}/>
                    </Stack>

                    <Stack sx={styles.tabContainer}>
                        <Tabs value={tab} onChange={(_e, tab) => setTab(tab)}>
                            <Tab
                                value="members"
                                label={<Typography><Text>client.members</Text></Typography>}/>
                            <Tab
                                value="information"
                                label={<Typography><Text>global.information</Text></Typography>}/>
                        </Tabs>
                    </Stack>
                </Stack>

                {tab === 'members' && <Members team={team}/>}
                {tab === 'information' && <Information team={team}/>}

            </Stack>

        </Drawer>
    );
};

export default Team;