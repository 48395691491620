import {DateTime , Duration} from 'luxon';

const MeetingTimer = ({time}) => {
    if(time && Number.isInteger(time)){
        return Duration.fromObject(DateTime.local().set({milliseconds: 0}).diff(DateTime.local().set({milliseconds: -time})).toObject()).toFormat('mm:ss');
    } else {
        return '00:00';
    }
};

export default MeetingTimer;