// loads selftest data from electron
export const runSelftest = async () => {
    if (window?.tactix?.selftest === undefined) {
        return Promise.resolve();
    }

    return window.tactix.selftest();
};

export const hasSelftest = async () => {
    if (window?.tactix?.hasSelftest === undefined) {
        return Promise.resolve();
    }

    return window.tactix.hasSelftest();
};
