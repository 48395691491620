import {create} from 'zustand';

import {middleware} from 'store/zustand';

import {produce} from 'immer';

export const usePlayerStore = create(middleware(set => ({
    player: null,
    set: p => set(produce(draft => {
        draft.player = p;
    })),
    unset: () => set(produce(draft => {
        draft.player = null;
    })),
})));
