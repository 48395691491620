import React, {useEffect} from 'react';

import {Popper} from '@mui/material';

import {useBannersStore} from 'module/banner';

const styles = {
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: 'black.light',
        padding: {
            mobile: '20px 20px',
            desktop: '60px 20px',
        },
    },
};

const Flyout = ({open, anchorEl, onClose, children}) => {
    const bannersVisible = useBannersStore((store) => store.visible);

    useEffect(() => {
        if (bannersVisible) {
            onClose();
        }
        // onClose is not wrapped into useCallback
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersVisible]);

    return <Popper open={open} anchorEl={anchorEl} placement="bottom" sx={styles.root}>
        {children}
    </Popper>;

};

export default Flyout;