import {useCallback} from 'react';

import storage from 'store/localStorage';

const version = storage.getItem('version');

const useMigration4to5 = () => {

    // there is no structural change, just for information, that we have upgraded immer to 10.1
    return useCallback(() => {

        if (version >= 5) {
            console.log('Migration4to5: already migrated to version 5');
            return;
        }

        storage.setItem('version', 5);

        // do not re-render/generate, runs at startup only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useMigration4to5;
