import React, {useCallback, useEffect, useState} from 'react';

import {
    SignalCellular0Bar as SignalCellular0BarIcon,
    SignalCellular1Bar as SignalCellular1BarIcon,
    SignalCellular2Bar as SignalCellular2BarIcon,
    SignalCellular3Bar as SignalCellular3BarIcon,
    SignalCellular4Bar as SignalCellular4BarIcon,
    SignalCellularNodata as SignalCellularNodataIcon,
} from '@mui/icons-material';
import {Box, Typography} from '@mui/material';
import {ClickAwayListener, Popper, Stack} from '@mui/material';

import {ActionButton, Text} from 'components';
import {useInterval} from 'hooks';
import {useBannersStore} from 'module/banner';
import {getWwanStatus} from 'module/sysInfo/sysInfo';

const styles = {
    container: {
        width: '7.692307%',
        display: 'flex',
        justifyContent: 'center',
    },
    iconStyle: {
        color: 'white.main',
        borderRadius: 0,
        position: 'relative',
    },
    icon: {
        fill: '#ffffff',
        width: 24,
        height: 24,
    },
    signalStrengthContainer: {
        width: '340px',
        position: 'absolute',
        top: '100%',
        marginTop: '10px',
        right: 0,
        backgroundColor: 'black.light',
        padding: '20px',
        zIndex: 99,
        color: 'white.main',
        fontSize: 17,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    signalStrengthContainerTitle: {
        width: '100%',
        marginBottom: '10px',
        borderBottom: '1px solid',
        borderColor: 'white.main',
        paddingBottom: '10px',
    },
};

const IconMap = [
    SignalCellular0BarIcon,
    SignalCellular1BarIcon,
    SignalCellular2BarIcon,
    SignalCellular3BarIcon,
    SignalCellular4BarIcon
];

const SignalIcon = ({quality, state}) => {
    let strength = Math.floor(quality / 20);

    if (strength < 0) {
        strength = 0;
    }

    if (strength > 4) {
        strength = 4;
    }

    const Icon = IconMap[strength];

    if (state !== 'connected') {
        return <SignalCellularNodataIcon/>;
    }

    return <Icon/>;
};

const Wwan = () => {
    const [data, setData] = useState();
    const [showSignalStrength, setShowSignalStrength] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const bannersVisible = useBannersStore((store) => store.visible);

    useInterval(useCallback(async () => {
        setData(await getWwanStatus());
    }, []), 30000);

    useEffect(() => {
        const getStatus = async () => {
            return setData(await getWwanStatus());
        };

        getStatus();
    }, []);

    useEffect(() => {
        if (bannersVisible) {
            handleClickAway();
        }
    }, [bannersVisible]);

    const handleIconClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setShowSignalStrength(!showSignalStrength);
    };

    const handleClickAway = () => {
        setShowSignalStrength(false);
        setAnchorEl(null);
    };

    if (data === undefined) {
        return null;
    }

    return <Box sx={styles.container}>
        <ClickAwayListener onClickAway={handleClickAway}>
            <Stack direction={'row'}>
                <ActionButton sx={styles.iconStyle} onClick={handleIconClick}>
                    <SignalIcon sx={styles.icon} quality={data.quality.value} state={data.status.state}/>
                </ActionButton>

                {showSignalStrength && (
                    <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement={'bottom-end'}>
                        <Box sx={styles.signalStrengthContainer}>
                            <Typography sx={styles.signalStrengthContainerTitle} variant="h1"><Text>net.signal.strength</Text> {data.quality.value} %</Typography>
                            {data.quality.value < 20 && (
                                <>
                                    <Typography mb={1} variant="h3"><Text>net.signal.quality.weak</Text></Typography>
                                    <Typography variant="subtitle1"><Text>net.signal.quality.description.weak</Text></Typography>
                                </>
                            )}
                            {data.quality.value >= 20 && data.quality.value < 40 && (
                                <>
                                    <Typography mb={1} variant="h3"><Text>net.signal.quality.acceptable</Text></Typography>
                                    <Typography variant="subtitle1"><Text>net.signal.quality.description.acceptable</Text></Typography>
                                </>
                            )}
                            {data.quality.value >= 40 && data.quality.value < 60 && (
                                <>
                                    <Typography mb={1} variant="h3"><Text>net.signal.quality.enhancing</Text></Typography>
                                    <Typography variant="subtitle1"><Text>net.signal.quality.description.enhancing</Text></Typography>
                                </>
                            )}
                            {data.quality.value >= 60 && data.quality.value < 80 && (
                                <>
                                    <Typography mb={1} variant="h3"><Text>net.signal.quality.strong</Text></Typography>
                                    <Typography variant="subtitle1"><Text>net.signal.quality.description.strong</Text></Typography>
                                </>
                            )}
                            {data.quality.value >= 80 && (
                                <>
                                    <Typography mb={1} variant="h3"><Text>net.signal.quality.excellent</Text></Typography>
                                    <Typography variant="subtitle1"><Text>net.signal.quality.message.excellent</Text></Typography>
                                </>
                            )}
                        </Box>
                    </Popper>
                )}
            </Stack>
        </ClickAwayListener>
    </Box>;
};

export default Wwan;
