import {useCallback} from 'react';

import {isOk, useFetcher} from 'fetcher';
import {getEnv} from 'lib/env';

const CONTROL_URL = getEnv('CONTROL_URL');

export const useApiAdd = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'user/sheet/{sheetId}/frame/', {
        method: 'POST',
    }, undefined, true);

    const add = useCallback(async (sheetId, frame, index = -1) => {

        const args = {
            urlParams: {
                sheetId
            },
            data: frame
        };

        if (index >= 0) {
            args.url = CONTROL_URL + 'user/sheet/{sheetId}/frame/{index}/';
            args.urlParams.index = index;
        }

        const res = await fetch(args);

        if (isOk(res) && typeof res.data === 'object') {
            return res.data;
        }
    }, [fetch]);

    return {add, loading};
};

export const useApiUpdate = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'user/sheet/{sheetId}/frame/{index}/', {
        method: 'PUT',
    }, undefined, true);

    const update = useCallback(async (sheetId, frame, index) => {
        const res = await fetch({
            urlParams: {
                sheetId,
                index
            },
            data: {
                frame
            }
        });

        if (isOk(res) && typeof res.data === 'object') {
            return res.data;
        }
    }, [fetch]);

    return {update, loading};
};

export const useApiDelete = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'user/sheet/{sheetId}/frame/{index}', {
        method: 'DELETE',
    }, undefined, true);

    const del = useCallback(async (sheetId, index) => {
        const res = await fetch({
            urlParams: {
                sheetId,
                index
            },
        });

        if (isOk(res)) {
            return true;
        }
    }, [fetch]);

    return {del, loading};
};

export const useApiClear = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'user/sheet/{sheetId}/frame/', {
        method: 'DELETE',
    }, undefined, true);

    const clear = useCallback(async (sheetId) => {
        const res = await fetch({
            urlParams: {
                sheetId,
            },
        });

        if (isOk(res)) {
            return true;
        }
    }, [fetch]);

    return {clear, loading};
};
