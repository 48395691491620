import {useEffect} from 'react';

import {useAudioDevice} from './useAudioDevice';
import {useLockerroom} from './useLockerroom';
import {useMediaDevice} from './useMediaDevice';
import {useMediaSoup} from './useMediaSoup';

const LockerroomHooks = () => {
    useAudioDevice();
    useLockerroom();
    useMediaSoup();
    useMediaDevice();
    useEffect(() => {
        console.log('LOCKERROOM HOOKS MOUNT');

        return () => {
            console.log('LOCKERROOM HOOKS UNMOUNT');

        };
    }, []);
    
    return null;
};

export default LockerroomHooks;
