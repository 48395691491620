import * as React from 'react';

import {Box} from '@mui/material';

const styles = {
    pingCircle: {
        width: '14px',
        height: '14px',
        borderRadius: '50%',
        border: '2px solid',
        borderColor: 'black.main',
        zIndex: '1',
    },
};

const ConnectionIcon = ({ping}) => {
    return (
        <>
            {ping >= 0 && ping <= 50 &&
                <Box sx={[styles.pingCircle, {backgroundColor: 'success.main'}]}/>
            }
            {ping > 50 && ping < 100 &&
                <Box sx={[styles.pingCircle, {backgroundColor: 'success.main'}]}/>
            }
            {ping >=100 && ping < 250 &&
                <Box sx={[styles.pingCircle, {backgroundColor: 'warning.main'}]}/>
            }
            {ping >=250 &&
                <Box sx={[styles.pingCircle, {backgroundColor: 'error.main'}]}/>
            }
            {!ping || ping < 0 &&
                <Box sx={[styles.pingCircle, {backgroundColor: 'error.main'}]}/>
            }

        </>
    );
};

export default ConnectionIcon;