import React from 'react';

import {Checkbox, Grid, Stack} from '@mui/material';

import {NoData} from 'components';
import {PlayerListItem} from 'module/settings/index';

const styles = {
    root: {

        width: '100%',
    },
    blockContainer: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        alignSelf: 'flex-start',
        zIndex: 910,
        borderBottom: '1px solid',
        borderBottomColor: 'grey.dark',
        '&:last-child': {
            borderBottom: 0,
        }
    },
    blockContainerRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        minHeight: '70px',
        height: 'auto',
        borderLeft: '10px solid',
        borderLeftColor: 'grey.light',
        color: 'white.main',
        fontSize: '16px',
        padding: '10px 20px',
        backgroundColor: 'grey.darker',
        cursor: 'normal',
    },
    radioButton: {
        padding: '0 0 0 20px',
        margin: 0,
        color: '#fff',
        backgroundColor: 'transparent',

        '&:hover': {
            backgroundColor: 'transparent',            
        },

        '&:selected': {
            color: 'green.main'
        },
    }
};

const PlayerList = ({selected, onSelect, players}) => {
    return <Grid sx={styles.root}>
        {players ? players.map((player, i) => (
            <Grid key={`panelgroup-${i}`} item sx={styles.blockContainer}>
                <Stack sx={styles.blockContainerRow}>
                    <PlayerListItem player={player} onClick={() => onSelect(player)}/>
                    {selected instanceof Set && <Checkbox
                        checked={selected && selected.has(player)}
                        value={player._id}
                        name="teams"
                        onClick={() => onSelect(player)}
                        sx={styles.radioButton}
                    />}
                </Stack>
            </Grid>
        )) : <NoData text="Keine Spieler vorhanden!" />
        }
    </Grid>;
};

export default PlayerList;
