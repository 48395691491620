import Lineup343 from './3-4-3.svg?react';
import Lineup352 from './3-5-2.svg?react';
import Lineup4231 from './4-2-3-1.svg?react';
import Lineup4321 from './4-3-2-1.svg?react';
import Lineup433 from './4-3-3.svg?react';
import Lineup442 from './4-4-2.svg?react';
import Lineup442diamond from './4-4-2-diamond.svg?react';
import Lineup451 from './4-5-1.svg?react';
import Lineup451libero from './4-5-1-libero.svg?react';
import Lineup532 from './5-3-2.svg?react';
import Lineup541 from './5-4-1.svg?react';

//const defGoal = [{x: 9, y: 33.5}];
const defGoal = [{x: 540, y: 1550}];
const defDefense4 = [{x: 100, y: 1180}, {x: 370, y: 1280}, {x: 710, y: 1280}, {x: 980, y: 1180}];
const defDefense5 = [{x: 70, y: 1100}, {x: 260, y: 1260}, {x: 540, y: 1300}, {x: 820, y: 1260}, {x: 1010, y: 1100}];
const defMidfield4 = [{x: 120, y: 850}, {x: 420, y: 940}, {x: 660, y: 940}, {x: 960, y: 850}];
const defAttack1 = [{x: 540, y: 520}];
const defAttack2 = [{x: 400, y: 570}, {x: 680, y: 570}];

const lineups = [
    {
        name: '3-4-3',
        Icon: Lineup343,
        positions: {
            goal: defGoal,
            defense: [{x: 200, y: 1240}, {x: 540, y: 1290}, {x: 880, y: 1240}],
            midfield: defMidfield4,
            attack: [{x: 210, y: 610}, {x: 540, y: 500}, {x: 870, y: 610}]
        },
        // positions: [
        //     {x: 3, y: 27}, {x: 9, y: 28}, {x: 15, y: 27},
        //     ...defMidfield4,
        //     {x: 3.5, y: 13}, {x: 9, y: 10.5}, {x: 14.5, y: 13},
        // ]
    },
    {
        name: '3-5-2',
        Icon: Lineup352,
        positions: {
            goal: defGoal,
            defense: [{x: 200, y: 1240}, {x: 540, y: 1290}, {x: 880, y: 1240}],
            midfield: [{x: 100, y: 850}, {x: 400, y: 960}, {x: 540, y: 806}, {x: 680, y: 960}, {x: 980, y: 850}],
            attack: defAttack2
        },
        // positions: [
        //     {x: 3, y: 27}, {x: 9, y: 28}, {x: 15, y: 27},
        //     {x: 1, y: 18.5}, {x: 6.5, y: 20.5}, {x: 9, y: 17.5}, {x: 11.5, y: 20.5}, {x: 17, y: 18.5},
        //     ...defAttack2
        // ]
    },
    {
        name: '4-2-3-1',
        Icon: Lineup4231,
        positions: {
            goal: defGoal,
            defense: defDefense4,
            midfield: [{x: 400, y: 1020}, {x: 680, y: 1020}, {x: 200, y: 750}, {x: 540, y: 750}, {x: 880, y: 750}],
            attack: defAttack1
        },
        // positions: [
        //     ...defDefense4,
        //     {x: 6.5, y: 22}, {x: 11.5, y: 22},
        //     {x: 3, y: 16}, {x: 9, y: 16}, {x: 15, y: 16},
        //     ...defAttack1
        // ]
    },
    {
        name: '4-3-2-1',
        Icon: Lineup4321,
        positions: {
            goal: defGoal,
            defense: defDefense4,
            midfield: [{x: 280, y: 950}, {x: 540, y: 1020}, {x: 800, y: 950}, {x: 360, y: 680}, {x: 720, y: 680}],
            attack: [{x: 540, y: 480}]
        },
        // positions: [
        //     ...defDefense4,
        //     {x: 4, y: 20.5}, {x: 9, y: 22}, {x: 14, y: 20.5},
        //     {x: 6, y: 14.5}, {x: 12, y: 14.5},
        //     {x: 9, y: 10}
        // ]
    },
    {
        name: '4-3-3',
        Icon: Lineup433,
        positions: {
            goal: defGoal,
            defense: defDefense4,
            midfield: [{x: 300, y: 900}, {x: 540, y: 1010}, {x: 780, y: 900}],
            attack: [{x: 200, y: 630}, {x: 540, y: 520}, {x: 880, y: 630}]
        },
        // positions: [
        //     ...defDefense4,
        //     {x: 5, y: 19.5}, {x: 9, y: 21.5}, {x: 13, y: 19.5},
        //     {x: 3, y: 13.5}, {x: 9, y: 11}, {x: 15, y: 13.5}
        // ]
    },
    {
        name: '4-4-2',
        Icon: Lineup442,
        positions: {
            goal: defGoal,
            defense: defDefense4,
            midfield: defMidfield4,
            attack: defAttack2
        },
        // positions: [
        //     ...defDefense4,
        //     ...defMidfield4,
        //     ...defAttack2,
        // ]
    },
    {
        name: '4-4-2 dia',
        Icon: Lineup442diamond,
        positions: {
            goal: defGoal,
            defense: defDefense4,
            midfield: [{x: 250, y: 850}, {x: 540, y: 690}, {x: 540, y: 1010}, {x: 830, y: 850}],
            attack: defAttack2
        },
        // positions: [
        //     ...defDefense4,
        //     {x: 4, y: 18.5}, {x: 9, y: 14.5}, {x: 9, y: 22}, {x: 14, y: 18.5},
        //     ...defAttack2,
        // ]
    },
    {
        name: '4-5-1',
        Icon: Lineup451,
        positions: {
            goal: defGoal,
            defense: defDefense4,
            midfield: [{x: 100, y: 850}, {x: 370, y: 910}, {x: 540, y: 760}, {x: 710, y: 910}, {x: 980, y: 850}],
            attack: defAttack1
        },
        // positions: [
        //     ...defDefense4,
        //     {x: 1, y: 18}, {x: 6, y: 20}, {x: 9, y: 16}, {x: 12, y: 20}, {x: 17, y: 18},
        //     ...defAttack1
        // ]
    },
    {
        name: '4-5-1 libero',
        Icon: Lineup451libero,
        positions: {
            goal: defGoal,
            defense: [{x: 150, y: 1270}, {x: 540, y: 1150}, {x: 540, y: 1330}, {x: 930, y: 1270}],
            midfield: [{x: 100, y: 900}, {x: 380, y: 950}, {x: 540, y: 730}, {x: 700, y: 950}, {x: 980, y: 900}],
            attack: defAttack1
        },
        // positions: [
        //     {x: 2, y: 27.5}, {x: 9, y: 25}, {x: 9, y: 29}, {x: 16, y: 27.5},
        //     {x: 1, y: 19.5}, {x: 6, y: 20.5}, {x: 9, y: 15.5}, {x: 12, y: 20.5}, {x: 17, y: 19.5},
        //     ...defAttack1
        // ]
    },
    {
        name: '5-3-2',
        Icon: Lineup532,
        positions: {
            goal: defGoal,
            defense: defDefense5,
            midfield: [{x: 290, y: 800}, {x: 540, y: 960}, {x: 790, y: 800}],
            attack: defAttack2
        },
        // positions: [
        //     ...defDefense5,
        //     {x: 5, y: 17}, {x: 9, y: 21}, {x: 13, y: 17},
        //     ...defAttack2,
        // ]
    },
    {
        name: '5-4-1',
        Icon: Lineup541,
        positions: {
            goal: defGoal,
            defense: defDefense5,
            midfield: defMidfield4,
            attack: defAttack1
        },
        // positions: [
        //     ...defDefense5,
        //     ...defMidfield4,
        //     ...defAttack1
        // ]
    },
];

export default lineups;
