import React from 'react';

import {Box, Stack, useMediaQuery, useTheme} from '@mui/material';

import {useClockTimer} from 'hooks/index.js';
import {dateFormat} from 'lib/datefns.js';

const styles = {
    clockStyle: {
        width: '15.384614%',
        fontSize: '20px',
        fontWeight: 200,
        height: '60px',
        paddingRight: '10px'
    },
    dateBox: {
        fontWeight: 200,
        fontSize: '14px'
    },
    hourBox: {
        color: 'white.main',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: 1,
    },
};

const Clock = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const dateTime = useClockTimer(new Date());

    if (isMobile) {
        return null;
    }

    return <Stack alignItems="flex-end" justifyContent="center" sx={styles.clockStyle}>
        <Box sx={styles.dateBox}>{dateFormat(dateTime, 'ccc, dd. MMM yyyy')}</Box>
        <Box sx={styles.hourBox}>{dateFormat(dateTime, 'HH:mm')}</Box>
    </Stack>;
};

export default Clock;
