import React from 'react';

import {
    Clear as CloseIcon,
} from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {Button, ButtonBase, Stack, Typography} from '@mui/material';

const styles = {
    menuHeader: {
        width: '100%',
        alignItems: 'center',
        borderBottom: '1px solid',
        borderBottomColor: 'grey.darkest2',
        position: 'sticky',
        top: 0,
        backgroundColor: 'black.dark',
        zIndex: 1100,
        flexShrink: 0,
    },
    menuHeaderButton: {
        color: 'white.main',
        cursor: 'pointer',
        justifyContent: 'flex-start',
        padding: 0,
        margin: 0,
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        flexShrink: 0,
        minWidth: 50,
        width: 60,
        height: 60,
        paddingLeft: 2.5,
    },
    menuHeaderButtonLast: {
        justifyContent: 'flex-end',
        paddingRight: 2.5,
    },
    menuHeaderTextContainer: {
        flexGrow: 1,
        overflow: 'hidden',
    },
};

const MenuHeader = ({text, handleBackButton, handleClose}) => {
    return (
        <Stack sx={styles.menuHeader} direction="row">

            {handleBackButton &&
                <ButtonBase sx={styles.menuHeaderButton} onClick={handleBackButton} disableRipple>
                    <ArrowBackIosIcon />
                </ButtonBase>
            }

            <Stack sx={styles.menuHeaderTextContainer}>
                <Typography align="center" variant="h4" color="white.main" noWrap>{text}</Typography>
            </Stack>

            {handleClose && <Button sx={[styles.menuHeaderButton, styles.menuHeaderButtonLast]} disableRipple onClick={handleClose}>
                <CloseIcon/>
            </Button>}

        </Stack>
    );
};

export default MenuHeader;