import {useEffect,useState} from 'react';

const useClockTimer = (currentDate, timeout = 1000) => {
    const [date, setDate] = useState(currentDate);

    useEffect(() => {
        const timerId = setInterval(() => tick(), timeout);

        return () => {
            clearInterval(timerId);
        };
    });

    const tick = () => {
        setDate(new Date());
    };

    return date;
};

export default useClockTimer;
