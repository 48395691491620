// electron pairing api

export const hasPairingApi = () => {
    return !!window.pairingApi;
};

export const getPairing = async () => {
    if (!hasPairingApi()) {
        return Promise.resolve();
    }

    return window.pairingApi.get();
};

export const deletePairing = async () => {
    if (!hasPairingApi()) {
        return Promise.resolve();
    }

    return window.pairingApi.delete();
};
