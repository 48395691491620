import React from 'react';

import {IconButton, useMediaQuery, useTheme} from '@mui/material';

const ActionButton = ({children, backgroundColor = undefined, color = '#fff', ...rest}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const size = isMobile ? '50px' : '70px';
    return (
        <IconButton
            data-testid="action-btn"
            fontSize={size}
            sx={{backgroundColor, borderRadius: 2, color}}
            disableRipple
            {...rest}
        >
            {children}
        </IconButton>
    );
};

export default ActionButton;
