import {useCallback} from 'react';

import {useMediaQuery, useTheme} from '@mui/material';

import {useSheet} from 'module/sheet/useSheet';
import {usePrefsStore} from 'store/prefs';

import {useAnalytics} from '../hooks';

import {canvasToJson, clear} from './canvas-helper';
import {useCanvasStore} from './zustand';

const removeSelectedObject = (canvas) => {
    if (canvas.getActiveObjects().length === 0) {
        // setLastObjectActive(canvas);
    }

    canvas.getActiveObjects().forEach((o) => {
        canvas.remove(o);
    });

    canvas.discardActiveObject(null);
    // setLastObjectActive(canvas);

    canvas.renderAll();

};

/**
 * A function that returns a dispatch function for handling actions related to canvas drawing and manipulation.
 * Canvas only, do not anything else, like storing data etc
 * @returns {{dispatch: function}} The dispatch function for handling actions.
 */
export const useAction = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const canvas = useCanvasStore(state => state.canvas);
    const pushCanvas = useCanvasStore(state => state.pushCanvas);
    const previousCanvasState = useCanvasStore(state => state.previousCanvasState);
    const nextCanvasState = useCanvasStore(state => state.nextCanvasState);

    const {frameIndex} = useSheet();

    const background = usePrefsStore(state => state.background);

    const editable = frameIndex === 0;

    const sendAnalytics = useAnalytics();

    const dispatch = useCallback((action) => {

        if (action === 'redo') {
            nextCanvasState({isMobile});
        }

        if (action === 'previous') {
            previousCanvasState({isMobile});
        }

        if (action === 'push') {
            pushCanvas(canvasToJson(canvas));
        }

        if (editable && action === 'remove') {
            if (canvas.getActiveObjects().length > 0) {
                removeSelectedObject(canvas);
                pushCanvas(canvasToJson(canvas));
            }
        }

        if (action === 'clear') {
            pushCanvas(canvasToJson(canvas));
            clear(canvas, background);
        }

        if (action !== 'lineup') {
            sendAnalytics('action', action);
        }

    }, [editable, nextCanvasState, isMobile, previousCanvasState, canvas, pushCanvas, sendAnalytics, background]);

    return {dispatch};
};
