import React, {useMemo} from 'react';

// import React, {useMemo} from 'react';
import {Box,Typography} from '@mui/material';

import {useTranslation} from 'hooks';
import {useUserStore} from 'module/user';

import {useChangeRoomEditor} from './api';
import MeetingTimer from './MeetingTimer';
import UserItem from './UserItem';
import {useLockerRoomStore} from './zustand';

import _ from 'lodash';

const styles = {
    toolbox: {
        zIndex: 999,
        overflow: 'unset',
        padding: 0,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        color: 'white',
        fontSize: '14px',
        flexWrap: 'wrap',
        width: '100%',
    },
    membersContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 0 90px 0',
        flexWrap: 'wrap',
        borderRadius: 0,
        overflow: 'hidden',
        height: 'auto',
        position: 'relative',
    },
    membersHeaderContainerInline: {
        display: 'flex',
    },
    membersTitleContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        padding: '25px 10px 10px 10px',
        overflow: 'hidden',
        fontWeight: '600',
        color: 'white.darkest',
        textTransform: 'uppercase',
        background: '#0f0f0f',
        borderBottom: '1px solid',
        borderColor: '#1f1f1f',
    },
    membersHeaderTitle: {
        fontSize: '15px',
        fontWeight: '600',
        color: 'white.dark',
        textOverflow: 'ellipsis',
        maxHeight: '45px',
        overflow: 'hidden',
    },
    membersHeaderContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '5px 10px 5px 10px',
        borderBottom: '1px solid',
        borderColor: '#1f1f1f',
        fontWeight: '600',
        color: 'white.darkest',
        background: '#0f0f0f',
    },
    membersHeader: {
        fontSize: '15px',
        fontWeight: '600',
        color: 'white.darkest',
    },
    membersHeaderBold: {
        fontWeight: '600',
        fontSize: '15px',
        color: 'white.darkest',
        marginRight: '4px',
    },
    membersHeaderNormal: {
        fontWeight: '300',
        fontSize: '15px',
        color: 'white.darkest',
    },
    content: {
        width: '100%',
        padding: '0',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    contentList: {
        padding: 0,
    },
    transferEditorContainerInline: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },

};

const UserList = () => {
    const users = useLockerRoomStore((state) => state.users);
    const role = useLockerRoomStore((state) => state.role);
    const loadingChangeEditor = useLockerRoomStore((state) => state.loadingChangeEditor);
    const [newEditorUser, setNewEditorUser] = React.useState(null);
    const roomTime = useLockerRoomStore((state) => state.roomTime);
    const translate = useTranslation();
    const currentRoom = useUserStore((state) => state.currentRoom);
    const activeUsers = useUserStore((state) => state.activeUsers);

    // sort > editing-users always first
    const adminUser = useMemo(
        () => users.filter(user => Object.prototype.hasOwnProperty.call(user, 'admin')),
        [users]
    );
    const editingUsers = useMemo(
        () => users.filter(user => user.status.editing === true && user),
        [users]
    );
    const handUsers = useMemo(
        () => users.filter(user => user.status.hand === true && !Object.prototype.hasOwnProperty.call(user, 'admin')),
        [users]
    );
    const normalUsers = useMemo(
        () => users.filter(user => user.status.editing === false && user.status.hand === false && !Object.prototype.hasOwnProperty.call(user, 'admin')),
        [users]
    );
   
    const sortedUsers = useMemo(
        () => _.uniqBy([...adminUser, ...editingUsers, ...handUsers, ...normalUsers], 'appId'),
        [adminUser, editingUsers,handUsers,normalUsers]);

    const {changeEditor} = useChangeRoomEditor();
    React.useEffect(() => {
        if(!loadingChangeEditor){
            setNewEditorUser(null);
        }
    }, [loadingChangeEditor]);
    const memoizedUserItems = useMemo(() => {
        return sortedUsers.map((user) => <UserItem
            key={user?.id}
            loading={loadingChangeEditor && newEditorUser?.id === user?.id}
            user={user}
            role={role}
            setEditor={!loadingChangeEditor ? () => {
                changeEditor(currentRoom, user);
                setNewEditorUser(user);
            } : () => null}
            // User-Info-Text in List (rest-Board-Api.teamrole + Moderator, Editor, User)
            infoLineSecondaryText={activeUsers.find((activeUser) => user.id === activeUser.id)?.teamrole?.name ? activeUsers.find((activeUser) => user.id === activeUser.id).teamrole?.name: translate('board.player.player')}
        />);
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortedUsers, loadingChangeEditor, role, newEditorUser, currentRoom]);

    return <Box sx={styles.toolbox}>
        {/* Teilnehmer-Liste */}
        {users.length > 0 &&<>
            <Box sx={styles.membersContainer}>
                <Box sx={styles.membersTitleContainer}>
                    {loadingChangeEditor && newEditorUser ? (
                        <Box sx={styles.transferEditorContainerInline}>
                            <Typography sx={styles.membersHeaderTitle}>{translate ('lockerroom.message.new_editor', newEditorUser?.firstname, newEditorUser?.lastname)} {' '}
                            </Typography>
                        </Box>
                    ) :(
                        <Typography sx={styles.membersHeaderTitle}>
                            {currentRoom.title ? currentRoom.title : translate('lockerroom.lockerroom')}
                        </Typography>
                    )
                    }
                </Box>
                <Box sx={styles.membersHeaderContainer}>
                    <Box sx={styles.membersHeaderContainerInline}>
                        <Typography sx={styles.membersHeaderBold}>{users.length} 
                        </Typography>
                        <Typography sx={styles.membersHeaderNormal}>{translate('lockerroom.member')}</Typography>
                    </Box>
                    <Typography sx={styles.membersHeader}>
                        <MeetingTimer time={roomTime}/>
                    </Typography>
                </Box>
                <Box sx={styles.content}>
                    <Box sx={styles.contentList}>
                        {memoizedUserItems}
                    </Box>

                </Box>
            </Box>
        </>
        }

    </Box>;
};

export default UserList;
