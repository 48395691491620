import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 350,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            mobile: 0,
            desktop: 900,
        },
    },
    palette: {
        primary: {
            main: '#fff',
        },
        secondary: {
            main: '#fff',
        },
        third: {
            main: '#888',
        },
        warning: {
            main: '#d50407',
        },
        error: {
            main: '#d50407',
        },
        success: {
            main: '#5eb202',
        },
        text: {
            primary: '#000',
            secondary: '#ccc',
            disabled: '#888',
        },
        info: {
            main: '#191919',
        },

        black: {
            dark: '#000000',
            main: '#0f0f0f',
            light: '#111111'
        },
        white: {
            main: '#f0f0f0',
            dark: '#e0e0e0',
            darker: '#d0d0d0',
            darkest: '#c0c0c0',
        },
        grey: {
            mainlight: '#aaaaaa',
            mainlight2: '#dddddd',
            darkest2: '#555555',
            darkest: '#222222',
            darker: '#3f3f3f',
            dark: '#4f4f4f',
            light: '#5f5f5f',
            lighter: '#6f6f6f',
            lightest: '#7f7f7f',
            lightester: '#8f8f8f',
            greyMainBackground: '#191919',
            greyMainBackgroundHover: '#212121',
        },
        green: {
            main: '#5eb202',
            lockerroom: '#408800',
            dark: '#29740f'
        },
        lockerroom: {
            background: '#f5f5f5',
            seperatorLines: '#e5e5e5',
            scrollbar: '#d5d5d5',
        },
        red: {
            main: '#f56b6b',
            dark: '#d50407',
            highlight: '#cc0033'
        },
        orange: {
            main: '#f1a700',
            warning: '#ec9807',
            dark: '#f16000'
        },
        yellow: {
            main: '#fdcf09',
            dark: '#f09e17'
        },
    },
    shape: {
        borderRadius: 2,
    },
    typography: {
        fontFamily: 'inter',
        fontSmooth: 'never',
        h1: {
            fontWeight: 500,
            fontSize: 24,
            letterSpacing: '-0.24px',
        },
        h2: {
            fontWeight: 500,
            fontSize: 20,
            letterSpacing: '-0.24px',
        },
        h3: {
            fontWeight: 500,
            fontSize: 18,
            letterSpacing: '-0.24px',
            color: '#fff',
        },
        h4: {
            fontWeight: 500,
            fontSize: 18,
        },
        h5: {
            fontWeight: 500,
            fontSize: 16,
            letterSpacing: '-0.24px',
        },
        h6: {
            fontWeight: 500,
            fontSize: 14,
            letterSpacing: '-0.24px',
        },
        subtitle1: {
            lineHeight: 1.4,
            fontSize: 16,
            color: '#fff',
        },
        body1: {
            color: '#fff',
        }
    },
    components: {
        MuiList: {
            styleOverrides: {
                root: {
                    '& .Mui-selected': {
                        backgroundColor: '#444444',
                    },
                },
                
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    padding: 0,
                    '& .MuiListItemButton-root':{
                        // padding: '16px',
                    }
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: '16px',
                    color: '#fff',
                    // whiteSpace: 'nowrap',
                },
                secondary: {
                    fontSize: '14px',
                    color: '#aaaaaa',
                    // whiteSpace: 'nowrap',
                },
            }
        },
        MuiDialog: {
            styleOverrides: {
                container: {},
                paper: {
                    backgroundColor: '#0f0f0f',
                },
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    backgroundColor: '#222222'
                },
                dividers: {
                    borderColor: '#ffffff',
                    borderBottom: 'none'
                },
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    color: '#888',
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                    '::before': {
                        borderColor: 'rgba(255, 255, 255, 0.5)'
                    },
                    '::after': {
                        borderColor: 'rgba(255, 255, 255, 0.5)'
                    }
                }
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    '& .MuiLinearProgress-barColorPrimary': {
                        backgroundColor: '#2f2f2f',

                    },
                },
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: '#fff',
                },
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: '#ffffff'
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: 20,
                    textTransform: 'none',
                    '&.MuiButtonBase-root.Mui-selected': {
                        color: '#ffffff'
                    },
                },
                textColorSecondary: {
                    color: '#fff',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    color: '#000',
                    backgroundColor: '#fff',
                    borderRadius: 4,
                    ':hover': {
                        backgroundColor: '#aaaaaa',
                    }
                },
                deleteIcon: {
                    color: '#000',
                },
            },

        },

        MuiAccordionSummary: {

            styleOverrides: {
                content: {
                    overflow: 'hidden',

                    root: {
                        '&.Mui-focusVisible': {
                            color: '#fff',
                        },
                    },
                },
            },
        },
        MuiPickersLayout: {
            styleOverrides: {
                root: {
                    '.MuiButtonBase-root': {
                        color: '#fff',
                    },
                    '.MuiTypography-root': {
                        color: '#fff',
                    },
                    '.MuiPickersDay-root.Mui-selected': {
                        background: '#fff',
                        color: '#000',
                    },
                    '.MuiPickersDay-root.Mui-selected:hover': {
                        background: '#fff',
                        color: '#000',
                    },
                }
            }
        },
        MuiButtonBase: {
            defaultProps: {
                //disableRipple: true,
            },
            styleOverrides: {
                root: {
                    '.MuiButton-endIcon': {
                        color: '#fff',
                    },

                    '&.MuiAutocomplete-clearIndicator': {
                        color: '#d50407',
                    },

                    '&.MuiAutocomplete-popupIndicator.Mui-disabled': {
                        display: 'none',
                    },

                    MuiTab: {
                        root: {
                            '&.Mui-select': {
                                color: '#ffffff'
                            },
                        },
                    }
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',

                    '&.MuiColorInput-Button': {
                        borderRadius: '50%',
                        width: 20,
                        height: 20,
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                sizeLarge: {
                    fontSize: 24,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
                margin: 'dense',
                autoComplete: 'off',
                autoCorrect: 'off',
                autoCapitalize: 'off',
                spellCheck: 'off',
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '&.MuiTextField-root': {
                        backgroundColor: '#222222'
                    }
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    zIndex: 1,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#aaaaaa',
                    borderRadius: '4px',
                    fontSize: 16,

                    '&.Mui-focused': {
                        color: '#aaaaaa',
                        borderColor: '#4e4e4e',
                    }
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    background: '#222222',
                    fontSize: 18,
                    '&.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
                        borderBottom: '1px solid #4e4e4e'
                    },
                    '&.MuiInput-root:before': {
                        color: '#aaaaaa',
                        borderBottom: '1px solid #4e4e4e'
                    },
                    '&.MuiInput-root:after': {
                        color: '#aaaaaa',
                        borderBottom: '1px solid #f5f5f5'
                    },
                    '&.MuiInput-root .Mui-disabled:before': {
                        borderBottom: '1px solid #4e4e4e'
                    },
                    '&.MuiInput-root.Mui-disabled:before': {
                        borderBottom: 'none'
                    },
                    '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#fff',
                    },
                },
                input: {
                    background: '#222222',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                popupIndicator: {
                    color: '#ffffff'
                },
                popper: {
                    backgroundColor: '#222222'
                },
                option: {
                    '&[aria-selected="true"]': {
                        backgroundColor: '#444444'
                    }
                }
            }
        },
        MuiSelect: {
            defaultProps: {
                variant: 'standard',
                margin: 'dense',
            },
            styleOverrides: {
                select: {
                    /*backgroundColor: '#494949',*/
                },
                icon: {
                    color: '#fff',
                    '&.Mui-disabled': {
                        color: 'transparent',
                    }
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#000',

                    '&.Mui-checked': {

                        color: '#5eb202',
                    }
                },
                colorSecondary: {
                    color: '#fff',
                }
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                    background: '#292929',
                }
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(0, 0, 0, 0.3)', // Transparente Hintergrundfarbe
                    backdropFilter: 'blur(20px)', // Blur-Effekt
                },
                rounded: {
                    borderRadius: '6px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '6px',
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    '.MuiMenu-list': {
                        color: '#fff'
                    }
                },
            },
        },
        MuiSlider: {

            styleOverrides: {

                valueLabel: {
                    fontSize: 18,
                    top: 29,
                    backgroundColor: 'unset',
                    color: '#000000',
                    '&:before': {
                        display: 'none',
                    },
                    '& *': {
                        background: 'transparent',
                        color: '#000000',
                        fontSize: 14,
                    }
                },
                mark: {
                    backgroundColor: '#aaaaaa',
                    height: '20px',
                    width: 2,
                },
                track: {
                    border: 'none',
                    height: '20px',
                    borderRadius: 0,
                },
                rail: {
                    opacity: 1,
                    backgroundColor: '#222222',
                    height: '20px',
                    border: 'none',
                    borderRadius: 0,
                },
                thumb: {
                    height: 28,
                    width: 28,
                    backgroundColor: '#fff',
                    boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
                    '&:focus, &:hover, &.Mui-active': {
                        boxShadow:
                            '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
                        // Reset on touch devices, it doesn't add specificity
                        '@media (hover: none)': {
                            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
                        },
                    },
                },
            },
        },
    },
});

export default theme;
