import * as React from "react";
const SvgSoccer = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Ebene_1", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:svg": "http://www.w3.org/2000/svg", "xmlns:rdf": "http://www.w3.org/1999/02/22-rdf-syntax-ns#", "xmlns:cc": "http://creativecommons.org/ns#", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", "xmlns:dc": "http://purl.org/dc/elements/1.1/", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 900 1345.9", width: 900, height: 1345, style: {
  enableBackground: "new 0 0 900 1345.9"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { id: "rect4", y: 3, style: {
  fill: "#629313"
}, width: 900, height: 1339.6 }), /* @__PURE__ */ React.createElement("g", { id: "g28", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("rect", { id: "rect6", x: -148.2, y: 1022.7, style: {
  fill: "#6C9E1E"
}, width: 895, height: 66.8 }), /* @__PURE__ */ React.createElement("rect", { id: "rect8", x: -148.2, y: 889.1, style: {
  fill: "#6C9E1E"
}, width: 895, height: 66.8 }), /* @__PURE__ */ React.createElement("rect", { id: "rect10", x: -148.2, y: 755.5, style: {
  fill: "#6C9E1E"
}, width: 895, height: 66.8 }), /* @__PURE__ */ React.createElement("rect", { id: "rect12", x: -148.2, y: 622, style: {
  fill: "#6C9E1E"
}, width: 895, height: 66.8 }), /* @__PURE__ */ React.createElement("rect", { id: "rect14", x: -148.2, y: 488.1, style: {
  fill: "#6C9E1E"
}, width: 895, height: 66.8 }), /* @__PURE__ */ React.createElement("rect", { id: "rect16", x: -148.2, y: 354.8, style: {
  fill: "#6C9E1E"
}, width: 895, height: 66.8 }), /* @__PURE__ */ React.createElement("rect", { id: "rect18", x: -148.2, y: 221.8, style: {
  fill: "#6C9E1E"
}, width: 895, height: 66.8 }), /* @__PURE__ */ React.createElement("rect", { id: "rect20", x: -148.2, y: 87.9, style: {
  fill: "#6C9E1E"
}, width: 895, height: 66.8 }), /* @__PURE__ */ React.createElement("rect", { id: "rect22", x: -148.2, y: -45.9, style: {
  fill: "#6C9E1E"
}, width: 895, height: 66.8 }), /* @__PURE__ */ React.createElement("rect", { id: "rect24", x: -148.2, y: -178.9, style: {
  fill: "#6C9E1E"
}, width: 895, height: 66.8 }), /* @__PURE__ */ React.createElement("rect", { id: "rect26", x: -148.2, y: -246.5, style: {
  fill: "#75AF2C"
}, width: 895, height: 0.8 })), /* @__PURE__ */ React.createElement("g", { id: "g32", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("path", { id: "path30", "inkscape:connector-curvature": 0, style: {
  fill: "#FFFFFF"
}, d: "M410.1,860.2l-4,2.7 c-22.9-33.3-62.8-53.1-106.7-53.1s-83.8,19.9-106.7,53.1l-3.9-2.7C212.6,825.7,254,805,299.5,805 C344.9,805.1,386.3,825.7,410.1,860.2z" })), /* @__PURE__ */ React.createElement("g", { id: "g36", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("path", { id: "path34", "inkscape:connector-curvature": 0, style: {
  fill: "#FFFFFF"
}, d: "M410.1-17.6C386.3,17,344.9,37.6,299.4,37.6 S212.5,17,188.7-17.6l3.9-2.7c22.9,33.3,62.8,53.1,106.7,53.1S383.1,12.9,406-20.3L410.1-17.6z" })), /* @__PURE__ */ React.createElement("g", { id: "g40", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("path", { id: "path38", "inkscape:connector-curvature": 0, style: {
  fill: "#FFFFFF"
}, d: "M434.7,421.4c0,74.6-60.7,135.3-135.3,135.3 S164.2,496,164.2,421.4s60.7-135.3,135.3-135.3S434.7,346.8,434.7,421.4L434.7,421.4z M169,421.4c0,71.9,58.5,130.5,130.5,130.5 c71.9,0,130.5-58.5,130.5-130.5c0-71.9-58.5-130.5-130.5-130.5S169,349.5,169,421.4L169,421.4z" })), /* @__PURE__ */ React.createElement("g", { id: "g44", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("path", { id: "path42", "inkscape:connector-curvature": 0, style: {
  fill: "#FFFFFF"
}, d: "M747,1059.8v4.8c-14.6,0-26.5,11.9-26.5,26.5h-4.8 C715.8,1073.8,729.8,1059.8,747,1059.8z" })), /* @__PURE__ */ React.createElement("g", { id: "g48", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("path", { id: "path46", "inkscape:connector-curvature": 0, style: {
  fill: "#FFFFFF"
}, d: "M-116.9,1091.1h-4.8c0-14.6-11.9-26.5-26.5-26.5 v-4.8C-130.9,1059.8-116.9,1073.8-116.9,1091.1z" })), /* @__PURE__ */ React.createElement("g", { id: "g52", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("path", { id: "path50", "inkscape:connector-curvature": 0, style: {
  fill: "#FFFFFF"
}, d: "M747-221.6v4.8c-17.2,0-31.3-14-31.3-31.3h4.8 C720.6-233.5,732.4-221.6,747-221.6z" })), /* @__PURE__ */ React.createElement("g", { id: "g56", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("path", { id: "path54", "inkscape:connector-curvature": 0, style: {
  fill: "#FFFFFF"
}, d: "M-116.9-248.1c0,17.2-14,31.3-31.3,31.3v-4.8 c14.6,0,26.5-11.9,26.5-26.5H-116.9z" })), /* @__PURE__ */ React.createElement("g", { id: "g60", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("polygon", { id: "polygon58", style: {
  fill: "#FFFFFF"
}, points: "580.3,1091.1 580.3,864 18.6,864 18.6,1091.1 13.8,1091.1 13.8,859.2  585.1,859.2 585.1,1091.1  " })), /* @__PURE__ */ React.createElement("g", { id: "g64", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("polygon", { id: "polygon62", style: {
  fill: "#FFFFFF"
}, points: "439.7,1091.1 439.7,1007.2 159.2,1007.2 159.2,1091.1 154.4,1091.1  154.4,1002.4 444.5,1002.4 444.5,1091.1  " })), /* @__PURE__ */ React.createElement("g", { id: "g68", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("polygon", { id: "polygon66", style: {
  fill: "#FFFFFF"
}, points: "154.4,-159.8 154.4,-248.5 159.2,-248.5 159.2,-164.6 439.7,-164.6  439.7,-248.5 444.5,-248.5 444.5,-159.8  " })), /* @__PURE__ */ React.createElement("g", { id: "g72", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("polygon", { id: "polygon70", style: {
  fill: "#FFFFFF"
}, points: "13.8,-16.6 13.8,-248.5 18.6,-248.5 18.6,-21.4 580.3,-21.4 580.3,-248.5  585.1,-248.5 585.1,-16.6  " })), /* @__PURE__ */ React.createElement("g", { id: "g76", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("rect", { id: "rect74", x: -148.2, y: 418.6, style: {
  fill: "#FFFFFF"
}, width: 895.2, height: 4.8 })), /* @__PURE__ */ React.createElement("g", { id: "g80", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("path", { id: "path78", "inkscape:connector-curvature": 0, style: {
  fill: "#FFFFFF"
}, d: "M749.4-250.5v1343.9h-900V-250.5H749.4z  M744.6,1088.7V-245.7h-890.4v1334.4L744.6,1088.7L744.6,1088.7z" })), /* @__PURE__ */ React.createElement("path", { id: "path82", "inkscape:connector-curvature": 0, style: {
  fill: "#FFFFFF"
}, d: "M449.9,664.4c4.7,0,8.5,3.8,8.5,8.5s-3.8,8.5-8.5,8.5 s-8.5-3.8-8.5-8.5S445.3,664.4,449.9,664.4z" }), /* @__PURE__ */ React.createElement("path", { id: "path84", "inkscape:connector-curvature": 0, style: {
  fill: "#FFFFFF"
}, d: "M449.9,153.2c4.7,0,8.5,3.8,8.5,8.5s-3.8,8.5-8.5,8.5 s-8.5-3.8-8.5-8.5S445.3,153.2,449.9,153.2z" }), /* @__PURE__ */ React.createElement("path", { id: "path86", "inkscape:connector-curvature": 0, style: {
  fill: "#FFFFFF"
}, d: "M449.9,1179.2c4.7,0,8.5,3.8,8.5,8.5 s-3.8,8.5-8.5,8.5s-8.5-3.8-8.5-8.5S445.3,1179.2,449.9,1179.2z" })));
export default SvgSoccer;
