import {useCallback} from 'react';

import {isOk, useFetcher} from 'fetcher';
import {getEnv} from 'lib/env';

const CONTROL_URL = getEnv('CONTROL_URL');

export const useApiLoad = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'user/sheet/', {
        method: 'GET',
    }, undefined, true);

    const load = useCallback(async () => {
        let sheets = [];

        const res = await fetch();

        if (isOk(res) && Array.isArray(res.data)) {
            sheets = res.data;
        }

        return sheets;
    }, [fetch]);

    return {load, loading};
};

export const useApiAdd = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'user/sheet/', {
        method: 'POST',
    }, undefined, true);

    const add = useCallback(async (sheet) => {

        const res = await fetch({
            data: sheet
        });

        if (isOk(res) && typeof res.data === 'object') {
            return res.data;
        }
    }, [fetch]);

    return {add, loading};
};

export const useApiUpdate = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'user/sheet/', {
        method: 'PUT',
    }, undefined, true);

    const update = useCallback(async (sheet) => {
        const res = await fetch({
            data: sheet
        });

        if (isOk(res) && typeof res.data === 'object') {
            return res.data;
        }
    }, [fetch]);

    return {update, loading};
};

export const useApiDelete = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'user/sheet/{id}', {
        method: 'DELETE',
    }, undefined, true);

    const del = useCallback(async (sheetId) => {
        const res = await fetch({
            urlParams: {
                id: sheetId
            },
        });

        if (isOk(res)) {
            return true;
        }
    }, [fetch]);

    return {del, loading};
};

export const useApiSort = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'user/sheet/sort/', {
        method: 'PATCH',
    }, undefined, true);

    const sort = useCallback(async (sheetId, direction) => {
        const res = await fetch({
            data: {
                id: sheetId,
                step: direction
            }
        });

        if (isOk(res)) {
            return true;
        }
    }, [fetch]);

    return {sort, loading};
};
