import React, {useState} from 'react';

import {
    Avatar,
    Stack, Tab, Tabs,
    Typography
} from '@mui/material';

import {
    Drawer,
    MenuHeader,
    Text} from 'components';
import {imageUrl} from 'lib/media';
import Information from 'module/client/Information';
import Teams from 'module/client/team/Teams';
import {useLayoutStore} from 'store';

const styles = {
    container: {
        alignItems: 'center',
        paddingX: 2.5,
    },
    avatarHolder: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 160,
        height: 160,
        borderRadius: '50%',
        backgroundColor: 'white.main',
        padding: 2.5,
        margin: 2.5,
        position: 'relative'
    },
    avatar: {
        width: 160,
        height: 160
    },
    headlineContainer: {
        overflow: 'hidden',
        width: '80%',
        marginBottom: 1,
    },
    tabContainer: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'grey.darkest2',
        width: '100%',
        alignItems: 'center'
    },
};

const Client = ({client}) => {
    const [tab, setTab] = useState('teams');

    const closeMenu = useLayoutStore(state => state.closeMenu);
    const setShowClient = useLayoutStore(state => state.setShowClient);

    const goBack = () => {
        setShowClient(false);
    };

    return (
        <Drawer open={true} onClose={goBack}>
            <MenuHeader text={client.name} handleBackButton={goBack} handleClose={closeMenu}/>

            <Stack sx={styles.container} direction="column">
                <Stack sx={styles.avatarHolder}>
                    <Avatar sx={styles.avatar} alt={client.name} src={imageUrl(client.image, {w: 100, h: 100})}/>
                </Stack>
                <Stack sx={styles.headlineContainer}>
                    <Typography align="center">{client.name}</Typography>
                </Stack>
                <Stack sx={styles.tabContainer}>
                    <Tabs value={tab} onChange={(_e, tab) => setTab(tab)}>
                        <Tab value="teams" label={<Typography><Text>team.teams</Text></Typography>}/>
                        <Tab value="information" label={<Typography><Text>global.information</Text></Typography>}/>
                    </Tabs>
                </Stack>
            </Stack>

            {tab === 'teams' && <Teams client={client}/>}
            {tab === 'information' && <Information client={client}/>}

        </Drawer>
    );
};

export default Client;