import {useCallback} from 'react';

import {v4 as uuidv4} from 'uuid';

import {useUserStore} from 'module/user';

import {useApiAdd, useApiClear, useApiDelete, useApiUpdate} from './useFrameApi';
import {useLocalStore} from './useLocalStore';
import {useSheetStore} from './zustand';

export const useFrameAdd = () => {
    const addFrame = useSheetStore(state => state.addFrame);

    const jwt = useUserStore(state => state.user?.jwt);

    const {add: backendAdd, loading} = useApiAdd();
    const addLocalFrame = useLocalStore(state => state.addFrame);

    const add = useCallback(async (sheet, frame, index) => {
        let newFrame;
        const mergedFrame = {
            frame,
        };

        if (jwt) {
            console.log('add frame backend');
            newFrame = await backendAdd(sheet.id, mergedFrame, index);
        } else {
            console.log('add frame locally', index);
            mergedFrame.id = uuidv4();
            addLocalFrame(sheet.id, mergedFrame, index);
            newFrame = mergedFrame;
        }

        if (newFrame) {
            addFrame(sheet.id, newFrame, index);
        }

        return newFrame;
    }, [addFrame, addLocalFrame, backendAdd, jwt]);

    return {add, loading};
};

export const useFrameUpdate = () => {
    const updateFrame = useSheetStore(state => state.updFrame);

    const {update: backendUpdate, loading} = useApiUpdate();
    const updateLocalFrame = useLocalStore(store => store.updateFrame);

    const update = useCallback(async (sheet, frame, index) => {
        if (sheet.local) {
            console.log('update frame locally');
            updateLocalFrame(sheet.id, frame, index);
        } else {
            console.log('update frame backend');
            await backendUpdate(sheet.id, frame, index);
        }

        updateFrame(sheet.id, frame, index);
    }, [updateFrame, updateLocalFrame, backendUpdate]);

    return {update, loading};
};

export const useFrameDelete = () => {
    const deleteFrame = useSheetStore(state => state.delFrame);

    const {del: backendDelete, loading} = useApiDelete();
    const deleteLocalFrame = useLocalStore(store => store.removeFrame);

    const del = useCallback(async (sheet, index) => {
        if (sheet.local) {
            console.log('delete frame locally', sheet.id);
            deleteLocalFrame(sheet.id, index);
        } else {
            console.log('delete frame backend', sheet.id);
            await backendDelete(sheet.id, index);
        }

        deleteFrame(sheet.id, index);
    }, [deleteFrame, deleteLocalFrame, backendDelete]);

    return {del, loading};
};

export const useFrameClear = () => {
    const clearFrames = useSheetStore(state => state.clearFrames);

    const {clear: backendClear, loading} = useApiClear();
    const clearLocalFrames = useLocalStore(store => store.clearFrames);

    const clear = useCallback(async (sheet) => {
        if (sheet.local) {
            console.log('delete alls frames locally', sheet.id);
            clearLocalFrames(sheet.id);
        } else {
            console.log('delete alls frames backend', sheet.id);
            await backendClear(sheet.id);
        }

        clearFrames(sheet.id);
    }, [clearFrames, clearLocalFrames, backendClear]);

    return {clear, loading};
};