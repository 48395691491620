import React from 'react';

import {Close as CloseIcon} from '@mui/icons-material';
import {DialogContent, Grid} from '@mui/material';

import {Dialog} from 'components';

import Gps from './Gps';
import NetDevs from './NetDevs';
import NetRoutes from './NetRoutes';
import Wwan from './Wwan';

const SelftestDialog = ({handleClose, results}) => {

    return <Dialog open={!!results} handleClose={handleClose} title="Selftest" Icon={<CloseIcon/>} maxWidth="lg">
        <DialogContent dividers>
            <Grid container direction="row" spacing={1}>
                <Grid item md={6}>
                    <Wwan data={results?.wwan}/>
                </Grid>
                <Grid item md={6}>
                    <Gps data={results?.gps}/>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogContent dividers>
            <Grid container direction="row" spacing={1}>
                <Grid item md={12}>
                    <NetDevs data={results?.net?.devices || []}/>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogContent dividers>
            <Grid container direction="row" spacing={1}>
                <Grid item md={12}>
                    <NetRoutes data={results?.net?.routes || []}/>
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>;
};

export default SelftestDialog;
