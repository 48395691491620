import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';

const Hidden = ({name, defaultValue, onChange, fullWidth: _fullWidth, ...rest}) => {
    const {control} = useFormContext();

    const outerOnChange = onChange;

    return <Controller
        control={control}
        name={name}
        shouldUnregister={true}
        defaultValue={defaultValue}
        render={({field: {onChange, value}}) => {
            const v = value === undefined || value === null ? '' : value;
            return <input
                type="hidden"
                name={name}
                value={v}
                onChange={e => {
                    outerOnChange && outerOnChange(e);
                    onChange(e);
                }}
                {...rest}/>;
        }}
    />;
};

Hidden.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func,
};

export default Hidden;
