import {useCallback, useRef} from 'react';

import {fabric} from 'fabric';

const useFabric = (onChange, opts = {}) => {
    const fabricRef = useRef(null);
    const disposeRef = useRef(null);
    const onChangeRef = useRef(onChange);
    const optsRef = useRef(opts);

    return useCallback(node => {
        if (node) {
            fabricRef.current = new fabric.Canvas(node, optsRef.current);

            if (onChangeRef.current) {
                disposeRef.current = onChangeRef.current(fabricRef.current);
            }
        } else if (fabricRef.current) {
            fabricRef.current.dispose();

            if (disposeRef.current) {
                disposeRef.current();
                disposeRef.current = undefined;
            }
        }
    }, []);
};

export default useFabric;
