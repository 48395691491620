import * as React from "react";
const SvgBasketball = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Ebene_1", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:svg": "http://www.w3.org/2000/svg", "xmlns:rdf": "http://www.w3.org/1999/02/22-rdf-syntax-ns#", "xmlns:cc": "http://creativecommons.org/ns#", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", "xmlns:dc": "http://purl.org/dc/elements/1.1/", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 900 1345.9", width: 900, height: 1346, style: {
  enableBackground: "new 0 0 900 1345.9"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "0.5,37.2 0.5,1303.9 0.5,1346 20.2,1346 430.6,1346 450.3,1346 450.3,1303.9 450.3,37.2  450.3,0.1 430.6,0.1 20.2,0.1 0.5,0.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "90.4,411 90.4,728.3 90.4,737.6 91.4,737.6 112,737.6 112.9,737.6 112.9,728.3 112.9,411  112.9,401.7 112,401.7 91.4,401.7 90.4,401.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "90.4,132.5 90.4,394 90.4,401.7 91.4,401.7 112,401.7 112.9,401.7 112.9,394 112.9,132.5  112.9,124.8 112,124.8 91.4,124.8 90.4,124.8  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "90.4,3.6 90.4,121.4 90.4,124.8 91.4,124.8 112,124.8 112.9,124.8 112.9,121.4 112.9,3.6  112.9,0.2 112,0.2 91.4,0.2 90.4,0.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "90.4,1080.9 90.4,1338.8 90.4,1346.2 91.4,1346.2 112,1346.2 112.9,1346.2 112.9,1338.8  112.9,1080.9 112.9,1073.5 112,1073.5 91.4,1073.5 90.4,1073.5  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "90.4,746.9 90.4,1064.2 90.4,1073.5 91.4,1073.5 112,1073.5 112.9,1073.5 112.9,1064.2  112.9,746.9 112.9,737.6 112,737.6 91.4,737.6 90.4,737.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "45.5,675.4 45.5,902.5 45.5,909.1 46.5,909.1 67,909.1 68,909.1 68,902.5 68,675.4  68,668.7 67,668.7 46.5,668.7 45.5,668.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "45.5,147.5 45.5,374.6 45.5,381.3 46.5,381.3 67,381.3 68,381.3 68,374.6 68,147.5  68,140.9 67,140.9 46.5,140.9 45.5,140.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "45.5,389.2 45.5,660.8 45.5,668.7 46.5,668.7 67,668.7 68,668.7 68,660.8 68,389.2  68,381.3 67,381.3 46.5,381.3 45.5,381.3  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "45.5,4 45.5,137 45.5,140.9 46.5,140.9 67,140.9 68,140.9 68,137 68,4 68,0.1 67,0.1  46.5,0.1 45.5,0.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "45.5,1154.9 45.5,1340.9 45.5,1346.2 46.5,1346.2 67,1346.2 68,1346.2 68,1340.9  68,1154.9 68,1149.6 67,1149.6 46.5,1149.6 45.5,1149.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "45.5,915.8 45.5,1142.9 45.5,1149.6 46.5,1149.6 67,1149.6 68,1149.6 68,1142.9 68,915.8  68,909.1 67,909.1 46.5,909.1 45.5,909.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "68,259 68,576.3 68,585.6 68.9,585.6 89.5,585.6 90.4,585.6 90.4,576.3 90.4,259  90.4,249.7 89.5,249.7 68.9,249.7 68,249.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "68,7.1 68,242.8 68,249.7 68.9,249.7 89.5,249.7 90.4,249.7 90.4,242.8 90.4,7.1 90.4,0.2  89.5,0.2 68.9,0.2 68,0.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "68,1193.4 68,1341.8 68,1346 68.9,1346 89.5,1346 90.4,1346 90.4,1341.8 90.4,1193.4  90.4,1189.2 89.5,1189.2 68.9,1189.2 68,1189.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "68,928.9 68,1181.7 68,1189.1 68.9,1189.1 89.5,1189.1 90.4,1189.1 90.4,1181.7  90.4,928.9 90.4,921.4 89.5,921.4 68.9,921.4 68,921.4  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "68,594.9 68,912.1 68,921.4 68.9,921.4 89.5,921.4 90.4,921.4 90.4,912.1 90.4,594.9  90.4,585.6 89.5,585.6 68.9,585.6 68,585.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "0.5,665.8 0.5,438.7 0.5,432 1.5,432 22,432 23,432 23,438.7 23,665.8 23,672.4 22,672.4  1.5,672.4 0.5,672.4  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "0.5,1193.7 0.5,966.6 0.5,959.9 1.5,959.9 22,959.9 23,959.9 23,966.6 23,1193.7  23,1200.3 22,1200.3 1.5,1200.3 0.5,1200.3  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "0.5,951.9 0.5,680.4 0.5,672.4 1.5,672.4 22,672.4 23,672.4 23,680.4 23,951.9 23,959.9  22,959.9 1.5,959.9 0.5,959.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "0.5,1342.2 0.5,1204.2 0.5,1200.3 1.5,1200.3 22,1200.3 23,1200.3 23,1204.2 23,1342.2  23,1346.1 22,1346.1 1.5,1346.1 0.5,1346.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "0.5,186.3 0.5,5.5 0.5,0.2 1.5,0.2 22,0.2 23,0.2 23,5.5 23,186.3 23,191.6 22,191.6  1.5,191.6 0.5,191.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "0.5,425.4 0.5,198.3 0.5,191.6 1.5,191.6 22,191.6 23,191.6 23,198.3 23,425.4 23,432  22,432 1.5,432 0.5,432  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "23,1082.2 23,764.9 23,755.6 24,755.6 44.5,755.6 45.5,755.6 45.5,764.9 45.5,1082.2  45.5,1091.5 44.5,1091.5 24,1091.5 23,1091.5  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "23,1339.3 23,1098.4 23,1091.5 24,1091.5 44.5,1091.5 45.5,1091.5 45.5,1098.4  45.5,1339.3 45.5,1346.2 44.5,1346.2 24,1346.2 23,1346.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "23,147.7 23,4.4 23,0.2 24,0.2 44.5,0.2 45.5,0.2 45.5,4.4 45.5,147.7 45.5,151.9  44.5,151.9 24,151.9 23,151.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "23,412.3 23,159.4 23,152 24,152 44.5,152 45.5,152 45.5,159.4 45.5,412.3 45.5,419.7  44.5,419.7 24,419.7 23,419.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "23,746.3 23,429 23,419.7 24,419.7 44.5,419.7 45.5,419.7 45.5,429 45.5,746.3 45.5,755.6  44.5,755.6 24,755.6 23,755.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "405.3,665.8 405.3,438.7 405.3,432 406.3,432 426.8,432 427.8,432 427.8,438.7  427.8,665.8 427.8,672.4 426.8,672.4 406.3,672.4 405.3,672.4  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "405.3,1193.7 405.3,966.6 405.3,959.9 406.3,959.9 426.8,959.9 427.8,959.9 427.8,966.6  427.8,1193.7 427.8,1200.3 426.8,1200.3 406.3,1200.3 405.3,1200.3  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "405.3,951.9 405.3,680.4 405.3,672.4 406.3,672.4 426.8,672.4 427.8,672.4 427.8,680.4  427.8,951.9 427.8,959.9 426.8,959.9 406.3,959.9 405.3,959.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "405.3,1342.2 405.3,1204.2 405.3,1200.3 406.3,1200.3 426.8,1200.3 427.8,1200.3  427.8,1204.2 427.8,1342.2 427.8,1346.1 426.8,1346.1 406.3,1346.1 405.3,1346.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "405.3,186.3 405.3,5.5 405.3,0.2 406.3,0.2 426.8,0.2 427.8,0.2 427.8,5.5 427.8,186.3  427.8,191.6 426.8,191.6 406.3,191.6 405.3,191.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "405.3,425.4 405.3,198.3 405.3,191.6 406.3,191.6 426.8,191.6 427.8,191.6 427.8,198.3  427.8,425.4 427.8,432 426.8,432 406.3,432 405.3,432  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "427.8,1082.2 427.8,764.9 427.8,755.6 428.8,755.6 449.3,755.6 450.3,755.6 450.3,764.9  450.3,1082.2 450.3,1091.5 449.3,1091.5 428.8,1091.5 427.8,1091.5  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "427.8,1339.3 427.8,1098.4 427.8,1091.5 428.8,1091.5 449.3,1091.5 450.3,1091.5  450.3,1098.4 450.3,1339.3 450.3,1346.2 449.3,1346.2 428.8,1346.2 427.8,1346.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "427.8,147.7 427.8,4.4 427.8,0.2 428.8,0.2 449.3,0.2 450.3,0.2 450.3,4.4 450.3,147.7  450.3,151.9 449.3,151.9 428.8,151.9 427.8,151.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "427.8,412.3 427.8,159.4 427.8,152 428.8,152 449.3,152 450.3,152 450.3,159.4  450.3,412.3 450.3,419.7 449.3,419.7 428.8,419.7 427.8,419.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "427.8,746.3 427.8,429 427.8,419.7 428.8,419.7 449.3,419.7 450.3,419.7 450.3,429  450.3,746.3 450.3,755.6 449.3,755.6 428.8,755.6 427.8,755.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "360.3,665.8 360.3,438.7 360.3,432 361.3,432 381.8,432 382.8,432 382.8,438.7  382.8,665.8 382.8,672.4 381.8,672.4 361.3,672.4 360.3,672.4  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "360.3,1193.7 360.3,966.6 360.3,959.9 361.3,959.9 381.8,959.9 382.8,959.9 382.8,966.6  382.8,1193.7 382.8,1200.3 381.8,1200.3 361.3,1200.3 360.3,1200.3  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "360.3,951.9 360.3,680.4 360.3,672.4 361.3,672.4 381.8,672.4 382.8,672.4 382.8,680.4  382.8,951.9 382.8,959.9 381.8,959.9 361.3,959.9 360.3,959.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "360.3,1342.2 360.3,1204.2 360.3,1200.3 361.3,1200.3 381.8,1200.3 382.8,1200.3  382.8,1204.2 382.8,1342.2 382.8,1346.1 381.8,1346.1 361.3,1346.1 360.3,1346.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "360.3,186.3 360.3,5.4 360.3,0.1 361.3,0.1 381.8,0.1 382.8,0.1 382.8,5.4 382.8,186.3  382.8,191.6 381.8,191.6 361.3,191.6 360.3,191.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "360.3,425.4 360.3,198.3 360.3,191.6 361.3,191.6 381.8,191.6 382.8,191.6 382.8,198.3  382.8,425.4 382.8,432 381.8,432 361.3,432 360.3,432  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "382.8,1082.2 382.8,764.9 382.8,755.6 383.8,755.6 404.3,755.6 405.3,755.6 405.3,764.9  405.3,1082.2 405.3,1091.5 404.3,1091.5 383.8,1091.5 382.8,1091.5  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "382.8,1339.3 382.8,1098.4 382.8,1091.5 383.8,1091.5 404.3,1091.5 405.3,1091.5  405.3,1098.4 405.3,1339.3 405.3,1346.2 404.3,1346.2 383.8,1346.2 382.8,1346.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "382.8,147.7 382.8,4.4 382.8,0.2 383.8,0.2 404.3,0.2 405.3,0.2 405.3,4.4 405.3,147.7  405.3,151.9 404.3,151.9 383.8,151.9 382.8,151.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "382.8,412.3 382.8,159.4 382.8,152 383.8,152 404.3,152 405.3,152 405.3,159.4  405.3,412.3 405.3,419.7 404.3,419.7 383.8,419.7 382.8,419.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "382.8,746.3 382.8,429 382.8,419.7 383.8,419.7 404.3,419.7 405.3,419.7 405.3,429  405.3,746.3 405.3,755.6 404.3,755.6 383.8,755.6 382.8,755.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "315.3,675.4 315.3,902.5 315.3,909.1 316.3,909.1 336.8,909.1 337.8,909.1 337.8,902.5  337.8,675.4 337.8,668.7 336.8,668.7 316.3,668.7 315.3,668.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "315.3,147.5 315.3,374.6 315.3,381.3 316.3,381.3 336.8,381.3 337.8,381.3 337.8,374.6  337.8,147.5 337.8,140.9 336.8,140.9 316.3,140.9 315.3,140.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "315.3,389.2 315.3,660.8 315.3,668.7 316.3,668.7 336.8,668.7 337.8,668.7 337.8,660.8  337.8,389.2 337.8,381.3 336.8,381.3 316.3,381.3 315.3,381.3  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "315.3,4 315.3,137 315.3,140.9 316.3,140.9 336.8,140.9 337.8,140.9 337.8,137 337.8,4  337.8,0.1 336.8,0.1 316.3,0.1 315.3,0.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "315.3,1154.9 315.3,1340.9 315.3,1346.2 316.3,1346.2 336.8,1346.2 337.8,1346.2  337.8,1340.9 337.8,1154.9 337.8,1149.6 336.8,1149.6 316.3,1149.6 315.3,1149.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "315.3,915.8 315.3,1142.9 315.3,1149.6 316.3,1149.6 336.8,1149.6 337.8,1149.6  337.8,1142.9 337.8,915.8 337.8,909.1 336.8,909.1 316.3,909.1 315.3,909.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "337.8,259 337.8,576.3 337.8,585.6 338.8,585.6 359.3,585.6 360.3,585.6 360.3,576.3  360.3,259 360.3,249.7 359.3,249.7 338.8,249.7 337.8,249.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "337.8,7.1 337.8,242.8 337.8,249.7 338.8,249.7 359.3,249.7 360.3,249.7 360.3,242.8  360.3,7.1 360.3,0.2 359.3,0.2 338.8,0.2 337.8,0.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "337.8,1193.4 337.8,1341.8 337.8,1346 338.8,1346 359.3,1346 360.3,1346 360.3,1341.8  360.3,1193.4 360.3,1189.2 359.3,1189.2 338.8,1189.2 337.8,1189.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "337.8,928.9 337.8,1181.7 337.8,1189.1 338.8,1189.1 359.3,1189.1 360.3,1189.1  360.3,1181.7 360.3,928.9 360.3,921.4 359.3,921.4 338.8,921.4 337.8,921.4  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "337.8,594.9 337.8,912.1 337.8,921.4 338.8,921.4 359.3,921.4 360.3,921.4 360.3,912.1  360.3,594.9 360.3,585.6 359.3,585.6 338.8,585.6 337.8,585.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "112.9,673.5 112.9,484.9 112.9,479.4 113.9,479.4 134.4,479.4 135.4,479.4 135.4,484.9  135.4,673.5 135.4,679.1 134.4,679.1 113.9,679.1 112.9,679.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "112.9,1112.1 112.9,923.4 112.9,917.9 113.9,917.9 134.4,917.9 135.4,917.9 135.4,923.4  135.4,1112.1 135.4,1117.6 134.4,1117.6 113.9,1117.6 112.9,1117.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "112.9,911.3 112.9,685.7 112.9,679.1 113.9,679.1 134.4,679.1 135.4,679.1 135.4,685.7  135.4,911.3 135.4,917.9 134.4,917.9 113.9,917.9 112.9,917.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "112.9,1339.8 112.9,1123.8 112.9,1117.6 113.9,1117.6 134.4,1117.6 135.4,1117.6  135.4,1123.8 135.4,1339.8 135.4,1346 134.4,1346 113.9,1346 112.9,1346  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "112.9,271.9 112.9,7.9 112.9,0.2 113.9,0.2 134.4,0.2 135.4,0.2 135.4,7.9 135.4,271.9  135.4,279.6 134.4,279.6 113.9,279.6 112.9,279.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "112.9,473.8 112.9,285.2 112.9,279.6 113.9,279.6 134.4,279.6 135.4,279.6 135.4,285.2  135.4,473.8 135.4,479.4 134.4,479.4 113.9,479.4 112.9,479.4  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "135.4,1019.5 135.4,755.9 135.4,748.2 136.4,748.2 156.9,748.2 157.9,748.2 157.9,755.9  157.9,1019.5 157.9,1027.2 156.9,1027.2 136.4,1027.2 135.4,1027.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "135.4,1343.3 135.4,1237.6 135.4,1234.6 136.4,1234.6 156.9,1234.6 157.9,1234.6  157.9,1237.6 157.9,1343.3 157.9,1346.2 156.9,1346.2 136.4,1346.2 135.4,1346.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "135.4,1228.9 135.4,1032.9 135.4,1027.2 136.4,1027.2 156.9,1027.2 157.9,1027.2  157.9,1032.9 157.9,1228.9 157.9,1234.6 156.9,1234.6 136.4,1234.6 135.4,1234.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "135.4,239.8 135.4,7 135.4,0.2 136.4,0.2 156.9,0.2 157.9,0.2 157.9,7 157.9,239.8  157.9,246.7 156.9,246.7 136.4,246.7 135.4,246.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "135.4,463 135.4,252.9 135.4,246.7 136.4,246.7 156.9,246.7 157.9,246.7 157.9,252.9  157.9,463 157.9,469.1 156.9,469.1 136.4,469.1 135.4,469.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "135.4,740.4 135.4,476.9 135.4,469.1 136.4,469.1 156.9,469.1 157.9,469.1 157.9,476.9  157.9,740.4 157.9,748.2 156.9,748.2 136.4,748.2 135.4,748.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "247.9,411 247.9,728.3 247.9,737.6 248.9,737.6 269.4,737.6 270.4,737.6 270.4,728.3  270.4,411 270.4,401.7 269.4,401.7 248.9,401.7 247.9,401.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "247.9,132.5 247.9,394 247.9,401.7 248.9,401.7 269.4,401.7 270.4,401.7 270.4,394  270.4,132.5 270.4,124.8 269.4,124.8 248.9,124.8 247.9,124.8  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "247.9,3.5 247.9,121.4 247.9,124.8 248.9,124.8 269.4,124.8 270.4,124.8 270.4,121.4  270.4,3.5 270.4,0.1 269.4,0.1 248.9,0.1 247.9,0.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "247.9,1080.9 247.9,1338.8 247.9,1346.2 248.9,1346.2 269.4,1346.2 270.4,1346.2  270.4,1338.8 270.4,1080.9 270.4,1073.5 269.4,1073.5 248.9,1073.5 247.9,1073.5  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "247.9,746.9 247.9,1064.2 247.9,1073.5 248.9,1073.5 269.4,1073.5 270.4,1073.5  270.4,1064.2 270.4,746.9 270.4,737.6 269.4,737.6 248.9,737.6 247.9,737.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "202.9,675.4 202.9,902.5 202.9,909.1 203.9,909.1 224.4,909.1 225.4,909.1 225.4,902.5  225.4,675.4 225.4,668.7 224.4,668.7 203.9,668.7 202.9,668.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "202.9,147.5 202.9,374.6 202.9,381.3 203.9,381.3 224.4,381.3 225.4,381.3 225.4,374.6  225.4,147.5 225.4,140.9 224.4,140.9 203.9,140.9 202.9,140.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "202.9,389.2 202.9,660.8 202.9,668.7 203.9,668.7 224.4,668.7 225.4,668.7 225.4,660.8  225.4,389.2 225.4,381.3 224.4,381.3 203.9,381.3 202.9,381.3  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "202.9,4 202.9,137 202.9,140.9 203.9,140.9 224.4,140.9 225.4,140.9 225.4,137 225.4,4  225.4,0.1 224.4,0.1 203.9,0.1 202.9,0.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "202.9,1154.9 202.9,1340.9 202.9,1346.2 203.9,1346.2 224.4,1346.2 225.4,1346.2  225.4,1340.9 225.4,1154.9 225.4,1149.6 224.4,1149.6 203.9,1149.6 202.9,1149.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "202.9,915.8 202.9,1142.9 202.9,1149.6 203.9,1149.6 224.4,1149.6 225.4,1149.6  225.4,1142.9 225.4,915.8 225.4,909.1 224.4,909.1 203.9,909.1 202.9,909.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "225.4,259 225.4,576.3 225.4,585.6 226.4,585.6 246.9,585.6 247.9,585.6 247.9,576.3  247.9,259 247.9,249.7 246.9,249.7 226.4,249.7 225.4,249.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "225.4,7.1 225.4,242.8 225.4,249.7 226.4,249.7 246.9,249.7 247.9,249.7 247.9,242.8  247.9,7.1 247.9,0.2 246.9,0.2 226.4,0.2 225.4,0.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "225.4,1193.4 225.4,1341.8 225.4,1346 226.4,1346 246.9,1346 247.9,1346 247.9,1341.8  247.9,1193.4 247.9,1189.2 246.9,1189.2 226.4,1189.2 225.4,1189.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "225.4,928.9 225.4,1181.7 225.4,1189.1 226.4,1189.1 246.9,1189.1 247.9,1189.1  247.9,1181.7 247.9,928.9 247.9,921.4 246.9,921.4 226.4,921.4 225.4,921.4  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "225.4,594.9 225.4,912.1 225.4,921.4 226.4,921.4 246.9,921.4 247.9,921.4 247.9,912.1  247.9,594.9 247.9,585.6 246.9,585.6 226.4,585.6 225.4,585.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "157.9,665.8 157.9,438.7 157.9,432 158.9,432 179.4,432 180.4,432 180.4,438.7  180.4,665.8 180.4,672.4 179.4,672.4 158.9,672.4 157.9,672.4  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "157.9,1193.7 157.9,966.6 157.9,959.9 158.9,959.9 179.4,959.9 180.4,959.9 180.4,966.6  180.4,1193.7 180.4,1200.3 179.4,1200.3 158.9,1200.3 157.9,1200.3  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "157.9,951.9 157.9,680.4 157.9,672.4 158.9,672.4 179.4,672.4 180.4,672.4 180.4,680.4  180.4,951.9 180.4,959.9 179.4,959.9 158.9,959.9 157.9,959.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "157.9,1342.2 157.9,1204.2 157.9,1200.3 158.9,1200.3 179.4,1200.3 180.4,1200.3  180.4,1204.2 180.4,1342.2 180.4,1346.1 179.4,1346.1 158.9,1346.1 157.9,1346.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "157.9,186.3 157.9,5.4 157.9,0.1 158.9,0.1 179.4,0.1 180.4,0.1 180.4,5.4 180.4,186.3  180.4,191.6 179.4,191.6 158.9,191.6 157.9,191.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "157.9,425.4 157.9,198.3 157.9,191.6 158.9,191.6 179.4,191.6 180.4,191.6 180.4,198.3  180.4,425.4 180.4,432 179.4,432 158.9,432 157.9,432  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "180.4,1082.2 180.4,764.9 180.4,755.6 181.4,755.6 201.9,755.6 202.9,755.6 202.9,764.9  202.9,1082.2 202.9,1091.5 201.9,1091.5 181.4,1091.5 180.4,1091.5  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "180.4,1339.3 180.4,1098.4 180.4,1091.5 181.4,1091.5 201.9,1091.5 202.9,1091.5  202.9,1098.4 202.9,1339.3 202.9,1346.2 201.9,1346.2 181.4,1346.2 180.4,1346.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "180.4,239.8 180.4,7 180.4,0.2 181.4,0.2 201.9,0.2 202.9,0.2 202.9,7 202.9,239.8  202.9,246.7 201.9,246.7 181.4,246.7 180.4,246.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "180.4,414.9 180.4,251.5 180.4,246.7 181.4,246.7 201.9,246.7 202.9,246.7 202.9,251.5  202.9,414.9 202.9,419.7 201.9,419.7 181.4,419.7 180.4,419.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "180.4,746.3 180.4,429 180.4,419.7 181.4,419.7 201.9,419.7 202.9,419.7 202.9,429  202.9,746.3 202.9,755.6 201.9,755.6 181.4,755.6 180.4,755.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "270.4,673.5 270.4,484.9 270.4,479.4 271.4,479.4 291.9,479.4 292.9,479.4 292.9,484.9  292.9,673.5 292.9,679.1 291.9,679.1 271.4,679.1 270.4,679.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "270.4,1112.1 270.4,923.4 270.4,917.9 271.4,917.9 291.9,917.9 292.9,917.9 292.9,923.4  292.9,1112.1 292.9,1117.6 291.9,1117.6 271.4,1117.6 270.4,1117.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "270.4,911.3 270.4,685.7 270.4,679.1 271.4,679.1 291.9,679.1 292.9,679.1 292.9,685.7  292.9,911.3 292.9,917.9 291.9,917.9 271.4,917.9 270.4,917.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "270.4,1339.8 270.4,1123.8 270.4,1117.6 271.4,1117.6 291.9,1117.6 292.9,1117.6  292.9,1123.8 292.9,1339.8 292.9,1346 291.9,1346 271.4,1346 270.4,1346  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "270.4,271.9 270.4,7.9 270.4,0.2 271.4,0.2 291.9,0.2 292.9,0.2 292.9,7.9 292.9,271.9  292.9,279.6 291.9,279.6 271.4,279.6 270.4,279.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "270.4,473.8 270.4,285.2 270.4,279.6 271.4,279.6 291.9,279.6 292.9,279.6 292.9,285.2  292.9,473.8 292.9,479.4 291.9,479.4 271.4,479.4 270.4,479.4  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "292.9,1019.5 292.9,755.9 292.9,748.2 293.8,748.2 314.4,748.2 315.3,748.2 315.3,755.9  315.3,1019.5 315.3,1027.2 314.4,1027.2 293.8,1027.2 292.9,1027.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "292.9,1343.3 292.9,1237.6 292.9,1234.6 293.8,1234.6 314.4,1234.6 315.3,1234.6  315.3,1237.6 315.3,1343.3 315.3,1346.2 314.4,1346.2 293.8,1346.2 292.9,1346.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "292.9,1228.9 292.9,1032.9 292.9,1027.2 293.8,1027.2 314.4,1027.2 315.3,1027.2  315.3,1032.9 315.3,1228.9 315.3,1234.6 314.4,1234.6 293.8,1234.6 292.9,1234.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "292.9,239.8 292.9,7 292.9,0.2 293.8,0.2 314.4,0.2 315.3,0.2 315.3,7 315.3,239.8  315.3,246.7 314.4,246.7 293.8,246.7 292.9,246.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "292.9,463 292.9,252.9 292.9,246.7 293.8,246.7 314.4,246.7 315.3,246.7 315.3,252.9  315.3,463 315.3,469.1 314.4,469.1 293.8,469.1 292.9,469.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "292.9,740.4 292.9,476.9 292.9,469.1 293.8,469.1 314.4,469.1 315.3,469.1 315.3,476.9  315.3,740.4 315.3,748.2 314.4,748.2 293.8,748.2 292.9,748.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "450.2,37.2 450.2,1303.9 450.2,1346 469.9,1346 880.3,1346 900,1346 900,1303.9 900,37.2  900,0.1 880.3,0.1 469.9,0.1 450.2,0.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "540.2,411 540.2,728.3 540.2,737.6 541.1,737.6 561.7,737.6 562.6,737.6 562.6,728.3  562.6,411 562.6,401.7 561.7,401.7 541.1,401.7 540.2,401.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "540.2,132.5 540.2,394 540.2,401.7 541.1,401.7 561.7,401.7 562.6,401.7 562.6,394  562.6,132.5 562.6,124.8 561.7,124.8 541.1,124.8 540.2,124.8  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "540.2,3.6 540.2,121.4 540.2,124.8 541.1,124.8 561.7,124.8 562.6,124.8 562.6,121.4  562.6,3.6 562.6,0.2 561.7,0.2 541.1,0.2 540.2,0.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "540.2,1080.9 540.2,1338.8 540.2,1346.2 541.1,1346.2 561.7,1346.2 562.6,1346.2  562.6,1338.8 562.6,1080.9 562.6,1073.5 561.7,1073.5 541.1,1073.5 540.2,1073.5  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "540.2,746.9 540.2,1064.2 540.2,1073.5 541.1,1073.5 561.7,1073.5 562.6,1073.5  562.6,1064.2 562.6,746.9 562.6,737.6 561.7,737.6 541.1,737.6 540.2,737.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "495.2,675.4 495.2,902.5 495.2,909.1 496.2,909.1 516.7,909.1 517.7,909.1 517.7,902.5  517.7,675.4 517.7,668.7 516.7,668.7 496.2,668.7 495.2,668.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "495.2,147.5 495.2,374.6 495.2,381.3 496.2,381.3 516.7,381.3 517.7,381.3 517.7,374.6  517.7,147.5 517.7,140.9 516.7,140.9 496.2,140.9 495.2,140.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "495.2,389.2 495.2,660.8 495.2,668.7 496.2,668.7 516.7,668.7 517.7,668.7 517.7,660.8  517.7,389.2 517.7,381.3 516.7,381.3 496.2,381.3 495.2,381.3  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "495.2,4 495.2,137 495.2,140.9 496.2,140.9 516.7,140.9 517.7,140.9 517.7,137 517.7,4  517.7,0.1 516.7,0.1 496.2,0.1 495.2,0.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "495.2,1154.9 495.2,1340.9 495.2,1346.2 496.2,1346.2 516.7,1346.2 517.7,1346.2  517.7,1340.9 517.7,1154.9 517.7,1149.6 516.7,1149.6 496.2,1149.6 495.2,1149.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "495.2,915.8 495.2,1142.9 495.2,1149.6 496.2,1149.6 516.7,1149.6 517.7,1149.6  517.7,1142.9 517.7,915.8 517.7,909.1 516.7,909.1 496.2,909.1 495.2,909.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "517.7,259 517.7,576.3 517.7,585.6 518.7,585.6 539.2,585.6 540.2,585.6 540.2,576.3  540.2,259 540.2,249.7 539.2,249.7 518.7,249.7 517.7,249.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "517.7,7.1 517.7,242.8 517.7,249.7 518.7,249.7 539.2,249.7 540.2,249.7 540.2,242.8  540.2,7.1 540.2,0.2 539.2,0.2 518.7,0.2 517.7,0.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "517.7,1193.4 517.7,1341.8 517.7,1346 518.7,1346 539.2,1346 540.2,1346 540.2,1341.8  540.2,1193.4 540.2,1189.2 539.2,1189.2 518.7,1189.2 517.7,1189.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "517.7,928.9 517.7,1181.7 517.7,1189.1 518.7,1189.1 539.2,1189.1 540.2,1189.1  540.2,1181.7 540.2,928.9 540.2,921.4 539.2,921.4 518.7,921.4 517.7,921.4  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "517.7,594.9 517.7,912.1 517.7,921.4 518.7,921.4 539.2,921.4 540.2,921.4 540.2,912.1  540.2,594.9 540.2,585.6 539.2,585.6 518.7,585.6 517.7,585.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "450.2,665.8 450.2,438.7 450.2,432 451.2,432 471.7,432 472.7,432 472.7,438.7  472.7,665.8 472.7,672.4 471.7,672.4 451.2,672.4 450.2,672.4  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "450.2,1193.7 450.2,966.6 450.2,959.9 451.2,959.9 471.7,959.9 472.7,959.9 472.7,966.6  472.7,1193.7 472.7,1200.3 471.7,1200.3 451.2,1200.3 450.2,1200.3  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "450.2,951.9 450.2,680.4 450.2,672.4 451.2,672.4 471.7,672.4 472.7,672.4 472.7,680.4  472.7,951.9 472.7,959.9 471.7,959.9 451.2,959.9 450.2,959.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "450.2,1342.2 450.2,1204.2 450.2,1200.3 451.2,1200.3 471.7,1200.3 472.7,1200.3  472.7,1204.2 472.7,1342.2 472.7,1346.1 471.7,1346.1 451.2,1346.1 450.2,1346.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "450.2,186.3 450.2,5.5 450.2,0.2 451.2,0.2 471.7,0.2 472.7,0.2 472.7,5.5 472.7,186.3  472.7,191.6 471.7,191.6 451.2,191.6 450.2,191.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "450.2,425.4 450.2,198.3 450.2,191.6 451.2,191.6 471.7,191.6 472.7,191.6 472.7,198.3  472.7,425.4 472.7,432 471.7,432 451.2,432 450.2,432  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "472.7,1082.2 472.7,764.9 472.7,755.6 473.7,755.6 494.2,755.6 495.2,755.6 495.2,764.9  495.2,1082.2 495.2,1091.5 494.2,1091.5 473.7,1091.5 472.7,1091.5  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "472.7,1339.3 472.7,1098.4 472.7,1091.5 473.7,1091.5 494.2,1091.5 495.2,1091.5  495.2,1098.4 495.2,1339.3 495.2,1346.2 494.2,1346.2 473.7,1346.2 472.7,1346.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "472.7,147.7 472.7,4.4 472.7,0.2 473.7,0.2 494.2,0.2 495.2,0.2 495.2,4.4 495.2,147.7  495.2,151.9 494.2,151.9 473.7,151.9 472.7,151.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "472.7,412.3 472.7,159.4 472.7,152 473.7,152 494.2,152 495.2,152 495.2,159.4  495.2,412.3 495.2,419.7 494.2,419.7 473.7,419.7 472.7,419.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "472.7,746.3 472.7,429 472.7,419.7 473.7,419.7 494.2,419.7 495.2,419.7 495.2,429  495.2,746.3 495.2,755.6 494.2,755.6 473.7,755.6 472.7,755.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "855,665.8 855,438.7 855,432 856,432 876.5,432 877.5,432 877.5,438.7 877.5,665.8  877.5,672.4 876.5,672.4 856,672.4 855,672.4  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "855,1193.7 855,966.6 855,959.9 856,959.9 876.5,959.9 877.5,959.9 877.5,966.6  877.5,1193.7 877.5,1200.3 876.5,1200.3 856,1200.3 855,1200.3  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "855,951.9 855,680.4 855,672.4 856,672.4 876.5,672.4 877.5,672.4 877.5,680.4  877.5,951.9 877.5,959.9 876.5,959.9 856,959.9 855,959.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "855,1342.2 855,1204.2 855,1200.3 856,1200.3 876.5,1200.3 877.5,1200.3 877.5,1204.2  877.5,1342.2 877.5,1346.1 876.5,1346.1 856,1346.1 855,1346.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "855,186.3 855,5.4 855,0.1 856,0.1 876.5,0.1 877.5,0.1 877.5,5.4 877.5,186.3  877.5,191.6 876.5,191.6 856,191.6 855,191.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "855,425.4 855,198.3 855,191.6 856,191.6 876.5,191.6 877.5,191.6 877.5,198.3  877.5,425.4 877.5,432 876.5,432 856,432 855,432  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "877.5,1082.2 877.5,764.9 877.5,755.6 878.5,755.6 899,755.6 900,755.6 900,764.9  900,1082.2 900,1091.5 899,1091.5 878.5,1091.5 877.5,1091.5  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "877.5,1339.3 877.5,1098.4 877.5,1091.5 878.5,1091.5 899,1091.5 900,1091.5 900,1098.4  900,1339.3 900,1346.2 899,1346.2 878.5,1346.2 877.5,1346.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "877.5,147.7 877.5,4.4 877.5,0.2 878.5,0.2 899,0.2 900,0.2 900,4.4 900,147.7 900,151.9  899,151.9 878.5,151.9 877.5,151.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "877.5,412.3 877.5,159.4 877.5,152 878.5,152 899,152 900,152 900,159.4 900,412.3  900,419.7 899,419.7 878.5,419.7 877.5,419.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "877.5,746.3 877.5,429 877.5,419.7 878.5,419.7 899,419.7 900,419.7 900,429 900,746.3  900,755.6 899,755.6 878.5,755.6 877.5,755.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "810,665.8 810,438.7 810,432 811,432 831.5,432 832.5,432 832.5,438.7 832.5,665.8  832.5,672.4 831.5,672.4 811,672.4 810,672.4  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "810,1193.7 810,966.6 810,959.9 811,959.9 831.5,959.9 832.5,959.9 832.5,966.6  832.5,1193.7 832.5,1200.3 831.5,1200.3 811,1200.3 810,1200.3  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "810,951.9 810,680.4 810,672.4 811,672.4 831.5,672.4 832.5,672.4 832.5,680.4  832.5,951.9 832.5,959.9 831.5,959.9 811,959.9 810,959.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "810,1342.2 810,1204.2 810,1200.3 811,1200.3 831.5,1200.3 832.5,1200.3 832.5,1204.2  832.5,1342.2 832.5,1346.1 831.5,1346.1 811,1346.1 810,1346.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "810,186.3 810,5.4 810,0.1 811,0.1 831.5,0.1 832.5,0.1 832.5,5.4 832.5,186.3  832.5,191.6 831.5,191.6 811,191.6 810,191.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "810,425.4 810,198.3 810,191.6 811,191.6 831.5,191.6 832.5,191.6 832.5,198.3  832.5,425.4 832.5,432 831.5,432 811,432 810,432  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "832.5,1082.2 832.5,764.9 832.5,755.6 833.5,755.6 854,755.6 855,755.6 855,764.9  855,1082.2 855,1091.5 854,1091.5 833.5,1091.5 832.5,1091.5  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "832.5,1339.3 832.5,1098.4 832.5,1091.5 833.5,1091.5 854,1091.5 855,1091.5 855,1098.4  855,1339.3 855,1346.2 854,1346.2 833.5,1346.2 832.5,1346.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "832.5,147.7 832.5,4.4 832.5,0.2 833.5,0.2 854,0.2 855,0.2 855,4.4 855,147.7 855,151.9  854,151.9 833.5,151.9 832.5,151.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "832.5,412.3 832.5,159.4 832.5,152 833.5,152 854,152 855,152 855,159.4 855,412.3  855,419.7 854,419.7 833.5,419.7 832.5,419.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "832.5,746.3 832.5,429 832.5,419.7 833.5,419.7 854,419.7 855,419.7 855,429 855,746.3  855,755.6 854,755.6 833.5,755.6 832.5,755.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "765.1,675.4 765.1,902.5 765.1,909.1 766,909.1 786.6,909.1 787.5,909.1 787.5,902.5  787.5,675.4 787.5,668.7 786.6,668.7 766,668.7 765.1,668.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "765.1,147.5 765.1,374.6 765.1,381.3 766,381.3 786.6,381.3 787.5,381.3 787.5,374.6  787.5,147.5 787.5,140.9 786.6,140.9 766,140.9 765.1,140.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "765.1,389.2 765.1,660.8 765.1,668.7 766,668.7 786.6,668.7 787.5,668.7 787.5,660.8  787.5,389.2 787.5,381.3 786.6,381.3 766,381.3 765.1,381.3  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "765.1,4 765.1,137 765.1,140.9 766,140.9 786.6,140.9 787.5,140.9 787.5,137 787.5,4  787.5,0.1 786.6,0.1 766,0.1 765.1,0.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "765.1,1154.9 765.1,1340.9 765.1,1346.2 766,1346.2 786.6,1346.2 787.5,1346.2  787.5,1340.9 787.5,1154.9 787.5,1149.6 786.6,1149.6 766,1149.6 765.1,1149.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "765.1,915.8 765.1,1142.9 765.1,1149.6 766,1149.6 786.6,1149.6 787.5,1149.6  787.5,1142.9 787.5,915.8 787.5,909.1 786.6,909.1 766,909.1 765.1,909.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "787.5,259 787.5,576.3 787.5,585.6 788.5,585.6 809,585.6 810,585.6 810,576.3 810,259  810,249.7 809,249.7 788.5,249.7 787.5,249.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "787.5,7 787.5,242.8 787.5,249.7 788.5,249.7 809,249.7 810,249.7 810,242.8 810,7  810,0.1 809,0.1 788.5,0.1 787.5,0.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "787.5,1193.4 787.5,1341.8 787.5,1346 788.5,1346 809,1346 810,1346 810,1341.8  810,1193.4 810,1189.2 809,1189.2 788.5,1189.2 787.5,1189.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "787.5,928.9 787.5,1181.7 787.5,1189.1 788.5,1189.1 809,1189.1 810,1189.1 810,1181.7  810,928.9 810,921.4 809,921.4 788.5,921.4 787.5,921.4  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "787.5,594.9 787.5,912.1 787.5,921.4 788.5,921.4 809,921.4 810,921.4 810,912.1  810,594.9 810,585.6 809,585.6 788.5,585.6 787.5,585.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "562.6,673.5 562.6,484.9 562.6,479.4 563.6,479.4 584.2,479.4 585.1,479.4 585.1,484.9  585.1,673.5 585.1,679.1 584.2,679.1 563.6,679.1 562.6,679.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "562.6,1112.1 562.6,923.4 562.6,917.9 563.6,917.9 584.2,917.9 585.1,917.9 585.1,923.4  585.1,1112.1 585.1,1117.6 584.2,1117.6 563.6,1117.6 562.6,1117.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "562.6,911.3 562.6,685.7 562.6,679.1 563.6,679.1 584.2,679.1 585.1,679.1 585.1,685.7  585.1,911.3 585.1,917.9 584.2,917.9 563.6,917.9 562.6,917.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "562.6,1339.8 562.6,1123.8 562.6,1117.6 563.6,1117.6 584.2,1117.6 585.1,1117.6  585.1,1123.8 585.1,1339.8 585.1,1346 584.2,1346 563.6,1346 562.6,1346  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "562.6,271.9 562.6,7.9 562.6,0.2 563.6,0.2 584.2,0.2 585.1,0.2 585.1,7.9 585.1,271.9  585.1,279.6 584.2,279.6 563.6,279.6 562.6,279.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "562.6,473.8 562.6,285.2 562.6,279.6 563.6,279.6 584.2,279.6 585.1,279.6 585.1,285.2  585.1,473.8 585.1,479.4 584.2,479.4 563.6,479.4 562.6,479.4  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "585.1,1019.5 585.1,755.9 585.1,748.2 586.1,748.2 606.6,748.2 607.6,748.2 607.6,755.9  607.6,1019.5 607.6,1027.2 606.6,1027.2 586.1,1027.2 585.1,1027.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "585.1,1343.3 585.1,1237.6 585.1,1234.6 586.1,1234.6 606.6,1234.6 607.6,1234.6  607.6,1237.6 607.6,1343.3 607.6,1346.2 606.6,1346.2 586.1,1346.2 585.1,1346.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "585.1,1228.9 585.1,1032.9 585.1,1027.2 586.1,1027.2 606.6,1027.2 607.6,1027.2  607.6,1032.9 607.6,1228.9 607.6,1234.6 606.6,1234.6 586.1,1234.6 585.1,1234.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "585.1,239.8 585.1,7 585.1,0.2 586.1,0.2 606.6,0.2 607.6,0.2 607.6,7 607.6,239.8  607.6,246.7 606.6,246.7 586.1,246.7 585.1,246.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "585.1,463 585.1,252.9 585.1,246.7 586.1,246.7 606.6,246.7 607.6,246.7 607.6,252.9  607.6,463 607.6,469.1 606.6,469.1 586.1,469.1 585.1,469.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "585.1,740.4 585.1,476.9 585.1,469.1 586.1,469.1 606.6,469.1 607.6,469.1 607.6,476.9  607.6,740.4 607.6,748.2 606.6,748.2 586.1,748.2 585.1,748.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "697.6,411 697.6,728.3 697.6,737.6 698.6,737.6 719.1,737.6 720.1,737.6 720.1,728.3  720.1,411 720.1,401.7 719.1,401.7 698.6,401.7 697.6,401.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "697.6,132.5 697.6,394 697.6,401.7 698.6,401.7 719.1,401.7 720.1,401.7 720.1,394  720.1,132.5 720.1,124.8 719.1,124.8 698.6,124.8 697.6,124.8  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "697.6,3.5 697.6,121.4 697.6,124.8 698.6,124.8 719.1,124.8 720.1,124.8 720.1,121.4  720.1,3.5 720.1,0.1 719.1,0.1 698.6,0.1 697.6,0.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "697.6,1080.9 697.6,1338.8 697.6,1346.2 698.6,1346.2 719.1,1346.2 720.1,1346.2  720.1,1338.8 720.1,1080.9 720.1,1073.5 719.1,1073.5 698.6,1073.5 697.6,1073.5  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "697.6,746.9 697.6,1064.2 697.6,1073.5 698.6,1073.5 719.1,1073.5 720.1,1073.5  720.1,1064.2 720.1,746.9 720.1,737.6 719.1,737.6 698.6,737.6 697.6,737.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "652.6,675.4 652.6,902.5 652.6,909.1 653.6,909.1 674.1,909.1 675.1,909.1 675.1,902.5  675.1,675.4 675.1,668.7 674.1,668.7 653.6,668.7 652.6,668.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "652.6,147.5 652.6,374.6 652.6,381.3 653.6,381.3 674.1,381.3 675.1,381.3 675.1,374.6  675.1,147.5 675.1,140.9 674.1,140.9 653.6,140.9 652.6,140.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "652.6,389.2 652.6,660.8 652.6,668.7 653.6,668.7 674.1,668.7 675.1,668.7 675.1,660.8  675.1,389.2 675.1,381.3 674.1,381.3 653.6,381.3 652.6,381.3  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "652.6,4 652.6,137 652.6,140.9 653.6,140.9 674.1,140.9 675.1,140.9 675.1,137 675.1,4  675.1,0.1 674.1,0.1 653.6,0.1 652.6,0.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "652.6,1154.9 652.6,1340.9 652.6,1346.2 653.6,1346.2 674.1,1346.2 675.1,1346.2  675.1,1340.9 675.1,1154.9 675.1,1149.6 674.1,1149.6 653.6,1149.6 652.6,1149.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "652.6,915.8 652.6,1142.9 652.6,1149.6 653.6,1149.6 674.1,1149.6 675.1,1149.6  675.1,1142.9 675.1,915.8 675.1,909.1 674.1,909.1 653.6,909.1 652.6,909.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "675.1,259 675.1,576.3 675.1,585.6 676.1,585.6 696.6,585.6 697.6,585.6 697.6,576.3  697.6,259 697.6,249.7 696.6,249.7 676.1,249.7 675.1,249.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "675.1,7 675.1,242.8 675.1,249.7 676.1,249.7 696.6,249.7 697.6,249.7 697.6,242.8  697.6,7 697.6,0.1 696.6,0.1 676.1,0.1 675.1,0.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "675.1,1193.4 675.1,1341.8 675.1,1346 676.1,1346 696.6,1346 697.6,1346 697.6,1341.8  697.6,1193.4 697.6,1189.2 696.6,1189.2 676.1,1189.2 675.1,1189.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "675.1,928.9 675.1,1181.7 675.1,1189.1 676.1,1189.1 696.6,1189.1 697.6,1189.1  697.6,1181.7 697.6,928.9 697.6,921.4 696.6,921.4 676.1,921.4 675.1,921.4  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "675.1,594.9 675.1,912.1 675.1,921.4 676.1,921.4 696.6,921.4 697.6,921.4 697.6,912.1  697.6,594.9 697.6,585.6 696.6,585.6 676.1,585.6 675.1,585.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "607.6,665.8 607.6,438.7 607.6,432 608.6,432 629.1,432 630.1,432 630.1,438.7  630.1,665.8 630.1,672.4 629.1,672.4 608.6,672.4 607.6,672.4  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "607.6,1193.7 607.6,966.6 607.6,959.9 608.6,959.9 629.1,959.9 630.1,959.9 630.1,966.6  630.1,1193.7 630.1,1200.3 629.1,1200.3 608.6,1200.3 607.6,1200.3  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "607.6,951.9 607.6,680.4 607.6,672.4 608.6,672.4 629.1,672.4 630.1,672.4 630.1,680.4  630.1,951.9 630.1,959.9 629.1,959.9 608.6,959.9 607.6,959.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "607.6,1342.2 607.6,1204.2 607.6,1200.3 608.6,1200.3 629.1,1200.3 630.1,1200.3  630.1,1204.2 630.1,1342.2 630.1,1346.1 629.1,1346.1 608.6,1346.1 607.6,1346.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "607.6,186.3 607.6,5.4 607.6,0.1 608.6,0.1 629.1,0.1 630.1,0.1 630.1,5.4 630.1,186.3  630.1,191.6 629.1,191.6 608.6,191.6 607.6,191.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "607.6,425.4 607.6,198.3 607.6,191.6 608.6,191.6 629.1,191.6 630.1,191.6 630.1,198.3  630.1,425.4 630.1,432 629.1,432 608.6,432 607.6,432  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "630.1,1082.2 630.1,764.9 630.1,755.6 631.1,755.6 651.6,755.6 652.6,755.6 652.6,764.9  652.6,1082.2 652.6,1091.5 651.6,1091.5 631.1,1091.5 630.1,1091.5  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "630.1,1339.3 630.1,1098.4 630.1,1091.5 631.1,1091.5 651.6,1091.5 652.6,1091.5  652.6,1098.4 652.6,1339.3 652.6,1346.2 651.6,1346.2 631.1,1346.2 630.1,1346.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "630.1,239.8 630.1,7 630.1,0.2 631.1,0.2 651.6,0.2 652.6,0.2 652.6,7 652.6,239.8  652.6,246.7 651.6,246.7 631.1,246.7 630.1,246.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "630.1,414.9 630.1,251.5 630.1,246.7 631.1,246.7 651.6,246.7 652.6,246.7 652.6,251.5  652.6,414.9 652.6,419.7 651.6,419.7 631.1,419.7 630.1,419.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "630.1,746.3 630.1,429 630.1,419.7 631.1,419.7 651.6,419.7 652.6,419.7 652.6,429  652.6,746.3 652.6,755.6 651.6,755.6 631.1,755.6 630.1,755.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "720.1,673.5 720.1,484.9 720.1,479.4 721.1,479.4 741.6,479.4 742.6,479.4 742.6,484.9  742.6,673.5 742.6,679.1 741.6,679.1 721.1,679.1 720.1,679.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "720.1,1112.1 720.1,923.4 720.1,917.9 721.1,917.9 741.6,917.9 742.6,917.9 742.6,923.4  742.6,1112.1 742.6,1117.6 741.6,1117.6 721.1,1117.6 720.1,1117.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "720.1,911.3 720.1,685.7 720.1,679.1 721.1,679.1 741.6,679.1 742.6,679.1 742.6,685.7  742.6,911.3 742.6,917.9 741.6,917.9 721.1,917.9 720.1,917.9  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "720.1,1339.8 720.1,1123.8 720.1,1117.6 721.1,1117.6 741.6,1117.6 742.6,1117.6  742.6,1123.8 742.6,1339.8 742.6,1346 741.6,1346 721.1,1346 720.1,1346  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "720.1,271.9 720.1,7.9 720.1,0.2 721.1,0.2 741.6,0.2 742.6,0.2 742.6,7.9 742.6,271.9  742.6,279.6 741.6,279.6 721.1,279.6 720.1,279.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "720.1,473.8 720.1,285.2 720.1,279.6 721.1,279.6 741.6,279.6 742.6,279.6 742.6,285.2  742.6,473.8 742.6,479.4 741.6,479.4 721.1,479.4 720.1,479.4  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "742.6,1019.5 742.6,755.9 742.6,748.2 743.6,748.2 764.1,748.2 765.1,748.2 765.1,755.9  765.1,1019.5 765.1,1027.2 764.1,1027.2 743.6,1027.2 742.6,1027.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6C661"
}, points: "742.6,1343.3 742.6,1237.6 742.6,1234.6 743.6,1234.6 764.1,1234.6 765.1,1234.6  765.1,1237.6 765.1,1343.3 765.1,1346.2 764.1,1346.2 743.6,1346.2 742.6,1346.2  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "742.6,1228.9 742.6,1032.9 742.6,1027.2 743.6,1027.2 764.1,1027.2 765.1,1027.2  765.1,1032.9 765.1,1228.9 765.1,1234.6 764.1,1234.6 743.6,1234.6 742.6,1234.6  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "742.6,239.8 742.6,7 742.6,0.2 743.6,0.2 764.1,0.2 765.1,0.2 765.1,7 765.1,239.8  765.1,246.7 764.1,246.7 743.6,246.7 742.6,246.7  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E5A73B"
}, points: "742.6,463 742.6,252.9 742.6,246.7 743.6,246.7 764.1,246.7 765.1,246.7 765.1,252.9  765.1,463 765.1,469.1 764.1,469.1 743.6,469.1 742.6,469.1  " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#F6BA58"
}, points: "742.6,740.4 742.6,476.9 742.6,469.1 743.6,469.1 764.1,469.1 765.1,469.1 765.1,476.9  765.1,740.4 765.1,748.2 764.1,748.2 743.6,748.2 742.6,748.2  " }), /* @__PURE__ */ React.createElement("rect", { x: 354.6, y: 990.5, style: {
  fill: "#C59343"
}, width: 187.3, height: 265.3 }), /* @__PURE__ */ React.createElement("rect", { x: 356.1, y: 84.3, style: {
  fill: "#C59343"
}, width: 185.7, height: 265.6 }), /* @__PURE__ */ React.createElement("rect", { x: 35.1, y: 82.1, style: {
  fill: "none"
}, width: 830.4, height: 1177 }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#C59343"
}, d: "M0.5,82.1v1177v87.1h34.6h830.4H900v-87.1V82.1v-82h-34.6H0.5V82.1z M859.4,87.9v1165.2H41.2V87.9 H859.4z M35.1,1259.1v-0.2h830.4v0.2H35.1z M865.4,82.1L865.4,82.1L35.1,82.2v-0.1H865.4z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M34.5,1258.9H866V82.2H34.5V1258.9z M859.4,87.9v1165.2H41.2V87.9H859.4z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M546.5,670.5c0,53-43.2,96.1-96.2,96.1s-96.2-43.1-96.2-96.1s43.2-96.1,96.2-96.1 S546.5,617.6,546.5,670.5z M359.6,670.5c0,49.9,40.7,90.5,90.6,90.5c50,0,90.6-40.6,90.6-90.5S500.3,580,450.3,580 S359.6,620.6,359.6,670.5z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M359.9,343.6c-0.2,2.2-0.2,4.5-0.2,6.7h-5.6c0-2.4,0.1-4.7,0.3-7.1L359.9,343.6z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M544.2,329.6l-5.4,1.2c-0.9-4.2-2.2-8.3-3.7-12.4l5.2-2C542,320.7,543.3,325.2,544.2,329.6z  M534.6,304.1l-4.9,2.7c-2.1-3.8-4.4-7.4-7-10.8l4.4-3.3C529.9,296.2,532.4,300.1,534.6,304.1z M518.2,282.3l-3.9,3.9 c-3-3-6.3-5.9-9.8-8.4l3.3-4.4C511.5,276,515,279.1,518.2,282.3z M496.3,265.9l-2.7,4.9c-3.8-2.1-7.7-3.9-11.7-5.4l1.9-5.2 C488.1,261.8,492.3,263.7,496.3,265.9z M470.7,256.4l-1.2,5.4c-4.2-0.9-8.5-1.5-12.8-1.8l0.4-5.5 C461.7,254.8,466.2,255.5,470.7,256.4z M443.8,260c-4.3,0.3-8.6,0.9-12.8,1.8l-1.2-5.4c4.4-1,9-1.6,13.6-1.9L443.8,260z  M418.6,265.5c-4,1.5-8,3.3-11.7,5.3l-2.7-4.9c4-2.2,8.2-4.1,12.5-5.7L418.6,265.5z M396,277.8c-3.4,2.6-6.7,5.4-9.8,8.4 l-3.9-3.9c3.2-3.2,6.7-6.2,10.4-8.9L396,277.8z M377.8,295.9c-2.6,3.4-4.9,7.1-7,10.8l-4.9-2.7c2.2-4,4.7-7.8,7.4-11.5 L377.8,295.9z M365.4,318.5c-1.5,4-2.7,8.2-3.7,12.4l-5.4-1.2c1-4.4,2.3-8.9,3.9-13.1L365.4,318.5z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M546.5,350.3h-5.6c0-2.2-0.1-4.5-0.2-6.7l5.5-0.4C546.4,345.5,546.5,347.9,546.5,350.3z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M359.9,997.2l-5.5,0.4c-0.2-2.3-0.3-4.7-0.3-7.1h5.6C359.6,992.8,359.7,995,359.9,997.2z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M544.2,1011.2c-1,4.4-2.3,8.9-3.9,13.1l-5.2-2c1.5-4,2.7-8.2,3.7-12.4L544.2,1011.2z  M534.6,1036.8c-2.2,4-4.7,7.8-7.4,11.5l-4.4-3.3c2.6-3.4,4.9-7.1,7-10.8L534.6,1036.8z M518.2,1058.6 c-3.2,3.2-6.7,6.2-10.4,8.9l-3.3-4.4c3.4-2.6,6.7-5.4,9.8-8.4L518.2,1058.6z M496.3,1074.9c-4,2.2-8.2,4.1-12.5,5.7l-1.9-5.2 c4-1.5,8-3.3,11.7-5.4L496.3,1074.9z M470.7,1084.4c-4.4,1-9,1.6-13.6,1.9l-0.4-5.5c4.3-0.3,8.6-0.9,12.8-1.8L470.7,1084.4z  M443.8,1080.8l-0.4,5.5c-4.6-0.3-9.1-1-13.6-1.9l1.2-5.4C435.2,1079.9,439.5,1080.5,443.8,1080.8z M418.6,1075.4l-1.9,5.2 c-4.3-1.6-8.5-3.5-12.5-5.7l2.7-4.9C410.7,1072.1,414.6,1073.9,418.6,1075.4z M396,1063.1l-3.3,4.4c-3.6-2.7-7.1-5.7-10.4-8.9 l3.9-3.9C389.3,1057.7,392.6,1060.5,396,1063.1z M377.8,1044.9l-4.4,3.3c-2.7-3.6-5.2-7.5-7.4-11.5l4.9-2.7 C372.9,1037.8,375.2,1041.5,377.8,1044.9z M365.4,1022.4l-5.2,2c-1.6-4.3-2.9-8.7-3.9-13.1l5.4-1.2 C362.7,1014.2,363.9,1018.4,365.4,1022.4z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M546.5,990.5c0,2.4-0.1,4.8-0.3,7.1l-5.5-0.4c0.2-2.2,0.2-4.5,0.2-6.7L546.5,990.5L546.5,990.5z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M724.5,85.3v81.6c0,73.1-28.5,141.9-80.3,193.6s-120.6,80.2-193.9,80.2 c-73.2,0-142.1-28.5-193.9-80.2S176.1,240,176.1,166.9V85.3h5.6v81.6c0,147.9,120.5,268.2,268.6,268.2s268.6-120.3,268.6-268.2 V85.3H724.5z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M546.5,85.3v259.3c0,53-43.2,96.1-96.2,96.1s-96.2-43.1-96.2-96.1V85.3h5.6v259.3 c0,49.9,40.7,90.5,90.6,90.5c50,0,90.6-40.6,90.6-90.5V85.3H546.5z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 37.7, y: 667.6, style: {
  fill: "#FFFFFF"
}, width: 825.8, height: 5.8 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 808.8, y: 861.1, style: {
  fill: "#FFFFFF"
}, width: 53.8, height: 5.8 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 808.8, y: 477.8, style: {
  fill: "#FFFFFF"
}, width: 53.8, height: 5.8 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 36.8, y: 861.1, style: {
  fill: "#FFFFFF"
}, width: 53.8, height: 5.8 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 36.8, y: 477.8, style: {
  fill: "#FFFFFF"
}, width: 53.8, height: 5.8 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 357, y: 347.1, style: {
  fill: "#FFFFFF"
}, width: 186.6, height: 5.6 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 346.7, y: 325.6, style: {
  fill: "#FFFFFF"
}, width: 10.3, height: 5.6 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 346.7, y: 279.7, style: {
  fill: "#FFFFFF"
}, width: 10.3, height: 5.6 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 346.7, y: 237, style: {
  fill: "#FFFFFF"
}, width: 10.3, height: 5.6 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 346.7, y: 188.6, style: {
  fill: "#FFFFFF"
}, width: 10.3, height: 11.8 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 543.6, y: 325.6, style: {
  fill: "#FFFFFF"
}, width: 10.3, height: 5.6 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 543.6, y: 279.7, style: {
  fill: "#FFFFFF"
}, width: 10.3, height: 5.6 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 543.6, y: 237, style: {
  fill: "#FFFFFF"
}, width: 10.3, height: 5.6 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 543.6, y: 188.6, style: {
  fill: "#FFFFFF"
}, width: 10.3, height: 11.8 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M724.5,1173.5v81.6h-5.6v-81.6c0-147.9-120.5-268.2-268.6-268.2s-268.6,120.3-268.6,268.2v81.6 h-5.6v-81.6c0-73.1,28.5-141.9,80.3-193.6c51.8-51.7,120.6-80.2,193.9-80.2c73.2,0,142.1,28.5,193.9,80.2 C695.9,1031.6,724.5,1100.4,724.5,1173.5z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M546.5,995.8v259.3h-5.6V995.8c0-49.9-40.7-90.5-90.6-90.5c-50,0-90.6,40.6-90.6,90.5v259.3h-5.6 V995.8c0-53,43.2-96.1,96.2-96.1S546.5,942.8,546.5,995.8z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 357, y: 987.7, style: {
  fill: "#FFFFFF"
}, width: 186.6, height: 5.6 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 346.7, y: 1009.2, style: {
  fill: "#FFFFFF"
}, width: 10.3, height: 5.6 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 346.7, y: 1055.1, style: {
  fill: "#FFFFFF"
}, width: 10.3, height: 5.6 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 346.7, y: 1097.9, style: {
  fill: "#FFFFFF"
}, width: 10.3, height: 5.6 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 346.7, y: 1140, style: {
  fill: "#FFFFFF"
}, width: 10.3, height: 11.8 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 543.6, y: 1009.2, style: {
  fill: "#FFFFFF"
}, width: 10.3, height: 5.6 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 543.6, y: 1055.1, style: {
  fill: "#FFFFFF"
}, width: 10.3, height: 5.6 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 543.6, y: 1097.9, style: {
  fill: "#FFFFFF"
}, width: 10.3, height: 5.6 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 543.6, y: 1140, style: {
  fill: "#FFFFFF"
}, width: 10.3, height: 11.8 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FBFAEA"
}, d: "M466.5,1198.7c0,8.9-7.3,16.2-16.2,16.2c-8.9,0-16.2-7.3-16.2-16.2c0-8.9,7.3-16.2,16.2-16.2 S466.5,1189.8,466.5,1198.7z M435.2,1198.7c0,8.3,6.7,15,15,15s15-6.7,15-15s-6.7-15-15-15 C442,1183.7,435.2,1190.5,435.2,1198.7z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FBFAEA"
}, d: "M469.6,1198.7c0,10.6-8.7,19.3-19.3,19.3c-10.7,0-19.3-8.7-19.3-19.3c0-10.6,8.7-19.3,19.3-19.3 C460.9,1179.4,469.6,1188.1,469.6,1198.7z M432.1,1198.7c0,10,8.2,18.2,18.2,18.2s18.2-8.1,18.2-18.2c0-10-8.2-18.2-18.2-18.2 C440.3,1180.6,432.1,1188.7,432.1,1198.7z" })), /* @__PURE__ */ React.createElement("rect", { x: 443.5, y: 1221.6, style: {
  fill: "#EB641B"
}, width: 13.6, height: 10.4 }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#0F1011"
}, d: "M496,1329c5.2,0,9.1-4.2,8.7-9.4l-2-27c-0.4-5.1-4.9-9.4-10.1-9.4H408c-5.2,0-9.7,4.2-10.1,9.4 l-2,27c-0.4,5.1,3.5,9.4,8.7,9.4H496z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#888989"
}, points: "497,1235 449.9,1289.4 406.4,1234.8 415.4,1227.7 450.2,1271.4 488.3,1227.5  " })), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#0F1011"
}, d: "M503.1,1235.7c1.3,0,2.3-1,2.3-2.3v-4.2c0-1.3-1-2.3-2.3-2.3H397.5c-1.3,0-2.3,1-2.3,2.3v4.2 c0,1.3,1,2.3,2.3,2.3H503.1z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#888989"
}, d: "M456.8,1312.1c1.2,0,2.2-1,2.2-2.1l1-32c0-1.2-0.9-2.1-2.1-2.1h-16.8c-1.2,0-2.1,1-2.1,2.1l1,32 c0,1.2,1,2.1,2.2,2.1H456.8z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 427.4, y: 1198.2, style: {
  fill: "#FBFAEA"
}, width: 7.9, height: 1.1 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 466.7, y: 1198.2, style: {
  fill: "#FBFAEA"
}, width: 7.9, height: 1.1 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 450.4, y: 1175.2, style: {
  fill: "#FBFAEA"
}, width: 1.2, height: 7.9 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 450.4, y: 1214.5, style: {
  fill: "#FBFAEA"
}, width: 1.2, height: 7.9 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FBFAEA"
}, points: "468.1,1182.5 462.5,1188 461.7,1187.2 467.3,1181.7  " })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FBFAEA"
}, points: "440.3,1210.3 434.7,1215.8 433.9,1215 439.4,1209.4  " })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FBFAEA"
}, points: "472.5,1189.1 465.4,1192.5 464.9,1191.5 472,1188.1  " })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FBFAEA"
}, points: "437,1206 429.9,1209.4 429.4,1208.3 436.5,1205  " })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FBFAEA"
}, points: "473.4,1206.1 473,1207.2 465.6,1204.5 466,1203.5  " })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 431.9, y: 1188.1, transform: "matrix(0.3354 -0.9421 0.9421 0.3354 -835.5826 1199.6849)", style: {
  fill: "#FBFAEA"
}, width: 1.2, height: 7.9 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 458.8, y: 1212.5, transform: "matrix(0.9031 -0.4294 0.4294 0.9031 -477.8341 315.1254)", style: {
  fill: "#FBFAEA"
}, width: 1.2, height: 7.9 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 442, y: 1177.1, transform: "matrix(0.9031 -0.4294 0.4294 0.9031 -464.2584 304.4824)", style: {
  fill: "#FBFAEA"
}, width: 1.2, height: 7.9 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 440.4, y: 1216.6, transform: "matrix(0.3355 -0.942 0.942 0.3355 -851.3499 1227.3987)", style: {
  fill: "#FBFAEA"
}, width: 7.9, height: 1.2 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 453.5, y: 1179.7, transform: "matrix(0.3355 -0.942 0.942 0.3355 -807.9132 1215.2554)", style: {
  fill: "#FBFAEA"
}, width: 7.9, height: 1.2 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FBFAEA"
}, points: "468.1,1215 467.3,1215.8 461.7,1210.3 462.5,1209.4  " })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FBFAEA"
}, points: "440.3,1187.2 439.4,1188 433.9,1182.5 434.7,1181.7  " })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#EB641B"
}, d: "M474.3,1198.7c0,13.2-10.8,24-24.1,24c-13.3,0-24.1-10.8-24.1-24s10.8-24,24.1-24 S474.3,1185.5,474.3,1198.7z M428.5,1198.7c0,12,9.8,21.7,21.8,21.7s21.8-9.7,21.8-21.7s-9.8-21.7-21.8-21.7 S428.5,1186.8,428.5,1198.7z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FBFAEA"
}, d: "M466.5,143.5c0,8.9-7.3,16.2-16.2,16.2c-8.9,0-16.2-7.3-16.2-16.2s7.3-16.2,16.2-16.2 S466.5,134.6,466.5,143.5z M435.2,143.5c0,8.3,6.7,15,15,15s15-6.7,15-15s-6.7-15-15-15C442,128.5,435.2,135.2,435.2,143.5z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FBFAEA"
}, d: "M469.6,143.5c0,10.6-8.7,19.3-19.3,19.3c-10.7,0-19.3-8.7-19.3-19.3s8.7-19.3,19.3-19.3 S469.6,132.9,469.6,143.5z M432.1,143.5c0,10,8.2,18.2,18.2,18.2s18.2-8.1,18.2-18.2c0-10-8.2-18.2-18.2-18.2 C440.3,125.4,432.1,133.5,432.1,143.5z" })), /* @__PURE__ */ React.createElement("rect", { x: 443.5, y: 110.2, style: {
  fill: "#EB641B"
}, width: 13.6, height: 10.4 }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#0F1011"
}, d: "M496,13.2c5.2,0,9.1,4.2,8.7,9.4l-2,27c-0.4,5.1-4.9,9.4-10.1,9.4H408c-5.2,0-9.7-4.2-10.1-9.4 l-2-27c-0.4-5.1,3.5-9.4,8.7-9.4C404.6,13.2,496,13.2,496,13.2z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#888989"
}, points: "497,107.2 488.3,114.8 450.2,70.9 415.4,114.6 406.4,107.4 449.9,52.9  " })), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#0F1011"
}, d: "M503.1,106.6c1.3,0,2.3,1,2.3,2.3v4.2c0,1.3-1,2.3-2.3,2.3H397.5c-1.3,0-2.3-1-2.3-2.3v-4.2 c0-1.3,1-2.3,2.3-2.3H503.1z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#888989"
}, d: "M456.8,30.2c1.2,0,2.2,1,2.2,2.1l1,32c0,1.2-0.9,2.1-2.1,2.1h-16.8c-1.2,0-2.1-1-2.1-2.1l1-32 c0-1.2,1-2.1,2.2-2.1C442.2,30.2,456.8,30.2,456.8,30.2z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 427.4, y: 142.9, style: {
  fill: "#FBFAEA"
}, width: 7.9, height: 1.1 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 466.7, y: 142.9, style: {
  fill: "#FBFAEA"
}, width: 7.9, height: 1.1 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 450.4, y: 159.2, style: {
  fill: "#FBFAEA"
}, width: 1.2, height: 7.9 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 450.4, y: 119.9, style: {
  fill: "#FBFAEA"
}, width: 1.2, height: 7.9 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FBFAEA"
}, points: "468.1,159.8 467.3,160.6 461.7,155 462.5,154.2  " })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FBFAEA"
}, points: "440.3,132 439.4,132.8 433.9,127.3 434.7,126.4  " })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FBFAEA"
}, points: "472.5,153.1 472,154.2 464.9,150.8 465.4,149.7  " })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FBFAEA"
}, points: "437,136.2 436.5,137.3 429.4,133.9 429.9,132.9  " })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 465.6, y: 136.4, transform: "matrix(0.9424 -0.3345 0.3345 0.9424 -18.7609 164.9571)", style: {
  fill: "#FBFAEA"
}, width: 7.9, height: 1.1 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FBFAEA"
}, points: "436.3,149.3 428.9,152 428.5,150.9 436,148.2  " })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 455.5, y: 125.2, transform: "matrix(0.4298 -0.9029 0.9029 0.4298 148.4167 486.5908)", style: {
  fill: "#FBFAEA"
}, width: 7.9, height: 1.2 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 438.7, y: 160.7, transform: "matrix(0.4299 -0.9029 0.9029 0.4299 106.6978 491.5966)", style: {
  fill: "#FBFAEA"
}, width: 7.9, height: 1.2 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 443.8, y: 121.1, transform: "matrix(0.942 -0.3357 0.3357 0.942 -16.1922 156.4436)", style: {
  fill: "#FBFAEA"
}, width: 1.2, height: 7.9 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 457, y: 158.1, transform: "matrix(0.942 -0.3357 0.3357 0.942 -27.8461 163.0222)", style: {
  fill: "#FBFAEA"
}, width: 1.2, height: 7.9 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FBFAEA"
}, points: "468.1,127.3 462.5,132.8 461.7,132 467.3,126.4  " })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FBFAEA"
}, points: "440.3,155 434.7,160.6 433.9,159.8 439.4,154.2  " })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#EB641B"
}, d: "M474.3,143.5c0,13.2-10.8,24-24.1,24c-13.3,0-24.1-10.8-24.1-24s10.8-24,24.1-24 S474.3,130.3,474.3,143.5z M428.5,143.5c0,12,9.8,21.7,21.8,21.7s21.8-9.7,21.8-21.7s-9.8-21.7-21.8-21.7 S428.5,131.5,428.5,143.5z" })))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#4E3715"
}, d: "M900,0v1346.2H0.5V0H900z M895.2,1341.4V4.8H5.3v1336.6H895.2z" })))));
export default SvgBasketball;
