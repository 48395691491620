import React from 'react';
import {createRoot} from 'react-dom/client';

import App from './App';

// disable contextmenu

if (import.meta.env.DEV === false) {
    console.log('DEVMODE', import.meta.env.DEV);
    document.addEventListener('contextmenu', function (e) {
        e.preventDefault();
    });

    document.addEventListener('selectstart', function (e) {
        e.preventDefault();
    });
}

// hide loading info, displayed by index.html
const loadingInfo = document.getElementById('loadingInfo');
loadingInfo.parentNode.removeChild(loadingInfo);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App/>);
