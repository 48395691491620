import React from 'react';

import {
    Avatar, List, ListItem, ListItemButton, ListItemText, Stack, useTheme
} from '@mui/material';

import Icons from 'assets/icons';
import {Drawer, MenuHeader} from 'components';
import {useTranslation} from 'hooks';
import {isDevelopment} from 'lib/env';
import {imageUrl} from 'lib/media';
import {hasPairingApi} from 'module/pairing/Api';
import {useUserStore} from 'module/user';
import {useUserUtils} from 'module/user/useUser';
import {useLayoutStore} from 'store';

const styles = {
    overlay: {
        padding: 0,
        zIndex: 990,
    },
    menuButtonContainer: {
        borderBottom: '1px solid',
        borderBottomColor: 'grey.darkest2',

        '&:last-of-type': {
            borderBottom: 0,
        }
    },
    menuButton: {
        paddingY: 2.5,
        paddingX: 0,
        margin: 0,
        alignItems: 'flex-start',
    },
    clientContainer: {
        paddingX: 2.5,
    },
    listIconAvatar: {
        width: 26,
        height: 26,
        marginRight: 20,
        marginTop: 10,
        flexShrink: 0,
        backgroundColor: 'transparent',
    },
    listIcon: {
        width: 26,
        height: 26,
        marginRight: 20,
        marginTop: 10,
        flexShrink: 0,
    },
};

const MainMenu = () => {
    const translate = useTranslation();
    const theme = useTheme();

    const showDrawer = useLayoutStore((state) => state.showDrawer);
    const setShowDrawer = useLayoutStore((state) => state.setShowDrawer);
    //const setShowSettings = useLayoutStore((state) => state.setShowSettings);
    const setShowClientMenu = useLayoutStore((state) => state.setShowClientMenu);
    const setShowProfile = useLayoutStore((state) => state.setShowProfile);
    const setShowLanguage = useLayoutStore((state) => state.setShowLanguage);
    const setShowInformationDialog = useLayoutStore((state) => state.setShowInformationDialog);
    const setShowGroupMenu = useLayoutStore((state) => state.setShowGroupMenu);
    const setShowPairing = useLayoutStore((state) => state.setShowPairing);

    const user = useUserStore(state => state.user);
    const {isAuthenticated} = useUserUtils();

    const userInfo = user ? `${user?.firstname} ${user?.lastname}` : '';

    function handleClose() {
        setShowDrawer(false);
    }

    const iconStyle = {
        ...styles.listIcon,
        fill: theme.palette.primary.main
    };

    return <Drawer open={showDrawer} onClose={() => setShowDrawer(false)}>
        <List sx={styles.overlay}>
            <MenuHeader text={translate('board.menu.main')} handleBackButton={handleClose} handleClose={handleClose} />

            <Stack sx={styles.clientContainer}>

                {/* Profil und Einstellungen */}
                {isAuthenticated &&
                    <ListItem sx={styles.menuButtonContainer}>
                        <ListItemButton sx={styles.menuButton} onClick={() => setShowProfile(true)}>
                            <Avatar style={styles.listIconAvatar} src={imageUrl(user?.image, {w: 26, h: 26})} />
                            <ListItemText primary={translate('board.menu.profile')} secondary={userInfo} />
                        </ListItemButton>
                    </ListItem>
                }

                {/* Themen & Folien */}
                <ListItem sx={styles.menuButtonContainer}>
                    <ListItemButton sx={styles.menuButton} onClick={() => setShowGroupMenu(true, 'drawer')}>
                        <Icons.CopySlide style={iconStyle} />
                        <ListItemText primary={translate('board.menu.my_groups_sheets')} secondary={translate('board.menu.subtitle.groups_sheets')} />
                    </ListItemButton>
                </ListItem>

                {/* Teammanagment */}

                {isAuthenticated && <ListItem sx={styles.menuButtonContainer}>
                    <ListItemButton sx={styles.menuButton} onClick={() => setShowClientMenu(true)}>
                        <Icons.Managment style={iconStyle} />
                        <ListItemText primary={translate('board.menu.my_clubs')} secondary={translate('board.menu.subtitle.my_clubs')} />
                    </ListItemButton>
                </ListItem>}

                {/* Kalender */}
                {/*<ListItem sx={styles.menuButtonContainer}>*/}
                {/*    <ListItemButton>*/}
                {/*        <Icons.Calendar style={iconStyle} />*/}
                {/*        <ListItemText primary={'Kalender'} secondary={'Besprechungen & Trainings'}/>*/}
                {/*    </ListItemButton>*/}
                {/*</ListItem>*/}

                {/* Digital Locker Room */}

                {/* <ListItem sx={styles.menuButtonContainer}>
                    <ListItemButton>
                        <Icons.Lockerroom style={iconStyle} />
                        <ListItemText primary={'Digital Locker Room'} secondary={'Live Taktikbesprechungen'}/>
                    </ListItemButton>
                </ListItem> */}

                {/* QR Code Scanner */}
                {/*<ListItem sx={styles.menuButtonContainer}>*/}
                {/*    <ListItemButton>*/}
                {/*        <Icons.QrCode style={iconStyle} />*/}
                {/*        <ListItemText primary={'QR Code Scanner'}/>*/}
                {/*    </ListItemButton>*/}
                {/*</ListItem>*/}

                {/* Tutorials Videos */}
                {/*<ListItem sx={styles.menuButtonContainer}>*/}
                {/*    <ListItemButton>*/}
                {/*        <Icons.Videos style={iconStyle} />*/}
                {/*        <ListItemText primary={translate('board.menu.tutorials')}/>*/}
                {/*    </ListItemButton>*/}
                {/*</ListItem>*/}

                {/* FAQ */}
                {/*<ListItem sx={styles.menuButtonContainer}>*/}
                {/*    <ListItemButton>*/}
                {/*        <Icons.Faq style={iconStyle} />*/}
                {/*        <ListItemText primary={'FAQ'}/>*/}
                {/*    </ListItemButton>*/}
                {/*</ListItem>*/}

                {/* Follow us */}
                {/*<ListItem sx={styles.menuButtonContainer}>*/}
                {/*    <ListItemButton>*/}
                {/*        <Icons.Follow style={iconStyle} />*/}
                {/*        <ListItemText primary={'Follow us'}/>*/}
                {/*    </ListItemButton>*/}
                {/*</ListItem>*/}

                {/* Kontakt */}
                <ListItem sx={styles.menuButtonContainer}>
                    <ListItemButton sx={styles.menuButton} onClick={() => setShowInformationDialog(true)}>
                        <Icons.Contact style={iconStyle} />
                        <ListItemText primary={translate('global.contact')} secondary={translate('board.menu.subtitle.contact')} />
                    </ListItemButton>
                </ListItem>

                <ListItem sx={styles.menuButtonContainer}>
                    <ListItemButton sx={styles.menuButton} onClick={() => setShowLanguage(true)}>
                        <Icons.Language style={iconStyle} />
                        <ListItemText primary={translate('board.menu.language.language')} secondary={translate('board.menu.subtitle.language')} />
                    </ListItemButton>
                </ListItem>

                {/* Pairing */}
                {(hasPairingApi() || isDevelopment()) && <ListItem sx={styles.menuButtonContainer} key={'Pairing'}>
                    <ListItemButton sx={styles.menuButton} onClick={() => setShowPairing(true)}>
                        <Icons.Connect style={iconStyle} />
                        <ListItemText primary={translate('board.menu.pairing')} secondary={translate('board.menu.subtitle.synchronise')} />
                    </ListItemButton>
                </ListItem>}

            </Stack>

        </List>
    </Drawer>;
};

export default MainMenu;