import {create} from 'zustand';

import {middleware} from 'store/zustand';

import {produce} from 'immer';

export const useUserStore = create(middleware((set, get) => ({
    user: null,
    currentRoom: {},
    isLoadingLockerrooms: true,
    lockerrooms: [],
    activeUsers: [],
    // FIXME: Zustand will not trigger re-render if done prop-wise draft.user.id = user._id;
    setUser: user => set(produce(draft => {
        draft.user = user;
    })),
    setActiveUsers: activeUsers => set(produce(draft => {
        draft.activeUsers = activeUsers;
    })),
    setCurrentRoom: currentRoom => set(produce(draft => {
        draft.currentRoom = currentRoom;
    })),
    setLockerrooms: lockerrooms => set(produce(draft => {
        draft.lockerrooms = lockerrooms;
    })),
    setIsLoadingLockerrooms: loading => set(produce(draft => {
        draft.isLoadingLockerrooms = loading;
    })),
    checkPermission: (teamId, functionCode, permission) => {
        return !!get().user?.roles?.find(role => {
            if (role.team_id === teamId) {
                let permissions = role.functions?.find(f => f.code === functionCode)?.permissions;

                if (permissions?.includes(permission)) {
                    return true;
                }
            }

            return false;
        });
    }
}), {name: 'UserStore', debug: true}));
