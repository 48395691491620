/**
 * @param {float} price
 */
export const formatPrice = (price) => {
    if (typeof price !== 'number') {
        return '';
    }

    return formatNumber(price.toFixed(2));
};

const formatNumber = (number) => {
    number = number + '';
    const x = number.split('.');
    let x1 = x[0];
    const x2 = x.length > 1 ? ',' + x[1] : '';
    const rgx = /(\d+)(\d{3})/;

    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, `$1.$2`);
    }

    return x1 + x2;
};
