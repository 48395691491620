import {useCallback, useEffect, useRef} from 'react';

const useInterval = (callback, interval = 1000) => {

    // save interval to ref to survive re-renders
    const intervalId = useRef(0);

    // save callback to ref
    const savedCallback = useRef(null);

    // update callback ref if callback func changes
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    const clear = useCallback(() => {
        clearInterval(intervalId.current);
    }, []);

    const start = useCallback(() => {
        clearInterval(intervalId.current);

        intervalId.current = setInterval(savedCallback.current, interval);
    }, [interval]);

    useEffect(() => {
        intervalId.current = setInterval(savedCallback.current, interval);

        return () => {
            clearInterval(intervalId.current);
        };
    }, [callback, interval]);

    return [clear, start];
};

export default useInterval;
