export const getEnv = (varname) => {
    return import.meta.env[`VITE_APP_${varname}`];
};

export const isProduction = () => getEnv('ENVIRONMENT') === 'production';
export const isDemo = () => getEnv('TACTIX_DEMO') === '1';

// development flags
export const isDevelopment = () => getEnv('ENVIRONMENT') === 'development';
export const isOfflineDevelopment = () => getEnv('OFFLINE_MODE') === '1';

export const isTstEnvironment = () => getEnv('CONTROL_URL') === 'https://control-api-tst.geomix-soccerboard.com/rest-board/';
export const isProdEnvironment = () => getEnv('CONTROL_URL') === 'https://control-api.geomix-soccerboard.com/rest-board/';
