import React from 'react';

import {Close as CloseIcon} from '@mui/icons-material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {ButtonBase, Dialog, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme} from '@mui/material';

import {Text} from 'components';

const styles = {
    root: {
        '.MuiDialog-container': {
            alignItems: 'baseline',
        },
        margin: 0,
        width: '100%',
    },
    menuHeader: {
        color: 'white.main',
        height: '70px',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: 'black.dark',
        paddingX: '20px',
    },
    menuHeaderButton: {
        color: 'grey.mainlight',
        fontSize: '16px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 0,
        margin: 0,
        minWidth: 'auto',
        width: 'auto',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        flexShrink: 0,
        paddingRight: '20px',
    },
    menuHeaderButtonNoPadding: {
        paddingRight: 0,
    },
    menuHeaderText: {
        fontSize: '18px',
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        height: '70px',
        color: 'grey.mainlight',
        whiteSpace: 'nowrap',
        overflow: 'hidden',

        fontWeight: '300',
    },
    content: {
        padding: 0,
        borderTopColor: 'grey.lighter'
    }
};

const BaseDialog = ({open, handleBack, handleClose, title, children, Icon, pt= 20, dividers = false, fullscreen, ...rest}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

    const sx = styles.root;
    if (pt !== undefined) {
        sx['& .MuiDialog-container'] = {pt};
    }

    if (fullscreen === undefined) {
        fullscreen = isMobile;
    }

    return <Dialog
        fullScreen={fullscreen} open={open} onClose={handleClose} fullWidth={true} sx={sx} {...rest}>
        {title && <DialogTitle sx={styles.menuHeader} direction="row" alignItems="center">
            {handleBack &&
                <ButtonBase sx={styles.menuHeaderButton} onClick={handleBack} disableRipple data-testid="dialog-back-btn">
                    <KeyboardBackspaceIcon/>
                </ButtonBase>
            }
            <Typography sx={styles.menuHeaderText}><Text>{title}</Text></Typography>
            {handleClose &&
                <ButtonBase sx={[styles.menuHeaderButton, styles.menuHeaderButtonNoPadding]} onClick={handleClose} disableRipple data-testid="dialog-close-btn">
                    {Icon || <CloseIcon />}
                </ButtonBase>
            }
        </DialogTitle>
        }

        <DialogContent sx={styles.content} dividers={dividers}>
            {children}
        </DialogContent>
    </Dialog>;
};

export default BaseDialog;
