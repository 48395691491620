import React from 'react';

import {
    Card,
    CardContent,
    CardHeader,
    Divider, Grid,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';

import {useTranslation} from 'hooks';

const Addr = ({addr}) => {
    return <Grid container direction="row">
        <Grid item>
            {addr.family} {addr.family} {addr.local}/{addr.prefixlen}
        </Grid>
    </Grid>;
};

const Device = ({dev}) => {
    return <TableRow>
        <TableCell align="left">{dev.ifname}</TableCell>
        <TableCell align="left">{dev.link_type}</TableCell>
        <TableCell align="left">{dev.operstate}</TableCell>
        <TableCell align="left">{dev.addr_info.map((addr, idx) => <Addr addr={addr} key={idx}/>)}</TableCell>
    </TableRow>;
};

const Devices = ({data}) => {
    const translate = useTranslation();

    if (!Array.isArray(data)) {
        return null;
    }

    return <Table sx={{minWidth: 650}} size="small">
        <TableHead>
            <TableRow>
                <TableCell align="center" sx={{fontWeight: '600'}} width={150}>{translate('board.selftest.net_devs.interface')}</TableCell>
                <TableCell align="center" sx={{fontWeight: '600'}} width={100}>{translate('board.selftest.net_devs.link_type')}</TableCell>
                <TableCell align="center" sx={{fontWeight: '600'}} width={110}>{translate('board.selftest.net_devs.state')}</TableCell>
                <TableCell align="center" sx={{fontWeight: '600'}}>{translate('board.selftest.net_devs.address')}</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {data.map(dev => <Device key={dev.ifindex} dev={dev}/>)}
        </TableBody>
    </Table>;
};

const NetDevs = ({data}) => {
    const translate = useTranslation();

    return <Card>
        <CardHeader title={translate('board.selftest.net_devs.devices')} />
        <Divider/>
        <CardContent>
            <Devices data={data}/>
        </CardContent>
    </Card>;
};

export default NetDevs;
