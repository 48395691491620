import React from 'react';

import {Card, CardContent, CardHeader, Divider, Grid, Typography} from '@mui/material';

import {useTranslation} from 'hooks';

const Info = ({data}) => {
    if (typeof data !== 'object') {
        return null;
    }

    return <Grid container direction="column">

        <Grid container direction={'row'}>
            <Grid item md={5}>
                capabilities
            </Grid>
            <Grid item md={6}>
                {data?.location?.capabilities.join(', ')}
            </Grid>
        </Grid>

        <Grid container direction={'row'}>
            <Grid item md={5}>
                enabled
            </Grid>
            <Grid item md={6}>
                {data?.location?.enabled?.join(', ')}
            </Grid>
        </Grid>

        <Grid container direction={'row'}>
            <Grid item md={5}>
                signals
            </Grid>
            <Grid item md={6}>
                {data?.location?.signals}
            </Grid>
        </Grid>

        <Grid container direction={'row'}>
            <Grid item md={5}>
                refresh
            </Grid>
            <Grid item md={6}>
                {data?.location?.gps?.['refresh-rate']}
            </Grid>
        </Grid>
    </Grid>;
};

const Gps = ({data}) => {
    const translate = useTranslation();

    if (typeof data !== 'object') {
        return null;
    }

    console.log('gps', data);
    return <Card>
        <CardHeader title={translate('board.selftest.gps')} />
        <Divider/>
        <CardContent>
            {data.error && <Typography>{data.error}</Typography>}
            {!data.error && <Info data={data?.modem}/>}
        </CardContent>
    </Card>;
};

export default Gps;
