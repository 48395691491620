import {useCallback} from 'react';

import {v4 as uuidv4} from 'uuid';

import {useLocalStore as useLocalGroupStore} from 'module/group/useLocalStore';
import {migrationGroupId} from 'module/group/useLocalStore';
import {useLocalStore as useLocalSheetStore} from 'module/sheet/useLocalStore';
import storage from 'store/localStorage';

const version = storage.getItem('version');

const useMigration3to4 = () => {
    const sheets = useLocalSheetStore((state) => state.sheets);
    const updateSheet = useLocalSheetStore((state) => state.upd);
    const groups = useLocalGroupStore((state) => state.groups);
    const updateGroupId = useLocalGroupStore((state) => state.updateId);

    // make migration groupid uniq, migration group id was a constant which caused duplicate key errors
    return useCallback(() => {

        if (version >= 4) {
            console.log('Migration3to4: already migrated to version 4');
            return;
        }

        const newMigrationGroupId = uuidv4();

        groups.forEach(group => {
            if (group.id === migrationGroupId) {
                console.log('found migration groupid changing to:', newMigrationGroupId);
                updateGroupId(migrationGroupId, newMigrationGroupId);
            }
        });

        sheets.forEach(sheet => {
            if (sheet.group === migrationGroupId) {
                console.log(`found sheet ${sheet.id} with migration groupid changing to`);
                updateSheet({
                    id: sheet.id,
                    group: newMigrationGroupId
                });
            }
        });

        storage.setItem('version', 4);

        // do not re-render/generate, runs at startup only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useMigration3to4;
