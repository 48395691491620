import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';

import {FormControl, FormHelperText, TextField} from '@mui/material';
import {DesktopDateTimePicker} from '@mui/x-date-pickers';

import {useTranslation} from 'hooks';

import {dateFormat} from '../lib/datefns';

const DISPLAY_FORMAT = 'dd.LL.yyyy HH:mm:ss';
const VALUE_FORMAT = 'yyyy-LL-dd HH:mm:ss';

const DateTimeField = ({name, label, defaultValue, fullWidth, helperText, maxWidth, ...rest}) => {
    const {control} = useFormContext();
    const translate = useTranslation();

    return (<Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        shouldUnregister={true}
        render={({field: {onChange, onBlur, value}, fieldState: {error}}) => {
            return <FormControl fullWidth={fullWidth} error={!!error?.message}>
                <DesktopDateTimePicker
                    name={name}
                    label={translate(label)}
                    inputFormat={DISPLAY_FORMAT}
                    value={value}
                    autoOk={true}
                    onBlur={onBlur}
                    onChange={v => {
                        if (typeof v === 'object') {
                            v = dateFormat(v, VALUE_FORMAT);
                        }

                        onChange(v);
                    }}
                    fullWidth={true}
                    sx={{maxWidth}}
                    renderInput={(params) => <TextField size="small" variant="outlined" {...params} />}
                    {...rest}
                />
                <FormHelperText>{error?.message || translate(helperText)}</FormHelperText>
            </FormControl>;
        }}
    />);
};

DateTimeField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValue: PropTypes.any,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
};

export default DateTimeField;
