import {create} from 'zustand';

import {middleware} from 'store/zustand';

import {produce} from 'immer';

export const useTextStore = create(middleware(set => ({
    text: null,
    set: t => set(produce(draft => {
        draft.text = t;
    })),
    unset: () => set(produce(draft => {
        draft.text = null;
    })),
})));
