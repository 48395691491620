import PropTypes from 'prop-types';

import {v4 as uuidv4} from 'uuid';

import * as cliparts from 'assets/cliparts';
import {fabric} from 'fabric';

import {isCustom, limitReached, setLastObjectActive} from '../canvas-helper';

const SIZE = 40;

const Clipart = function ({canvas, color, clipart}) {
    canvas.on({
        'mouse:down': (o) => {
            if (limitReached(canvas)) {
                return;
            }

            if (canvas.getActiveObject()) {
                if (isCustom(canvas.getActiveObject())) {
                    return;
                }

                canvas.discardActiveObject();
            }

            fabric.loadSVGFromString(cliparts[clipart], (loaded) => {
                let pointer = canvas.getPointer(o.e);
                let img = fabric.util.groupSVGElements(loaded);
                img.scaleToWidth(SIZE);
                img.originX = 'center';
                img.originY = 'center';

                if (img.item) {
                    img.item(0).set('fill', color.value1);
                    img.item(1).set('fill', color.value2);
                    if (clipart === 'futbol') {
                        img.item(1).set('fill', color.value1 === '#000000' ? '#ffffff' :'#000000');
                    }
                } else {
                    img.set('fill', color.value1);
                }

                const group = new fabric.Group([img], {
                    custom: {
                        type: 'clipart',
                        clipart,
                        id: uuidv4()
                    },
                    left: pointer.x,
                    top: pointer.y,
                    hasBorders: false,
                    hasControls: false,
                });

                canvas.add(group);
                setLastObjectActive(canvas);
            });
        },
    });
};

Clipart.propTypes = {
    canvas: PropTypes.object.isRequired
};

export default Clipart;
