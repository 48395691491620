import React from 'react';

import {useInfoStore} from 'store';

import ReactQRCode from 'qrcode.react';

const QRCode = ({articleId, ...rest}) => {
    const deviceId = useInfoStore(store => store.deviceId);

    // const data = JSON.stringify({
    //     articleId,
    //     deviceId
    // });

    const data = `geomix://Article?deviceId=${deviceId}&articleId=${articleId}`;
    // const data = `geomix://Shop/Article?deviceId=${deviceId}&articleId=${articleId}`;
    //const data = `exp://192.168.201.122:19000/--/Article?deviceId=${deviceId}&articleId=${articleId}`;
    //const data = `https://www.google.at/?deviceId=${deviceId}`;

    return <ReactQRCode value={data} level={'H'} {...rest}/>;
};

export default QRCode;
