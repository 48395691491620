import Lineup343 from './3-4-3.svg?react';
import Lineup352 from './3-5-2.svg?react';
import Lineup4231 from './4-2-3-1.svg?react';
import Lineup4321 from './4-3-2-1.svg?react';
import Lineup433 from './4-3-3.svg?react';
import Lineup442 from './4-4-2.svg?react';
import Lineup442diamond from './4-4-2-diamond.svg?react';
import Lineup451 from './4-5-1.svg?react';
import Lineup451libero from './4-5-1-libero.svg?react';
import Lineup532 from './5-3-2.svg?react';
import Lineup541 from './5-4-1.svg?react';

const defGoal = [{x: 540, y: 50}];
const defDefense4 = [{x: 100, y: 420}, {x: 370, y: 340}, {x: 710, y: 340}, {x: 980, y: 420}];
const defDefense5 = [{x: 70, y: 500}, {x: 260, y: 360}, {x: 540, y: 310}, {x: 820, y: 360}, {x: 1010, y: 500}];
const defMidfield4 = [{x: 120, y: 760}, {x: 420, y: 670}, {x: 660, y: 670}, {x: 960, y: 760}];
const defAttack1 = [{x: 540, y: 1060}];
const defAttack2 = [{x: 400, y: 1050}, {x: 680, y: 1050}];

const lineups = [
    {
        name: '3-4-3',
        Icon: Lineup343,
        positions: {
            goal: defGoal,
            defense: [{x: 200, y: 370}, {x: 540, y: 320}, {x: 880, y: 370}],
            midfield: defMidfield4,
            attack: [{x: 210, y: 1000}, {x: 540, y: 1050}, {x: 870, y: 1000}]
        },
        // positions: [
        //     {x: 3, y: 7.5}, {x: 9, y: 6.5}, {x: 15, y: 7.5},
        //     ...defMidfield4,
        //     {x: 3.5, y: 21.5}, {x: 9, y: 22.5}, {x: 14.5, y: 21.5},
        // ]
    },
    {
        name: '3-5-2',
        Icon: Lineup352,
        positions: {
            goal: defGoal,
            defense: [{x: 200, y: 390}, {x: 540, y: 340}, {x: 880, y: 390}],
            midfield: [{x: 100, y: 760}, {x: 400, y: 640}, {x: 540, y: 806}, {x: 680, y: 640}, {x: 980, y: 760}],
            attack: defAttack2
        },
        // positions: [
        //     {x: 3, y: 8}, {x: 9, y: 7}, {x: 15, y: 8},
        //     {x: 1, y: 16}, {x: 6.5, y: 13.5}, {x: 9, y: 17.5}, {x: 11.5, y: 13.5}, {x: 17, y: 16},
        //     ...defAttack2
        // ]
    },
    {
        name: '4-2-3-1',
        Icon: Lineup4231,
        positions: {
            goal: defGoal,
            defense: defDefense4,
            midfield: [{x: 400, y: 600}, {x: 680, y: 600}, {x: 200, y: 860}, {x: 540, y: 860}, {x: 880, y: 860}],
            attack: defAttack1
        },
        // positions: [
        //     ...defDefense4,
        //     {x: 6.5, y: 12.5}, {x: 11.5, y: 12.5},
        //     {x: 3, y: 18.5}, {x: 9, y: 18.5}, {x: 15, y: 18.5},
        //     ...defAttack1
        // ]
    },
    {
        name: '4-3-2-1',
        Icon: Lineup4321,
        positions: {
            goal: defGoal,
            defense: defDefense4,
            midfield: [{x: 280, y: 660}, {x: 540, y: 570}, {x: 800, y: 660}, {x: 360, y: 900}, {x: 720, y: 900}],
            attack: [{x: 540, y: 1100}]
        },
        // positions: [
        //     ...defDefense4,
        //     {x: 4, y: 14}, {x: 9, y: 12}, {x: 14, y: 14},
        //     {x: 6, y: 20}, {x: 12, y: 20},
        //     {x: 9, y: 24}
        // ]
    },
    {
        name: '4-3-3',
        Icon: Lineup433,
        positions: {
            goal: defGoal,
            defense: defDefense4,
            midfield: [{x: 300, y: 700}, {x: 540, y: 600}, {x: 780, y: 700}],
            attack: [{x: 200, y: 980}, {x: 540, y: 1080}, {x: 880, y: 980}]
        },
        // positions: [
        //     ...defDefense4,
        //     {x: 5, y: 15}, {x: 9, y: 13}, {x: 13, y: 15},
        //     {x: 3, y: 21}, {x: 9, y: 23}, {x: 15, y: 21}
        // ]
    },
    {
        name: '4-4-2',
        Icon: Lineup442,
        positions: {
            goal: defGoal,
            defense: defDefense4,
            midfield: defMidfield4,
            attack: defAttack2
        },
        // positions: [
        //     ...defDefense4,
        //     ...defMidfield4,
        //     ...defAttack2,
        // ]
    },
    {
        name: '4-4-2 dia',
        Icon: Lineup442diamond,
        positions: {
            goal: defGoal,
            defense: defDefense4,
            midfield: [{x: 250, y: 760}, {x: 540, y: 600}, {x: 540, y: 920}, {x: 830, y: 760}],
            attack: defAttack2
        },
        // positions: [
        //     ...defDefense4,
        //     {x: 4, y: 16.5}, {x: 9, y: 13}, {x: 9, y: 20}, {x: 14, y: 16.5},
        //     ...defAttack2,
        // ]
    },
    {
        name: '4-5-1',
        Icon: Lineup451,
        positions: {
            goal: defGoal,
            defense: defDefense4,
            midfield: [{x: 100, y: 760}, {x: 370, y: 690}, {x: 540, y: 860}, {x: 710, y: 690}, {x: 980, y: 760}],
            attack: defAttack1
        },
        // positions: [
        //     ...defDefense4,
        //     {x: 1, y: 16.5}, {x: 6, y: 15}, {x: 9, y: 18.5}, {x: 12, y: 15}, {x: 17, y: 16.5},
        //     ...defAttack1
        // ]
    },
    {
        name: '4-5-1 libero',
        Icon: Lineup451libero,
        positions: {
            goal: defGoal,
            defense: [{x: 150, y: 340}, {x: 540, y: 450}, {x: 540, y: 270}, {x: 930, y: 340}],
            midfield: [{x: 100, y: 700}, {x: 380, y: 650}, {x: 540, y: 880}, {x: 700, y: 650}, {x: 980, y: 700}],
            attack: defAttack1
        },
        // positions: [
        //     {x: 2, y: 7}, {x: 9, y: 9.5}, {x: 9, y: 5.5}, {x: 16, y: 7},
        //     {x: 1, y: 15}, {x: 6, y: 14}, {x: 9, y: 19}, {x: 12, y: 14}, {x: 17, y: 15},
        //     ...defAttack1
        // ]
    },
    {
        name: '5-3-2',
        Icon: Lineup532,
        positions: {
            goal: defGoal,
            defense: defDefense5,
            midfield: [{x: 290, y: 800}, {x: 540, y: 640}, {x: 790, y: 800}],
            attack: defAttack2
        },
        // positions: [
        //     ...defDefense5,
        //     {x: 5, y: 17}, {x: 9, y: 14}, {x: 13, y: 17},
        //     ...defAttack2,
        // ]
    },
    {
        name: '5-4-1',
        Icon: Lineup541,
        positions: {
            goal: defGoal,
            defense: defDefense5,
            midfield: defMidfield4,
            attack: defAttack1
        },
        // positions: [
        //     ...defDefense5,
        //     ...defMidfield4,
        //     ...defAttack1
        // ]
    },
];

export default lineups;
