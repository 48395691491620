import {useCallback} from 'react';

import {useQuery} from '@tanstack/react-query';

import axios from 'axios';

import {isOk, useFetcher} from 'fetcher';
import {getEnv} from 'lib/env';
import {useUserUtils} from 'module/user/useUser';

const CONTROL_URL = getEnv('CONTROL_URL');

const defaultData = {
    name: '',
    colors: {
        value1: '',
        value2: '',
    },
    contact: {
        phone: '',
        email: '',
    }
};

const clientSearch = async ({signal}) => {
    const res = await axios.get(CONTROL_URL + 'user/client/', {signal});

    return res.data;
};

export const useApiQuery = () => {
    const {isAuthenticated} = useUserUtils();

    return useQuery({
        queryKey: ['clients'],
        queryFn: clientSearch,
        placeholderData: [],
        staleTime: 30 * 1000,
        enabled: isAuthenticated
    });
};

const userSearch = async ({clientId, signal}) => {
    const res = await axios.get(CONTROL_URL + `user/client/${clientId}/user/`, {signal});

    return res.data;
};

export const useApiUserQuery = ({clientId, ...rest}) => {
    const {isAuthenticated} = useUserUtils();

    return useQuery({
        queryKey: ['client-users', clientId],
        queryFn: ({signal}) => userSearch({signal, clientId}),
        placeholderData: [],
        enabled: isAuthenticated,
        ...rest
    });
};

export const useApiAdd = () => {
    const {loading, fetch, data, error} = useFetcher(CONTROL_URL + 'user/client/', {
        method: 'POST',
    }, defaultData, true);

    const add = useCallback(async (data) => {
        const res = await fetch({data});
        if (isOk(res) && typeof res.data === 'object') {
            return res.data;
        }
    }, [fetch]);

    return {add, loading, data, errors: error.errors};
};

export const useApiDelete = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'user/client/{id}', {
        method: 'DELETE',
    }, undefined, true);

    const del = useCallback(async (id) => {
        const res = await fetch({
            urlParams: {
                id
            },
        });

        if (isOk(res)) {
            return true;
        }
    }, [fetch]);

    return {del, loading};
};

export const useApiUpdate = (defaultData) => {
    const {loading, fetch, data, error} = useFetcher(CONTROL_URL + 'user/client/', {
        method: 'PATCH',
    }, defaultData, true);

    const update = useCallback(async (data) => {
        const res = await fetch({data});

        if (isOk(res) && typeof res.data === 'object') {
            return res.data;
        }
    }, [fetch]);

    return {update, loading, data, errors: error.errors};
};

export const useApiUpload = () => {
    const {loading, fetch, data, error} = useFetcher(CONTROL_URL + 'user/client/{id}/image-upload', {
        method: 'POST',
    }, undefined, true);

    const upload = useCallback(async (id, file) => {
        const formData = new FormData();
        formData.append('file', file);

        const res = await fetch({
            data: formData,
            urlParams: {
                id
            },
        });

        if (isOk(res) && typeof res.data === 'object') {
            return res.data;
        }
    }, [fetch]);

    return {upload, loading, data, errors: error.errors};
};
