import React from 'react';

import {Grid} from '@mui/material';

import {useLockerRoomStore} from 'module/lockerRoom/zustand';

import {Infobar} from './components';

const styles = {
    root: {
        backgroundColor: 'black.dark',
    }
};

const Header = () => {
    const editor = useLockerRoomStore((state) => state.editor);

    return (
        <Grid sx={styles.root} container direction="row" alignItems="space-around">
            <Grid item flexGrow={1}>
                {editor && <Infobar/>}
            </Grid>
        </Grid>
    );
};

export default Header;
