import React from 'react';

import {Box, Stack, Typography} from '@mui/material';

import {Text} from 'components';
import {usePrefsStore} from 'store';

import FlyoutButton from './FlyoutButton';

const styles = {
    titleHolder: {
        marginLeft: '0.5%',
    },
};

export const colors = [
    {
        name: 'black-white',
        value1: '#000000',
        value2: '#ffffff',
        textColor: '#ffffff',
    },
    {
        name: 'white-black',
        value1: '#ffffff',
        value2: '#000000',
        textColor: '#000000',
    },
    {
        name: 'red-black',
        value1: '#ff0000',
        value2: '#000000',
        textColor: '#000000',
    },
    {
        name: 'yellow-black',
        value1: '#ffff00',
        value2: '#000000',
        textColor: '#000000',
    },
    {
        name: 'lime-white',
        value1: '#00ff00',
        value2: '#ffffff',
        textColor: '#000000',
    },
    {
        name: 'green-white',
        value1: '#008000',
        value2: '#ffffff',
        textColor: '#ffffff',
    },
    {
        name: 'aqua-black',
        value1: '#00ffff',
        value2: '#000000',
        textColor: '#000000',
    },
    {
        name: 'blue-white',
        value1: '#0000ff',
        value2: '#ffffff',
        textColor: '#ffffff',
    },
    {
        name: 'darkorange-black',
        value1: '#ff8c00',
        value2: '#000000',
        textColor: '#000000',
    },
    {
        name: 'violet-white',
        value1: '#421477',
        value2: '#ffffff',
        textColor: '#ffffff',
    },
];

const ColorTool = ({onSelect}) => {
    const color = usePrefsStore((state) => state.color);
    const setColor = usePrefsStore((state) => state.setColor);

    const onClick = (clr) => {
        setColor(clr);
        onSelect(clr);
    };

    return <>
        <Box>
            <Typography sx={styles.titleHolder} variant="h1" color="grey.mainlight" mb={3}><Text>board.toolbar.color.select</Text></Typography>
        </Box>
        <Stack direction="row" flexWrap="wrap" useFlexGap={true}>
            {colors.map(clr => <FlyoutButton
                key={clr.name}
                color={clr.value1}
                icon="CircleFull"
                selected={color.name !== 'custom' && clr.value1 === color.value1}
                onClick={() => onClick(clr)}/>
            )}
        </Stack>
    </>;
};

export default ColorTool;