import PropTypes from 'prop-types';

import {fabric} from 'fabric';

import {limitReached} from '../canvas-helper';

const Rect = function ({canvas, stroke, width, fill = ''}) {
    let isDrawing = false;
    let rect = null;
    let initialLeft;
    let initialTop;

    canvas.on('mouse:down', (o) => {
        if (limitReached(canvas)) {
            return;
        }

        // don't draw if an object is selected on the canvas
        if (canvas.getActiveObject()) {
            return;
        }

        isDrawing = true;
        let pointer = canvas.getPointer(o.e);
        initialLeft = pointer.x;
        initialTop = pointer.y;
        rect = new fabric.Rect({
            strokeWidth: width,
            fill: fill,
            stroke: stroke,
            left: initialLeft,
            top: initialTop,
            hasBorders: false,
            hasControls: false,
        });
        canvas.add(rect);
    });

    canvas.on('mouse:move', (o) => {
        if (!isDrawing) {
            return;
        }

        let pointer = canvas.getPointer(o.e);

        if (initialLeft > pointer.x) {
            rect.set({left: Math.abs(pointer.x)});
        }

        if (initialTop > pointer.y) {
            rect.set({top: Math.abs(pointer.y)});
        }

        rect.set({
            width: Math.abs(initialLeft - pointer.x),
            height: Math.abs(initialTop - pointer.y)
        });
        canvas.renderAll();
    });

    canvas.on('mouse:up', () => {
        isDrawing = false;

        if (rect) {
            canvas.remove(rect).add(rect);
            canvas.sendToBack(rect);
        }
    });

};

Rect.propTypes = {
    canvas: PropTypes.object.isRequired
};

export default Rect;
