import React from 'react';

import {ButtonBase, useTheme} from '@mui/material';

import icons from 'assets/icons';

import _ from 'lodash';

const svgIcons = {
    'delete': icons.Delete,
    'pencil': icons.Pencil,
    'arrowdown': icons.ArrowDownward,
    'arrowup': icons.ArrowUpward,
};

const styles = {
    root: {
        width: '60px',
        height: '50px'
    },
};

const IconButton = ({icon, onClick, color = 'grey.mainlight', disabled = false}) => {
    const theme = useTheme();

    const SvgIcon = svgIcons[icon];

    return <ButtonBase
        sx={styles.root}
        onClick={onClick}
        disabled={disabled}
    >
        <SvgIcon style={{
            fill: color ? _.get(theme.palette, color) : theme.palette.white.main,
            opacity: disabled ? 0.2 : 1,
            width: 25,
            height: 25,
        }}/>
    </ButtonBase>;
};

export default IconButton;