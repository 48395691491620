import * as React from 'react';

import {Box} from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import Controls from './Controls';
import UserList from './UserList';

//import UserItem from './UserItem';
//import {setRoomEditor} from './api';

const drawerBleeding = 70; // should be the same as closed drawer height

const styles = {

    controlsContainer: {
        position: 'absolute',
        top: -drawerBleeding,
        visibility: 'visible',
        height: `${drawerBleeding + 15}px`, //to add the subtitle if open,
        right: 0,
        left: 0,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        overflow: 'visible',
        zIndex:1300,
        background: 'linear-gradient(#2f2f2f, #0f0f0f)',
        borderBottom: '1px solid',
        borderColor: '#1f1f1f',
    },
    controlsButtonContainer: {
        position: 'relative',
        padding: '2px  0px',
        justifyContent: 'center',
        height: `${drawerBleeding}px`,
        overflow: 'visible',
    },
    content: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        padding: '0',
        background: '#0f0f0f',
        overflow: 'auto',

    },
};

const ControlsDrawer = () => {
    // const translate = useTranslation();

    const [open, setOpen] = React.useState(false);

    // const currentRoom = useUserStore((state) => state.currentRoom);

    // const role = useLockerRoomStore((state) => state.role);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    // filter > admin-user
    // const adminUser = users.filter(user => user.admin);

    // filter > editor-users
    // const editingUsers = users.filter(user => user.status.editing === true);
    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <>
            {/* Lockerroom Controls */}
            <SwipeableDrawer
                container={container}
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
                PaperProps={{
                    sx: {
                        height: `calc(50% - ${drawerBleeding}px)`,
                        overflow: 'visible',
                    }
                }}
            >
                <Box sx={styles.controlsContainer}>
                    <Box sx={styles.controlsButtonContainer}>
                        <Controls openState={open} setOpen={setOpen}/>
                    </Box>
                </Box>
                <Box sx={[
                    styles.content, {
                        '&::-webkit-scrollbar': {
                            height: '100%',
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'grey.light',
                            borderRadius: 2
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'grey.lightest',
                            borderRadius: 2
                        }
                    }]
                }>
                    {/* Userlist Body */}
                    <UserList />
                </Box>

            </SwipeableDrawer>
        </>
    );
};

export default ControlsDrawer;