export const updateRemoteLockerroomState = (flag) => {
    if (typeof window.dataApi?.setLockerroomActive !== 'function') {
        return;
    }

    return window.dataApi.setLockerroomActive({value: flag});
};

export const updateRemoteLanguage = (lang) => {
    console.log('updateRemoteLanguage', window.dataApi);
    if (typeof window.dataApi?.changeLanguage !== 'function') {
        return;
    }

    return window.dataApi.changeLanguage({value: lang});
};

// deprecated as no more team management in the app
// export const updateRemoteTeam = (team, language) => {
//     return window.dataApi?.updateTeam({team, language});
// };

// export const getRemoteClients = (language) => {
//     return window.dataApi?.getClients({language});
// };

// export const getRemoteTeams = (clientId, language) => {
//     return window.dataApi?.getTeams({clientId, language});
// };

// export const getRemotePlayers = (teamId, language) => {
//     return window.dataApi?.getPlayers({teamId, language});
// };
