import {useCallback, useEffect, useRef, useState} from 'react';

import {v4 as uuidv4} from 'uuid';

import {useUserStore} from 'module/user';
import storage from 'store/localStorage';

import {useLocalStore} from './useLocalStore';
import {useApiAdd, useApiDelete, useApiLoad, useApiSort, useApiUpdate} from './useSheetApi';
import {useSheetStore} from './zustand';

export const useSheet = (groupId) => {
    const [sheets, setSheets] = useState([]);
    const [selectedSheet, setSelectedSheet] = useState();

    const storedSheets = useSheetStore(state => state.sheets);
    const selected = useSheetStore(state => state.selected);
    const setSelected = useSheetStore(state => state.setSelected);

    const frameIndex = useSheetStore(state => state.frameIndex);
    const setFrameIndex = useSheetStore(state => state.setFrameIndex);

    const storedSheetsRef = useRef(storedSheets);

    // use ref for accessing storedSheets, this avoids useless re-rendering, exchange it with useEffectEvent if available
    const getById = useCallback((id) => {
        return storedSheetsRef.current.find(sht => sht?.id === id);
    }, []);

    const filterByGroup = useCallback((allSheets, groupId) => {
        return allSheets.filter(sht => sht.group === groupId).sort((a, b) => a.sort - b.sort);
    }, []);

    const getNumFrames = useCallback(() => {
        return selectedSheet?.frames?.length || 0;
    }, [selectedSheet]);

    useEffect(() => {
        storedSheetsRef.current = storedSheets;
    }, [storedSheets]);

    useEffect(() => {
        let filtered = storedSheets;

        if (groupId) {
            filtered = filterByGroup(storedSheets, groupId);
        }

        setSheets(filtered);
    }, [storedSheets, groupId, filterByGroup]);

    useEffect(() => {
        setSelectedSheet(storedSheets.find(sht => sht.id === selected));
    }, [storedSheets, selected]);

    return {sheets, selected, selectedSheet, setSelected, frameIndex, setFrameIndex, getNumFrames, getById, filterByGroup};
};

/**
 * Load sheet data from store backend
 *
 * @param {boolean} defaultLoading - The initial loading state (default: false).
 * @returns {Object} - An object containing the load function and loading state.
 */
export const useSheetLoad = (defaultLoading = false) => {
    // we need a dedicated loading indicator, to block rendering, until data was loaded
    const [loading, setLoading] = useState(defaultLoading);

    const setSheets = useSheetStore(state => state.setSheets);

    const {load: backendLoad} = useApiLoad();

    const load = useCallback(async (local) => {
        let sheets;

        setLoading(true);

        if (!local) {
            try {
                sheets = await backendLoad();
            } catch (e) {
                setLoading(false);
                throw e;
            }
        } else {
            const sheetsJson = localStorage.getItem('sheets');
            sheets = JSON.parse(sheetsJson)?.state?.sheets || [];
        }

        sheets.forEach(s => s.local = local);

        setSheets(sheets);
        setLoading(false);

        console.debug('loaded sheets', sheets);

        return sheets;
    }, [backendLoad, setSheets]);

    return {load, loading};
};

export const useSheetCopy = () => {
    const {add: addSheet, loading} = useSheetAdd();
    const storedSheets = useSheetStore(state => state.sheets);

    const copy = useCallback(async (sheet) => {
        if (!sheet) {
            return;
        }

        const num = storedSheets.filter(sht => sht.name.replace(/\.\d+$/, '') === sheet.name).length;

        // does not work anymore, because we can not generate the newId in advance
        // const newName = sheet.name.charAt(sheet.name.length - 5) === '_'
        //     ? `${sheet.name.slice(0, -5)}_${newId.slice(0, 4)}`
        //     : `${sheet.name}_${newId.slice(0, 4)}`;
        const newName = sheet.name + `.${num}`;
        const newSheet = {name: newName, type: 'soccer', group: sheet.group};

        // do not select newly created sheet, this causes the SheetSwitcher to be triggered, but we need to save
        // the canvas before triggering the new selection
        return await addSheet(newSheet);
    }, [addSheet, storedSheets]);

    return {copy, loading};
};

export const useSheetAdd = () => {
    const addSheet = useSheetStore(state => state.add);
    const getNextSort = useSheetStore(state => state.getNextSort);

    const jwt = useUserStore(state => state.user?.jwt);

    const {add: backendAdd, loading} = useApiAdd();

    const addLocalSheet = useLocalStore(store => store.add);

    const add = useCallback(async (sheet, select = false) => {
        let newSheet;
        const mergedSheet = {
            name: '',
            group: null,
            frames: [],
            type: 'soccer',
            created: (new Date()).toISOString(),
            modified: null,
            local: !jwt,
            ...sheet,
        };

        if (jwt) {
            console.debug('add backend');
            newSheet = await backendAdd(mergedSheet);
        } else {
            console.debug('add locally');
            mergedSheet.id = uuidv4();
            mergedSheet.sort = getNextSort(sheet.group);
            addLocalSheet(mergedSheet);
            newSheet = mergedSheet;
        }

        if (newSheet) {
            addSheet(newSheet, select);
        }

        return newSheet;
    }, [jwt, addSheet, backendAdd, getNextSort, addLocalSheet]);

    return {add, loading};
};

export const useSheetUpdate = () => {
    const updateSheet = useSheetStore(state => state.upd);

    const {update: backendUpdate, loading} = useApiUpdate();
    const updateLocalSheet = useLocalStore(store => store.upd);

    const update = useCallback(async (sheet) => {
        let modifiedSheet;
        const mergedSheet = {
            ...sheet,
            modified: new Date(),
        };

        if (sheet.local) {
            console.debug('update locally');
            updateLocalSheet(mergedSheet);
            modifiedSheet = mergedSheet;
        } else {
            console.debug('update backend');
            modifiedSheet = await backendUpdate(mergedSheet);
            console.debug('update backend done');
        }

        if (modifiedSheet) {
            updateSheet(modifiedSheet);
        }

        return modifiedSheet;
    }, [backendUpdate, updateLocalSheet, updateSheet]);

    return {update, loading};
};

export const useSheetDelete = () => {
    const deleteSheet = useSheetStore(state => state.del);

    const {del: backendDelete, loading} = useApiDelete();
    const deleteLocalSheet = useLocalStore(store => store.del);

    const del = useCallback(async (sheet) => {
        if (sheet.local) {
            console.debug('delete locally', sheet.id);
            deleteLocalSheet(sheet.id);
            storage.removeItem(`sheet_${sheet.id}`);
        } else {
            console.debug('delete backend', sheet.id);
            await backendDelete(sheet.id);
        }

        deleteSheet(sheet.id);

        // do not clear or set dirty to false, because the active sheet can not be deleted

    }, [deleteSheet, deleteLocalSheet, backendDelete]);

    return {del, loading};
};

export const useSheetSort = () => {
    const updateSheet = useSheetStore(state => state.upd);
    const sheets = useSheetStore(state => state.sheets);

    const {sort: backendSort, loading} = useApiSort();
    const updateLocalSheet = useLocalStore(store => store.upd);

    const sort = useCallback(async (sheet, direction) => {

        const sortedSheets = sheets.filter(sht => sht.group === sheet.group).sort((a, b) => {
            return direction === 'down' ? a.sort - b.sort : b.sort - a.sort;
        });

        // we do not need consecutive sort numbers, gaps are ok, so we do not need to resort after deleting an element
        const neighbourElement = sortedSheets.find(sht => {
            return direction === 'down' ? sht.sort > sheet.sort : sht.sort < sheet.sort;
        });

        // already at top/bottom
        if (!neighbourElement) {
            return;
        }

        const me = {...sheet, sort: neighbourElement.sort};
        const neigh = {...neighbourElement, sort: sheet.sort};

        if (sheet.local) {
            console.debug('sort locally');
            updateLocalSheet(me);
            updateLocalSheet(neigh);
        } else {
            console.debug('sort backend');
            await backendSort(sheet.id, direction === 'down' ? 1 : -1);
        }

        updateSheet(me);
        updateSheet(neigh);

    }, [sheets, updateSheet, updateLocalSheet, backendSort]);

    return {sort, loading};
};