import PropTypes from 'prop-types';

import {fabric} from 'fabric';

import {limitReached} from '../canvas-helper';

const Line = function (props) {
    const {canvas, stroke, width, dashed} = props;

    let isDrawing = false;
    let line = null;
    let arrow = null;

    canvas.on('mouse:down', (o) => {
        if (limitReached(canvas)) {
            return;
        }

        // don't draw if an object is selected on the canvas
        if (canvas.getActiveObject()) {
            return;
        }

        isDrawing = true;

        let pointer = canvas.getPointer(o.e);
        let points = [pointer.x, pointer.y, pointer.x, pointer.y];
        line = new fabric.Line(points, {
            custom: {
                type: 'line'
            },
            strokeWidth: width,
            fill: 'black',
            strokeDashArray: dashed ? [15, 15] : null,
            stroke: stroke,
            originX: 'center',
            originY: 'center',
            selectable: false,
            hasBorders: false,
            hasControls: false,
        });

        canvas.add(line);
    });

    canvas.on('mouse:move', (o) => {
        if (!isDrawing) {
            return;
        }

        const pointer = canvas.getPointer(o.e);

        const left = pointer.x;
        const top = pointer.y;

        line.set({x2: left, y2: top});

        const angle = calcArrowAngle(line.get('x1'), line.get('y1'), left, top) + 90;

        if (arrow === null) {
            arrow = new fabric.Triangle({
                left: left,
                top: top,
                originX: 'center',
                originY: 'center',
                hasBorders: false,
                hasControls: false,
                lockScalingX: true,
                lockScalingY: true,
                lockRotation: true,
                angle: angle,
                width: 30,
                height: 30,
                fill: stroke,
                stroke: stroke,
            });
            canvas.add(arrow);
        } else {
            arrow.angle = angle;
            arrow.left = left;
            arrow.top = top;
        }

        canvas.renderAll();
    });

    canvas.on('mouse:up', () => {
        isDrawing = false;

        if (line && arrow) {
            canvas.remove(line).remove(arrow);
            const group = new fabric.Group([line, arrow], {
                hasBorders: false,
                hasControls: false
            });
            canvas.add(group);
            canvas.sendToBack(group);
            canvas.renderAll();
            line = null;
            arrow = null;
        }
    });

};

Line.propTypes = {
    canvas: PropTypes.object.isRequired,
    stroke: PropTypes.string,
    width: PropTypes.number
};

export default Line;

export function calcArrowAngle(x1, y1, x2, y2) {
    let angle, x, y;

    x = (x2 - x1);
    y = (y2 - y1);

    if (x === 0) {
        angle = (y === 0) ? 0 : (y > 0) ? Math.PI / 2 : Math.PI * 3 / 2;
    } else if (y === 0) {
        angle = (x > 0) ? 0 : Math.PI;
    } else {
        angle = (x < 0) ? Math.atan(y / x) + Math.PI : (y < 0) ? Math.atan(y / x) + (2 * Math.PI) : Math.atan(y / x);
    }

    return (angle * 180 / Math.PI);
}
