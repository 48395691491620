import {useCallback} from 'react';

import {isCancel, isOk, useFetcher} from 'fetcher';
import {getEnv} from 'lib/env';

const CONTROL_URL = getEnv('CONTROL_URL');

export const useApiSave = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'user/sheet/{id}/canvas/', {
        method: 'POST',
    }, undefined, true);

    const save = useCallback(async (sheetId, data) => {
        const payload = JSON.stringify(data);

        const headers = {
            'Content-Type': 'application/json'
        };

        let buf;

        if (window.CompressionStream) {
            const stream = new Blob([payload], {
                type: 'application/json',
            }).stream();
            
            const compressedReadableStream = stream.pipeThrough(new CompressionStream('gzip'));

            const resp = new Response(compressedReadableStream);
            headers['Content-Encoding'] = 'gzip';
            buf = await resp.arrayBuffer();
        } else {
            buf = payload;
        }

        const res = await fetch({
            headers,
            urlParams: {
                id: sheetId,
            },
            data: buf
        });

        if (isOk(res)) {
            return true;
        }
    }, [fetch]);

    return {save, loading};
};

export const useApiLoad = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + 'user/sheet/{id}/canvas', {
        method: 'GET',
    });

    const load = useCallback(async (sheetId) => {
        const res = await fetch({
            urlParams: {
                id: sheetId,
            },
            // axios by default decompresses, add this just for the docs, that we are getting gzip'd data
            decompress: true
        });

        if (isCancel(res)) {
            return null;
        }

        if (isOk(res) && typeof res.data === 'object') {
            return res.data;
        }

        if (!res || res.status === 404) {
            return null;
        }

        throw new Error(res.error?.message, {cause: res});
    }, [fetch]);

    return {load, loading};
};
