import {useCallback, useEffect, useState} from 'react';

import {getAnalytics, isSupported, logEvent} from 'firebase/analytics';
import {initializeApp} from 'firebase/app';

import {useInfoStore} from '../store';

export const firebaseConfig = {
    apiKey: 'AIzaSyDj_9-BDSOLzbjNdf8c624s82zsodg-S6k',
    authDomain: 'tactix-d2939.firebaseapp.com',
    projectId: 'tactix-d2939',
    storageBucket: 'tactix-d2939.appspot.com',
    messagingSenderId: '1008960472667',
    appId: '1:1008960472667:web:3bcd63cd0822fb48b6617e',
    measurementId: 'G-L7JEPR0K66',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const useAnalytics = () => {
    const deviceId = useInfoStore((store) => store.deviceId);
    const [analyticsSupport, setAnalyticsSupport] = useState(false);

    useEffect(() => {
        const checkFunc = async () => {
            const res = await isSupported();

            if (res) {
                setAnalyticsSupport(true);
            }
        };

        checkFunc();
    }, []);

    return useCallback((event, type, opts) => {
        if (!analyticsSupport || !deviceId) {
            return;
        }

        logEvent(analytics, event, {
            device: deviceId,
            type: type,
            ...opts,
        });
    },[deviceId, analyticsSupport]);
};

export default useAnalytics;
