import {useCallback} from 'react';

import {useGroupAdd} from './module/group/useGroup';
import {useGroupStore} from './module/group/zustand';
import {useSheetStore} from './module/sheet';
import {useSheetAdd,} from './module/sheet/useSheet';
import {useLayoutStore} from './store';

export const useInit = () => {
    const {add: addGroup} = useGroupAdd();
    const {add: addSheet} = useSheetAdd();

    const setShowFieldSelector = useLayoutStore((state) => state.setShowFieldSelector);

    const setSelectedSheet = useSheetStore(state => state.setSelected);
    const setSelectedGroup = useGroupStore(state => state.setSelected);

    const initData = useCallback(async (groups, sheets) => {
        console.log('init data', groups.length, sheets.length);

        let sheet;

        if (sheets.length === 0) {
            let group;

            if (groups.length === 0) {
                group = await addGroup({name: 'Demo'});
                if (!group) {
                    return;
                }

            } else {
                group = groups[0];
            }

            sheet = await addSheet({name: 'Demo', group: group.id}, true);
            if (!sheet) {
                return;
            }

            setShowFieldSelector(true);
        } else {
            sheet = sheets[0];
        }

        if (!sheet) {
            console.log('warning, no sheet available');
            return;
        }

        // make sure we have a selected sheet/group after startup, because these stores are not persistant anymore
        setSelectedSheet(sheet.id);
        setSelectedGroup(sheet.group);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addGroup, addSheet]);

    return {initData};
};
