import {DateTime} from 'luxon';

export const dateFormat = (date, fmt, options = {}) => {
    if (date === undefined) {
        return '';
    }

    let d;

    if (date === null) {
        d = DateTime.utc();
    } else if (date instanceof Date) {
        d = DateTime.fromJSDate(date);
    } else {
        d = DateTime.fromISO(date, {zone: 'utc'});
    }

    if (typeof fmt === 'object') {
        return d.toLocaleString(fmt);
    } else {
        return d.toLocal().toFormat(fmt, options);
    }
};

export const dateFormatFromNow = (date, options = {}) => {
    if (date === undefined) {
        return '';
    }

    const d = DateTime.fromISO(date, {zone: 'utc'});
    return d.toRelative(options);
};
