import * as React from "react";
const SvgAmericanfootball = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Ebene_1", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:svg": "http://www.w3.org/2000/svg", "xmlns:rdf": "http://www.w3.org/1999/02/22-rdf-syntax-ns#", "xmlns:cc": "http://creativecommons.org/ns#", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", "xmlns:dc": "http://purl.org/dc/elements/1.1/", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 900 1345.9", width: 900, height: 1346, style: {
  enableBackground: "new 0 0 900 1345.9"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#418733"
}, points: "0,101.8 0,228.7 0,355.7 0,482.6 0,609.5 0,736.4 0,863.3 0,990.2 0,1117.2 0,1244.1  0,1345.9 900,1345.9 900,1244.1 900,1117.2 900,990.2 900,863.3 900,736.4 900,609.5 900,482.6 900,355.7 900,228.7 900,101.8  900,0 0,0  " })), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "42,143.4 42,148.1 42,152.8 42,157.6 42,162.3 42,167 42,171.8 42,176.5 42,181.2  42,185.9 42,189.6 858,189.6 858,185.9 858,181.2 858,176.5 858,171.8 858,167 858,162.3 858,157.6 858,152.8 858,148.1  858,143.4 858,139.7 42,139.7  " })), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "42,252.6 42,257.3 42,262 42,266.7 42,271.5 42,276.2 42,280.9 42,285.6 42,290.4  42,295.1 42,298.8 858,298.8 858,295.1 858,290.4 858,285.6 858,280.9 858,276.2 858,271.5 858,266.7 858,262 858,257.3  858,252.6 858,248.9 42,248.9  " })), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "42,357.6 42,362.3 42,367 42,371.8 42,376.5 42,381.2 42,385.9 42,390.7 42,395.4  42,400.1 42,403.8 858,403.8 858,400.1 858,395.4 858,390.7 858,385.9 858,381.2 858,376.5 858,371.8 858,367 858,362.3  858,357.6 858,353.9 42,353.9  " })), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "42,465.1 42,469.8 42,474.5 42,479.3 42,484 42,488.7 42,493.4 42,498.2 42,502.9  42,507.6 42,511.3 858,511.3 858,507.6 858,502.9 858,498.2 858,493.4 858,488.7 858,484 858,479.3 858,474.5 858,469.8  858,465.1 858,461.4 42,461.4  " })), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "42,572.6 42,577.3 42,582 42,586.8 42,591.5 42,596.2 42,600.9 42,605.7 42,610.4  42,615.1 42,618.8 858,618.8 858,615.1 858,610.4 858,605.7 858,600.9 858,596.2 858,591.5 858,586.8 858,582 858,577.3  858,572.6 858,568.9 42,568.9  " })), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "42,678.4 42,683.2 42,687.9 42,692.6 42,697.3 42,702.1 42,706.8 42,711.5 42,716.2  42,721 42,724.7 858,724.7 858,721 858,716.2 858,711.5 858,706.8 858,702.1 858,697.3 858,692.6 858,687.9 858,683.2 858,678.4  858,674.7 42,674.7  " })), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "42,785.1 42,789.8 42,794.6 42,799.3 42,804 42,808.7 42,813.5 42,818.2 42,822.9  42,827.6 42,831.3 858,831.3 858,827.6 858,822.9 858,818.2 858,813.5 858,808.7 858,804 858,799.3 858,794.6 858,789.8  858,785.1 858,781.4 42,781.4  " })), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "42,892.6 42,897.3 42,902.1 42,906.8 42,911.5 42,916.2 42,921 42,925.7 42,930.4  42,935.1 42,938.8 858,938.8 858,935.1 858,930.4 858,925.7 858,921 858,916.2 858,911.5 858,906.8 858,902.1 858,897.3  858,892.6 858,888.9 42,888.9  " })), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "42,999.3 42,1004 42,1008.8 42,1013.5 42,1018.2 42,1022.9 42,1027.7 42,1032.4 42,1037.1  42,1041.8 42,1045.5 858,1045.5 858,1041.8 858,1037.1 858,1032.4 858,1027.7 858,1022.9 858,1018.2 858,1013.5 858,1008.8  858,1004 858,999.3 858,995.6 42,995.6  " })), /* @__PURE__ */ React.createElement("g", { style: {
  opacity: 0.2
} }, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "42,1106 42,1110.7 42,1115.4 42,1120.2 42,1124.9 42,1129.6 42,1134.3 42,1139.1  42,1143.8 42,1148.5 42,1152.2 858,1152.2 858,1148.5 858,1143.8 858,1139.1 858,1134.3 858,1129.6 858,1124.9 858,1120.2  858,1115.4 858,1110.7 858,1106 858,1102.3 42,1102.3  " })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { x: 42, y: 1099.2, style: {
  fill: "#FFFFFF"
}, width: 816, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 1152.7, style: {
  fill: "#FFFFFF"
}, width: 816, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 1045.7, style: {
  fill: "#FFFFFF"
}, width: 816, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 938.7, style: {
  fill: "#FFFFFF"
}, width: 816, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 831.7, style: {
  fill: "#FFFFFF"
}, width: 816, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 724.7, style: {
  fill: "#FFFFFF"
}, width: 816, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 617.7, style: {
  fill: "#FFFFFF"
}, width: 816, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 510.7, style: {
  fill: "#FFFFFF"
}, width: 816, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 403.6, style: {
  fill: "#FFFFFF"
}, width: 816, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 296.6, style: {
  fill: "#FFFFFF"
}, width: 816, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 189.6, style: {
  fill: "#FFFFFF"
}, width: 816, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 992.2, style: {
  fill: "#FFFFFF"
}, width: 816, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 885.2, style: {
  fill: "#FFFFFF"
}, width: 816, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 778.2, style: {
  fill: "#FFFFFF"
}, width: 816, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 671.2, style: {
  fill: "#FFFFFF"
}, width: 816, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 564.2, style: {
  fill: "#FFFFFF"
}, width: 816, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 457.2, style: {
  fill: "#FFFFFF"
}, width: 816, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 350.1, style: {
  fill: "#FFFFFF"
}, width: 816, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 243.1, style: {
  fill: "#FFFFFF"
}, width: 816, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 1195.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 1184.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 1174.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 1163.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 1142, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 1131.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 1120.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 1109.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 1088.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 1077.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 1067.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 1056.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 1035, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 1024.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 1013.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 1002.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 981.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 970.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 960.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 949.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 928, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 917.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 906.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 895.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 874.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 863.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 853.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 842.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 821, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 810.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 799.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 788.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 767.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 756.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 746.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 735.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 714, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 703.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 692.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 681.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 660.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 649.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 639.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 628.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 607, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 596.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 585.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 574.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 553.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 542.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 532.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 521.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 500, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 489.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 478.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 467.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 446.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 435.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 425.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 414.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 393, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 382.2, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 371.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 360.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 339.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 328.7, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 318, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 307.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 285.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 275.2, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 264.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 253.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 232.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 221.7, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 211, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 200.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 178.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 168.2, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 157.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 42, y: 146.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 1195.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 1184.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 1174.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 1163.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 1142, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 1131.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 1120.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 1109.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 1088.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 1077.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 1067.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 1056.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 1035, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 1024.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 1013.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 1002.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 981.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 970.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 960.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 949.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 928, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 917.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 906.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 895.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 874.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 863.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 853.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 842.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 821, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 810.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 799.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 788.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 767.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 756.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 746.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 735.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 714, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 703.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 692.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 681.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 660.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 649.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 639.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 628.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 607, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 596.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 585.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 574.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 553.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 542.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 532.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 521.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 500, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 489.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 478.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 467.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 446.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 435.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 425.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 414.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 393, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 382.2, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 371.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 360.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 339.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 328.7, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 318, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 307.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 285.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 275.2, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 264.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 253.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 232.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 221.7, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 211, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 200.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 178.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 168.2, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 157.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 846, y: 146.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 1195.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 1184.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 1174.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 1163.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 1142, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 1131.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 1120.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 1109.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 1088.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 1077.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 1067.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 1056.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 1035, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 1024.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 1013.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 1002.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 981.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 970.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 960.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 949.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 928, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 917.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 906.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 895.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 874.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 863.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 853.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 842.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 821, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 810.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 799.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 788.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 767.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 756.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 746.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 735.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 714, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 703.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 692.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 681.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 660.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 649.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 639.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 628.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 607, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 596.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 585.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 574.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 553.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 542.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 532.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 521.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 500, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 489.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 478.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 467.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 446.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 435.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 425.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 414.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 393, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 382.2, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 371.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 360.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 339.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 328.7, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 318, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 307.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 285.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 275.2, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 264.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 253.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 232.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 221.7, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 211, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 200.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 178.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 168.2, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 157.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 648.7, y: 146.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 1195.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 1184.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 1174.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 1163.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 1142, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 1131.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 1120.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 1109.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 1088.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 1077.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 1067.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 1056.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 1035, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 1024.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 1013.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 1002.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 981.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 970.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 960.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 949.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 928, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 917.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 906.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 895.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 874.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 863.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 853.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 842.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 821, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 810.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 799.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 788.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 767.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 756.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 746.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 735.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 714, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 703.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 692.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 681.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 660.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 649.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 639.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 628.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 607, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 596.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 585.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 574.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 553.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 542.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 532.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 521.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 500, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 489.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 478.6, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 467.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 446.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 435.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 425.1, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 414.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 393, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 382.2, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 371.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 360.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 339.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 328.7, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 318, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 307.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 285.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 275.2, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 264.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 253.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 232.4, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 221.7, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 211, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 200.3, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 178.9, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 168.2, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 157.5, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 239.3, y: 146.8, style: {
  fill: "#FFFFFF"
}, width: 12, height: 3.6 }), /* @__PURE__ */ React.createElement("rect", { x: 439.6, y: 1184.8, style: {
  fill: "#FFFFFF"
}, width: 20.8, height: 3.6 }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M285.3,20.5h-3.6v7.3H28.2v1290.4h253.5v7.3h3.6v-7.3h329.5v7.3h3.6v-7.3h253.5V27.8H618.3v-7.3 h-3.6v7.3H285.3V20.5z M34.4,139.7h831.1v1066.5H34.4V139.7z M618.3,1311.9v-7.3h-3.6v7.3H285.3v-7.3h-3.6v7.3H34.4v-102.1h831.1 v102.1H618.3z M614.7,34v7.3h3.6V34h247.3v102.1H34.4V34h247.3v7.3h3.6V34H614.7z" }), /* @__PURE__ */ React.createElement("rect", { x: 439.6, y: 157.5, style: {
  fill: "#FFFFFF"
}, width: 20.8, height: 3.6 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M760.1,1109h5.5v18.5h-5.5v-5.8H749v5.8h-4.1c-0.2-3.3-2-7.7-3.9-10.5v-2.4h19.1V1109z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M753.3,1074.5c7.6,0,12.7,2.3,12.7,10.4s-5.1,10.4-12.7,10.4c-7.6,0-12.7-2.3-12.7-10.4 S745.7,1074.5,753.3,1074.5z M751.5,1088.3h3.5c4.3,0,6.1-0.9,6.1-3.4s-1.8-3.4-6.1-3.4h-3.5c-4.3,0-6.1,0.9-6.1,3.4 C745.5,1087.4,747.2,1088.3,751.5,1088.3z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M755.3,803.1V796h-5.1c-1.7-1.2-5.2-3.2-7.2-3.7v-3h12.3v-3.4h5.2v3.4h5v6.8h-5V808h-5.3 c-3.4-2.3-9-5.2-14.6-6.8v-7C744.5,794.7,751.9,799.7,755.3,803.1z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M753.3,752c7.6,0,12.7,2.3,12.7,10.4c0,8.1-5.1,10.4-12.7,10.4c-7.6,0-12.7-2.3-12.7-10.4 C740.6,754.2,745.7,752,753.3,752z M751.5,765.8h3.5c4.3,0,6.1-0.9,6.1-3.4s-1.8-3.4-6.1-3.4h-3.5c-4.3,0-6.1,0.9-6.1,3.4 C745.5,764.9,747.2,765.8,751.5,765.8z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M747.3,894.6c2.5,0,4.6,1.5,5.4,3.6h0.1c0.9-2.7,3.1-4.3,6.1-4.3c3.9,0.2,7.1,3.3,7.1,10.3 c0,7.2-3.2,10.4-7.1,10.4h-0.7v-6.3c1.6,0,2.9-0.9,2.9-3.7c0-2.7-1.4-3.7-3.3-3.7c-1.7,0-2.6,1.1-2.6,2.5v2.6h-4.4v-2 c0-1.4-0.8-2.5-2.6-2.5c-1.5,0-2.8,1.1-2.8,3.1c0,2.1,1.2,3.2,2.7,3.2v6h-0.9c-3.7,0-6.7-3.5-6.7-9.8 C740.6,898,743.7,894.6,747.3,894.6z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M753.3,859.3c7.6,0,12.7,2.3,12.7,10.4c0,8.1-5.1,10.4-12.7,10.4c-7.6,0-12.7-2.3-12.7-10.4 S745.7,859.3,753.3,859.3z M751.5,873.1h3.5c4.3,0,6.1-0.9,6.1-3.4s-1.8-3.4-6.1-3.4h-3.5c-4.3,0-6.1,0.9-6.1,3.4 C745.4,872.2,747.2,873.1,751.5,873.1z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M748,1001.5c5.3,0,7.9,5.4,11.6,10.4v-10.7h6v20.3h-1.7c-2.7,0-4.6-1.4-6.3-3.4 c-5-5.6-6.9-9.6-9.3-9.6c-1.4,0-2.6,0.9-2.6,3.1c0,2.2,1.4,3.5,3.4,3.5h0.7v6.3c-0.3,0-0.5,0-0.8,0c-5,0-8.3-3.5-8.3-10.5 C740.6,1005.1,743.6,1001.5,748,1001.5z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M753.3,966.4c7.6,0,12.7,2.3,12.7,10.4c0,8.1-5.1,10.4-12.7,10.4c-7.6,0-12.7-2.3-12.7-10.4 S745.7,966.4,753.3,966.4z M751.5,980.3h3.5c4.3,0,6.1-0.9,6.1-3.4s-1.8-3.4-6.1-3.4h-3.5c-4.3,0-6.1,0.9-6.1,3.4 C745.5,979.3,747.2,980.3,751.5,980.3z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M747.1,693.2l3.7,0.3c0,0-1.6-1.9-1.6-5.2c0-5.2,3.1-8.5,8.3-8.5c4.7,0,8.4,3.9,8.4,10.1 c0,7.1-3.4,10.8-8.3,10.8v-6.3c1.9,0,3.4-1.3,3.4-3.8s-1.6-3.8-3.6-3.8c-2.1,0-3.7,1.3-3.7,3.7s1.3,3,1.8,3.4l-0.8,5.6 l-13.7-1v-17h6.1L747.1,693.2L747.1,693.2z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M753.3,645.2c7.6,0,12.7,2.3,12.7,10.4c0,8.1-5.1,10.4-12.7,10.4c-7.6,0-12.7-2.3-12.7-10.4 S745.7,645.2,753.3,645.2z M751.5,659.1h3.5c4.3,0,6.1-0.9,6.1-3.4s-1.8-3.4-6.1-3.4h-3.5c-4.3,0-6.1,0.9-6.1,3.4 C745.5,658.1,747.2,659.1,751.5,659.1z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M755.3,589.1V582h-5.1c-1.7-1.2-5.2-3.2-7.2-3.7v-3h12.3v-3.4h5.2v3.4h5v6.8h-5V594h-5.3 c-3.4-2.3-9-5.2-14.6-6.8v-7C744.5,580.6,751.9,585.7,755.3,589.1z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M753.3,537.9c7.6,0,12.7,2.3,12.7,10.4s-5.1,10.4-12.7,10.4c-7.6,0-12.7-2.3-12.7-10.4 S745.7,537.9,753.3,537.9z M751.5,551.8h3.5c4.3,0,6.1-0.9,6.1-3.4s-1.8-3.4-6.1-3.4h-3.5c-4.3,0-6.1,0.9-6.1,3.4 C745.5,550.9,747.2,551.8,751.5,551.8z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M760.1,252.9h5.5v18.5h-5.5v-5.8H749v5.8h-4.1c-0.2-3.3-2-7.7-3.8-10.5v-2.4h19.1v-5.6H760.1z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M753.3,218.4c7.6,0,12.7,2.3,12.7,10.4s-5.1,10.4-12.7,10.4c-7.6,0-12.7-2.3-12.7-10.4 S745.7,218.4,753.3,218.4z M751.5,232.2h3.5c4.3,0,6.1-0.9,6.1-3.4s-1.8-3.4-6.1-3.4h-3.5c-4.3,0-6.1,0.9-6.1,3.4 C745.5,231.3,747.2,232.2,751.5,232.2z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M748,359.4c5.3,0,7.9,5.4,11.6,10.4v-10.7h6v20.3h-1.7c-2.7,0-4.6-1.4-6.3-3.4 c-5-5.6-7-9.6-9.3-9.6c-1.4,0-2.6,0.9-2.6,3.1s1.4,3.5,3.4,3.5h0.7v6.3c-0.3,0-0.5,0-0.8,0c-5,0-8.3-3.5-8.3-10.5 C740.6,363.1,743.6,359.4,748,359.4z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M753.3,324.4c7.6,0,12.7,2.3,12.7,10.4s-5.1,10.4-12.7,10.4c-7.6,0-12.7-2.3-12.7-10.4 S745.7,324.4,753.3,324.4z M751.5,338.2h3.5c4.3,0,6.1-0.9,6.1-3.4s-1.8-3.4-6.1-3.4h-3.5c-4.3,0-6.1,0.9-6.1,3.4 C745.4,337.3,747.2,338.2,751.5,338.2z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M747.3,466.5c2.5,0,4.6,1.5,5.4,3.6h0.1c0.9-2.7,3.1-4.3,6.1-4.3c3.9,0.2,7.1,3.3,7.1,10.3 c0,7.2-3.2,10.4-7.1,10.4h-0.7v-6.3c1.6,0,2.9-0.9,2.9-3.7c0-2.7-1.4-3.7-3.3-3.7c-1.7,0-2.6,1.1-2.6,2.5v2.6h-4.4v-2 c0-1.4-0.8-2.5-2.6-2.5c-1.5,0-2.8,1.1-2.8,3.1c0,2.1,1.2,3.2,2.7,3.2v6h-0.9c-3.7,0-6.7-3.5-6.7-9.8 C740.6,469.9,743.7,466.5,747.3,466.5z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M753.3,431.3c7.6,0,12.7,2.3,12.7,10.4s-5.1,10.4-12.7,10.4c-7.6,0-12.7-2.3-12.7-10.4 C740.6,433.6,745.7,431.3,753.3,431.3z M751.5,445.1h3.5c4.3,0,6.1-0.9,6.1-3.4s-1.8-3.4-6.1-3.4h-3.5c-4.3,0-6.1,0.9-6.1,3.4 C745.5,444.2,747.2,445.1,751.5,445.1z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "753.3,1140.1 753.3,1140.1 756.4,1132.2 754.8,1132.2 751.8,1132.2 750.2,1132.2  " }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "753.3,1034 753.3,1034 756.4,1026.1 754.8,1026.1 751.8,1026.1 750.2,1026.1  " }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "753.3,927.2 753.3,927.2 756.4,919.3 754.8,919.3 751.8,919.3 750.2,919.3  " }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "753.3,820.6 753.3,820.6 756.4,812.6 754.8,812.6 751.8,812.6 750.2,812.6  " }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "753.3,525.3 753.3,525.3 750.2,533.3 751.8,533.3 754.8,533.3 756.4,533.3  " }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "753.3,418.7 753.3,418.7 750.2,426.6 751.8,426.6 754.8,426.6 756.4,426.6  " }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "753.3,311.8 753.3,311.8 750.2,319.8 751.8,319.8 754.8,319.8 756.4,319.8  " }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "753.3,205.8 753.3,205.8 750.2,213.7 751.8,213.7 754.8,213.7 756.4,213.7  " })))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M139.9,236.9h-5.5v-18.5h5.5v5.8H151v-5.8h4.1c0.2,3.3,2,7.7,3.9,10.5v2.4h-19.1V236.9z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M146.7,271.5c-7.6,0-12.7-2.3-12.7-10.4s5.1-10.4,12.7-10.4s12.7,2.3,12.7,10.4 C159.4,269.2,154.3,271.5,146.7,271.5z M148.5,257.6H145c-4.3,0-6.1,0.9-6.1,3.4s1.8,3.4,6.1,3.4h3.5c4.3,0,6.1-0.9,6.1-3.4 C154.5,258.5,152.8,257.6,148.5,257.6z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M144.7,542.9v7.1h5.1c1.7,1.2,5.2,3.2,7.2,3.7v3h-12.3v3.4h-5.2v-3.4h-5v-6.8h5V538h5.3 c3.4,2.3,9,5.2,14.6,6.8v7C155.5,551.3,148.1,546.2,144.7,542.9z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M146.7,594c-7.6,0-12.7-2.3-12.7-10.4c0-8.1,5.1-10.4,12.7-10.4s12.7,2.3,12.7,10.4 S154.3,594,146.7,594z M148.5,580.1H145c-4.3,0-6.1,0.9-6.1,3.4s1.8,3.4,6.1,3.4h3.5c4.3,0,6.1-0.9,6.1-3.4 C154.5,581.1,152.8,580.1,148.5,580.1z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M152.7,451.4c-2.5,0-4.6-1.5-5.4-3.6h-0.1c-0.9,2.7-3.1,4.3-6.1,4.3c-3.9-0.2-7.1-3.3-7.1-10.3 c0-7.2,3.2-10.4,7.1-10.4h0.7v6.3c-1.6,0-2.9,0.9-2.9,3.7c0,2.7,1.4,3.7,3.3,3.7c1.7,0,2.6-1.1,2.6-2.5V440h4.4v2 c0,1.4,0.8,2.5,2.6,2.5c1.5,0,2.8-1.1,2.8-3.1c0-2.1-1.2-3.2-2.7-3.2v-6h0.9c3.7,0,6.7,3.5,6.7,9.8 C159.4,447.9,156.3,451.4,152.7,451.4z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M146.7,486.6c-7.6,0-12.7-2.3-12.7-10.4s5.1-10.4,12.7-10.4c7.6,0,12.7,2.3,12.7,10.4 C159.4,484.3,154.3,486.6,146.7,486.6z M148.5,472.8H145c-4.3,0-6.1,0.9-6.1,3.4s1.8,3.4,6.1,3.4h3.5c4.3,0,6.1-0.9,6.1-3.4 C154.6,473.7,152.8,472.8,148.5,472.8z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M152,344.5c-5.3,0-7.9-5.4-11.6-10.4v10.7h-6v-20.3h1.7c2.7,0,4.6,1.4,6.3,3.4 c5,5.6,6.9,9.6,9.3,9.6c1.4,0,2.6-0.9,2.6-3.1s-1.4-3.5-3.4-3.5h-0.7v-6.3c0.3,0,0.5,0,0.8,0c5,0,8.3,3.5,8.3,10.5 C159.4,340.8,156.4,344.5,152,344.5z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M146.7,379.5c-7.6,0-12.7-2.3-12.7-10.4s5.1-10.4,12.7-10.4s12.7,2.3,12.7,10.4 S154.3,379.5,146.7,379.5z M148.5,365.6H145c-4.3,0-6.1,0.9-6.1,3.4s1.8,3.4,6.1,3.4h3.5c4.3,0,6.1-0.9,6.1-3.4 C154.5,366.6,152.8,365.6,148.5,365.6z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M152.9,652.7l-3.7-0.3c0,0,1.6,1.9,1.6,5.2c0,5.2-3.1,8.5-8.3,8.5c-4.7,0-8.4-3.9-8.4-10.1 c0-7.1,3.4-10.8,8.3-10.8v6.3c-1.9,0-3.4,1.3-3.4,3.8s1.6,3.8,3.6,3.8c2.1,0,3.7-1.3,3.7-3.7s-1.3-3-1.8-3.4l0.8-5.6l13.7,1 v17h-6.1L152.9,652.7L152.9,652.7z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M146.7,700.7c-7.6,0-12.7-2.3-12.7-10.4c0-8.1,5.1-10.4,12.7-10.4s12.7,2.3,12.7,10.4 S154.3,700.7,146.7,700.7z M148.5,686.8H145c-4.3,0-6.1,0.9-6.1,3.4s1.8,3.4,6.1,3.4h3.5c4.3,0,6.1-0.9,6.1-3.4 C154.5,687.8,152.8,686.8,148.5,686.8z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M144.7,756.9v7.1h5.1c1.7,1.2,5.2,3.2,7.2,3.7v3h-12.3v3.4h-5.2v-3.4h-5v-6.8h5V752h5.3 c3.4,2.3,9,5.2,14.6,6.8v7C155.5,765.3,148.1,760.3,144.7,756.9z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M146.7,808c-7.6,0-12.7-2.3-12.7-10.4s5.1-10.4,12.7-10.4s12.7,2.3,12.7,10.4 S154.3,808,146.7,808z M148.5,794.1H145c-4.3,0-6.1,0.9-6.1,3.4s1.8,3.4,6.1,3.4h3.5c4.3,0,6.1-0.9,6.1-3.4 C154.5,795.1,152.8,794.1,148.5,794.1z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M139.9,1093h-5.5v-18.5h5.5v5.8H151v-5.8h4.1c0.2,3.3,2,7.7,3.8,10.5v2.4h-19.1L139.9,1093 L139.9,1093z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M146.7,1127.5c-7.6,0-12.7-2.3-12.7-10.4s5.1-10.4,12.7-10.4s12.7,2.3,12.7,10.4 S154.3,1127.5,146.7,1127.5z M148.5,1113.7H145c-4.3,0-6.1,0.9-6.1,3.4s1.8,3.4,6.1,3.4h3.5c4.3,0,6.1-0.9,6.1-3.4 S152.8,1113.7,148.5,1113.7z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M152,986.5c-5.3,0-7.9-5.4-11.6-10.4v10.7h-6v-20.3h1.7c2.7,0,4.6,1.4,6.3,3.4 c5,5.6,7,9.6,9.3,9.6c1.4,0,2.6-0.9,2.6-3.1c0-2.2-1.4-3.5-3.4-3.5h-0.7v-6.3c0.3,0,0.5,0,0.8,0c5,0,8.3,3.5,8.3,10.5 C159.4,982.9,156.4,986.5,152,986.5z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M146.7,1021.5c-7.6,0-12.7-2.3-12.7-10.4c0-8.1,5.1-10.4,12.7-10.4c7.6,0,12.7,2.3,12.7,10.4 S154.3,1021.5,146.7,1021.5z M148.5,1007.7H145c-4.3,0-6.1,0.9-6.1,3.4s1.8,3.4,6.1,3.4h3.5c4.3,0,6.1-0.9,6.1-3.4 C154.6,1008.6,152.8,1007.7,148.5,1007.7z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M152.7,879.4c-2.5,0-4.6-1.5-5.4-3.6h-0.1c-0.9,2.7-3.1,4.3-6.1,4.3c-3.9-0.2-7.1-3.3-7.1-10.3 c0-7.2,3.2-10.4,7.1-10.4h0.7v6.3c-1.6,0-2.9,0.9-2.9,3.7c0,2.7,1.4,3.7,3.3,3.7c1.7,0,2.6-1.1,2.6-2.5V868h4.4v2 c0,1.4,0.8,2.5,2.6,2.5c1.5,0,2.8-1.1,2.8-3.1c0-2.1-1.2-3.2-2.7-3.2v-6h0.9c3.7,0,6.7,3.5,6.7,9.8 C159.4,876,156.3,879.4,152.7,879.4z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#FFFFFF"
}, d: "M146.7,914.6c-7.6,0-12.7-2.3-12.7-10.4c0-8.1,5.1-10.4,12.7-10.4s12.7,2.3,12.7,10.4 C159.4,912.4,154.3,914.6,146.7,914.6z M148.5,900.8H145c-4.3,0-6.1,0.9-6.1,3.4s1.8,3.4,6.1,3.4h3.5c4.3,0,6.1-0.9,6.1-3.4 C154.5,901.7,152.8,900.8,148.5,900.8z" }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "146.7,205.8 146.7,205.8 143.6,213.7 145.2,213.7 148.2,213.7 149.8,213.7  " }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "146.7,311.9 146.7,311.9 143.6,319.8 145.2,319.8 148.2,319.8 149.8,319.8  " }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "146.7,418.7 146.7,418.7 143.6,426.7 145.2,426.7 148.2,426.7 149.8,426.7  " }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "146.7,525.4 146.7,525.4 143.6,533.3 145.2,533.3 148.2,533.3 149.8,533.3  " }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "146.7,820.6 146.7,820.6 149.8,812.6 148.2,812.6 145.2,812.6 143.6,812.6  " }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "146.7,927.2 146.7,927.2 149.8,919.3 148.2,919.3 145.2,919.3 143.6,919.3  " }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "146.7,1034.1 146.7,1034.1 149.8,1026.2 148.2,1026.2 145.2,1026.2 143.6,1026.2  " }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#FFFFFF"
}, points: "146.7,1140.1 146.7,1140.1 149.8,1132.2 148.2,1132.2 145.2,1132.2 143.6,1132.2  " }))))))));
export default SvgAmericanfootball;
