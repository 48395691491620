const storage = {
    length: localStorage.length,
    allKeys: () => Object.keys(localStorage),
    setItem: (k, v) => localStorage.setItem(k, v),
    getItem: (k) => localStorage.getItem(k),
    removeItem: (k) => localStorage.removeItem(k),
    clear: () => localStorage.clear()
};

export const loadWithPrefix = (prefix) => {
    let ret = [],
        keys = storage.allKeys(),
        i = keys.length;

    while (i--) {
        if (!keys[i].startsWith(prefix + '_')) {
            continue;
        }

        let o = storage.getItem(keys[i]);

        if (o) {
            ret.push(JSON.parse(o));
        }
    }

    return ret;
};

export default storage;
