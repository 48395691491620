import {useCallback} from 'react';

export const useLoadTeams = () => {
    const load = useCallback(async (_clientId) => {

        return [];

    }, []);

    return {
        loadTeams: load,
        loadingTeams: false,
    };
};

export const useLoadPlayers = () => {
    return useCallback(async (_teamId) => {
        return [];
    }, []);
};
