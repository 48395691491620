import {useCallback} from 'react';

import storage from 'store/localStorage';

const version = storage.getItem('version');

const useMigration2to3 = () => {

    // there is no structural change, just for information, that we made changes to the stores.
    // migration is done inside the stores directly
    return useCallback(() => {

        if (version >= 3) {
            console.log('Migration2to3: already migrated to version 3');
            return;
        }

        storage.setItem('version', 3);

        // do not re-render/generate, runs at startup only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useMigration2to3;
