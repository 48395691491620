import {useCallback} from 'react';

import {useApiSave as useCanvasApiSave} from 'canvas/useCanvasApi';
import {Modes} from 'lib/tactix';
import {useApiAdd as useGroupApiAdd} from 'module/group/useGroupApi';
import {useLocalStore as useLocalGroupStore} from 'module/group/useLocalStore';
import {useLocalStore as useLocalSheetStore} from 'module/sheet/useLocalStore';
import {useApiAdd as useSheetApiAdd} from 'module/sheet/useSheetApi';
import storage from 'store/localStorage';

import {produce} from 'immer';

const useMigrationToServer = (mode) => {

    const localGroups = useLocalGroupStore(state => state.groups);
    const deleteLocalGroup = useLocalGroupStore(state => state.del);
    const {add: addGroup} = useGroupApiAdd();

    const localSheets = useLocalSheetStore(state => state.sheets);
    const deleteLocalSheet = useLocalSheetStore(state => state.del);
    const {add: addSheet} = useSheetApiAdd();

    const {save: saveCanvas} = useCanvasApiSave();

    return useCallback(async () => {
        if (mode === Modes.MONITOR || storage.getItem('migrated')) {
            return;
        }

        console.log('migrating groups to server');

        for (const group of localGroups) {
            try {
                await addGroup(group);
                deleteLocalGroup(group.id);
            } catch (e) {
                if (e.cause?.status !== 409) {
                    throw(e);
                }
            }
        }

        console.log('migrating sheets to server');

        for (let sheet of localSheets) {
            console.log(sheet);
            try {
                // some sheets have invalid frame data, whereas frame is wrapped into a frame property
                if (Array.isArray(sheet?.frames) && sheet.frames.length > 0) {
                    if (!Array.isArray(sheet.frames[0]?.frame)) {
                        // sheet is an immutable, use immer to modify it
                        sheet = produce(sheet, draft => {
                            draft.frames.forEach(fr => {
                                fr.frame = fr.frame.frame;
                            });
                        });
                        console.log('sheet has invalid framedata, fixed', sheet);
                    }
                }

                if (!sheet.name) {
                    sheet = produce(sheet, draft => {
                        draft.name = 'Kein Titel';
                    });
                    console.log('sheet has no name, fixed');
                }

                try {
                    await addSheet(sheet);
                } catch (e) {
                    console.log(e.cause);
                    // this is a workaround for users with inconsistent data
                    if (e.cause?.error?.errors?.group === 'board.group.unknown') {
                        console.log(`group ${sheet.group} does not exists, adding`);
                        await addGroup({id: sheet.group, name: 'Demo'});
                        await addSheet(sheet);
                    } else {
                        throw(e);
                    }
                }

                deleteLocalSheet(sheet.id);

                const canvas = storage.getItem(`sheet_${sheet.id}`);

                if (!canvas) {
                    continue;
                }

                const canvasData = JSON.parse(canvas);
                await saveCanvas(sheet.id, canvasData);
                storage.removeItem(`sheet_${sheet.id}`);
            } catch (e) {
                if (e.cause?.status !== 409) {
                    throw(e);
                }
            }
        }

        storage.setItem('migrated', true);
    }, [mode, localGroups, addGroup, deleteLocalGroup, localSheets, addSheet, deleteLocalSheet, saveCanvas]);
};

export default useMigrationToServer;
