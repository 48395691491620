import React from 'react';

import {Box} from '@mui/system';

import {useInfoStore} from 'store';

import ReactQRCode from 'qrcode.react';

const qrCodePosMap = {
    topLeft: {top: 10, left: 10},
    topCenter: {top: 10, left: '50%', translate: '-64px 0'},
    topRight: {top: 10, right: 60},
    middleLeft: {top: '50%', left: 10, translate: '0 -64px'},
    middleCenter: {top: '50%', left: '50%', translate: '-64px -64px'},
    middleRight: {top: '50%', right: 10, translate: '0 -64px'},
    bottomLeft: {bottom: 40, left: 10},
    bottomCenter: {bottom: 10, left: '50%', translate: '-64px 0'},
    bottomRight: {bottom: 10, right: 10},
};

const styles = {
    qrcode: {
        position: 'absolute'
    }
};

const Tracking = ({banner}) => {
    let targetUrl = banner.target_url;
    const qrCodePos = banner.qrcode_position;
    const bannerName = banner.name;
    const bannerType = banner.type;
    const deviceId = useInfoStore(state => state.deviceId);
    const title = useInfoStore(state => state.title);

    const hasQrcode = targetUrl && qrCodePos;

    if (!hasQrcode) {
        return null;
    }

    targetUrl = encodeURIComponent(targetUrl);

    const trackingUrl = `https://analytics-collector.geomix-soccerboard.com/generic/collect/ga4?target_url=${targetUrl}&utm_source=${encodeURIComponent(title)}&utm_medium=${encodeURIComponent(bannerType)}&utm_campaign=${encodeURIComponent(bannerName)}&utm_content=${encodeURIComponent(deviceId)}`;

    return <Box sx={{...styles.qrcode, ...qrCodePosMap[qrCodePos]}}>
        <ReactQRCode value={trackingUrl} level={'H'}/>
    </Box>;
};

export default Tracking;
