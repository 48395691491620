import React, {useEffect} from 'react';

import {Box, Modal, Typography} from '@mui/material';

import BackgroundComponents from 'assets/backgroundComponents';
import {useAction} from 'canvas/canvas-actions';
import {useCanvasStore} from 'canvas/zustand';
import {Text} from 'components';
import {useAnalytics} from 'hooks';
import {useBannersStore} from 'module/banner';
import {useLayoutStore, usePrefsStore} from 'store';

const styles = {
    box: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {desktop: 'auto', sm: '80%', mobile: '90%'},
        height: 'auto',
        maxHeight: '95%',
        backgroundColor: 'grey.darkest',
        border: '0',
        borderRadius: 2,
        outline: 'none',
        p: 3,
        overflowY: 'auto',
        boxSizing: 'border-box',
    },
    innerBox: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    },
    toolBox: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '10px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        mb: 4,
    },
    titlesContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        mb: 2,
    },
    optionsTitle: {
        color: 'white.main',
        fontSize: {
            mobile: '14px',
            desktop: '22px',
        },
        ml: 1,
        fontWeight: '700',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        textTransform: 'uppercase',
    },
    optionsSubtitles: {
        color: 'grey.lightest',
        fontSize: {
            mobile: '14px',
            desktop: '22px',
        },
        ml: 1,
        fontWeight: '700',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        textTransform: 'uppercase',
    },
    field: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        WebkitUserSelect: 'none !important',
        opacity: 0.95,
        position: 'relative',
        padding: 0.25,
        transition: 'all 0.25s ease-in-out',
        backgroundColor: 'grey.light',
        width: '31.333333%',
        height: 'auto',
        margin: '1%',
    },
    fieldIcon: {
        width: '100%',
        height: '100%',
    }
};

const soccerFields = [{
    name: 'soccer',
    Icon: BackgroundComponents.Soccer
}, {
    name: 'soccerLandscape',
    Icon: BackgroundComponents.SoccerLandscape
}, {
    name: 'soccerThird',
    Icon: BackgroundComponents.SoccerThird
}, {
    name: 'soccerBW',
    Icon: BackgroundComponents.SoccerBW
}, {
    name: 'soccerLandscapeBW',
    Icon: BackgroundComponents.SoccerLandscapeBW
}, {
    name: 'soccerQuarterBW',
    Icon: BackgroundComponents.SoccerQuarterBW
}, {
    name: 'soccerThirdBW',
    Icon: BackgroundComponents.SoccerThirdBW
}, {
    name: 'soccerSquaresBW',
    Icon: BackgroundComponents.SoccerSquaresBW
}, {
    name: 'soccerVerticalZonesBW',
    Icon: BackgroundComponents.SoccerVerticalZonesBW
}, {
    name: 'fieldWhite',
    Icon: BackgroundComponents.FieldWhite
}];

const Field = ({name, Icon, onClick}) => {
    return <Box sx={styles.field} onClick={() => onClick(name)}>
        <Icon style={styles.fieldIcon}/>
    </Box>;
};

const FieldSelector = ({sheet}) => {
    const setBackground = usePrefsStore((state) => state.setBackground);
    const setShowFieldSelector = useLayoutStore((state) => state.setShowFieldSelector);

    const setDirty = useCanvasStore(state => state.setDirty);

    const bannersVisible = useBannersStore((store) => store.visible);

    const sendAnalytics = useAnalytics();
    const {dispatch} = useAction();

    const selectBackground = async (background) => {
        sendAnalytics('background', background);
        setBackground(background);
        setDirty(true);
        dispatch('push');
        setShowFieldSelector(false);
    };

    useEffect(() => {
        if (bannersVisible) {
            setShowFieldSelector(false);
        }
    }, [bannersVisible, setShowFieldSelector]);

    if (!sheet) {
        return null;
    }

    return <Modal open={true} disableEscapeKeyDown={true} hideBackdrop={true}>
        <Box sx={styles.box}>
            <Box sx={styles.innerBox}>
                <Box sx={styles.titlesContainer}>
                    <Typography sx={styles.optionsTitle}>
                        <Text>board.sheet.select_background</Text>
                    </Typography>
                    <Typography sx={styles.optionsSubtitles}>
                        <Text>global.soccer</Text>
                    </Typography>
                </Box>
                <Box sx={styles.toolBox}>
                    {soccerFields.map(f => <Field key={f.name} name={f.name} Icon={f.Icon} onClick={selectBackground}/>)}
                    {/*<Divider></Divider>*/}
                </Box>
            </Box>
            {/* <Box sx={styles.innerBox}>
                <Box sx={styles.titlesContainer}>
                    <Typography sx={styles.optionsTitle}>
                        <Text>board.sheet.select_background</Text>
                    </Typography>
                    <Typography sx={styles.optionsSubtitles}>
                        <Text>board.sheet.sports</Text>
                    </Typography>
                </Box>

                <Box sx={styles.toolBox}>

                    <Field
                        name="basketball"
                        Icon={BackgroundComponents.Basketball}
                        onClick={selectBackground}
                    />

                    <Field
                        name="handball"
                        Icon={BackgroundComponents.Handball}
                        onClick={selectBackground}
                    />
                    <Field
                        name="icehockey"
                        Icon={BackgroundComponents.Icehockey}
                        onClick={selectBackground}
                    />
                    <Field
                        name="americanFootball"
                        Icon={BackgroundComponents.AmericanFootball}
                        iconColor={'white.main'}
                    />

                    <Field
                        name="clear"
                        Icon={BackgroundComponents.FieldWhite}
                        onClick={selectBackground}
                    />
                </Box>
            </Box> */}
        </Box>
    </Modal>;
};

export default FieldSelector;
