import React, {useEffect, useState} from 'react';

import {
    MenuItem,
    Stack,
} from '@mui/material';

import {yupResolver} from '@hookform/resolvers/yup';

import {useLayoutStore, useSnackbarStore} from 'store';

import {schema, sizes, useApiUpdate} from './useUserApi';

import {Form, FormField, SelectField, SubmitableField, useForm} from 'form';

const styles = {
    root: {
        background: theme => theme.palette.grey.darkest,
    },
};

const Equipment = ({user, onSave}) => {
    const [editable, setEditable] = useState('');

    const {update, loading, data, errors} = useApiUpdate(user);

    const setShowLoading = useLayoutStore((state) => state.setShowLoading);
    const showInfo = useSnackbarStore((state) => state.show);

    const rhf = useForm(data, errors, true, {resolver: yupResolver(schema)});

    const doSave = () => {
        rhf.methods.handleSubmit(async formData => {
            try {
                const res = await update(formData);
                setEditable(null);
                showInfo('user.action.save_success');
                if (res !== undefined) {
                    onSave(res);
                }
            } catch (e) {
                showInfo(e.message, {severity: 'warning'});
            }
        })();
    };

    useEffect(() => {
        setShowLoading(loading);
        return () => {
            setShowLoading(false);
        };
    }, [loading, setShowLoading]);

    return (
        <Stack sx={styles.root} direction="column" justifyContent="space-between">
            <Form methods={rhf.methods} onSubmit={doSave}>
                <Stack direction="column" justifyContent="flex-end" p={2}>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <FormField
                            name="size.shoe"
                            label="user.size.shoe"
                            type="number"
                        />
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <SelectField label="user.size.shirt" name="size.shirt">
                            {sizes.map(sz => <MenuItem key={sz} value={sz}>{sz}</MenuItem>)}
                        </SelectField>
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <SelectField label="user.size.pants" name="size.pants">
                            {sizes.map(sz => <MenuItem key={sz} value={sz}>{sz}</MenuItem>)}
                        </SelectField>
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <SelectField label="user.size.head" name="size.head">
                            {sizes.map(sz => <MenuItem key={sz} value={sz}>{sz}</MenuItem>)}
                        </SelectField>
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <SelectField label="user.size.jacket" name="size.jacket">
                            {sizes.map(sz => <MenuItem key={sz} value={sz}>{sz}</MenuItem>)}
                        </SelectField>
                    </SubmitableField>
                </Stack>
            </Form>
        </Stack>
    );
};

export default Equipment;