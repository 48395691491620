import React, {useEffect, useState} from 'react';

import {
    Check as CheckIcon,
    Delete as DeleteIcon
} from '@mui/icons-material';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Drawer as MuiDrawer,
    List,
    Typography
} from '@mui/material';

import {MenuHeader} from 'components';
import {ConfirmDialog, Text, useConfirmDialog} from 'components';
import {useTranslation} from 'hooks';
import {useGroupDeleteAll} from 'module/group/useGroup';
import {hasSelftest, runSelftest, SelftestDialog} from 'module/selftest';
import {useUserUtils} from 'module/user/useUser';
import {useInfoStore, useLayoutStore} from 'store';

import {useInit} from '../useInit.js';

const styles = {
    drawer: {
        width: '100%',
        backgroundColor: 'black.light',
    },
    overlay: {
        padding: 0,
        width: '100%',
        zIndex: '999',
        animationName: 'slideToLeft',
        animationDuration: '0.1s',
        animationFillMode: 'forwards',
        backgroundColor: 'black.light',
    },
    container: {
        width: '100%',
        padding: '0 20px',
    },
    card: {
        width: '100%',
        padding: '0',
        borderBottom: '1px solid',
        borderBottomColor: 'grey.lighter',
        borderRadius: 0,
        backgroundColor: 'black.light',
    },
    cardLast: {
        borderBottom: 'none',
    },
    title: {
        width: '100%',
        padding: '20px 0 0 0',
    },
    content: {
        padding: '10px 0 20px 0',
    },
};

const InformationDialog = () => {
    const translate = useTranslation();

    const [enableSelftest, setEnableSelftest] = useState(false);
    const [selftestResults, setSelftestResults] = useState(false);
    const setShowInformationDialog = useLayoutStore(state => state.setShowInformationDialog);
    const showInformationDialog = useLayoutStore(state => state.showInformationDialog);
    const setShowTimeline = useLayoutStore((state) => state.setShowTimeline);
    const setShowHelp = useLayoutStore((state) => state.setShowHelp);

    const deviceId = useInfoStore(state => state.deviceId);
    const title = useInfoStore(state => state.title);

    const {isAuthenticated} = useUserUtils();

    const {initData} = useInit();

    const {deleteAll} = useGroupDeleteAll();

    const execSelftest = async () => {
        setSelftestResults(await runSelftest());
    };

    useEffect(() => {
        const checkTest = async () => {
            setEnableSelftest(await hasSelftest());
        };

        checkTest();

    }, []);

    const closeDialog = () => {
        setShowInformationDialog(false);
    };

    const closeAll = () => {
        setShowInformationDialog(false);
    };

    const deleteConfirm = useConfirmDialog(async () => {
        setShowTimeline(false);
        await deleteAll();
        initData([], []);
        setShowInformationDialog(false);
    }, undefined, translate('board.menu.action.confirm_delete_all'));

    return <MuiDrawer
        PaperProps={{sx: styles.drawer}}
        anchor={'left'}
        open={showInformationDialog}
        onClose={closeAll}
    >
        <List sx={styles.overlay}>
            <ConfirmDialog {...deleteConfirm}/>
            <SelftestDialog handleClose={() => setSelftestResults(false)} results={selftestResults}/>

            <MenuHeader text={<Text>board.menu.infos</Text>} handleBackButton={closeDialog} handleForwardButton={closeAll} />

            <Box sx={styles.container}>
                <Card sx={styles.card}>
                    <CardHeader
                        sx={styles.title} title={translate('board.menu.contact_club_order')}
                        titleTypographyProps={{variant: 'h2'}}/>
                    <CardContent sx={styles.content}>
                        <Typography>vereine@tactix-sports.com</Typography>
                        {/*<Typography>Telefonnummer</Typography>*/}
                        <Typography>www.tactix-sports.com</Typography>
                    </CardContent>
                </Card>

                <Card sx={styles.card}>
                    <CardHeader
                        sx={styles.title} title={translate('board.menu.contact_tactix')}
                        titleTypographyProps={{variant: 'h2'}}/>
                    <CardContent sx={styles.content}>
                        <Typography>ts@tactix-sports.com</Typography>
                        {/*<Typography>Telefonnummer</Typography>*/}
                        <Typography>www.tactix-sports.com</Typography>
                    </CardContent>
                </Card>

                {deviceId && <Card sx={styles.card}>
                    <CardHeader
                        sx={styles.title}
                        title={translate('board.menu.tactix')}
                        titleTypographyProps={{variant: 'h2'}}/>
                    <CardContent sx={styles.content}>
                        <Typography>{deviceId}</Typography>
                        <Typography>{title}</Typography>
                    </CardContent>
                </Card>}

                {enableSelftest && <Card sx={styles.card}>
                    <CardHeader
                        sx={styles.title} title={translate('board.menu.selftest')}
                        titleTypographyProps={{variant: 'h2'}}/>
                    <CardActions sx={styles.content}>
                        <Button
                            color={'black'}
                            data-testid="dialog-execute-btn"
                            variant={'contained'} onClick={execSelftest}
                            startIcon={<CheckIcon/>}>ausführen</Button>
                        <Button
                            color={'black'}
                            data-testid="dialog-help-btn"
                            variant={'contained'} onClick={() => setShowHelp(true)}
                            startIcon={<CheckIcon/>}><Text>board.menu.tutorials</Text></Button>
                    </CardActions>
                </Card>}

                {!isAuthenticated && <Card sx={[styles.card, styles.cardLast]}>
                    <CardHeader
                        sx={styles.title} title={translate('board.menu.system')}
                        titleTypographyProps={{variant: 'h2'}}/>
                    <CardActions sx={styles.content}>
                        <Button
                            data-testid="action-deleteAll-btn"
                            color={'error'} variant={'contained'} onClick={deleteConfirm.show}
                            startIcon={<DeleteIcon/>}><Text>ui.actions.delete_all</Text></Button>
                    </CardActions>
                </Card>}
            </Box>
        </List>
    </MuiDrawer>;
};

export default InformationDialog;
