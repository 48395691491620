import React, {useEffect, useMemo, useState} from 'react';

import {ArrowDownward as ArrowDownIcon, ArrowUpward as ArrowUpIcon} from '@mui/icons-material';
import {
    Avatar, Badge, Box, IconButton, List, ListItem, ListItemText, MenuItem, Select, Stack, Typography, useTheme
} from '@mui/material';

import {useShallow} from 'zustand/react/shallow';

import Icons from 'assets/icons';
import lineups from 'assets/lineups/home';
import {getPlayerById} from 'canvas/Drawer/player-utils';
import {useCanvasStore} from 'canvas/zustand';
import {useTranslation} from 'hooks';
import {imageUrl} from 'lib/media';
import {TeamSelector} from 'module/client/team/index';
import {DraggablePlayer} from 'module/client/team/member';
import {memberInfo} from 'module/client/team/member/useInfo';
import {groupByPosition, useApiQuery} from 'module/client/team/member/useMemberApi';
import {useDrawTeamLineup} from 'module/client/team/useLineup';
import {positions} from 'module/client/team/useTeamApi';
import {useSettingsStore} from 'module/settings/zustand';
import {useLayoutStore, usePrefsStore} from 'store';

const styles = {
    playerNumber: {
        borderRadius: '15px',
        padding: 0.5,
        backgroundColor: 'green.main'
    },
    playerDisabled: {
        opacity: 0.2,
    },
    lineupSelect: {
        minWidth: 100,
        paddingX: 1
    },
    teamHeader: {
        justifyContent: 'space-between', alignItems: 'center'
    }
};

const PlayerBadge = ({player, disabled}) => {
    const {initials} = memberInfo(player);

    return <Badge
        badgeContent={<Box sx={styles.playerNumber}>{player.number}</Box>}
        overlap="circular"
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
    >
        <Avatar
            sx={[disabled && styles.playerDisabled]}
            src={imageUrl(player.user?.image, {w: 56, h: 56})}>{initials}</Avatar>
    </Badge>;
};

export const Player = ({team, player}) => {
    const canvas = useCanvasStore(state => state.canvas);
    // watch for canvas size to trigger a re-rendering, otherwise disabled state will not be updated
    useCanvasStore(useShallow(state => state.canvas?.getObjects()));

    const disabled = !!getPlayerById(canvas, player.id);

    return <DraggablePlayer team={team} player={player} disabled={disabled}>
        <PlayerBadge player={player} disabled={disabled}/>
    </DraggablePlayer>;
};

const TeamLineup = ({team}) => {
    const theme = useTheme();

    const translate = useTranslation();

    const [lineup, setLineup] = useState('4-3-3');

    const setSelectedTeam = useSettingsStore(state => state.setSelectedTeam);

    const setShowLoading = useLayoutStore((state) => state.setShowLoading);
    const prefs = usePrefsStore((state) => state.prefs.player);

    const drawLineup = useDrawTeamLineup();

    const queryMembers = useApiQuery({teamId: team?.id});

    const doDrawLineup = (direction) => {
        drawLineup(queryMembers.data, team, lineup, direction, prefs.type, prefs.size);
    };

    const lineupChanged = (e) => {
        setLineup(e.target.value);
    };

    const players = useMemo(() => {
        return groupByPosition(queryMembers.data);
    }, [queryMembers.data]);

    const unsetSelectedTeam = () => {
        setSelectedTeam(null);
    };

    useEffect(() => {
        if (team) {
            setLineup(team.lineup);
        }
    }, [team]);

    useEffect(() => {
        setShowLoading(queryMembers.isFetching);
        return () => {
            setShowLoading(false);
        };
    }, [queryMembers.isFetching, setShowLoading]);

    if (!team) {
        return <TeamSelector/>;
    }

    const switchIconStyle = {fill: theme.palette.white.main};

    return <Stack direction="column" m={2} alignItems="center">
        <Stack direction="row" sx={styles.teamHeader}>
            <Typography>{team.name}</Typography>
            <IconButton onClick={unsetSelectedTeam}>
                <Icons.ArrowSwitch width={25} height={25} style={switchIconStyle}/>
            </IconButton>
        </Stack>
        <Box m={1}>
            <Select
                variant="standard"
                label={translate('team.lineup')}
                name="lineup"
                sx={styles.lineupSelect}
                fullWidth={true}
                value={lineup}
                onChange={lineupChanged}>
                {lineups.map(lineup => <MenuItem
                    key={lineup.name}
                    value={lineup.name}>{lineup.name}</MenuItem>)}
            </Select>
        </Box>
        <Stack direction="row" spacing={1} m={1}>
            <IconButton onClick={() => doDrawLineup('up')}><ArrowUpIcon color="primary"/></IconButton>
            <IconButton onClick={() => doDrawLineup('down')}><ArrowDownIcon color="primary"/></IconButton>
        </Stack>
        {positions.map(position => <List key={position} sx={styles.listHolder}>
            {players[position].length > 0 && <>
                <ListItem>
                    <ListItemText><Typography>{translate(`team.position.${position}`)}</Typography></ListItemText>
                </ListItem>
                <Stack direction="column" alignItems="center" gap={2}>
                    {players[position].map(player => <Player key={player.id} team={team} player={player}/>)}
                </Stack>
            </>}
        </List>)}
    </Stack>;
};

export default TeamLineup;
