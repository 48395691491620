import React, {forwardRef, useEffect, useState} from 'react';

import {
    ButtonBase,
    MenuItem,
    Slide, Stack
} from '@mui/material';
import {MuiColorInput} from 'mui-color-input';

import {yupResolver} from '@hookform/resolvers/yup';

import {sizeOptions} from 'canvas/Drawer/player-utils';
import {
    Dialog, HiddenFileInput, Text,
} from 'components';
import {useLayoutStore, useSnackbarStore} from 'store';

import {schema, useApiUpdate, useApiUpload} from './useTeamApi';

import {Form, FormField, SelectField, SubmitableField, useForm} from 'form';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

import Icons from 'assets/icons';
import lineups from 'assets/lineups/home';
import {imageUrl} from 'lib/media';
import UserSelectOnDemand from 'module/client/UserSelect';

const styles = {
    teamLogoHolder: {
        width: '100%',
        position: 'relative',
        '& img': {
            display: 'block',
            maxWidth: '100%',
            height: 'auto',
            maxHeight: '750px'
        },
    },
    absolutIconBox: {
        //position: 'absolute',
        right: '40px',
        bottom: '40px',
        backgroundColor: 'red.highlight',
        borderRadius: '50%',
        height: 36,
        width: 36,
    },
    absolutIconBoxWithoutImage: {
        backgroundColor: 'red.highlight',
        borderRadius: '50%',
        height: 60,
        width: 60,
        marginTop: '30px'
    },
    uploadButton: {
        backgroundColor: 'red.highlight',
        padding: 1,
        '> svg': {
            fill: theme => theme.palette.white.main,
            width: 18,
            height: 18
        }
    },
    uploadButtonWithoutImage: {
        backgroundColor: 'red.highlight',
        '> svg': {
            fill: theme => theme.palette.white.main,
            width: 30,
            height: 30
        }
    }
};

const Edit = ({team, onClose, onSave}) => {
    const [editable, setEditable] = useState('');

    const showInfo = useSnackbarStore((state) => state.show);

    const setShowLoading = useLayoutStore((state) => state.setShowLoading);

    const {update, loading: loading1, data, errors} = useApiUpdate(team);
    const {upload, loading: loading2} = useApiUpload();

    const rhf = useForm(data, errors, true, {resolver: yupResolver(schema)});

    const doSave = () => {
        rhf.methods.handleSubmit(async formData => {
            formData.id = team.id;

            try {
                const res = await update(formData);
                setEditable(null);
                showInfo('team.action.save_success');
                onSave && onSave(res);
            } catch (e) {
                showInfo(e.message, {severity: 'warning'});
            }
        })();
    };

    const doUpload = async (e) => {
        if (e.target.files.length === 1) {
            const res = await upload(team.id, e.target.files[0]);
            e.target.value = null;
            onSave && onSave(res);
        }
    };

    useEffect(() => {
        setShowLoading(loading1 || loading2);
        return () => {
            setShowLoading(false);
        };
    }, [loading1, loading2, setShowLoading]);

    return (
        <Dialog
            maxWidth={false}
            pt={0}
            open={true}
            TransitionComponent={Transition}
            title="team.action.edit"
            handleClose={onClose}
            dividers={true}
        >
            <Stack sx={styles.teamLogoHolder} justifyContent="center" alignItems="center">
                {team.image && <img src={imageUrl(team.image, {w: 300})} alt={team.name} />}
                <Stack sx={team.image ? styles.absolutIconBox : styles.absolutIconBoxWithoutImage} alignItems="center" justifyContent="center">
                    <ButtonBase component="label" sx={team.image ? styles.uploadButton : styles.uploadButtonWithoutImage}>
                        <Icons.Camera />
                        <HiddenFileInput name="file" onChange={doUpload}/>
                    </ButtonBase>
                </Stack>
            </Stack>
            <Form methods={rhf.methods} onSubmit={doSave}>
                <Stack direction="column" justifyContent="flex-end" p={2}>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <FormField
                            name="name"
                            label="team.name"
                        />
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <FormField
                            name="num_players"
                            label="team.prefs.player.count"
                            type="number"
                        />
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <SelectField label="team.lineup" name="lineup">
                            {lineups.map(lineup => <MenuItem
                                key={lineup.name}
                                value={lineup.name}>{lineup.name}</MenuItem>)}
                        </SelectField>
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <SelectField label="team.prefs.direction" name="prefs.direction">
                            <MenuItem value="home"><Text>team.prefs.direction_home</Text></MenuItem>
                            <MenuItem value="guest"><Text>team.prefs.direction_guest</Text></MenuItem>
                        </SelectField>
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <FormField
                            name="prefs.player.color1"
                            label="client.color.primary"
                            as={MuiColorInput}
                            format="hex"
                        />
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <FormField
                            name="prefs.player.color2"
                            label="client.color.secondary"
                            as={MuiColorInput}
                            format="hex"
                        />
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <SelectField label="team.prefs.player.size" name="prefs.player.size">
                            {Object.keys(sizeOptions).map((key, index) =>
                                <MenuItem
                                    key={key}
                                    value={sizeOptions[index + 1].label}>{sizeOptions[index + 1].label}</MenuItem>
                            )}
                        </SelectField>
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <SelectField label="team.prefs.player.type" name="prefs.player.type">
                            <MenuItem value="player"><Text>team.prefs.player.display.shirts</Text></MenuItem>
                            <MenuItem value="playerCircle"><Text>team.prefs.player.display.magnets</Text></MenuItem>
                        </SelectField>
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <SelectField label="team.prefs.player.image" name="prefs.player.image">
                            <MenuItem value={true}><Text>global.yes</Text></MenuItem>
                            <MenuItem value={false}><Text>global.no</Text></MenuItem>
                        </SelectField>
                    </SubmitableField>
                    <SubmitableField editable={editable} setEditable={setEditable} onSave={doSave}>
                        <UserSelectOnDemand name="managers" label="team.member.managers" multiple disableClearable clientId={team.client_id}/>
                    </SubmitableField>
                </Stack>
            </Form>
        </Dialog>
    );
};

export default Edit;