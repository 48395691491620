import React, {useState} from 'react';

import {ArrowDownward as ArrowDownIcon, ArrowUpward as ArrowUpIcon} from '@mui/icons-material';
import {
    Avatar,
    Box,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material';

import Icons from 'assets/icons';
import {homeLineup} from 'assets/lineups';
import {getNextPlayerNumber} from 'canvas/Drawer/player-utils';
import {useCanvasStore} from 'canvas/zustand';
import {Drawer, Text} from 'components';
import {TeamLineup} from 'module/client/team';
import {DraggablePlayer} from 'module/client/team/member';
import {useDrawAnonLineup} from 'module/client/team/useLineup';
import {useApiRead} from 'module/client/team/useTeamApi';
import {useSettingsStore} from 'module/settings/zustand';
import {useLayoutStore, usePrefsStore} from 'store';

const styles = {
    drawer: {
        width: 'auto',
        maxWidth: '200px',
        '&::-webkit-scrollbar': {
            height: '100%',
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'grey.light',
            borderRadius: 2
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'grey.lightest',
            borderRadius: 2
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'grey.lightest',
            borderRadius: 2
        },
    },
    iconBoxGroup: {
        padding: '10px',
        '&:last-child': {
            marginBottom: '40px',
        }
    },
    iconBox: {
        width: '100%',
        marginBottom: '5px',
    },
    iconBoxText: {
        fontSize: '14px',
    },
    icon: {
        display: 'block',
        opacity: 1,
        height: '80px',
    },
    toggleButtonGroup: {
        padding: '10px',
    },
    toggleButton: theme => ({
        padding: '12px',
        color: 'white.main',

        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },

        [theme.breakpoints.up('sm')]: {
            padding: '12px',
        },
    }),
    playerDisabled: {
        opacity: 0.5,
    },
    playerShirt: {
        position: 'relative',
        '& span': {
            position: 'absolute', top: 40, left: 20
        }
    },
};

const AnonLineup = () => {
    const [direction, setDirection] = useState('up');

    const color = usePrefsStore((state) => state.color);
    const playerPrefs = usePrefsStore((state) => state.prefs.player);

    const drawLineup = useDrawAnonLineup();

    const onClick = (name) => {
        drawLineup(name, direction, playerPrefs.type, color, playerPrefs.size);
    };

    return <Stack direction="column" alignItems="center">
        <Player/>
        <Stack direction="row">
            <ToggleButtonGroup
                color="primary"
                value={direction}
                exclusive
                onChange={(e, v) => setDirection(v)}
                sx={styles.toggleButtonGroup}
            >
                <ToggleButton sx={styles.toggleButton} value="up"><ArrowUpIcon color="primary"/></ToggleButton>
                <ToggleButton sx={styles.toggleButton} value="down"><ArrowDownIcon color="primary"/></ToggleButton>
            </ToggleButtonGroup>
        </Stack>
        <Stack direction="column">
            {homeLineup.map(l => <Stack key={l.name} onClick={() => onClick(l.name)} sx={styles.iconBoxGroup}>
                <Box sx={styles.iconBox} display="flex" justifyContent="center">
                    <l.Icon style={styles.icon}/>
                </Box>
                <Typography align="center" sx={styles.iconBoxText}>{l.name}</Typography>
            </Stack>)}
        </Stack>
    </Stack>;
};

const PlayerCircle = ({number, colors, disabled}) => {
    return <Avatar sx={[colors, disabled && styles.playerDisabled]}>{number}</Avatar>;
};

const PlayerShirt = ({number, colors, disabled}) => {
    const svgStyle = {width: '50px', fill: colors.bgcolor};

    return <Box sx={[styles.playerShirt, disabled && styles.playerDisabled]}>
        <Icons.Shirt style={svgStyle}>{number}</Icons.Shirt>
        <Typography component="span" variant="h2" sx={{color: colors.color}}>{number}</Typography>
    </Box>;
};

export const Player = () => {
    const canvas = useCanvasStore(state => state.canvas);
    // watch for canvas size to trigger a re-rendering, otherwise next number will not be updated
    useCanvasStore(state => state.canvas?.size());

    const color = usePrefsStore(state => state.color);

    const nextNumber = getNextPlayerNumber(canvas, color.name);

    const colors = {
        color: color.value2, bgcolor: color.value1
    };

    const player = {
        team: color.name,
        number: nextNumber,
    };

    return <Stack direction="row" alignItems="center" gap={2}>
        <DraggablePlayer type="playerCircle" player={player}>
            <PlayerCircle number={nextNumber} colors={colors}/>
        </DraggablePlayer>
        <DraggablePlayer type="player" player={player}>
            <PlayerShirt number={nextNumber} colors={colors}/>
        </DraggablePlayer>
    </Stack>;
};

const LineupTool = () => {
    const [type, setType] = useState('anon');

    const showLineup = useLayoutStore((state) => state.showLineup);
    const setShowLineup = useLayoutStore((state) => state.setShowLineup);
    const selectedTeam = useSettingsStore((state) => state.selectedTeam);

    const queryTeam = useApiRead({teamId: selectedTeam});

    const doClose = () => {
        setShowLineup(false);
    };

    const onChange = (v) => {
        if (v !== null) {
            setType(v);
        }
    };

    return <Drawer
        open={showLineup} onClose={doClose} sx={styles.drawer}
        slotProps={{backdrop: {invisible: true}}}>
        <ToggleButtonGroup
            color="primary"
            value={type}
            exclusive
            onChange={(_e, v) => onChange(v)}
            sx={styles.toggleButtonGroup}
        >
            <ToggleButton value="anon" sx={styles.toggleButton}><Text>board.toolbar.lineup</Text></ToggleButton>
            <ToggleButton value="team" sx={styles.toggleButton}><Text>team.team</Text></ToggleButton>
        </ToggleButtonGroup>
        {type === 'team' && <TeamLineup team={queryTeam.data}/>}
        {type === 'anon' && <AnonLineup/>}

    </Drawer>;
};

export default LineupTool;