import React, {useEffect, useState} from 'react';

import {Autocomplete, ButtonBase, Stack, TextField} from '@mui/material';

import {clear} from 'canvas/canvas-helper';
import {useCanvasSave} from 'canvas/useCanvas';
import {useCanvasStore} from 'canvas/zustand';
import {getKeyboardOptions, Text} from 'components';
import {useTranslation} from 'hooks';
import {useGroup} from 'module/group/useGroup';
import {useKeyboardStore, useLayoutStore, usePrefsStore, useSnackbarStore} from 'store';

import {useSheet, useSheetAdd, useSheetUpdate} from './useSheet';

const styles = {
    button: {
        color: 'white.main',
        marginLeft: 4,
        fontSize: '16px'
    }
};

export const Sheet = ({sheet = null, onSave, onCancel}) => {
    const translate = useTranslation();

    const [name, setName] = useState(sheet?.name || '');
    const [group, setGroup] = useState(sheet?.group || null);

    const showInfo = useSnackbarStore((state) => state.show);

    const showKeyboard = useKeyboardStore((state) => state.show);
    const hideKeyboard = useKeyboardStore((state) => state.hide);

    const background = usePrefsStore(state => state.background);
    const setShowFieldSelector = useLayoutStore((state) => state.setShowFieldSelector);
    const setShowLoading = useLayoutStore((state) => state.setShowLoading);

    const canvas = useCanvasStore((store) => store.canvas);

    const {save: saveCanvas} = useCanvasSave();

    const {update: updateSheet, loading: loading1} = useSheetUpdate();
    const {add: addSheet, loading: loading2} = useSheetAdd();

    const {setSelected} = useSheet();

    const {groups, selected: groupId} = useGroup();

    const onInputFocus = (e, setVal) => {
        showKeyboard(getKeyboardOptions(e, setVal));
    };

    const onInputChange = (e) => {
        setName(e.target.value);
    };

    const onInputBlur = () => {
        hideKeyboard();
    };

    const doCancel = () => {
        setName(sheet?.name || '');
        onCancel && onCancel();
    };

    const doSave = async () => {
        if (!name) {
            showInfo('board.sheet.validation.name_required', {severity: 'warning'});
            return;
        }

        if (sheet && !group) {
            showInfo('board.sheet.validation.group_required', {severity: 'warning'});
            return;
        }

        let msg;
        let sht;

        try {

            if (sheet === null) {
                clear(canvas, background);
                sht = await addSheet({name, group: groupId});
                if (!sht) {
                    return;
                }

                await saveCanvas(sht, {background});

                // this triggers the sheet switcher
                setSelected(sht.id);

                msg = 'board.sheet.action.adding_success';
                setShowFieldSelector(true);
            } else {
                sht = await updateSheet({...sheet, name, group});
                if (!sht) {
                    return;
                }

                msg = 'board.sheet.action.save_success';
            }
        } catch (e) {
            showInfo(e.message, {severity: 'warning'});
            return;
        }

        showInfo(msg);
        setName('');
        onSave(sht);
    };

    useEffect(() => {
        setShowLoading(loading1 || loading2);
    }, [loading1, loading2, setShowLoading]);

    useEffect(() => {
        return () => {
            hideKeyboard();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const label = sheet ? 'board.sheet.name' : 'board.sheet.name_new';

    return <Stack direction="column" p={2}>
        <TextField
            label={translate(label)}
            value={name}
            autoFocus
            autoComplete="off"
            onFocus={(e) => {
                onInputFocus(e, setName);
            }}
            onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
            }}
            onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                    doSave();
                    ev.preventDefault();
                }
            }}
            onBlur={onInputBlur}
            onChange={onInputChange}
        />
        {sheet && <Autocomplete
            renderInput={params => <TextField
                label={translate('board.group.name')}
                {...params}
            />}
            options={groups}
            disableClearable={true}
            getOptionLabel={(option) => {
                // when rendering the option list an object is passed
                if (option instanceof Object) {
                    return option.name || '';
                }

                // when rendering the defaultValue an id is passed as option
                return groups.find(o => o.id === option)?.name || '';
            }}
            isOptionEqualToValue={(option, selected) => {
                const id = selected instanceof Object ? selected.id : selected;
                return option.id === id;
            }}
            value={group}
            onChange={(_e, v) => {
                setGroup(v?.id);
            }}
        />}
        <Stack direction="row" justifyContent="flex-end" pb={1} pt={2}>
            <ButtonBase disableRipple sx={styles.button} onClick={doCancel}>
                <Text>ui.actions.cancel</Text>
            </ButtonBase>
            <ButtonBase disableRipple onClick={doSave} sx={styles.button} type="submit">
                <Text>ui.actions.save</Text>
            </ButtonBase>
        </Stack>
    </Stack>;
};

export default Sheet;
