import {useEffect} from 'react';

import {useLockerRoomStore} from 'module/lockerRoom/zustand';
import {useUserStore} from 'module/user';

import {createMediaRoom, exitRoom, joinMediaRoom, mediaType, pauseProducer, prepareMediaRoom, producerLabel, resumeProducer} from './mediaLib';

// const hasWindow = typeof window !== 'undefined';

export const useMediaSoup = () => {
    const roomId = useLockerRoomStore((state) => state.roomId);
    const role = useLockerRoomStore((state) => state.role);
    const user = useUserStore((store) => store.user);

    const micro = useLockerRoomStore((state) => state.micro);
    const selectedDevice = useLockerRoomStore((state) => state.selectedDevice);
    const mediaRoomConnected = useLockerRoomStore((state) => state.mediaRoomConnected);
    const setMediaRoomConnected = useLockerRoomStore((state) => state.setMediaRoomConnected);
    // const users = useLockerRoomStore((state) => state.users);
    // const active = useLockerRoomStore((state) => state.active);
    const producing = useLockerRoomStore((state) => state.producing);
    // const setProducing = useLockerRoomStore((state) => state.setProducing);
    const connected = useLockerRoomStore((state) => state.connected);
    const created = useLockerRoomStore((state) => state.created);
    const opening = useLockerRoomStore((state) => state.opening);

    const mediaClientDevice = useLockerRoomStore((state) => state.mediaClientDevice);
    const mediaRoomCreated = useLockerRoomStore((state) => state.mediaRoomCreated);
    const setMediaRoomCreated = useLockerRoomStore((state) => state.setMediaRoomCreated);
    const producerTransport = useLockerRoomStore((state) => state.producerTransport);
    const producerTransportIceParams = useLockerRoomStore((state) => state.producerTransportIceParams);
    const producerTransportConnected = useLockerRoomStore((state) => state.producerTransportConnected);
    const consumerTransport = useLockerRoomStore((state) => state.consumerTransport);
    const consumerTransportIceParams = useLockerRoomStore((state) => state.consumerTransportIceParams);
    const consumerTransportConnected = useLockerRoomStore((state) => state.consumerTransportConnected);

    const mediaRoomForceReconnect = useLockerRoomStore((state) => state.mediaRoomForceReconnect);
    const setMediaRoomForceReconnect = useLockerRoomStore((state) => state.setMediaRoomForceReconnect);

    // console.log('isVisible', [isVisible, selectedDevice, producing]);
    useEffect(() => {
        // useeffect for the connection to the media room

        const create = async () => { 
            setMediaRoomCreated(await createMediaRoom(roomId));
        };

        const connect = async () => { 
            setMediaRoomConnected(await prepareMediaRoom(roomId, user.appId));
        };

        // 1. admin create room
        if (connected && roomId && !mediaRoomConnected) {
            // admin creates and then joins room
            if (role === 'admin') {
                if (created && !mediaRoomCreated) {
                    console.log('MEDIASOUP EFFECT: CREATE ROOM');
                    // create room needs to be done first before media device can be loaded
                    create();
                } else {
                    if (mediaClientDevice?._loaded === true) {
                        console.log('MEDIASOUP EFFECT: ADMIN JOIN MEDIA ROOM');
                        connect();
                    }
                }
                
            } else {
            
                // user joins room
                if (mediaClientDevice?._loaded === true) {
                    console.log('MEDIASOUP EFFECT: USER JOIN MEDIA ROOM');
                    connect();
                }
            }
        }
     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connected, roomId, created, mediaRoomCreated, role, mediaRoomConnected, mediaClientDevice]);
    
    useEffect(() => {
        const join = async () => { 
            await joinMediaRoom(role, roomId, user.appId, selectedDevice, (!producing || micro === false));
            if (mediaRoomForceReconnect) {
                setMediaRoomForceReconnect(false);
            }

        };

        // has audio device and media device loaded
        if (connected && Object.keys(selectedDevice).length > 0) {
            // 3. connect to media room
            if (role === 'admin' && created && !mediaRoomCreated) {
                console.log('MEDIASOUP EFFECT: RETURN JOIN ROOM');
                // if admin has not created the media room yet
                return;
            }

            console.log('MEDIASOUP EFFECT: JOIN PRODUCE mediaroom:', mediaRoomConnected, 'mediadevice:', mediaClientDevice?._loaded, 'producerlabel: ', producerLabel, 'PT:', !!producerTransport, producerTransportConnected, 'CT:', !!consumerTransport, consumerTransportConnected);
            if (roomId && mediaClientDevice?._loaded && mediaRoomConnected && producerTransport && consumerTransport) {
                // if (roomId && mediaClientDevice?._loaded && mediaRoomConnected && producerTransport && consumerTransport && !producerTransportConnected && !consumerTransportConnected && !producerTransport?._closed === true && !consumerTransport?._closed === true) {
                console.log('MEDIASOUP: important join media room forceReconnect:', mediaRoomForceReconnect);
                join();
            }
        }
    }, [connected, selectedDevice, mediaClientDevice, role, roomId, user, mediaRoomCreated, mediaRoomConnected, producing, micro, created, opening, producerTransportConnected, consumerTransportConnected, producerTransport, consumerTransport, mediaRoomForceReconnect, setMediaRoomForceReconnect]);
    
    useEffect(() => {
        if ( producerTransport?._closed === true || consumerTransport?._closed === true) {
            console.log('MEDIASOUP: PRODUCER TRANSPORT CLOSED: ',!producerTransport?._closed === true, 'MEDIASOUP: CONSUMER TRANSPORT CLOSED: ',!consumerTransport?._closed === true);
            // please checkout this method how it works
            // exitRoom(false, true, role, roomId, user.appId, selectedDevice, producing);
            // TODO cleanup producer and consumer transports
        }
        // INFO: Do not add more dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [producerTransport, consumerTransport]);

    // to reconnect media room after rejoin or network change
    useEffect(() => {
        if (mediaRoomForceReconnect && consumerTransport && producerTransport) {
            console.log('Important RECONNECT TRIGGERED',connected, mediaRoomConnected);
            // TODO add iceparameters
            consumerTransport.restartIce(consumerTransportIceParams);
            producerTransport.restartIce(producerTransportIceParams);
            // please checkout this method how it works
            // exitRoom(false, true, role, roomId, user.appId, selectedDevice, producing);
            setMediaRoomForceReconnect(false);
        }
        // INFO: Do not add more dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaRoomForceReconnect]);

    useEffect( () => {
        // console.log('microphoneStatus resume:', producing);
        if (connected && mediaRoomConnected && producerTransportConnected) {
            // catches the reconnect case if microfone has been on and user reconnects to the lockerroom 
            if (producing || micro === true) {
                resumeProducer(mediaType.audio);
            } else {
                pauseProducer(mediaType.audio);
            }
        } else {
            console.log('microphoneStatus - NOT CONNECTED', connected, mediaRoomConnected, producerTransportConnected);
        }
            
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connected, producing, mediaRoomConnected]);

    useEffect( () => {
        return () => {
            console.log('MEDIASOUP: Board exit -> Exit room');
            exitRoom(!connected, false, role, roomId, user.appId, selectedDevice, producing);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

};
