import * as React from "react";
const SvgSoccerVerticalZones = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Ebene_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 900 1345.9", width: 900, height: 1346, style: {
  enableBackground: "new 0 0 900 1345.9"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("g", { id: "Ebene_2_00000136377386218138465010000002045065273268449154_" }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("rect", { id: "rect4_00000121999796107226998210000008611661105368321172_", y: 3, style: {
  fill: "#629313"
}, width: 900, height: 1339.6 }), /* @__PURE__ */ React.createElement("rect", { id: "rect6_00000042718483855753798660000015089241841393741728_", x: 2.4, y: 1274.2, style: {
  fill: "#6C9E1E"
}, width: 895, height: 66.8 }), /* @__PURE__ */ React.createElement("rect", { id: "rect8_00000161610866734787754890000017811919065283115439_", x: 2.4, y: 1140.6, style: {
  fill: "#6C9E1E"
}, width: 895, height: 66.8 }), /* @__PURE__ */ React.createElement("rect", { id: "rect10_00000078018723245687341160000006544635747210027669_", x: 2.4, y: 1008.9, style: {
  fill: "#6C9E1E"
}, width: 895, height: 64.9 }), /* @__PURE__ */ React.createElement("rect", { id: "rect12_00000031897143809269736700000016142706218341041849_", x: 2.4, y: 873.5, style: {
  fill: "#6C9E1E"
}, width: 895, height: 66.8 }), /* @__PURE__ */ React.createElement("rect", { id: "rect14_00000116210703029220551960000009766959149283105974_", x: 2.4, y: 739.6, style: {
  fill: "#6C9E1E"
}, width: 895, height: 66.8 }), /* @__PURE__ */ React.createElement("rect", { id: "rect16_00000106106768812446526320000000580664022858134957_", x: 2.4, y: 606.3, style: {
  fill: "#6C9E1E"
}, width: 895, height: 66.8 }), /* @__PURE__ */ React.createElement("rect", { id: "rect18_00000065760927032709275210000014329766911757909132_", x: 2.4, y: 473.3, style: {
  fill: "#6C9E1E"
}, width: 895, height: 66.8 }), /* @__PURE__ */ React.createElement("rect", { id: "rect20_00000170238289200896493430000013677208889854599046_", x: 2.4, y: 335.9, style: {
  fill: "#6C9E1E"
}, width: 895, height: 70.3 }), /* @__PURE__ */ React.createElement("rect", { id: "rect22_00000007419499117086255960000015637275807066782625_", x: 2.4, y: 205.6, style: {
  fill: "#6C9E1E"
}, width: 895, height: 66.8 }), /* @__PURE__ */ React.createElement("rect", { id: "rect24_00000183217811826126227020000001826123891017461376_", x: 2.4, y: 72.6, style: {
  fill: "#6C9E1E"
}, width: 895, height: 66.8 }), /* @__PURE__ */ React.createElement("rect", { id: "rect26_00000070102362688334012870000001932410556546159272_", x: 2.4, y: 5, style: {
  fill: "#75AF2C"
}, width: 895, height: 0.8 }), /* @__PURE__ */ React.createElement("g", { id: "g32_00000056428402046885499860000017704429980857936027_", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("path", { id: "path30_00000179604591964653403580000003528397085240255899_", style: {
  fill: "#FFFFFF"
}, d: "M410.1,860.2l-4,2.7 c-22.9-33.3-62.8-53.1-106.7-53.1s-83.8,19.9-106.7,53.1l-3.9-2.7C212.6,825.7,254,805,299.5,805 C344.9,805.1,386.3,825.7,410.1,860.2z" })), /* @__PURE__ */ React.createElement("g", { id: "g36_00000062904280380709850820000004490509227171351739_", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("path", { id: "path34_00000038407470951785346670000006399920539888664457_", style: {
  fill: "#FFFFFF"
}, d: "M410.1-17.6 C386.3,17,344.9,37.6,299.4,37.6S212.5,17,188.7-17.6l3.9-2.7c22.9,33.3,62.8,53.1,106.7,53.1S383.1,12.9,406-20.3L410.1-17.6z" })), /* @__PURE__ */ React.createElement("g", { id: "g40_00000049214073686621068710000002914582737541429658_", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("path", { id: "path38_00000118378212996526092560000016270750826817655180_", style: {
  fill: "#FFFFFF"
}, d: "M434.7,421.4 c0,74.6-60.7,135.3-135.3,135.3S164.2,496,164.2,421.4s60.7-135.3,135.3-135.3S434.7,346.8,434.7,421.4L434.7,421.4z M169,421.4 c0,71.9,58.5,130.5,130.5,130.5c71.9,0,130.5-58.5,130.5-130.5c0-71.9-58.5-130.5-130.5-130.5S169,349.5,169,421.4L169,421.4z" })), /* @__PURE__ */ React.createElement("g", { id: "g44_00000016796683973288045750000017060082041202470301_", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("path", { id: "path42_00000093862669294569716770000010770637899119790751_", style: {
  fill: "#FFFFFF"
}, d: "M747,1059.8v4.8 c-14.6,0-26.5,11.9-26.5,26.5h-4.8C715.8,1073.8,729.8,1059.8,747,1059.8z" })), /* @__PURE__ */ React.createElement("g", { id: "g48_00000133488605956289520330000011005769789139433381_", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("path", { id: "path46_00000117664119080379677450000014233376408723494324_", style: {
  fill: "#FFFFFF"
}, d: "M-116.9,1091.1h-4.8 c0-14.6-11.9-26.5-26.5-26.5v-4.8C-130.9,1059.8-116.9,1073.8-116.9,1091.1z" })), /* @__PURE__ */ React.createElement("g", { id: "g52_00000098909015367329383780000009536062550056021179_", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("path", { id: "path50_00000129901358947733148290000001835632807558930325_", style: {
  fill: "#FFFFFF"
}, d: "M747-221.6v4.8 c-17.2,0-31.3-14-31.3-31.3h4.8C720.6-233.5,732.4-221.6,747-221.6z" })), /* @__PURE__ */ React.createElement("g", { id: "g56_00000065038837527681561820000010551399325188732592_", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("path", { id: "path54_00000097482272736698896390000013416850441650603198_", style: {
  fill: "#FFFFFF"
}, d: "M-116.9-248.1 c0,17.2-14,31.3-31.3,31.3v-4.8c14.6,0,26.5-11.9,26.5-26.5H-116.9z" })), /* @__PURE__ */ React.createElement("g", { id: "g60_00000059273192844880950270000016914172876223668097_", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("polygon", { id: "polygon58_00000162331844128595418050000002141568048552994476_", style: {
  fill: "#FFFFFF"
}, points: "580.3,1091.1  580.3,864 18.6,864 18.6,1091.1 13.8,1091.1 13.8,859.2 585.1,859.2 585.1,1091.1  " })), /* @__PURE__ */ React.createElement("g", { id: "g64_00000049190112254829768900000000219239739042025395_", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("polygon", { id: "polygon62_00000021836609887954091660000010637459273121367439_", style: {
  fill: "#FFFFFF"
}, points: "439.7,1091.1  439.7,1007.2 159.2,1007.2 159.2,1091.1 154.4,1091.1 154.4,1002.4 444.5,1002.4 444.5,1091.1  " })), /* @__PURE__ */ React.createElement("g", { id: "g68_00000158722143115411636050000005990015236563632814_", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("polygon", { id: "polygon66_00000011711212840454853060000012203627826199078290_", style: {
  fill: "#FFFFFF"
}, points: "154.4,-159.8  154.4,-248.5 159.2,-248.5 159.2,-164.6 439.7,-164.6 439.7,-248.5 444.5,-248.5 444.5,-159.8  " })), /* @__PURE__ */ React.createElement("g", { id: "g72_00000103960283571962307160000012916834519344374666_", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("polygon", { id: "polygon70_00000067931250508004592510000000551324626710526347_", style: {
  fill: "#FFFFFF"
}, points: "13.8,-16.6  13.8,-248.5 18.6,-248.5 18.6,-21.4 580.3,-21.4 580.3,-248.5 585.1,-248.5 585.1,-16.6  " })), /* @__PURE__ */ React.createElement("g", { id: "g76_00000136403414666864546150000003640195554932916894_", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("rect", { id: "rect74_00000022539129448653272290000008378945603131547834_", x: -148.2, y: 418.6, style: {
  fill: "#FFFFFF"
}, width: 895.2, height: 4.8 })), /* @__PURE__ */ React.createElement("g", { id: "g80_00000129901661136986031350000005584575410470232249_", transform: "translate(150.6,251.5)" }, /* @__PURE__ */ React.createElement("path", { id: "path78_00000097491895044939692420000005976560971969407620_", style: {
  fill: "#FFFFFF"
}, d: "M749.4-250.5v1343.9h-900 V-250.5H749.4z M744.6,1088.7V-245.7h-890.4v1334.4L744.6,1088.7L744.6,1088.7z" })), /* @__PURE__ */ React.createElement("path", { id: "path82_00000103960964817745210920000009294511682498879385_", style: {
  fill: "#FFFFFF"
}, d: "M449.9,664.4 c4.7,0,8.5,3.8,8.5,8.5s-3.8,8.5-8.5,8.5s-8.5-3.8-8.5-8.5S445.3,664.4,449.9,664.4z" }), /* @__PURE__ */ React.createElement("path", { id: "path84_00000013887477439405826490000000549368664104199066_", style: {
  fill: "#FFFFFF"
}, d: "M449.9,153.2 c4.7,0,8.5,3.8,8.5,8.5s-3.8,8.5-8.5,8.5s-8.5-3.8-8.5-8.5S445.3,153.2,449.9,153.2z" }), /* @__PURE__ */ React.createElement("path", { id: "path86_00000024723320083980195360000012509605554818221735_", style: {
  fill: "#FFFFFF"
}, d: "M449.9,1179.2 c4.7,0,8.5,3.8,8.5,8.5s-3.8,8.5-8.5,8.5s-8.5-3.8-8.5-8.5S445.3,1179.2,449.9,1179.2z" }))), /* @__PURE__ */ React.createElement("rect", { x: 314.8, y: 5.8, style: {
  opacity: 0.1,
  enableBackground: "new"
}, width: 270.5, height: 1334.4 }), /* @__PURE__ */ React.createElement("rect", { x: 4.8, y: 5.8, style: {
  opacity: 0.1,
  enableBackground: "new"
}, width: 159.5, height: 1334.3 }), /* @__PURE__ */ React.createElement("rect", { x: 735.7, y: 5.8, style: {
  opacity: 0.1,
  enableBackground: "new"
}, width: 159.5, height: 1334.3 }));
export default SvgSoccerVerticalZones;
