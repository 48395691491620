import React, {forwardRef, useEffect} from 'react';

import {ButtonBase, Slide, Stack} from '@mui/material';

import {Dialog, Text} from 'components';
import {useLayoutStore, useSnackbarStore} from 'store';

import {useApiAdd} from './useClientApi';

import {Form, FormField, useForm} from 'form';

const styles = {
    button: {
        color: 'white.main',
        marginLeft: 4,
        fontSize: '16px'
    }
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const CreateDialog = ({onClose, onSave, open}) => {
    const setShowLoading = useLayoutStore((state) => state.setShowLoading);
    const showInfo = useSnackbarStore((state) => state.show);

    const {add, loading, data, errors} = useApiAdd();

    const doCancel = () => {
        onClose && onClose();
    };

    const doSave = async (formData) => {
        try {
            const res = await add(formData);
            onSave && onSave(res);
        } catch (e) {
            showInfo(e.message, {severity: 'warning'});
        }
    };

    useEffect(() => {
        setShowLoading(loading);
        return () => {
            setShowLoading(false);
        };
    }, [loading, setShowLoading]);

    const rhf = useForm(data, errors);

    return <Dialog
        maxWidth={false}
        pt={0}
        open={open}
        TransitionComponent={Transition}
        handleClose={onClose}
        title="client.action.create2"
    >
        <Form methods={rhf.methods} onSubmit={doSave}>
            <Stack direction="column" p={2}>
                <FormField name="name" label="client.name" autoFocus/>
                <Stack direction="row" justifyContent="flex-end" pb={1} pt={2}>
                    <ButtonBase disableRipple sx={styles.button} onClick={doCancel}>
                        <Text>ui.actions.cancel</Text>
                    </ButtonBase>
                    <ButtonBase disableRipple sx={styles.button} type="submit">
                        <Text>ui.actions.save</Text>
                    </ButtonBase>
                </Stack>
            </Stack>
        </Form>
    </Dialog>;
};

export default CreateDialog;