import React, {useEffect,useState} from 'react';

import {
    Videocam as VideocamIcon
} from '@mui/icons-material';
import {Box, IconButton, Menu, MenuItem} from '@mui/material';
import {keyframes} from '@mui/system';

import {useApiRead} from 'module/client/team/useTeamApi';
import {useLoadPlayers,useLoadTeams} from 'module/settings/useSettings';
import {useSettingsStore} from 'module/settings/zustand';
import {useUserStore} from 'module/user';
import {useLayoutStore, useSnackbarStore} from 'store';

import StartDialog from './StartDialog';
import {useLockerRoomStore} from './zustand';

const pulseGreenAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(94, 178, 2, 1);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(94, 178, 2, 0);
  }
`;

const styles = {
    lockerRoomToolbar: {
        position: 'absolute',
        bottom: '0px',
        left: 0,
        right: 0,
        gap: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tactixButtonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        gap: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconButtonStyle: {
        width: '100px',
        height: '50px',
        borderRadius: '50px 50px 0 0',
        borderColor: '#fff',
        justifyContent: 'center',
        padding: '0',
        zIndex: 99,
        backgroundColor: 'white.main',
        ':hover': {
            backgroundColor: 'white.main',
        },
        '& svg': {
            marginTop: '10px'
        }
    },
    iconButtonStyleGreen: {
        background: 'linear-gradient(to top, #408800, #5eb202)',

        ':hover': {
            background: 'linear-gradient(to top, #5eb202, #6fc313)',
        }
    },
    // selectedClientText: {
    //     color: 'white.main',
    //     fontSize: '13px',
    //     backgroundColor: 'grey.main',
    //     ':hover': {
    //         backgroundColor: 'green.main',
    //     }
    // },
    buttonIcon: {
        width: '36px',
        height: '36px',
    },
    pulse: {
        animation: `${pulseGreenAnimation} 1s infinite linear`,
    },
    roomMenuItem: {
        color: 'green.lockerroom',
        fontSize: '14px',
        borderBottom: '1px solid',
        borderBottomColor: 'green.lockerroom',

        '&:last-child': {
            borderBottom: 0,
        }
    },
};

const RoomsMenu = ({rooms, joinFunction}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const showMenu = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (room) => {
        handleClose();
        joinFunction(room);
    };

    if (rooms.length <= 1) {
        //return null;
    }

    return <>
        <IconButton
            sx={[styles.iconButtonStyle, styles.iconButtonStyleGreen, styles.pulse]}
            onClick={showMenu}
            aria-controls={open ? 'roomMenu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
        >
            <VideocamIcon sx={[styles.buttonIcon, {color: 'white.main', backgroundColor: 'transparent'}]}/>
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            elevation={0}
            id="roomMenu"
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    backgroundColor: 'white.main',
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        bottom: -10,
                        right: '50%',
                        translate: 5,
                        width: 10,
                        height: 10,
                        bgcolor: 'white.main',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                    '& ul': {
                        paddingTop: 0,
                        paddingBottom: 0,
                    }
                },
            }}
            transformOrigin={{horizontal: 'center', vertical: 'bottom'}}
            anchorOrigin={{horizontal: 'center', vertical: 'top'}}
        >
            {rooms.map(room => <MenuItem
                key={room._id}
                sx={styles.roomMenuItem}
                value={room.room_id}
                onClick={() => handleClick(room)}>
                {room.title}
            </MenuItem>)}
        </Menu>
    </>;
};

const Toolbar = () => {
    const [showDialog, setShowDialog] = useState(false);

    const active = useLockerRoomStore((state) => state.active);
    const selectedTeam = useSettingsStore((state) => state.selectedTeam);
    
    const selectedClient = useSettingsStore((store) => store.selectedClient);
    const setSelectedTeam = useSettingsStore((store) => store.setSelectedTeam);
    // const getSelectedClient = useSettingsStore((store) => store.getSelectedClient);
    
    const setRoomId = useLockerRoomStore((store) => store.setRoomId);
    const user = useUserStore((store) => store.user);
    const rooms = useUserStore((store) => store.lockerrooms);
    const isLoadingRooms = useUserStore(state => state.isLoadingLockerrooms);
    const queryTeam = useApiRead({teamId: selectedTeam});
    const managers = queryTeam?.data?.managers;

    const showInfo = useSnackbarStore((state) => state.show);
    const setOpening = useLockerRoomStore((state) => state.setOpening);
    const opening = useLockerRoomStore((state) => state.opening);
    const setRole = useLockerRoomStore((state) => state.setRole);

    const setShowSettings = useLayoutStore((state) => state.setShowSettings);

    const loadPlayers = useLoadPlayers();
    const {loadTeams} = useLoadTeams();

    //get inititial data for teams
    useEffect(() => {
        async function load() {
            await loadTeams(selectedClient);
        }

        load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCreate = async () => {
        if (selectedTeam) {
            setShowDialog(true);
        }
        else {
            setShowSettings(true);
            showInfo('lockerroom.message.select_team');
        }
    };

    const onJoin = (room) => {
        console.log('joining room', room);
        setRoomId(room.room_id);
        // INFO:
        // If you are not currently in the team you would join for a lockerroom, we now load selected team and players
        // to display in controls list (avatars info etc.)
        if (room.team_id) {
            setSelectedTeam(room.team_id);
            loadPlayers(room.team_id);
        }

        //join is always role user, if the user was admin he gets role admin later
        setRole('user');

        setOpening(true);
    };

    if (!selectedTeam) {
        return null;
    }

    // console.log('lockerrooms', isLoadingRooms , rooms);
    // console.log('selected team', selectedTeam , getSelectedTeam());
    // console.log('selected client', selectedClient);
    // Lockerroom Start/Leave/Join
    return <Box sx={styles.lockerRoomToolbar}>
        {/* Start Lockerroom Form/Settins */}
        <Box sx={styles.tactixButtonContainer}>
            { managers?.find((id) => id === user.id) && rooms.length === 0 && showDialog && <StartDialog open={showDialog} handleClose={() => setShowDialog(false)}/>}

            {/* Start Lockerroom Button */}
            {!isLoadingRooms && managers?.find((id) => id === user.id) && rooms.length === 0 && !active && !opening &&
            <IconButton sx={[styles.iconButtonStyle, styles.iconButtonStyleGreen]} onClick={() => onCreate()}>
                <VideocamIcon sx={[styles.buttonIcon, {color: 'white.main'}]}/>
            </IconButton>}

            {/* Join Lockerroom direct */}
            {!isLoadingRooms && rooms.length === 1 && !opening &&
            <IconButton
                sx={[styles.iconButtonStyle, styles.iconButtonStyleGreen, styles.pulse]}
                onClick={() => onJoin(rooms[0])}>
                <VideocamIcon sx={[styles.buttonIcon, {color: 'white.main', backgroundColor: 'transparent'}]}/>
            </IconButton>
            }

            {/* Join any Lockerroom */}
            {!isLoadingRooms && rooms.length > 1 && !opening &&
            <RoomsMenu rooms={rooms} joinFunction={onJoin}/>
            }
    
        </Box>
    </Box>;
};

export default Toolbar;