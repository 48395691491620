import {useCallback} from 'react';

import {useQuery} from '@tanstack/react-query';

import axios from 'axios';
import * as yup from 'yup';

import {isOk, useFetcher} from 'fetcher';
import {getEnv} from 'lib/env';
import {useUserUtils} from 'module/user/useUser';

const CONTROL_URL = getEnv('CONTROL_URL');

const defaultData = {
    firstname: '',
    lastname: '',
    number: 0,
    position: '',
    roles: ['player'],
};

export const schema = yup.object({
    user_id: yup.string(),
    firstname: yup.string(),
    lastname: yup.string(),
    number: yup.number(),
    position: yup.string(),
    role: yup.string(),
});

const memberSearch = async ({signal, teamId}) => {
    const res = await axios.get(CONTROL_URL + `user/team/${teamId}/member/`, {signal});

    return res.data;
};

export const useApiQuery = ({teamId}) => {
    const {isAuthenticated} = useUserUtils();

    return useQuery({
        queryKey: ['client-team-members', teamId],
        queryFn: ({signal}) => memberSearch({signal, teamId: teamId}),
        enabled: isAuthenticated && !!teamId,
        placeholderData: [],
    });
};

export const groupByPosition = (members, withOfficials = false) => {
    const res = {
        goal: [],
        defense: [],
        midfield: [],
        attack: [],
        no_position: [],
        officials: [],
    };

    for (const member of members.sort((a, b) => a.number > b.number ? 1 : -1)) {
        if (member.roles.includes('player')) {
            if (Array.isArray(res[member.position])) {
                res[member.position].push(member);
            } else {
                res.no_position.push(member);
            }
        }

        if (['official', 'trainer'].some(e => member.roles.includes(e))) {
            res.officials.push(member);
        }
    }

    if (!withOfficials) {
        delete res.officials;
    }

    return res;
};

export const useApiAdd = () => {
    const {loading, fetch, data, error} = useFetcher(CONTROL_URL + 'user/team/{teamId}/member/', {
        method: 'POST',
    }, defaultData, true);

    const add = useCallback(async (teamId, data) => {
        const res = await fetch({
            data,
            urlParams: {
                teamId
            }
        });

        if (isOk(res) && typeof res.data === 'object') {
            return res.data;
        }
    }, [fetch]);

    return {add, loading, data, errors: error.errors};
};

export const useApiDelete = () => {
    const {loading, fetch} = useFetcher(CONTROL_URL + `user/team/{teamId}/member/{id}`, {
        method: 'DELETE',
    }, undefined, true);

    const del = useCallback(async (teamId, id) => {
        const res = await fetch({
            urlParams: {
                teamId,
                id
            },
        });

        if (isOk(res)) {
            return true;
        }
    }, [fetch]);

    return {del, loading};
};

export const useApiUpdate = (defaultData) => {
    const {loading, fetch, data, error} = useFetcher(CONTROL_URL + 'user/team/{teamId}/member/', {
        method: 'PATCH',
    }, defaultData, true);

    const update = useCallback(async (teamId, data) => {
        const res = await fetch({
            data,
            urlParams: {
                teamId
            }
        });

        if (isOk(res) && typeof res.data === 'object') {
            return res.data;
        }
    }, [fetch]);

    return {update, loading, data, errors: error.errors};
};

export const useApiInviteToken = () => {
    const {loading, fetch, data, error} = useFetcher(CONTROL_URL + 'user/team/{id}/invite-token', {
        method: 'GET',
    }, undefined, true);

    const invite = useCallback(async (id) => {
        const res = await fetch({
            urlParams: {
                id
            },
        });

        if (isOk(res) && typeof res.data === 'object') {
            return res.data;
        }
    }, [fetch]);

    return {inviteToken: invite, loading, data, errors: error.errors};
};

export const useApiInvite = () => {
    const {loading, fetch, error} = useFetcher(CONTROL_URL + 'user/team/{id}/invite', {
        method: 'POST',
    }, undefined, true);

    const invite = useCallback(async (id, data) => {
        const res = await fetch({
            urlParams: {
                id
            },
            data
        });

        if (isOk(res)) {
            return true;
        }
    }, [fetch]);

    return {invite, loading, errors: error.errors};
};
