import React, {useEffect, useState} from 'react';

import {Chip} from '@mui/material';

import {useApiUserQuery} from 'module/client/useClientApi';
import {useUserUtils} from 'module/user/useUser';

import {AutocompleteField} from 'form';

const UserSelectOnDemand = ({clientId, multiple = false, ...params}) => {
    const [isOpen, setIsOpen] = useState(false);

    const {isOwner} = useUserUtils();

    const {data, refetch, isFetching} = useApiUserQuery({enabled: true, clientId});

    const getUserLabel = (usr) => {
        if (!usr) {
            return '';
        }

        return `${usr.firstname} ${usr.lastname}, ${usr.username}`;
    };

    useEffect(() => {
        if (isOpen) {
            refetch();
        }
    }, [isOpen, refetch]);

    return <AutocompleteField
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        mapValue={v => {
            if (!multiple) {
                return v;
            }

            return v instanceof Object ? v.id : v;
        }}
        loading={isFetching}
        multiple={multiple}
        options={data}
        renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
                const {key, onDelete, ...tagProps} = getTagProps({index});
                const usr = data.find(o => o.id === option);
                if (!usr) {
                    return null;
                }

                const doDelete = isOwner(option) ? undefined : onDelete;

                return <Chip key={key} label={getUserLabel(usr)} {...tagProps} onDelete={doDelete}/>;
            })
        }
        isOptionEqualToValue={(option, selected) => {
            const id = selected instanceof Object ? selected.id : selected;
            return option.id === id;
        }}
        getOptionLabel={usr => {
            if (!multiple) {
                return getUserLabel(usr);
            }

            // when opening the box, an object id passed
            if (usr instanceof Object) {
                return getUserLabel(usr);
            }

            // when rendering the selected only the id is passed
            return getUserLabel(data.find(o => o.id === usr));
        }}
        {...params}
    />;
};

export default UserSelectOnDemand;
